import React, { useEffect, useState } from "react";
import LeftMenuSidebar from "../../../unicomponents/LeftMenuSidebar";
import { useLocation } from "react-router-dom";
import useGroups from "../../../hooks/useGroups";
import AuthoritySetting from "./components/AuthoritySetting";
import { useTranslation } from "react-i18next";

export default function GroupsAuthorities() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];

  const { t } = useTranslation();

  const [activeMenu, setActiveMenu] = useState({
    menu_name: "teacher",
    menu_id: "teacher",
  });

  const menuData = [
    { group_name: t("common.user.faculty") || "교직원", group_type: "teacher" },
    // { group_name: {t("common.user.student") || "학생"}, group_type: "student" },
    // { group_name: "학부모", group_type: "parent" },
  ];

  const [groupsQueryConditions, setGroupsQueryConditions] = useState({
    schoolid: schoolId,
    group_type: "teacher",
  });

  const {
    groupsQuery: { data: groupsData },
  } = useGroups(groupsQueryConditions);

  useEffect(() => {
    setGroupsQueryConditions((prev) => ({
      ...prev,
      group_type: activeMenu.menu_name,
    }));
  }, [activeMenu]);

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <LeftMenuSidebar
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              menuData={menuData}
              // extraFunction={handleQueryCondition}
              itemName="group_type"
              itemLabel="group_name"
            />
          </div>
          <div className="col-12 col-md-10">
            <AuthoritySetting groupsData={groupsData} schoolId={schoolId} />
            {/* {groupsData && renderComponent()} */}
          </div>
        </div>
      </div>
    </div>
  );
}
