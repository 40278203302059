import React, { useEffect, useState } from "react";
import useEducationalActivities from "../../../hooks/useEducationalActivities";
import { useUIContext } from "../../../context/UIContext";
import { useAuthContext } from "../../../context/AuthContext";
import { MdOutlineCancel } from "react-icons/md";
import { useTranslation } from "react-i18next";

export default function AddActivitiesByFields({
  conditions,
  schoolYearId,
  schoolStageId,
  user,
  activeMenu,
  tagList = [],
  thisYearTerm,
  selectedTerm,
}) {
  const { t } = useTranslation();

  const optionCategory =
    activeMenu?.options?.[0]?.[conditions?.schoolid]?.category || "";

  const optionEndDate =
    activeMenu?.options?.[0]?.[activeMenu?.schoolid]?.endDate || "";

  const optionHours =
    activeMenu?.options?.[0]?.[activeMenu?.schoolid]?.hours || "";

  const optionTermName =
    activeMenu?.options?.[0]?.[activeMenu?.schoolid]?.termName || "";

  const optionTags =
    activeMenu?.options?.[0]?.[activeMenu?.schoolid]?.tags || "";

  const [formData, setFormData] = useState({
    schoolid: conditions.schoolid,
    school_year_id: schoolYearId,
    hierarchy_one: "0",
    title: "",
    content: "",
    start_date: "",
    creator_username: user?.username,
    activity_type_id: 6,
    extra_attributes: {},
    tags: [],
  });
  //submit 누르면 로딩 뜨도록
  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();
  const { hasPermission } = useAuthContext();

  //submit를 위한
  const { createEducationalActivities } = useEducationalActivities(conditions);

  const [tag, setTag] = useState("");
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (selectedTerm && optionTermName) {
      setFormData((formData) => ({
        ...formData,
        extra_attributes: {
          ...formData.extra_attributes,
          termName: selectedTerm,
        },
      }));
    }
  }, [selectedTerm, thisYearTerm, optionTermName]);

  const handleInputChange = (event) => {
    const { name, value, id } = event.target;

    if (name === "extra_attributes") {
      setFormData((formData) => ({
        ...formData,
        [name]: {
          ...formData[name],
          [id]: value,
        },
      }));
    } else if (name === "tags") {
      setTag(value);
      const newTag = { [value]: "3" };
      const isTagSelected = formData.tags.some((tag) =>
        tag.hasOwnProperty(value)
      );
      if (!isTagSelected && value !== "") {
        setFormData((formData) => ({
          ...formData,
          tags: [...formData.tags, newTag], // 새 태그 객체를 배열에 추가
        }));
      }
    } else {
      setFormData((formData) => ({
        ...formData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Process the formData here

    const submitData = {
      ...formData,
      school_year_id: schoolYearId,
      hierarchy_one: schoolStageId,
      creator_username: user?.username,
    };

    if (!hasPermission(submitData?.creator_username, "can_create")) {
      handleToastCenterTop(t("common.messages.ownerPermission"));
      return;
    }

    setIsLoadingModal(true);
    createEducationalActivities.mutate(submitData, {
      onSuccess: () => {
        setIsLoadingModal(false);
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
        }, 2000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error?.message);
      },
    });
  };

  const handleClick = (tagKey) => {
    setFormData((formData) => ({
      ...formData,
      tags: formData.tags.filter((tag) => !tag.hasOwnProperty(tagKey)),
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="container-fluid">
      <h3 className="fw-bold">
        {activeMenu?.activity_name && activeMenu.activity_name}
      </h3>
      <table
        className="table"
        style={
          activeMenu?.options?.length > 0 &&
          activeMenu?.options[0]?.[conditions.schoolid]
            ? { fontSize: "smaller" }
            : {}
        }
      >
        <thead>
          <tr className="table-secondary">
            {optionCategory && (
              <th style={{ width: "100px" }}>{t("common.labels.category")}</th>
            )}
            <th style={{ minWidth: "80px", width: "160px" }}>
              {t("common.labels.activityName")}
            </th>
            <th style={{ width: "100px" }}>{t("common.labels.startDate")}</th>
            {optionEndDate && (
              <th style={{ width: "100px" }}>{t("common.labels.endDate")}</th>
            )}
            {optionHours && (
              <th style={{ width: "80px" }}>{t("common.labels.hours")}</th>
            )}
            <th style={{ minWidth: "200px" }}>{t("common.labels.content")}</th>
            {optionTags && (
              <>
                <th className="ps-0 pe-0" style={{ minWidth: "65px" }}>
                  {t("common.labels.selectTag")}
                </th>
                <th>{t("common.labels.tag")}</th>
              </>
            )}
            <th style={{ width: "60px" }}>
              {t("common.labels.add") || "추가"}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {optionCategory && (
              <td>
                <select
                  className="select-like-span"
                  name="extra_attributes"
                  id="category"
                  value={formData.extra_attributes?.category}
                  onChange={handleInputChange}
                  required
                >
                  <option value=""></option>
                  {optionCategory.map((category, key) => (
                    <option key={key} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </td>
            )}
            <td className="p-0">
              <textarea
                type="text"
                className="input-like-span"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                rows={4}
                required
              />
            </td>
            <td>
              <input
                type="date"
                className="input-like-span"
                name="start_date"
                value={formData.start_date}
                onChange={handleInputChange}
                style={{ width: "105px" }}
              />
            </td>
            {optionEndDate && (
              <td>
                <input
                  type="date"
                  className="input-like-span"
                  name="extra_attributes"
                  id="end_date"
                  value={formData.extra_attributes?.end_date}
                  onChange={handleInputChange}
                  style={{ width: "105px" }}
                />
              </td>
            )}
            {optionHours && (
              <td>
                <input
                  type="text"
                  className="input-like-span"
                  name="extra_attributes"
                  id="hours"
                  value={formData.extra_attributes?.hours}
                  onChange={handleInputChange}
                />
              </td>
            )}
            <td className="p-0">
              <textarea
                type="text"
                className="input-like-span"
                name="content"
                value={formData.content}
                onChange={handleInputChange}
                rows={4}
              />
            </td>
            {optionTags && (
              <>
                <td>
                  <select
                    className="select-like-span"
                    name="tags"
                    value={tag}
                    onChange={handleInputChange}
                  >
                    <option value="">
                      {t("common.labels.select") || "선택"}
                    </option>
                    {tagList &&
                      tagList[0]?.tags.map((tag, index) => (
                        <option key={index} value={tag}>
                          {tag}
                        </option>
                      ))}
                  </select>
                </td>
                <td width={128}>
                  {formData.tags.map((tagObj, index) => {
                    const [tagKey] = Object.keys(tagObj); // 객체에서 키 추출
                    return (
                      <span key={index}>
                        {tagKey}
                        <MdOutlineCancel
                          role="button"
                          onClick={() => {
                            handleClick(tagKey); // 태그 삭제 함수 호출
                          }}
                        />
                      </span>
                    );
                  })}
                </td>
              </>
            )}
            <td width={60} rowSpan={2} valign="middle">
              <button type="submit" className="btn btn-sm p-1 btn-primary">
                {isSaved
                  ? t("common.labels.complete") || "완료"
                  : t("common.labels.add") || "추가"}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
}
