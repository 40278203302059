import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DynamicTable from "../../../../../unicomponents/Tables/DynamicTable";
import useLessonStudents from "../../../../../hooks/useLessonStudentData";
import DetailedGrades from "./DetailedGrades";
export default function StudentGrades({ keyData, currentPeriod }) {
  const [lessonStudentsArray, setLessonStudentsArray] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);

  const { getAllLessonStudentsByStudentId } = useLessonStudents();
  const getAllLessonStudentsByStudentIdRef = useRef(
    getAllLessonStudentsByStudentId
  );

  const { t } = useTranslation();

  //어떤 항목을 보이게 할지 학교별로 옵션 넣을 수 있게 해야겠군
  //평가항목별일 경우에만 row를 클릭하면 오른쪽에 평가항목별이 보이도록 해야겠군
  //밑에 통계도 넣으면 좋겠는데(평균), 시수도
  //extra_attributes와 eval_area_weight도 옵션으로 넣을 수 있겠구나.
  //지금 다른 학교가 요구하지도 않는데 괜히 만들지 말고, 요구할 때 그때 확장하자.

  // 테이블 설정
  const tableConfig = {
    style: { fontSize: "14px" }, // 폰트 크기만 커스텀
    headers: [
      {
        label: t("student.studentGrades.subjectName"),
        style: { width: "200px" },
        sortField: "subject_name",
      },
      {
        label: t("student.studentGrades.lessonHours"),
        style: { width: "80px" },
      },
      {
        label: t("student.studentGrades.teacher"),
        style: { width: "150px" },
      },
      {
        label: t("student.studentGrades.score"),
        style: { width: "80px" },
      },
    ],
    columns: [
      {
        valueKey: "lesson.subject_name",
      },
      {
        valueKey: "lesson.lesson_hours",
        cellStyle: { textAlign: "center" },
      },
      {
        valueKey: "lesson.teachers",
        valueFormatter: (value) => {
          // 날짜 형식 변환 (YYYY-MM-DD → YYYY.MM.DD)
          if (!value) return "";

          return value.map((teacher) => teacher.tname).join(", ");
        },
        cellStyle: { textAlign: "center" },
      },
      {
        valueKey: "grade",
        cellStyle: { textAlign: "center" },
      },
    ],
    rows: lessonStudentsArray,
  };

  useEffect(() => {
    if (
      keyData?.username &&
      currentPeriod &&
      Object.keys(currentPeriod).length > 0
    ) {
      const forWhere = {
        schoolid: keyData?.schoolid,
        username: keyData?.username,
      };

      getAllLessonStudentsByStudentIdRef.current.mutate(forWhere, {
        onSuccess: (data) => {
          if (data && data.length > 0) {
            const filteredData = data.filter(
              (item) =>
                item.school_year_id ===
                  keyData?.schoolYearDates[0]?.school_year_id &&
                item.lesson?.term_id === currentPeriod?.id
            );
            setLessonStudentsArray(filteredData);
            handleClearSelection();
          }
        },
        onError: (error) => {
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(t("common.messages.errorAlert") + error.message);
        },
      });
    } else {
      setLessonStudentsArray([]);
    }
  }, [currentPeriod, keyData, t]);

  const handleRowClick = (item) => {
    setSelectedLesson(item);
  };

  // 특정 버튼 클릭 시 선택 초기화 예시
  const handleClearSelection = () => {
    setSelectedRowId(null);
    setSelectedLesson(null);
  };
  return (
    <div>
      <div className="ms-5">
        <h3>{t("student.studentGrades.title")}</h3>
      </div>

      <div className="row mt-5">
        <div className="col-5 me-2">
          <DynamicTable
            tableConfig={tableConfig}
            dataStructure="flat"
            tableStyle="cleanModern"
            // 페이지네이션 활성화
            // pagination={true}
            // pageSize={5} // 페이지당 5개 항목 표시
            // initialPage={1} // 초기 페이지 설정
            // 정렬 활성화
            sortable={false}
            initialSortField="subject_name"
            initialSortDirection="asc"
            // 검색 기능 활성화
            // searchable={true}
            // searchPlaceholder="날짜, 출석 검색..."
            // searchFields={[
            //   "date", // input 필드
            //   "attendance_status",
            // ]}
            // Excel 내보내기 기능 활성화
            // exportable={true}
            // exportFileName="출석이당"
            // exportButtonLabel="Excel"
            onRowClick={handleRowClick}
            rowClickable={true}
            selectedRowId={selectedRowId}
            setSelectedRowId={setSelectedRowId}
          />
        </div>
        <div className="col-6">
          {selectedLesson && <DetailedGrades selectedLesson={selectedLesson} />}
        </div>
      </div>
    </div>
  );
}
