import React, { useEffect, useRef, useState } from "react";
import {
  averageAllNumbersOfObject,
  sumAllNumbersOfObject,
} from "../../../../util/dealingArrObj";
import { useUIContext } from "../../../../context/UIContext";
import PromptModal from "../../../../unicomponents/PromptModal";
import { useAuthContext } from "../../../../context/AuthContext";
import useFlexibleData from "../../../../hooks/useFlexibleData";
import { gradeToConvert100 } from "../../../../util/specialCase";
import { useTranslation } from "react-i18next";

export default function LessonStudentsItemsTable({
  lessonStudentsData,
  setLessonStudentsData,
  lessonSetting,
  updateLessonStudents,
  updateLessonStudentsBulk,
  deleteLessonStudents,
  lessonInfo,
  evalItemsData,
}) {
  /*
생각해 보자. level1 행을 찾아서 td 1개 만든다. 
그 level1을 parent_id를 갖고 있는 level2를 찾아서 그 옆에 td를 1번 추가한다. 없으면 빈 td 2개 추가한다.
 parent_id가 같은 level2가 또 있으면 tr를 1개 추가한다. 앞에 td는 빈칸 
추가된 level2를 parent_id를 갖고 있는 level3를 찾아서 그 옆에 td를 추가한다. 없으면 빈 td 1개 추가한다.
 parent_id가 같은 level3가 또 있으면 tr를 1개 추가한다. 앞에 td 2개는 빈칸 

좋아 됐다.

이제 해야 할 것은..
1. 저장된 성적이 표시되도록
2. 정보 입력 하는 것에 따라서 학생별로 state에 기록되도록
3. 저장버튼 누르면 해당 학생의 성적이 create (저장) 또는 업데이트 되도록
4. 모두 저장 누르면 수정 경험이 있는 애들 것 모두 저장 되도록...
5. 기타 스타일 좀 손 보고.... input, select 등 eval type에 따라서 다르게 나오는 것 하면 되겠군

 */

  const { t } = useTranslation();

  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();
  const [savedRows, setSavedRows] = useState([]);
  const [savedBulk, setSavedBulk] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [dataForPromptModal, setDataForPromptModal] = useState({});
  const [confirmDelete, setConfirmDelete] = useState([]);

  const { user, hasPermission } = useAuthContext();
  const {
    flexibleDataQuery: { data: flexibleData },
  } = useFlexibleData({
    schoolid: lessonSetting[0]?.schoolid,
    main_category: "convert100",
  });

  const convertCondition =
    lessonInfo && lessonInfo.extra_attributes?.subjectSort === "P/F"
      ? "P/F"
      : lessonInfo.hierarchyOne;

  const initiatePromptModal = (data) => {
    setModalOpen(true);
    setDataForPromptModal(data);
  };

  const handlePromptModalSubmit = (dataForPromptModal) => {
    const { id, keyName, title, upperKey, ...updatedData } = dataForPromptModal;

    setLessonStudentsData((currentDataSets) =>
      currentDataSets.map((dataSet) => {
        if (dataSet.id !== id) return dataSet;

        if (upperKey) {
          return {
            ...dataSet,
            [upperKey]: {
              ...dataSet[upperKey],
              ...updatedData,
            },
            isModified: true,
          };
        }

        return { ...dataSet, ...updatedData, isModified: true };
      })
    );
  };

  const closeModal = () => setModalOpen(false);

  // 일반적인 row 데이터 변경을 처리하는 함수
  const handleTdChange = (username, evalItemId, event) => {
    const { value } = event.target;
    const field = event.target.name;
    let updatedRows = [...lessonStudentsData];

    //lessonid와 username으로 찾은 후 eval_items_grades에서 evalItems id를 key 값으로
    if (field === "eval_item_grades") {
      updatedRows = updatedRows.map((row) => {
        if (row.lesson_id === lessonInfo.id && row.username === username) {
          const prev = row.eval_item_grades || {};
          return {
            ...row,
            eval_item_grades: {
              ...prev,
              [evalItemId]: value,
            },
            isModified: true,
          };
        }
        return row;
      });
      setLessonStudentsData(updatedRows);
      // console.log("updatedRows", updatedRows);
    } else if (field === "extra_data") {
      updatedRows = updatedRows.map((row) => {
        if (row.lesson_id === lessonInfo.id && row.username === username) {
          const prev = row.extra_data || {};
          return {
            ...row,
            extra_data: {
              ...prev,
              [evalItemId]: value,
            },
            isModified: true,
          };
        }
        return row;
      });
      setLessonStudentsData(updatedRows);
    }
  };

  // 태그 변경을 처리하는 함수
  const handleTagChange = (tagName, event, username, tagIndex) => {
    const { value } = event.target; // 선택된 새로운 태그 값
    const updatedRows = lessonStudentsData.map((row) => {
      if (row.lesson_id === lessonInfo.id && row.username === username) {
        const updatedTags = row.tags.map((tag, idx) => {
          if (idx === tagIndex) {
            return { [tagName]: value }; // 태그 값을 업데이트
          }
          return tag;
        });

        return { ...row, tags: updatedTags, isModified: true };
      }
      return row;
    });

    // console.log("updatedRows", updatedRows);
    setLessonStudentsData(updatedRows); // 상태 업데이트
  };

  function renderTd(
    evaluationType,
    eval_item_grades,
    evalItemId,
    username,
    student
  ) {
    const uniqueKey = `${username}-${evalItemId}-${evaluationType}`;
    switch (evaluationType) {
      case "100점제":
        return (
          <td key={uniqueKey} className="text-center">
            <input
              className="input-like-span"
              type="number"
              name="eval_item_grades"
              onChange={(e) => handleTdChange(username, evalItemId, e)}
              value={eval_item_grades?.[evalItemId] ?? ""}
              autoComplete="off"
            />
            {lessonSetting
              ?.find((item) => item.item_code === "evalItem")
              ?.detailed_setting?.includes("피드백") && (
              <button
                className={`btn btn-sm ${
                  student?.extra_data?.[evalItemId]
                    ? "btn-outline-success"
                    : "btn-outline-secondary"
                } ps-1 p-0 m-0`}
                style={{
                  fontSize: "11px",
                  minWidth: "50px",
                  maxWidth: "60px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onClick={(e) =>
                  initiatePromptModal({
                    id: student.id,
                    [evalItemId]: student?.extra_data?.[evalItemId],
                    keyName: evalItemId,
                    title: t("teacher.lessonRelated.feedback"),
                    upperKey: "extra_data",
                  })
                }
              >
                {student?.extra_data?.[evalItemId] ??
                  t("teacher.lessonRelated.feedback")}
              </button>
            )}
          </td>
        );

      case "이수제":
        return (
          <td key={uniqueKey} className="text-center">
            <select
              className="select-like-span"
              name="eval_item_grades"
              onChange={(e) => handleTdChange(username, evalItemId, e)}
              value={eval_item_grades?.[evalItemId] ?? ""}
            >
              <option></option>
              <option value="pass">{t("teacher.lessonRelated.pass")}</option>
              <option value="fail">{t("teacher.lessonRelated.fail")}</option>
            </select>
            {lessonSetting
              ?.find((item) => item.item_code === "evalItem")
              ?.detailed_setting?.includes("피드백") && (
              <button
                className={`btn btn-sm ${
                  student?.extra_data?.[evalItemId]
                    ? "btn-outline-success"
                    : "btn-outline-secondary"
                } ps-1 p-0 m-0`}
                style={{
                  fontSize: "11px",
                  minWidth: "50px",
                  maxWidth: "60px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onClick={(e) =>
                  initiatePromptModal({
                    id: student.id,
                    [evalItemId]: student?.extra_data?.[evalItemId],
                    keyName: evalItemId,
                    title: t("teacher.lessonRelated.feedback"),
                    upperKey: "extra_data",
                  })
                }
              >
                {student?.extra_data?.[evalItemId] ??
                  t("teacher.lessonRelated.feedback")}
              </button>
            )}
          </td>
        );
      case "3단평가":
        return (
          <td key={uniqueKey} className="text-center">
            <select
              className="select-like-span"
              name="eval_item_grades"
              onChange={(e) => handleTdChange(username, evalItemId, e)}
              value={eval_item_grades?.[evalItemId] ?? ""}
            >
              <option value="3">{t("teacher.lessonRelated.good")}</option>
              <option value="2">{t("teacher.lessonRelated.normal")}</option>
              <option value="1">{t("teacher.lessonRelated.effort")}</option>
              <option value="0">{t("teacher.lessonRelated.notDone")}</option>
              <option value=""></option>
            </select>
            {lessonSetting
              ?.find((item) => item.item_code === "evalItem")
              ?.detailed_setting?.includes("피드백") && (
              <button
                className={`btn btn-sm ${
                  student?.extra_data?.[evalItemId]
                    ? "btn-outline-success"
                    : "btn-outline-secondary"
                } ps-1 p-0 m-0`}
                style={{
                  fontSize: "11px",
                  minWidth: "50px",
                  maxWidth: "60px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onClick={(e) =>
                  initiatePromptModal({
                    id: student.id,
                    [evalItemId]: student?.extra_data?.[evalItemId],
                    keyName: evalItemId,
                    title: t("teacher.lessonRelated.feedback"),
                    upperKey: "extra_data",
                  })
                }
              >
                {student?.extra_data?.[evalItemId] ??
                  t("teacher.lessonRelated.feedback")}
              </button>
            )}
          </td>
        );

      case "ABC":
        return (
          <td key={uniqueKey} className="text-center">
            <select
              className="select-like-span"
              name="eval_item_grades"
              onChange={(e) => handleTdChange(username, evalItemId, e)}
              value={eval_item_grades?.[evalItemId] ?? ""}
            >
              <option value=""></option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
              <option value="F">F</option>
            </select>
            {lessonSetting
              ?.find((item) => item.item_code === "evalItem")
              ?.detailed_setting?.includes("피드백") && (
              <button
                className={`btn btn-sm ${
                  student?.extra_data?.[evalItemId]
                    ? "btn-outline-success"
                    : "btn-outline-secondary"
                } ps-1 p-0 m-0`}
                style={{
                  fontSize: "11px",
                  minWidth: "50px",
                  maxWidth: "60px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onClick={(e) =>
                  initiatePromptModal({
                    id: student.id,
                    [evalItemId]: student?.extra_data?.[evalItemId],
                    keyName: evalItemId,
                    title: t("teacher.lessonRelated.feedback"),
                    upperKey: "extra_data",
                  })
                }
              >
                {student?.extra_data?.[evalItemId] ??
                  t("teacher.lessonRelated.feedback")}
              </button>
            )}
          </td>
        );
      case "직접입력":
        return (
          <td key={uniqueKey} className="text-center">
            <input
              className="input-like-span"
              type="text"
              name="eval_item_grades"
              onChange={(e) => handleTdChange(username, evalItemId, e)}
              value={eval_item_grades?.[evalItemId] ?? ""}
              autoComplete="off"
            />
            {lessonSetting
              ?.find((item) => item.item_code === "evalItem")
              ?.detailed_setting?.includes("피드백") && (
              <button
                className={`btn btn-sm ${
                  student?.extra_data?.[evalItemId]
                    ? "btn-outline-success"
                    : "btn-outline-secondary"
                } ps-1 p-0 m-0`}
                style={{
                  fontSize: "11px",
                  minWidth: "50px",
                  maxWidth: "60px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onClick={(e) =>
                  initiatePromptModal({
                    id: student.id,
                    [evalItemId]: student?.extra_data?.[evalItemId],
                    keyName: evalItemId,
                    title: t("teacher.lessonRelated.feedback"),
                    upperKey: "extra_data",
                  })
                }
              >
                {student?.extra_data?.[evalItemId] ??
                  t("teacher.lessonRelated.feedback")}
              </button>
            )}
          </td>
        );
      case "5단평가":
        return (
          <td key={uniqueKey} className="text-center">
            <select
              className="select-like-span"
              name="eval_item_grades"
              onChange={(e) => handleTdChange(username, evalItemId, e)}
              value={eval_item_grades?.[evalItemId] ?? ""}
            >
              <option value="5">5</option>
              <option value="4">4</option>
              <option value="3">3</option>
              <option value="2">2</option>
              <option value="1">1</option>
              <option value="0">0</option>
              <option value=""></option>
            </select>
            {lessonSetting
              ?.find((item) => item.item_code === "evalItem")
              ?.detailed_setting?.includes("피드백") && (
              <button
                className={`btn btn-sm ${
                  student?.extra_data?.[evalItemId]
                    ? "btn-outline-success"
                    : "btn-outline-secondary"
                } ps-1 p-0 m-0`}
                style={{
                  fontSize: "11px",
                  minWidth: "50px",
                  maxWidth: "60px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onClick={(e) =>
                  initiatePromptModal({
                    id: student.id,
                    [evalItemId]: student?.extra_data?.[evalItemId],
                    keyName: evalItemId,
                    title: t("teacher.lessonRelated.feedback"),
                    upperKey: "extra_data",
                  })
                }
              >
                {student?.extra_data?.[evalItemId] ??
                  t("teacher.lessonRelated.feedback")}
              </button>
            )}
          </td>
        );
      default:
        return (
          <td key={uniqueKey} className="text-center">
            {t("common.status.error")}
          </td>
        );
    }
  }

  const findItems = (parentId, level) =>
    evalItemsData.filter(
      (item) => item.hierarchy_level === level && item.parent_id === parentId
    );

  const findByLevels = (level) =>
    evalItemsData.filter((item) => item.hierarchy_level === level);

  const checkEvalTypes = (evalTypes) =>
    evalTypes.some((evalType) =>
      evalItemsData.every((item) => item.evaluation_type === evalType)
    );

  const renderTableRows = () => {
    const level1Items = findItems(0, 1);
    return level1Items.flatMap((level1) => {
      const level2Items = findItems(level1.id, 2);
      if (level2Items.length === 0) {
        const rowKey = `level1-${level1.id}`;
        return (
          <tr key={rowKey}>
            <td style={{ ...getStickyColumnStyle(0) }}>
              {level1.content}
              {level1.extra_data?.weight && (
                <>
                  <br />
                  {t("teacher.lessonRelated.weight")}:{" "}
                  {level1.extra_data.weight}%
                </>
              )}
              {level1.extra_data?.total && !level2Items.length && (
                <>
                  <br />
                  {t("teacher.lessonRelated.totalScore")}:{" "}
                  {level1.extra_data.total}
                  <button
                    className="btn btn-sm btn-outline-secondary pt-0 pb-0 ps-2 pe-2"
                    onClick={() => handleAllClick(level1, 1)}
                  >
                    All
                  </button>
                </>
              )}
            </td>
            {findByLevels(2).length > 0 && (
              <td style={{ ...getStickyColumnStyle(1) }}></td>
            )}
            {findByLevels(3).length > 0 && (
              <td style={{ ...getStickyColumnStyle(2) }}></td>
            )}
            {lessonStudentsData?.map((student) => {
              return renderTd(
                level1.evaluation_type,
                student.eval_item_grades,
                level1.id,
                student.username,
                student
              );
            })}
          </tr>
        );
      } else {
        return level2Items.flatMap((level2, level2Index) => {
          const level3Items = findItems(level2.id, 3);
          if (level3Items.length === 0) {
            const rowKey = `level2-${level2.id}-${level2Index}`;

            return (
              <tr key={rowKey}>
                <td style={{ ...getStickyColumnStyle(0) }}>
                  {level2Index === 0 ? (
                    <>
                      {level1.content}
                      {level1.extra_data?.weight && (
                        <>
                          <br />
                          {t("teacher.lessonRelated.weight")}:{" "}
                          {level1.extra_data.weight}%
                        </>
                      )}
                      {level1.extra_data?.total && !level2Items.length && (
                        <>
                          <br />
                          {t("teacher.lessonRelated.totalScore")}:{" "}
                          {level1.extra_data.total}
                          <button
                            className="btn btn-sm btn-outline-secondary pt-0 pb-0 ps-2 pe-2"
                            onClick={() => handleAllClick(level1, 1)}
                          >
                            All
                          </button>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </td>
                <td style={{ ...getStickyColumnStyle(1) }}>
                  {level2.content}
                  {level2.extra_data?.weight && (
                    <>
                      <br />
                      {t("teacher.lessonRelated.weight")}:{" "}
                      {level2.extra_data.weight}%
                    </>
                  )}
                  {level2.extra_data?.total && !level3Items.length && (
                    <>
                      <br />
                      {t("teacher.lessonRelated.totalScore")}:{" "}
                      {level2.extra_data.total}
                      <button
                        className="btn btn-sm btn-outline-secondary pt-0 pb-0 ps-2 pe-2"
                        onClick={() => handleAllClick(level2, 2)}
                      >
                        All
                      </button>
                    </>
                  )}
                </td>
                {findByLevels(3).length > 0 && (
                  <td style={{ ...getStickyColumnStyle(2) }}></td>
                )}

                {lessonStudentsData?.map((student) => {
                  return renderTd(
                    level2.evaluation_type,
                    student.eval_item_grades,
                    level2.id,
                    student.username,
                    student
                  );
                })}
              </tr>
            );
          } else {
            return level3Items.flatMap((level3, level3Index) => {
              const rowKey = `level3-${level3.id}-${level3Index}`;

              return (
                <tr key={rowKey}>
                  <td style={{ ...getStickyColumnStyle(0) }}>
                    {level2Index === 0 && level3Index === 0 ? (
                      <>
                        {level1.content}
                        {level1.extra_data?.weight && (
                          <>
                            <br />
                            {t("teacher.lessonRelated.weight")}:{" "}
                            {level1.extra_data.weight}%
                          </>
                        )}
                        {level1.extra_data?.total && !level2Items.length && (
                          <>
                            <br />
                            {t("teacher.lessonRelated.totalScore")}:{" "}
                            {level1.extra_data.total}
                            <button
                              className="btn btn-sm btn-outline-secondary pt-0 pb-0 ps-2 pe-2"
                              onClick={() => handleAllClick(level1, 1)}
                            >
                              All
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                  <td style={{ ...getStickyColumnStyle(1) }}>
                    {level3Index === 0 ? (
                      <>
                        {level2.content}
                        {level2.extra_data?.weight && (
                          <>
                            <br />
                            {t("teacher.lessonRelated.weight")}:{" "}
                            {level2.extra_data.weight}%
                          </>
                        )}
                        {level2.extra_data?.total && !level3Items.length && (
                          <>
                            <br />
                            {t("teacher.lessonRelated.totalScore")}:{" "}
                            {level2.extra_data.total}
                            <button
                              className="btn btn-sm btn-outline-secondary pt-0 pb-0 ps-2 pe-2"
                              onClick={() => handleAllClick(level2, 2)}
                            >
                              All
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                  <td style={{ ...getStickyColumnStyle(2) }}>
                    {level3.content}
                    {level3.extra_data?.weight && (
                      <>
                        <br />
                        {t("teacher.lessonRelated.weight")}:{" "}
                        {level3.extra_data.weight}%
                      </>
                    )}
                    {level3.extra_data?.total && (
                      <>
                        <br />
                        {t("teacher.lessonRelated.totalScore")}:{" "}
                        {level3.extra_data.total}
                        <button
                          className="btn btn-sm btn-outline-secondary pt-0 pb-0 ps-2 pe-2"
                          onClick={() => handleAllClick(level3, 3)}
                        >
                          All
                        </button>
                      </>
                    )}
                  </td>
                  {lessonStudentsData?.map((student) => {
                    return renderTd(
                      level3.evaluation_type,
                      student.eval_item_grades,
                      level3.id,
                      student.username,
                      student
                    );
                  })}
                </tr>
              );
            });
          }
        });
      }
    });
  };

  const handleAllClick = (item, level) => {
    const total = item.extra_data?.total;
    if (!total) return;

    const updatedRows = lessonStudentsData.map((student) => {
      if (level === 1) {
        return {
          ...student,
          eval_item_grades: {
            ...student.eval_item_grades,
            [item.id]: total,
          },
          isModified: true,
        };
      } else if (level === 2) {
        return {
          ...student,
          eval_item_grades: {
            ...student.eval_item_grades,
            [item.id]: total,
          },
          isModified: true,
        };
      } else if (level === 3) {
        // level3일 때는 현재 선택된 항목만 total로 설정
        return {
          ...student,
          eval_item_grades: {
            ...student.eval_item_grades,
            [item.id]: total,
          },
          isModified: true,
        };
      }
      return student;
    });

    setLessonStudentsData(updatedRows);
  };

  //아... 드디어 save 만 하면 되는 것 같다...
  const saveStudentGrade = (student) => {
    // console.log("student", student);

    if (!student.isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop(t("common.messages.notModifed"));
      // setAlertModalState({
      //   visible: true,
      //   message: "테스트 해보자",
      // });
      return;
    }
    const { id, comment, eval_item_grades, tags, extra_data } = student;
    let grade = "";
    const evalArea100Setting = lessonSetting?.find(
      (r) => r.item_code === "evalArea100"
    )?.saved_setting;
    if (checkEvalTypes(["100점제"]) && evalArea100Setting === "원점수기록") {
      grade = calculateTotalScore(student, evalItemsData) || "";
    } else {
      grade = sumAllNumbersOfObject(student?.eval_item_grades || {}) || "";
    }

    const dataSet = { id, comment, eval_item_grades, tags, extra_data, grade };

    // convert100 데이터가 있을 때만 추가
    const convert100Setting = flexibleData?.find(
      (r) => r.sub_category === "평가항목별"
    )?.data_value;
    if (convert100Setting) {
      dataSet.convert100 = gradeToConvert100(
        grade,
        convert100Setting,
        convertCondition
      );
    }

    const checkOwner = lessonInfo.teachers.some(
      (teacher) => teacher.username === user.username
    );
    if (!checkOwner && !hasPermission(null, "can_update")) {
      handleToastCenterTop(t("common.messages.ownerUpdatePermission"));
      return;
    }

    setIsLoadingModal(true);
    updateLessonStudents.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, dataSet.id]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
    // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
    setLessonStudentsData((currentDataSets) =>
      currentDataSets.map((dataSet) =>
        dataSet.id === id ? { ...dataSet, isModified: false } : dataSet
      )
    );
  };

  const saveAllStudentsGrade = () => {
    const dataArray = lessonStudentsData
      .filter((data) => data.isModified === true)
      .map((student) => {
        const { id, comment, eval_item_grades, tags, extra_data } = student;

        let grade = "";
        const evalArea100Setting = lessonSetting?.find(
          (r) => r.item_code === "evalArea100"
        )?.saved_setting;
        if (
          checkEvalTypes(["100점제"]) &&
          evalArea100Setting === "원점수기록"
        ) {
          grade = calculateTotalScore(student, evalItemsData) || "";
        } else {
          grade = sumAllNumbersOfObject(student?.eval_item_grades || {}) || "";
        }

        const dataSet = {
          id,
          comment,
          eval_item_grades,
          tags,
          extra_data,
          grade,
        };

        // convert100 데이터가 있을 때만 추가
        const convert100Setting = flexibleData?.find(
          (r) => r.sub_category === "평가항목별"
        )?.data_value;
        if (convert100Setting) {
          dataSet.convert100 = gradeToConvert100(
            grade,
            convert100Setting,
            convertCondition
          );
        }

        return dataSet;
      });

    if (dataArray.length < 1) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop(t("common.messages.notModifed"));
      return;
    }

    const checkOwner = lessonInfo.teachers.some(
      (teacher) => teacher.username === user.username
    );
    if (!checkOwner && !hasPermission(null, "can_update")) {
      handleToastCenterTop(t("common.messages.ownerUpdatePermission"));
      return;
    }

    // 변경된 데이터 세트 처리 로직...
    // console.log("제출된 데이터 세트:", dataArray);
    setIsLoadingModal(true);
    updateLessonStudentsBulk.mutate(dataArray, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedBulk(true);
        setTimeout(() => {
          setSavedBulk(false);
        }, 1000);

        // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
        setLessonStudentsData((currentDataSets) =>
          currentDataSets.map((data) =>
            data.isModified === true ? { ...data, isModified: false } : data
          )
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleDelete = (row) => {
    const id = row.id;

    const checkOwner = lessonInfo.teachers.some(
      (teacher) => teacher.username === user.username
    );
    if (!checkOwner && !hasPermission(null, "can_update")) {
      handleToastCenterTop(t("common.messages.ownerUpdatePermission"));
      return;
    }

    setIsLoadingModal(true);
    deleteLessonStudents.mutate(id, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setConfirmDelete([]);
        setLessonStudentsData((rows) => rows.filter((row) => row.id !== id));
      },
      onError: (error) => {
        setIsLoadingModal(false);
        setConfirmDelete([]);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleCancelDelete = () => {
    setConfirmDelete([]);
  };

  const handleConfirmDelete = (row) => {
    setConfirmDelete((prev) => [...prev, row.id]);
  };

  /* 완전 복잡하군... 찬찬히 하나씩 적어보자. 
이 함수는 3단계 계층 구조(Level 1 > Level 2 > Level 3)의 평가 항목에 대해 다음과 같은 특징을 가진 점수 계산을 수행합니다:
각 레벨은 가중치를 가질 수 있음
빈 항목 제외 옵션 (excludeEmptyGrade)
두 가지 계산 방식:
maxAverage: 각 항목의 퍼센티지 평균
기본: (총점/총만점) * 100
가중치가 있는 경우와 없는 경우를 구분하여 처리
각 레벨의 가중치 합이 100인지 검증


flexibleData에서 covert100Setting 정보를 가져와 아래 2개의 유무를 결정한다.
calculType: 
  - maxAverage 그레이스기독학교의 해당 단계의 만점의 점수를 퍼센티지로 환산한 것이 점수이고,
그 점수를 해당 카테고리안에서 모두 더한 후 평균을 내는 방식이다.
  - 이거 없으면, 헤이븐이 사용하는 방식으로 한 카데고리 내에서 모든 입력 점수 나누기 모든 만점이다.

excludeEmptyGrade
 - true면, 헤이븐 학교에서 점수를 입력하지 않은 항목은, 만점을 모두 더하는 것에서 제외 된다.
 - false면, 입력 안 한 항목이 0점과 동일한 효과가 된다.

 - true이면서 maxAverage일 때, 평균 점수 낼 때 제외되도록 추가 개발해야 한다. 
*/

  function calculateTotalScore(student, evalItemsData) {
    // 설정 가져오기: 평가항목별 설정에서 계산 방식과 빈 항목 제외 여부 확인
    const covert100Setting = flexibleData?.find(
      (r) => r.sub_category === "평가항목별" && r.main_category === "convert100"
    )?.data_value;
    const calculType = covert100Setting?.calculType || ""; // maxAverage or 기본
    const excludeEmptyGrade = covert100Setting?.excludeEmptyGrade || false; // 빈 항목 제외 여부

    // 기본 유효성 검사: 필수 데이터 확인
    if (!student || !evalItemsData || !Array.isArray(evalItemsData)) {
      console.warn("Invalid input parameters for calculateTotalScore");
      return 0;
    }

    // 이수제 평가인 경우 빈 문자열 반환
    if (evalItemsData.some((item) => item.evaluation_type === "이수제")) {
      return "";
    }

    // 평가 점수가 없는 경우 빈 문자열 반환
    if (!student.eval_item_grades) return "";

    // Level 1 항목들만 필터링
    const level1Items = evalItemsData.filter(
      (item) => item.hierarchy_level === 1
    );
    if (level1Items.length === 0) return "";

    // 빈 값 체크 헬퍼 함수
    const isEmptyGrade = (gradeValue) => {
      return (
        gradeValue === "" || gradeValue === null || gradeValue === undefined
      );
    };

    let finalScore = 0; // 최종 점수
    let totalWeights = 0; // Level 1 가중치 합계

    // 여기 위까지 기본 설정 했음------------------------

    // Level 1 항목들 순회
    level1Items.forEach((level1Item) => {
      if (!level1Item?.id) return;

      // level1Item의 하위 항목인 Level 2 항목들 찾기
      const level2Items = evalItemsData.filter(
        (item) => item.hierarchy_level === 2 && item.parent_id === level1Item.id
      );

      // === Level 1 직접 평가 케이스 (하위 항목이 없는 경우) ===

      const level1Weight = Number(level1Item.extra_data?.weight || 0);
      if (!isNaN(level1Weight)) totalWeights += level1Weight;

      if (level2Items.length === 0) {
        //1단계의 점수
        const directScore = student.eval_item_grades?.[level1Item.id];
        //1단계의 만점
        const directTotal = level1Item.extra_data?.total;

        // 빈 항목 제외 설정이 true이고 점수가 비어있으면 건너뛰기
        //빈 항목 건너뛰기는 가중치가 있을 때는 작동할 수 없고, 1단계는 가중치가 필수이기 때문에 여기선 필요 없다.
        // if (excludeEmptyGrade && isEmptyGrade(directScore)) {
        //   return;
        // }

        // 원점수제에 작동하는 것이기에 1단계에 가중치가 없으면 작동할 수 없다.

        // 만점이 있을 경우 점수 계산: (획득점수/만점) * 100 * 가중치
        if (directScore && directTotal) {
          const percentage = (Number(directScore) / Number(directTotal)) * 100;
          finalScore +=
            Math.round(((percentage * level1Weight) / 100) * 100) / 100;
          return;
        }

        //만점이 없을 경우 점수 계산: 획득점수 * 가중치
        if (directScore) {
          finalScore +=
            Math.round(((Number(directScore) * level1Weight) / 100) * 100) /
            100;
          return;
        }
      }

      // === Level 2 처리 ===

      //이 아래는 그냥 2단계 가중치가 100인지 경고 메시지 날려주는 곳이다. -------------
      // Level 2 가중치 합계 검증
      let level2TotalWeights = 0;
      let hasLevel2Weights = false;

      // Level 2 가중치 확인
      level2Items.forEach((item) => {
        const weight = Number(item.extra_data?.weight || 0);
        if (!isNaN(weight) && weight > 0) {
          hasLevel2Weights = true;
          level2TotalWeights += weight;
        }
      });

      // Level 2 가중치 합이 100이 아닌 경우 경고
      if (
        hasLevel2Weights &&
        level2TotalWeights !== 100 &&
        !window.level2WeightWarningShown &&
        !evalItemsData.some((item) => item.evaluation_type === "이수제")
      ) {
        console.warn(
          `Level 2 total weights (${level2TotalWeights}) does not equal 100%`
        );
        alert(t("teacher.lessonRelated.level2WeightSumMessage"));
        window.level2WeightWarningShown = true;
        setTimeout(() => {
          window.level2WeightWarningShown = false;
        }, 3000);
      }

      //이 위는 그냥 2단계 가중치가 100인지 경고 메시지 날려주는 곳이다. -------------

      let level1WeightedScore = 0; // Level 1의 가중 점수

      // excludeEmptyGrade가 true면 빈칸은 합산하지 않는다.
      const nonEmptyLevel2Items = excludeEmptyGrade
        ? level2Items.filter(
            (item) => !isEmptyGrade(student.eval_item_grades?.[item.id])
          )
        : level2Items;

      // 모든 Level 2 항목이 비어있으면 이 Level 1은 건너뛰기, 하지만 이렇게 하면 3단계로도 못 가잖아? 그래서 안 되었던 거군.
      // if (excludeEmptyGrade && nonEmptyLevel2Items.length === 0) {
      //   return;
      // }

      // Level 2 항목들 처리
      level2Items.forEach((level2Item) => {
        // Level 3 항목 찾기
        const level3Items = evalItemsData.filter(
          (item) =>
            item.hierarchy_level === 3 && item.parent_id === level2Item.id
        );

        const level2Weight = Number(level2Item.extra_data?.weight || 0);

        // === Level 2 직접 평가 케이스 (Level 3이 없는 경우) ===
        if (level3Items.length === 0) {
          const score = student.eval_item_grades?.[level2Item.id] || 0;
          const total = level2Item.extra_data?.total;

          // 빈 항목 제외 설정이 true이고 점수가 비어있으면 건너뛰기
          if (excludeEmptyGrade && isEmptyGrade(score)) {
            return;
          }

          if (score && total) {
            const percentage = (Number(score) / Number(total)) * 100;

            if (hasLevel2Weights) {
              // 가중치와 만점이 있는 경우: 퍼센티지 * 가중치가 1단계 가중치 적용된 점수가 된다.
              // 예를 들어서 2단계의 score 50, total 100, 가중치 50이면, 50/100 * 50 = 25이고, 이게 1단계 가중치 적용된 점수가 된다.
              level1WeightedScore += (percentage * level2Weight) / 100;
            } else {
              if (calculType === "maxAverage") {
                // 아무도 안 쓴느 방식. 그레이스는 3단계로 하니까. 이건 2단계에서 가중치 없이 만점만 있을 때 이렇게 하고 싶다고 하면 할 수 있는 방식 평균 방식: 각 항목의 퍼센티지 평균
                level1WeightedScore +=
                  percentage /
                  (excludeEmptyGrade
                    ? nonEmptyLevel2Items.length
                    : level2Items.length);
              } else {
                // 헤이븐 방식. 2단계에 가중치가 없고 만점만 있는 경우: (총점/총만점) * 100

                //입력된 모든 점수 합하기
                const totalScore = nonEmptyLevel2Items.reduce((sum, item) => {
                  const score = Number(
                    student.eval_item_grades?.[item.id] || 0
                  );
                  return isNaN(score) ? sum : sum + score;
                }, 0);

                //만점 점수 모두 합하기 (빈칸 합산 설정 했으면 빈칸은 합산하지 않는다.)
                const totalPossible = nonEmptyLevel2Items.reduce(
                  (sum, item) => {
                    const total = Number(item.extra_data?.total || 0);
                    return isNaN(total) ? sum : sum + total;
                  },
                  0
                );

                if (totalPossible > 0) {
                  const percentage = (totalScore / totalPossible) * 100;
                  level1WeightedScore = percentage;
                }
              }
            }
          }
          return;
        }

        // 이 아래는 3단계 처리. 그레이스 학교 중점  -------------------------------------

        // Level 3 처리
        let level2WeightedScore = 0;

        //이 아래는 그냥 3단계 가중치가 100인지 경고 메시지 날려주는 곳이다. -------------
        // Level 3 가중치 합계 검증
        let level3TotalWeights = 0;
        let hasLevel3Weights = false;

        level3Items.forEach((item) => {
          const weight = Number(item.extra_data?.weight || 0);
          if (!isNaN(weight) && weight > 0) {
            hasLevel3Weights = true;
            level3TotalWeights += weight;
          }
        });

        if (
          hasLevel3Weights && // 가중치가 있는 경우에만 검증
          level3TotalWeights !== 100 &&
          !window.level3WeightWarningShown &&
          !evalItemsData.some((item) => item.evaluation_type === "이수제")
        ) {
          console.warn(
            `Level 3 total weights (${level3TotalWeights}) does not equal 100%`
          );
          alert(
            t("teacher.lessonRelated.level3WeightSumMessage") ||
              "가중치 합계는 100이어야 합니다. (3단계)"
          );
          window.level3WeightWarningShown = true;
          setTimeout(() => {
            window.level3WeightWarningShown = false;
          }, 3000);
        }

        //이 위는 그냥 3단계 가중치가 100인지 경고 메시지 날려주는 곳이다. -------------

        // 비어있지 않은 level3 항목만 필터링
        const nonEmptyLevel3Items = excludeEmptyGrade
          ? level3Items.filter(
              (item) => !isEmptyGrade(student.eval_item_grades?.[item.id])
            )
          : level3Items;

        if (excludeEmptyGrade && nonEmptyLevel3Items.length === 0) {
          return; // 모든 level3 항목이 비어있으면 이 level2는 건너뜀
          //그럼 해당 level2는 0점으로 처리된다.
        }

        //그레이스 기독학교 방식
        if (calculType === "maxAverage") {
          if (hasLevel3Weights) {
            // 가중치와 만점이 있는 경우. 만점이 없으면 안되네. 가중치가 있으면 maxAverage 방식도 적용 안 된다.
            level2WeightedScore = nonEmptyLevel3Items.reduce((sum, item) => {
              const score = Number(student.eval_item_grades?.[item.id] || 0);
              const total = Number(item.extra_data?.total || 0);
              const weight = Number(item.extra_data?.weight || 0);
              const percentage = total > 0 ? (score / total) * 100 : 0;
              return sum + (percentage * weight) / 100;
            }, 0);
          } else {
            // 각 Level 3 항목의 개별 퍼센티지 계산 후 평균 - 이게 maxAverage 방식이다.
            const percentages = nonEmptyLevel3Items.map((item) => {
              const score = Number(student.eval_item_grades?.[item.id] || 0);
              const total = Number(item.extra_data?.total || 0);
              return total > 0 ? (score / total) * 100 : 0;
            });

            if (percentages.length > 0) {
              level2WeightedScore =
                percentages.reduce((sum, p) => sum + p, 0) / percentages.length;
            }
          }
        } else {
          if (hasLevel3Weights) {
            // 가중치가 있는 경우
            level2WeightedScore = nonEmptyLevel3Items.reduce((sum, item) => {
              const score = Number(student.eval_item_grades?.[item.id] || 0);
              const total = Number(item.extra_data?.total || 0);
              const weight = Number(item.extra_data?.weight || 0);
              const percentage = total > 0 ? (score / total) * 100 : 0;
              return sum + (percentage * weight) / 100;
            }, 0);
          } else {
            // Level 3 만점 방식 처리
            const totalScore = nonEmptyLevel3Items.reduce((sum, item) => {
              const score = Number(student.eval_item_grades?.[item.id] || 0);
              return isNaN(score) ? sum : sum + score;
            }, 0);

            const totalPossible = nonEmptyLevel3Items.reduce((sum, item) => {
              const total = Number(item.extra_data?.total || 0);
              return isNaN(total) ? sum : sum + total;
            }, 0);

            if (totalPossible > 0) {
              const percentage = (totalScore / totalPossible) * 100;
              level2WeightedScore = percentage;
            }
          }
        }
        level1WeightedScore += (level2WeightedScore * level2Weight) / 100;
      });

      finalScore +=
        Math.round(((level1WeightedScore * level1Weight) / 100) * 100) / 100;
    });

    // Level 1 가중치 합계 검증
    let hasLevel1Weights = false;
    level1Items.forEach((item) => {
      const weight = Number(item.extra_data?.weight || 0);
      if (!isNaN(weight) && weight > 0) {
        hasLevel1Weights = true;
      }
    });

    if (
      hasLevel1Weights && // 가중치가 있는 경우에만 검증
      totalWeights !== 100 &&
      !window.level1WeightWarningShown &&
      !evalItemsData.some((item) => item.evaluation_type === "이수제")
    ) {
      console.warn(
        `Level 1 total weights (${totalWeights}) does not equal 100%`
      );
      alert(t("teacher.lessonRelated.level1WeightSumMessage"));
      window.level1WeightWarningShown = true;
      setTimeout(() => {
        window.level1WeightWarningShown = false;
      }, 3000);
    }
    const score = Math.min(Math.max(finalScore, 0), 100);
    return Number(score.toFixed(2)).toString();
  }

  // 열 너비를 저장할 state 추가
  const [columnWidths, setColumnWidths] = useState({
    col1: 110,
    col2: 110,
    col3: 120,
  });

  // 참조를 위한 refs 설정
  const col1Ref = useRef(null);
  const col2Ref = useRef(null);
  const col3Ref = useRef(null);

  // 컴포넌트 마운트 및 리사이즈 시 열 너비 계산
  useEffect(() => {
    const calculateColumnWidths = () => {
      const widths = {
        col1: col1Ref.current ? col1Ref.current.offsetWidth : 110,
        col2: col2Ref.current ? col2Ref.current.offsetWidth : 110,
        col3: col3Ref.current ? col3Ref.current.offsetWidth : 120,
      };

      setColumnWidths(widths);
    };

    // 초기 계산
    calculateColumnWidths();

    // 리사이즈 이벤트에 대한 리스너 추가
    window.addEventListener("resize", calculateColumnWidths);

    return () => {
      window.removeEventListener("resize", calculateColumnWidths);
    };
  }, [evalItemsData]); // 평가 항목이 변경될 때 다시 계산

  // 동적 위치 계산 함수
  const getStickyColumnStyle = (columnIndex) => {
    // 기본 스타일: 모든 위치에 공통 스타일
    const baseStyle = {
      backgroundColor: "#fff",
      boxShadow:
        "inset 0 -1px 0 #dee2e6, inset 0 1px 0 #dee2e6, inset -1px 0 0 #dee2e6, inset 1px 0 0 #dee2e6, 2px 0 5px -2px rgba(0,0,0,0.2)",
    };
    if (columnIndex === 0) {
      return {
        position: "sticky",
        left: 0,
        zIndex: 3,
        ...baseStyle,
      };
    } else if (columnIndex === 1) {
      return {
        position: "sticky",
        left: `${columnWidths.col1}px`,
        zIndex: 2,
        ...baseStyle,
      };
    } else if (columnIndex === 2) {
      return {
        position: "sticky",
        left:
          findByLevels(2).length > 0
            ? `${columnWidths.col1 + columnWidths.col2}px`
            : `${columnWidths.col1}px`,
        zIndex: 1,
        ...baseStyle,
      };
    }
    return {};
  };

  return (
    <div>
      <div className="ms-3" style={{ minWidth: "800px" }}>
        <table
          className="table table-sm table-bordered"
          style={{
            fontSize: "13px",
            width: "auto",
          }}
        >
          <thead>
            <tr
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#fff",
                zIndex: 4,
              }}
            >
              <th
                className="align-middle"
                style={{
                  minWidth: "110px",
                  maxWidth: "250px",
                  ...getStickyColumnStyle(0),
                }}
              >
                {t("teacher.lessonRelated.level1")}
                <button
                  className="btn btn-sm btn-primary ps-1 pe-1 ms-1"
                  style={{ fontSize: "11px" }}
                  onClick={() => saveAllStudentsGrade()}
                >
                  {savedBulk
                    ? t("common.labels.savedWell")
                    : t("common.labels.saveAll")}
                </button>
              </th>
              {findByLevels(2).length > 0 && (
                <th
                  className="align-middle"
                  style={{
                    minWidth: "110px",
                    maxWidth: "250px",
                    ...getStickyColumnStyle(1),
                  }}
                >
                  {t("teacher.lessonRelated.level2")}
                </th>
              )}
              {findByLevels(3).length > 0 && (
                <th
                  className="align-middle"
                  style={{
                    minWidth: "120px",
                    maxWidth: "250px",
                    ...getStickyColumnStyle(2),
                  }}
                >
                  {t("teacher.lessonRelated.level3")}
                </th>
              )}
              {lessonStudentsData.map((student) => (
                <th
                  key={`student-${student.id}`}
                  className="text-center"
                  style={{
                    minWidth: "50px",
                    width: "100px",
                    maxWidth: "150px",
                    fontSize: "11px",
                    // verticalAlign: "top",
                  }}
                >
                  {student.fullname}
                  <br />
                  <button
                    className="btn btn-sm btn-primary ps-2 pe-2 p-0 m-0"
                    style={{ fontSize: "11px" }}
                    onClick={() => saveStudentGrade(student)}
                  >
                    {savedRows.includes(student.id)
                      ? t("common.labels.complete") || "완료"
                      : t("common.labels.save") || "저장"}
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
          <tfoot>
            {checkEvalTypes(["100점제"]) &&
            lessonSetting.find((r) => r.item_code === "evalArea100")
              .saved_setting === "원점수기록" ? (
              <tr>
                <th
                  className="align-middle"
                  style={{ ...getStickyColumnStyle(0) }}
                >
                  합계
                </th>
                {findByLevels(2).length > 0 && (
                  <th
                    className="align-middle"
                    style={{ ...getStickyColumnStyle(1) }}
                  ></th>
                )}
                {findByLevels(3).length > 0 && (
                  <th
                    className="align-middle"
                    style={{ ...getStickyColumnStyle(2) }}
                  ></th>
                )}

                {lessonStudentsData.map((student) => (
                  <th
                    key={`sum-${student.id}`}
                    className="text-center"
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    {student.eval_item_grades
                      ? calculateTotalScore(student, evalItemsData)
                      : ""}
                  </th>
                ))}
              </tr>
            ) : (
              <tr>
                <th
                  className="align-middle"
                  style={{ ...getStickyColumnStyle(0) }}
                >
                  {t("teacher.lessonRelated.sum")}
                </th>
                {findByLevels(2).length > 0 && (
                  <th
                    className="align-middle"
                    style={{ ...getStickyColumnStyle(1) }}
                  ></th>
                )}
                {findByLevels(3).length > 0 && (
                  <th
                    className="align-middle"
                    style={{ ...getStickyColumnStyle(2) }}
                  ></th>
                )}

                {lessonStudentsData.map((student) => (
                  <th
                    key={`sum-${student.id}`}
                    className="text-center"
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    {student.eval_item_grades
                      ? sumAllNumbersOfObject(student?.eval_item_grades || {})
                      : ""}
                  </th>
                ))}
              </tr>
            )}
            {checkEvalTypes(["100점제", "3단평가", "5단평가"]) &&
              lessonSetting.find((r) => r.item_code === "evalArea100")
                .saved_setting !== "원점수기록" && (
                <tr>
                  <th
                    className="align-middle"
                    style={{ ...getStickyColumnStyle(0) }}
                  >
                    {t("teacher.lessonRelated.average")}
                  </th>
                  {findByLevels(2).length > 0 && (
                    <th
                      className="align-middle"
                      style={{ ...getStickyColumnStyle(1) }}
                    ></th>
                  )}
                  {findByLevels(3).length > 0 && (
                    <th
                      className="align-middle"
                      style={{ ...getStickyColumnStyle(2) }}
                    ></th>
                  )}

                  {lessonStudentsData.map((student) => (
                    <th
                      key={`avg-${student.id}`}
                      className="text-center"
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {student.eval_item_grades
                        ? typeof averageAllNumbersOfObject(
                            student.eval_item_grades
                          ) === "number"
                          ? averageAllNumbersOfObject(
                              student.eval_item_grades
                            ).toFixed(1)
                          : ""
                        : ""}
                    </th>
                  ))}
                </tr>
              )}

            {flexibleData?.find((r) => r.sub_category === "평가항목별") &&
              (lessonSetting.find((r) => r.item_code === "evalArea100")
                .saved_setting === "원점수기록" ? (
                <tr>
                  <th
                    className="align-middle"
                    style={{ ...getStickyColumnStyle(0) }}
                  >
                    {t("teacher.lessonRelated.convert100")}
                  </th>
                  {findByLevels(2).length > 0 && (
                    <th
                      className="align-middle"
                      style={{ ...getStickyColumnStyle(1) }}
                    ></th>
                  )}
                  {findByLevels(3).length > 0 && (
                    <th
                      className="align-middle"
                      style={{ ...getStickyColumnStyle(2) }}
                    ></th>
                  )}

                  {lessonStudentsData.map((student) => (
                    <th
                      key={`convert100-${student.id}`}
                      className="text-center"
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {gradeToConvert100(
                        calculateTotalScore(student, evalItemsData),
                        flexibleData?.find(
                          (r) => r.sub_category === "평가항목별"
                        )?.data_value,
                        convertCondition
                      )}
                    </th>
                  ))}
                </tr>
              ) : (
                <tr>
                  <th
                    className="align-middle"
                    style={{ ...getStickyColumnStyle(0) }}
                  >
                    {t("teacher.lessonRelated.convert100")}
                  </th>
                  {findByLevels(2).length > 0 && (
                    <th
                      className="align-middle"
                      style={{ ...getStickyColumnStyle(1) }}
                    ></th>
                  )}
                  {findByLevels(3).length > 0 && (
                    <th
                      className="align-middle"
                      style={{ ...getStickyColumnStyle(2) }}
                    ></th>
                  )}

                  {lessonStudentsData.map((student) => (
                    <th
                      key={`convert100-${student.id}`}
                      className="text-center"
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {gradeToConvert100(
                        sumAllNumbersOfObject(student?.eval_item_grades || {}),
                        flexibleData?.find(
                          (r) => r.sub_category === "평가항목별"
                        )?.data_value,
                        convertCondition
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            <tr>
              <th
                className="align-middle"
                style={{ ...getStickyColumnStyle(0) }}
              >
                {t("teacher.lessonRelated.specificComment")}
              </th>
              {findByLevels(2).length > 0 && (
                <th
                  className="align-middle"
                  style={{ ...getStickyColumnStyle(1) }}
                ></th>
              )}
              {findByLevels(3).length > 0 && (
                <th
                  className="align-middle"
                  style={{ ...getStickyColumnStyle(2) }}
                ></th>
              )}

              {lessonStudentsData.map((student) => (
                <th
                  key={`comment-${student.id}`}
                  className="text-center"
                  style={{
                    fontSize: "11px",
                    minWidth: "50px",
                  }}
                >
                  <button
                    className={`btn btn-sm ${
                      student.comment ? "btn-success" : "btn-outline-secondary"
                    } ps-1 p-0 m-0`}
                    style={{
                      fontSize: "11px",
                      minWidth: "50px",
                      maxWidth: "60px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      opacity: student.comment ? 0.85 : 1,
                    }}
                    onClick={() =>
                      initiatePromptModal({
                        id: student.id,
                        comment: student.comment,
                        keyName: "comment",
                        title: t("teacher.lessonRelated.specificComment"),
                      })
                    }
                  >
                    {student.comment
                      ? student.comment
                      : t("teacher.lessonRelated.empty")}
                  </button>
                </th>
              ))}
            </tr>
            <tr>
              <th
                className="align-middle"
                style={{ ...getStickyColumnStyle(0) }}
              >
                {t("teacher.lessonRelated.remark")}
              </th>
              {findByLevels(2).length > 0 && (
                <th
                  className="align-middle"
                  style={{ ...getStickyColumnStyle(1) }}
                ></th>
              )}
              {findByLevels(3).length > 0 && (
                <th
                  className="align-middle"
                  style={{ ...getStickyColumnStyle(2) }}
                ></th>
              )}

              {lessonStudentsData.map((student) => (
                <th
                  key={`remark-${student.id}`}
                  className="text-center"
                  style={{
                    fontSize: "11px",
                    minWidth: "50px",
                  }}
                >
                  <textarea
                    type="text"
                    rows="3"
                    className="input-like-span"
                    name="extra_data"
                    value={student?.extra_data?.remark || ""}
                    onChange={(e) =>
                      handleTdChange(student.username, "remark", e)
                    }
                  />
                </th>
              ))}
            </tr>
            <tr>
              <th
                className="align-middle"
                style={{ ...getStickyColumnStyle(0) }}
              >
                {t("common.labels.tag")}
              </th>
              {findByLevels(2).length > 0 && (
                <th
                  className="align-middle"
                  style={{ ...getStickyColumnStyle(1) }}
                ></th>
              )}
              {findByLevels(3).length > 0 && (
                <th
                  className="align-middle"
                  style={{ ...getStickyColumnStyle(2) }}
                ></th>
              )}
              {lessonStudentsData.map((student) => (
                <th
                  key={`tag-${student.id}`}
                  className="text-center"
                  style={{
                    minWidth: "50px",
                    fontSize: "11px",
                  }}
                >
                  <div style={{ fontSize: "11px" }}>
                    <div>
                      {student?.tags?.map((tag, tagIndex) => {
                        const tagName = Object.keys(tag)[0];
                        return (
                          <div key={tagIndex}>
                            <div
                              className="text-center"
                              style={{ marginBottom: "1px" }}
                            >
                              {tagName}
                            </div>
                            <select
                              className="form-control p-0"
                              style={{ fontSize: "11px" }}
                              value={tag[tagName]}
                              name="tags"
                              onChange={(e) =>
                                handleTagChange(
                                  tagName,
                                  e,
                                  student.username,
                                  tagIndex
                                )
                              }
                            >
                              <option value="1">
                                {t("common.labels.veryWeak")}
                              </option>
                              <option value="2">
                                {t("common.labels.weak")}
                              </option>
                              <option value="3">
                                {t("common.labels.normal")}
                              </option>
                              <option value="4">
                                {t("common.labels.strong")}
                              </option>
                              <option value="5">
                                {t("common.labels.veryStrong")}
                              </option>
                            </select>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
            <tr>
              <th style={{ ...getStickyColumnStyle(0) }}>
                {t("teacher.lessonRelated.deleteButton")}
              </th>
              {findByLevels(2).length > 0 && (
                <th
                  className="align-middle"
                  style={{ ...getStickyColumnStyle(1) }}
                ></th>
              )}
              {findByLevels(3).length > 0 && (
                <th
                  className="align-middle"
                  style={{ ...getStickyColumnStyle(2) }}
                ></th>
              )}
              {lessonStudentsData.map((student) => (
                <td
                  key={`delete-${student.id}`}
                  className="text-center"
                  style={{
                    minWidth: "50px",
                    fontSize: "11px",
                  }}
                >
                  {confirmDelete.includes(student.id) ? (
                    <>
                      <button
                        type="button"
                        onClick={() => handleDelete(student)}
                        className="btn btn-sm btn-danger ps-2 pe-2 p-0 m-0"
                        style={{ fontSize: "11px" }}
                      >
                        {t("common.labels.confirm") || "확인"}
                      </button>
                      <button
                        type="button"
                        onClick={() => handleCancelDelete()}
                        className="btn btn-sm btn-secondary ps-2 pe-2 p-0 m-0"
                        style={{ fontSize: "11px" }}
                      >
                        {t("common.labels.cancel") || "취소"}
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleConfirmDelete(student)}
                      className="btn btn-sm btn-warning ps-2 pe-2 p-0 m-0"
                      style={{ fontSize: "11px" }}
                    >
                      {t("common.labels.delete") || "삭제"}
                    </button>
                  )}
                </td>
              ))}
            </tr>
          </tfoot>
        </table>
        <PromptModal
          isOpen={isModalOpen}
          onClose={closeModal}
          initialState={dataForPromptModal}
          handleSubmit={handlePromptModalSubmit}
          textCount={true}
          closeOnOutsideClick={false}
        />
      </div>
    </div>
  );
}

/* 그레이스 기독학교 요청으로 maxAverage 일 때도 건너뛰도록 하기 위해 대대적으로 코드 수정 전에 백업한 것
  function calculateTotalScore(student, evalItemsData) {
    // 설정 가져오기: 평가항목별 설정에서 계산 방식과 빈 항목 제외 여부 확인
    const covert100Setting = flexibleData?.find(
      (r) => r.sub_category === "평가항목별" && r.main_category === "convert100"
    )?.data_value;
    const calculType = covert100Setting?.calculType || ""; // maxAverage or 기본
    const excludeEmptyGrade = covert100Setting?.excludeEmptyGrade || false; // 빈 항목 제외 여부

    // 기본 유효성 검사: 필수 데이터 확인
    if (!student || !evalItemsData || !Array.isArray(evalItemsData)) {
      console.warn("Invalid input parameters for calculateTotalScore");
      return 0;
    }

    // 이수제 평가인 경우 빈 문자열 반환
    if (evalItemsData.some((item) => item.evaluation_type === "이수제")) {
      return "";
    }

    // 평가 점수가 없는 경우 빈 문자열 반환
    if (!student.eval_item_grades) return "";

    // Level 1 항목들만 필터링
    const level1Items = evalItemsData.filter(
      (item) => item.hierarchy_level === 1
    );
    if (level1Items.length === 0) return "";

    // 빈 값 체크 헬퍼 함수
    const isEmptyGrade = (gradeValue) => {
      return (
        gradeValue === "" || gradeValue === null || gradeValue === undefined
      );
    };

    let finalScore = 0; // 최종 점수
    let totalWeights = 0; // Level 1 가중치 합계

    // 여기 위까지 기본 설정 했음------------------------

    // Level 1 항목들 순회
    level1Items.forEach((level1Item) => {
      if (!level1Item?.id) return;

      // level1Item의 하위 항목인 Level 2 항목들 찾기
      const level2Items = evalItemsData.filter(
        (item) => item.hierarchy_level === 2 && item.parent_id === level1Item.id
      );

      // === Level 1 직접 평가 케이스 (하위 항목이 없는 경우) ===

      const level1Weight = Number(level1Item.extra_data?.weight || 0);
      if (!isNaN(level1Weight)) totalWeights += level1Weight;

      if (level2Items.length === 0) {
        //1단계의 점수
        const directScore = student.eval_item_grades?.[level1Item.id];
        //1단계의 만점
        const directTotal = level1Item.extra_data?.total;

        // 빈 항목 제외 설정이 true이고 점수가 비어있으면 건너뛰기
        //빈 항목 건너뛰기는 가중치가 있을 때는 작동할 수 없고, 1단계는 가중치가 필수이기 때문에 여기선 필요 없다.
        // if (excludeEmptyGrade && isEmptyGrade(directScore)) {
        //   return;
        // }

        // 원점수제에 작동하는 것이기에 1단계에 가중치가 없으면 작동할 수 없다.

        // 만점이 있을 경우 점수 계산: (획득점수/만점) * 100 * 가중치
        if (directScore && directTotal) {
          const percentage = (Number(directScore) / Number(directTotal)) * 100;
          finalScore +=
            Math.round(((percentage * level1Weight) / 100) * 100) / 100;
          return;
        }

        //만점이 없을 경우 점수 계산: 획득점수 * 가중치
        if (directScore) {
          finalScore +=
            Math.round(((Number(directScore) * level1Weight) / 100) * 100) /
            100;
          return;
        }
      }

      // === Level 2 처리 ===

//이 아래는 그냥 2단계 가중치가 100인지 경고 메시지 날려주는 곳이다. -------------  
      // Level 2 가중치 합계 검증
      let level2TotalWeights = 0;
      let hasLevel2Weights = false;

      // Level 2 가중치 확인
      level2Items.forEach((item) => {
        const weight = Number(item.extra_data?.weight || 0);
        if (!isNaN(weight) && weight > 0) {
          hasLevel2Weights = true;
          level2TotalWeights += weight;
        }
      });

      // Level 2 가중치 합이 100이 아닌 경우 경고
      if (
        hasLevel2Weights &&
        level2TotalWeights !== 100 &&
        !window.level2WeightWarningShown &&
        !evalItemsData.some((item) => item.evaluation_type === "이수제")
      ) {
        console.warn(
          `Level 2 total weights (${level2TotalWeights}) does not equal 100%`
        );
        alert(t("teacher.lessonRelated.level2WeightSumMessage"));
        window.level2WeightWarningShown = true;
        setTimeout(() => {
          window.level2WeightWarningShown = false;
        }, 3000);
      }

//이 위는 그냥 2단계 가중치가 100인지 경고 메시지 날려주는 곳이다. -------------  


let level1WeightedScore = 0; // Level 1의 가중 점수


      // excludeEmptyGrade가 true면 빈칸은 합산하지 않는다. 
      const nonEmptyLevel2Items = excludeEmptyGrade
        ? level2Items.filter(
            (item) => !isEmptyGrade(student.eval_item_grades?.[item.id])
          )
        : level2Items;

      // 모든 Level 2 항목이 비어있으면 이 Level 1은 건너뛰기, 하지만 이렇게 하면 3단계로도 못 가잖아? 그래서 안 되었던 거군.
      if (excludeEmptyGrade && nonEmptyLevel2Items.length === 0) {
        return;
      }

      // Level 2 항목들 처리
      nonEmptyLevel2Items.forEach((level2Item) => {
        // Level 3 항목 찾기
        const level3Items = evalItemsData.filter(
          (item) =>
            item.hierarchy_level === 3 && item.parent_id === level2Item.id
        );

        // === Level 2 직접 평가 케이스 (Level 3이 없는 경우) ===
        if (level3Items.length === 0) {
          const level2Weight = Number(level2Item.extra_data?.weight || 0);
          const score = student.eval_item_grades?.[level2Item.id] || 0;
          const total = level2Item.extra_data?.total;

          // 빈 항목 제외 설정이 true이고 점수가 비어있으면 건너뛰기
          if (excludeEmptyGrade && isEmptyGrade(score)) {
            return;
          }

          if (score && total) {
            const percentage = (Number(score) / Number(total)) * 100;

            if (hasLevel2Weights) {
              // 가중치가 있는 경우: 퍼센티지 * 가중치
              level1WeightedScore += (percentage * level2Weight) / 100;
            } else {
              if (calculType === "maxAverage") {
                // 평균 방식: 각 항목의 퍼센티지 평균
                level1WeightedScore +=
                  percentage /
                  (excludeEmptyGrade
                    ? nonEmptyLevel2Items.length
                    : level2Items.length);
              } else {
                // 만점 방식: (총점/총만점) * 100
                const totalScore = nonEmptyLevel2Items.reduce((sum, item) => {
                  const score = Number(
                    student.eval_item_grades?.[item.id] || 0
                  );
                  return isNaN(score) ? sum : sum + score;
                }, 0);

                const totalPossible = nonEmptyLevel2Items.reduce(
                  (sum, item) => {
                    const total = Number(item.extra_data?.total || 0);
                    return isNaN(total) ? sum : sum + total;
                  },
                  0
                );

                if (totalPossible > 0) {
                  const percentage = (totalScore / totalPossible) * 100;
                  level1WeightedScore = percentage;
                }
              }
            }
          }
          return;
        }

        // Level 3 처리
        let level2WeightedScore = 0;
        const level2Weight = Number(level2Item.extra_data?.weight || 0);

        // Level 3 가중치 합계 검증
        let level3TotalWeights = 0;
        let hasLevel3Weights = false;

        level3Items.forEach((item) => {
          const weight = Number(item.extra_data?.weight || 0);
          if (!isNaN(weight) && weight > 0) {
            hasLevel3Weights = true;
            level3TotalWeights += weight;
          }
        });

        if (
          hasLevel3Weights && // 가중치가 있는 경우에만 검증
          level3TotalWeights !== 100 &&
          !window.level3WeightWarningShown &&
          !evalItemsData.some((item) => item.evaluation_type === "이수제")
        ) {
          console.warn(
            `Level 3 total weights (${level3TotalWeights}) does not equal 100%`
          );
          alert(
            t("teacher.lessonRelated.level3WeightSumMessage") ||
              "가중치 합계는 100이어야 합니다. (3단계)"
          );
          window.level3WeightWarningShown = true;
          setTimeout(() => {
            window.level3WeightWarningShown = false;
          }, 3000);
        }

        // 비어있지 않은 level3 항목만 필터링
        const nonEmptyLevel3Items = excludeEmptyGrade
          ? level3Items.filter(
              (item) => !isEmptyGrade(student.eval_item_grades?.[item.id])
            )
          : level3Items;

        if (excludeEmptyGrade && nonEmptyLevel3Items.length === 0) {
          return; // 모든 level3 항목이 비어있으면 이 level2는 건너뜀
        }

        if (calculType === "maxAverage") {
          if (hasLevel3Weights) {
            // 가중치가 있는 경우
            level2WeightedScore = nonEmptyLevel3Items.reduce((sum, item) => {
              const score = Number(student.eval_item_grades?.[item.id] || 0);
              const total = Number(item.extra_data?.total || 0);
              const weight = Number(item.extra_data?.weight || 0);
              const percentage = total > 0 ? (score / total) * 100 : 0;
              return sum + (percentage * weight) / 100;
            }, 0);
          } else {
            // 각 Level 3 항목의 개별 퍼센티지 계산 후 평균
            const percentages = nonEmptyLevel3Items.map((item) => {
              const score = Number(student.eval_item_grades?.[item.id] || 0);
              const total = Number(item.extra_data?.total || 0);
              return total > 0 ? (score / total) * 100 : 0;
            });

            if (percentages.length > 0) {
              level2WeightedScore =
                percentages.reduce((sum, p) => sum + p, 0) / percentages.length;
            }
          }
        } else {
          if (hasLevel3Weights) {
            // 가중치가 있는 경우
            level2WeightedScore = nonEmptyLevel3Items.reduce((sum, item) => {
              const score = Number(student.eval_item_grades?.[item.id] || 0);
              const total = Number(item.extra_data?.total || 0);
              const weight = Number(item.extra_data?.weight || 0);
              const percentage = total > 0 ? (score / total) * 100 : 0;
              return sum + (percentage * weight) / 100;
            }, 0);
          } else {
            // Level 3 만점 방식 처리
            const totalScore = nonEmptyLevel3Items.reduce((sum, item) => {
              const score = Number(student.eval_item_grades?.[item.id] || 0);
              return isNaN(score) ? sum : sum + score;
            }, 0);

            const totalPossible = nonEmptyLevel3Items.reduce((sum, item) => {
              const total = Number(item.extra_data?.total || 0);
              return isNaN(total) ? sum : sum + total;
            }, 0);

            if (totalPossible > 0) {
              const percentage = (totalScore / totalPossible) * 100;
              level2WeightedScore = percentage;
            }
          }
        }
        level1WeightedScore += (level2WeightedScore * level2Weight) / 100;
      });

      finalScore +=
        Math.round(((level1WeightedScore * level1Weight) / 100) * 100) / 100;
    });

    // Level 1 가중치 합계 검증
    let hasLevel1Weights = false;
    level1Items.forEach((item) => {
      const weight = Number(item.extra_data?.weight || 0);
      if (!isNaN(weight) && weight > 0) {
        hasLevel1Weights = true;
      }
    });

    if (
      hasLevel1Weights && // 가중치가 있는 경우에만 검증
      totalWeights !== 100 &&
      !window.level1WeightWarningShown &&
      !evalItemsData.some((item) => item.evaluation_type === "이수제")
    ) {
      console.warn(
        `Level 1 total weights (${totalWeights}) does not equal 100%`
      );
      alert(t("teacher.lessonRelated.level1WeightSumMessage"));
      window.level1WeightWarningShown = true;
      setTimeout(() => {
        window.level1WeightWarningShown = false;
      }, 3000);
    }
    const score = Math.min(Math.max(finalScore, 0), 100);
    return Number(score.toFixed(2)).toString();
  } 
   */
