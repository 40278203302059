import React, { useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import EvalItemTr from "./EvalItemTr";
import EmptyEvalItemTr from "./EmptyEvalItemTr";
import { useTranslation } from "react-i18next";

export default function EvalItemTable({
  conditions,
  lessonSetting,
  evalItemsData,
  setEvalItemsData,
  getEvalItemsByLessonId,
}) {
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();

  const [addNewRow, setAddNewRow] = useState({
    schoolid: conditions.schoolid,
    lesson_id: conditions.lesson_id,
    hierarchy_level: "",
    content: "",
    evaluation_type: "",
    order: "",
    parent_id: "",
  });

  const [isAddingRow, setIsAddingRow] = useState(false);
  const [hideAddBtn, setHideAddBtn] = useState(false);

  useEffect(() => {
    if (evalItemsData) {
      setRows(evalItemsData);
    }
  }, [evalItemsData]);

  const handleAddClick = () => {
    setHideAddBtn(true);
    setIsAddingRow(true);
    setAddNewRow((prev) => ({
      ...prev,
      hierarchy_level: 1,
      content: "",
      evaluation_type: "",
      order: "",
      parent_id: 0,
      extra_data: {},
    }));
  };

  const evalItemSettings = lessonSetting?.find(
    (item) => item.item_code === "evalItem"
  )?.detailed_setting;

  return (
    <div className="mt-3">
      <table className="table text-center align-middle p-0 m-0">
        <thead>
          <tr>
            <th scope="col" className="ps-0 pe-0" style={{ width: "40px" }}>
              {t("teacher.lessonRelated.level")}
            </th>
            <th scope="col">{t("teacher.lessonRelated.evalContent")}</th>
            <th scope="col" className="ps-0 pe-0" style={{ width: "80px" }}>
              {t("teacher.lessonRelated.evaluationType")}
            </th>
            <th scope="col" className="ps-0 pe-0" style={{ width: "40px" }}>
              {t("common.labels.order")}
            </th>
            {evalItemSettings?.includes("가중치") && (
              <th scope="col" className="ps-0 pe-0" style={{ width: "60px" }}>
                {t("teacher.lessonRelated.weight")}
              </th>
            )}
            {evalItemSettings?.includes("만점") && (
              <th scope="col" className="ps-0 pe-0" style={{ width: "60px" }}>
                {t("teacher.lessonRelated.maxScore")}
              </th>
            )}
            {evalItemSettings?.includes("메모") && (
              <th scope="col" className="ps-0 pe-0" style={{ width: "60px" }}>
                {t("teacher.lessonRelated.memo")}
              </th>
            )}
            <th
              scope="col"
              className="ps-0 pe-0 pb-1"
              style={{ width: "60px" }}
            >
              {t("common.labels.add")}
              {!hideAddBtn && (
                <MdAddCircleOutline
                  className="p-0 mb-1"
                  role="button"
                  size={25}
                  color="blue"
                  onClick={handleAddClick}
                />
              )}
            </th>
            <th scope="col" className="ps-0 pe-0" style={{ width: "115px" }}>
              {t("common.labels.save_delete")}
            </th>
          </tr>
          {isAddingRow && (
            <EmptyEvalItemTr
              setHideAddBtn={setHideAddBtn}
              setIsAddingRow={setIsAddingRow}
              addNewRow={addNewRow}
              lessonSetting={lessonSetting}
              setAddNewRow={setAddNewRow}
              setEvalItemsData={setEvalItemsData}
              getEvalItemsByLessonId={getEvalItemsByLessonId}
            />
          )}
        </thead>
        <tbody>
          {evalItemsData?.length > 0 &&
            rows?.length > 0 &&
            rows.map(
              (item) =>
                item.hierarchy_level !== 0 && (
                  <EvalItemTr
                    key={item.id}
                    item={item}
                    lessonSetting={lessonSetting}
                    addNewRow={addNewRow}
                    setAddNewRow={setAddNewRow}
                    hideAddBtn={hideAddBtn}
                    setHideAddBtn={setHideAddBtn}
                    evalItemsData={evalItemsData}
                    setRows={setRows}
                    setEvalItemsData={setEvalItemsData}
                    getEvalItemsByLessonId={getEvalItemsByLessonId}
                  />
                )
            )}
        </tbody>
      </table>
    </div>
  );
}
