// 이벤트 추적 함수
export const logEvent = (category, action, label, value) => {
  if (window.gtag) {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
    console.log(
      `Logged event - Category: ${category}, Action: ${action}, Label: ${label}`
    );
  }
};

// 페이지 뷰 추적 함수
export const logPageView = (path) => {
  if (window.gtag) {
    window.gtag("config", "G-L9ZVEXNG8S", {
      page_path: path,
    });
    console.log(`Logged page view for: ${path}`);
  }
};
