import React, { useEffect, useState } from "react";
import useEducationalActivities from "../../../hooks/useEducationalActivities";
import { useUIContext } from "../../../context/UIContext";
import InsertStudentsSidebar from "../../../unicomponents/InsertStudentsSidebar";
import HelpHyperLink from "../../../unicomponents/HelpHyperLink";
import { useAuthContext } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";

export default function AwardActivity({
  rows,
  selectedActivity,
  setSelectedActivity,
  schoolStageId,
  schoolStageOrganPath,
  setFilters,
  studentDataForDuplicateCheck,
  schoolYear,
  createEduActiStudents,
  createEduActiStudentsBulk,
}) {
  const { t } = useTranslation();

  const [isAdded, setIsAdded] = useState([]);
  const [isAddedAll, setIsAddedAll] = useState(false);
  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();
  const { hasPermission } = useAuthContext();

  function handleActivityChange(e) {
    const { value } = e.target;
    // console.log("rows", rows);
    const selected = rows.filter(
      (row) => row.id.toString() === value.toString()
    );
    // console.log("selected", selected);
    setSelectedActivity(selected[0]);
    if (selected.length > 0) {
      setFilters((filters) => ({
        ...filters,
        educational_activities_id: selected[0].id,
      }));
    } else {
      setFilters((filters) => {
        const { educational_activities_id, ...remainingFilters } = filters;
        return remainingFilters;
      });
    }
  }

  const [selectedOptions, setSelectedOptions] = useState([]);

  const { updateEducationalActivities } = useEducationalActivities();

  // selectedActivity 값이 변경될 때마다 selectedOptions 상태를 업데이트
  useEffect(() => {
    if (selectedActivity && selectedActivity.tags) {
      // console.log("selectedActivity", selectedActivity);
      // const newSelectedOptions = selectedActivity.tags.reduce((acc, tag) => {
      //   const key = Object.keys(tag)filters;
      //   return { ...acc, [key]: tag[key] };
      // }, {});

      // console.log("selectedActivity.tags", selectedActivity.tags);
      setSelectedOptions(selectedActivity.tags);
    }
  }, [selectedActivity]);

  // "저장" 버튼 클릭 핸들러
  const saveChanges = (e) => {
    e.preventDefault();
    const dataSet = { id: e.target.name, tags: selectedOptions };

    setIsLoadingModal(true);
    updateEducationalActivities.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  // 선택 옵션 변경 핸들러
  const handleSelectChange = (tagName, newValue) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.map((option) => {
        // Object.keys(option)[0]은 현재 옵션 객체의 키를 가져옵니다.
        // 이 키가 tagName과 일치하면, 새 값을 가진 객체를 반환합니다.

        if (Object.keys(option)[0] === tagName) {
          return { [tagName]: newValue };
        }
        // 일치하지 않는 경우, 원래 객체를 그대로 반환합니다.
        return option;
      })
    );
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAddClick = (row) => {
    if (!selectedActivity || Object.keys(selectedActivity).length === 0) {
      // selectedActivity가 null이거나, 빈 객체일 때 실행할 코드
      handleToastCenterTop(t("teacher.doActivities.selectActivity"));

      return;
    }

    const filteredData = studentDataForDuplicateCheck.filter(
      (r) =>
        r.school_year === schoolYear &&
        r.hierarchy_one === selectedActivity.hierarchy_one
    );
    const dataForDuplicateUser = filteredData.filter(
      (r) => r.educational_activities_id === selectedActivity.id
    );
    if (dataForDuplicateUser.find((r) => r.username === row.username)) {
      handleToastCenterTop(t("teacher.doActivities.alreadyAssigned"));
      return;
    }

    const awardNumbers = filteredData
      .map((r) => r.extra_attributes.award_number)
      .filter((number) => !isNaN(number)) // 숫자만 필터링
      .map(Number) // 문자열을 숫자로 변환
      .sort((a, b) => a - b); // 오름차순 정렬

    // 가장 큰 숫자에 1을 더함. 배열이 비어있으면 기본값으로 1을 사용
    const newAwardNumber =
      awardNumbers.length > 0 ? awardNumbers[awardNumbers.length - 1] + 1 : 1;

    // console.log(newAwardNumber); // 새로운 award_number 출력

    // tags 배열 내의 각 객체를 순회하며 값을 3으로 설정
    const generatedTags = (selectedActivity.tags || []).map((tagObject) => {
      // 객체 내의 모든 키에 대해 값을 3으로 설정
      const updatedTagObject = {};
      Object.keys(tagObject).forEach((key) => {
        updatedTagObject[key] = "3"; // 값으로 문자열 "3"을 설정, 숫자로 설정하려면 3으로 변경
      });
      return updatedTagObject;
    });

    //데이터베이스에 저장할 데이터 만들기
    const addDataSet = {
      schoolid: selectedActivity.schoolid,
      school_year: selectedActivity.school_year,
      educational_activities_id: selectedActivity.id,
      username: row.username,
      title: selectedActivity.title,
      content: selectedActivity.content,
      on_schoollife_record: "등재",
      extra_attributes: { award_rank: "", award_number: newAwardNumber },
      tags: generatedTags,
    };

    setIsLoadingModal(true);
    createEduActiStudents.mutate(addDataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setIsAdded((prev) => [...prev, row.id]);
        setTimeout(() => {
          setIsAdded([]);
        }, 1500);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleAddAllClick = (rows) => {
    if (!selectedActivity || Object.keys(selectedActivity).length === 0) {
      // selectedActivity가 null이거나, 빈 객체일 때 실행할 코드
      handleToastCenterTop(t("teacher.doActivities.selectActivity"));

      return;
    }

    const bulkDataSets = [];
    const addedStudents = []; // 성공적으로 추가될 학생들의 이름을 저장할 배열
    const duplicateStudents = []; // 중복으로 추가되지 않은 학생들의 이름을 저장할 배열
    let maxAwardNumber = 0; // 현재까지의 최대 award_number를 추적
    let message;

    rows.forEach((row) => {
      const filteredData = studentDataForDuplicateCheck.filter(
        (r) =>
          r.school_year === schoolYear &&
          r.hierarchy_one === selectedActivity.hierarchy_one
      );

      const isDuplicate = filteredData.some(
        (r) =>
          r.educational_activities_id === selectedActivity.id &&
          r.username === row.username
      );

      if (isDuplicate) {
        duplicateStudents.push(row.fullname);
        return;
      }

      const awardNumbers = filteredData
        .map((r) => r.extra_attributes.award_number)
        .filter((number) => !isNaN(number))
        .map(Number)
        .sort((a, b) => a - b);

      // 현재까지의 최대 award_number 업데이트
      if (awardNumbers.length > 0) {
        maxAwardNumber = Math.max(
          maxAwardNumber,
          awardNumbers[awardNumbers.length - 1]
        );
      }

      const newAwardNumber = maxAwardNumber + 1; // 다음 award_number 계산
      maxAwardNumber = newAwardNumber; // 최대 award_number 업데이트

      const generatedTags = (selectedActivity.tags || []).map((tagObject) => {
        const updatedTagObject = {};
        Object.keys(tagObject).forEach((key) => {
          updatedTagObject[key] = "3";
        });
        return updatedTagObject;
      });

      const addDataSet = {
        schoolid: selectedActivity.schoolid,
        school_year: selectedActivity.school_year,
        educational_activities_id: selectedActivity.id,
        username: row.username,
        title: selectedActivity.title,
        content: selectedActivity.content,
        on_schoollife_record: "등재",
        extra_attributes: { award_rank: "", award_number: newAwardNumber },
        tags: generatedTags,
      };

      addedStudents.push(row.fullname);
      bulkDataSets.push(addDataSet);
    });

    if (bulkDataSets.length > 0) {
      setIsLoadingModal(true);
      createEduActiStudentsBulk.mutate(bulkDataSets, {
        onSuccess: (successMessage) => {
          console.log(successMessage);
          setIsLoadingModal(false);
          // 성공적으로 추가된 row.id들을 setIsAddedAll로 처리
          setIsAddedAll(true);
          setTimeout(() => {
            setIsAddedAll(false);
          }, 1500);

          message = `${addedStudents.length}${t(
            "teacher.doActivities.addedStudents"
          )} <br>- ${addedStudents.join(", ")}`;

          if (duplicateStudents.length > 0) {
            message = `${addedStudents.length}${t(
              "teacher.doActivities.addedStudents"
            )} <br>- ${addedStudents.join(", ")} <br> ${
              duplicateStudents.length
            }${t(
              "teacher.doActivities.duplicateStudents"
            )} <br> - ${duplicateStudents.join(", ")}`;
          }
          handleToastCenterTop(message, 4000);
        },
        onError: (error) => {
          setIsLoadingModal(false);
          console.error(error);
          alert("에러 발생: " + error.message);
        },
      });
    } else {
      if (duplicateStudents.length > 0) {
        message = `${duplicateStudents.length}${t(
          "teacher.doActivities.duplicateStudents"
        )} <br> - ${duplicateStudents.join(", ")}`;
      }
      handleToastCenterTop(message, 4000);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3 className="fw-bold">
          {t("teacher.doActivities.awardActivityTitle")}
          <HelpHyperLink link={"https://youtu.be/eeLMjA4R4QE"} />
        </h3>
        {hasPermission(selectedActivity?.creator_username, "can_create") ? (
          <button
            className="btn btn-sm btn-secondary mt-2"
            onClick={toggleSidebar}
          >
            {t("common.labels.assign")}
          </button>
        ) : (
          ""
        )}
      </div>
      <InsertStudentsSidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        handleAddClick={handleAddClick}
        isAdded={isAdded}
        isAddedAll={isAddedAll}
        handleAddAllClick={handleAddAllClick}
        schoolStageId={schoolStageId}
        schoolStageOrganPath={schoolStageOrganPath}
      />
      <table className="table mb-1">
        <thead>
          <tr className="table-secondary">
            <th style={{ width: "17%" }}>
              {t("teacher.createActivities.awardName")}
            </th>
            <th style={{ width: "10%" }}>
              {t("teacher.createActivities.awardDate")}
            </th>
            <th style={{ width: "12%" }}>
              {t("teacher.createActivities.awardOrgan")}
            </th>
            <th style={{ width: "12%" }}>
              {t("teacher.createActivities.awardTarget")}
            </th>
            <th style={{ width: "9%" }}>
              {t("teacher.createActivities.activityCreator")}
            </th>
            <th style={{ width: "40%" }}>{t("common.labels.content")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <select
                className="select-like-span"
                name="educational_activities"
                value={selectedActivity?.id || ""}
                onChange={handleActivityChange}
              >
                <option value="0">{t("common.labels.select") || "선택"}</option>
                {rows &&
                  rows.map((row, index) => (
                    <option key={index} value={row.id}>
                      {row.title}
                    </option>
                  ))}
              </select>
            </td>
            <td>{selectedActivity?.start_date}</td>
            <td>{selectedActivity?.extra_attributes?.institute}</td>
            <td>{selectedActivity?.extra_attributes?.participants}</td>
            <td width={80}>{selectedActivity?.fullname}</td>
            <td>{selectedActivity?.content}</td>
          </tr>
        </tbody>
      </table>
      <form>
        <div className="d-flex" style={{ fontSize: "14px" }}>
          <div className="me-2 ms-2">
            <div
              className="text-center"
              style={{ fontWeight: "bold", marginBottom: "6px" }}
            >
              {t("common.labels.tag")}
            </div>
            <button
              onClick={saveChanges}
              className="btn btn-sm btn-primary"
              name={selectedActivity?.id}
              style={{ fontSize: "14px" }}
            >
              {t("common.labels.save")}
            </button>
          </div>
          <div style={{ display: "flex", alignItems: "flex-end", gap: "5px" }}>
            {selectedActivity?.tags?.map((tag, index) => {
              const tagName = Object.keys(tag)[0];
              const optionValue =
                selectedOptions.find(
                  (option) => Object.keys(option)[0] === tagName
                )?.[tagName] ?? "";

              return (
                <div
                  key={index}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div className="text-center" style={{ marginBottom: "5px" }}>
                    {tagName}
                  </div>
                  <select
                    className="form-control p-1"
                    value={optionValue}
                    onChange={(e) =>
                      handleSelectChange(tagName, e.target.value)
                    }
                  >
                    <option value="1">{t("common.labels.veryWeak")}</option>
                    <option value="2">{t("common.labels.weak")}</option>
                    <option value="3">{t("common.labels.normal")}</option>
                    <option value="4">{t("common.labels.strong")}</option>
                    <option value="5">{t("common.labels.veryStrong")}</option>
                  </select>
                </div>
              );
            })}
          </div>
        </div>
      </form>
    </>
  );
}
