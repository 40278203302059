import React, { useEffect, useState } from "react";
import { verifyEmailLink } from "../../../api/firebase";
import { useUIContext } from "../../../context/UIContext";
import useUsers from "../../../hooks/useUsers";
import { useAuthContext } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";

export default function EmailVerification() {
  const [verifiedEmail, setVerifiedEmail] = useState("");
  const [verifiedUsername, setVerifiedUsername] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [error, setError] = useState(null);
  const { setIsLoadingModal } = useUIContext();
  const { updateUserEmail } = useUsers();
  const { refreshUser } = useAuthContext();

  const { t } = useTranslation();

  useEffect(() => {
    const verifyEmail = async () => {
      const url = window.location.href;
      const urlParams = new URLSearchParams(window.location.search);
      const isVerificationLink =
        urlParams.has("mode") && urlParams.has("oobCode");
      const username = urlParams.get("username");

      if (!isVerificationLink) {
        setError(
          t("common.commonPages.invalidLink") ||
            "유효하지 않은 인증 링크입니다."
        );
        return;
      }

      setIsLoadingModal(true);
      try {
        const result = await verifyEmailLink(url);
        setVerifiedEmail(result.user.email);
        setVerifiedUsername(username || "");
        setIsVerified(true);
      } catch (error) {
        setError(
          error.message ||
            t("common.commonPages.emailVerificationFailed") ||
            "이메일 인증에 실패했습니다."
        );
      } finally {
        setIsLoadingModal(false);
      }
    };

    verifyEmail();
  }, [setIsLoadingModal, t]);

  const handleConfirm = async () => {
    if (!verifiedUsername || !verifiedEmail) return;

    setIsLoadingModal(true);
    try {
      await updateUserEmail.mutate(
        { username: verifiedUsername, email: verifiedEmail },
        {
          onSuccess: () => {
            refreshUser();
            setIsLoadingModal(false);
            setIsUpdating(true);
          },
          onError: (error) => {
            setIsLoadingModal(false);
            setIsUpdating(false);
            setError(
              error.message ||
                t("common.commonPages.emailUpdateFailed") ||
                "이메일 업데이트에 실패했습니다."
            );
          },
        }
      );
    } catch (error) {
      setIsLoadingModal(false);
      setIsUpdating(false);
      setError(
        error.message ||
          t("common.commonPages.emailUpdateFailed") ||
          "이메일 업데이트에 실패했습니다."
      );
    }
  };

  const handleCancel = () => {
    setIsCanceled(true);
  };

  if (error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    );
  }

  if (isCanceled) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          {t("common.commonPages.emailVerificationCanceled") ||
            "이메일 인증이 취소되었습니다. 창을 닫아주세요."}
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body text-center">
          {isVerified ? (
            <>
              <h4 className="mb-4">
                {t("common.commonPages.emailVerificationCompleted") ||
                  "이메일 인증 완료"}
              </h4>
              <p className="mb-4">
                {t("common.commonPages.userId") || "사용자 아이디"}:{" "}
                <strong>{verifiedUsername}</strong> <br />
                {t("common.commonPages.verifiedEmail") || "인증된 이메일"}:{" "}
                <strong>{verifiedEmail}</strong>
                <br />
                {t("common.commonPages.registerEmail") ||
                  "이 이메일로 등록하시겠습니까?"}
              </p>
              {isUpdating ? (
                <div className="alert alert-success">
                  {t("common.commonPages.updateSuccess") ||
                    "성공적으로 업데이트가 되었습니다. 창을 닫고 에듀씨드 페이지에서 확인해 주세요."}
                </div>
              ) : (
                <div className="d-flex justify-content-center gap-2">
                  <button
                    className="btn btn-primary"
                    onClick={handleConfirm}
                    disabled={isUpdating}
                  >
                    {t("common.labels.confirm") || "확인"}
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={handleCancel}
                    disabled={isUpdating}
                  >
                    {t("common.labels.cancel") || "취소"}
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
