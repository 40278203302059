import React from "react";
import { useTranslation } from "react-i18next";

function ChangeDate({ currentDate, setCurrentDate }) {
  const { t } = useTranslation();

  // 이전 주로 이동하는 함수
  const goToPreviousWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 7);
    setCurrentDate(newDate);
    // handleDateChange();
  };

  // 다음 주로 이동하는 함수
  const goToNextWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 7);
    setCurrentDate(newDate);
    // handleDateChange();
  };

  return (
    <div className="row mb-4">
      <div className="row col-12 col-md-7">
        <div className="col-3 col-sm-2 d-flex flex-column justify-content-center align-items-center mb-3">
          {t("teacher.attendance.date") || "날짜:"}
        </div>
        <div className="col-6 col-sm-4 mb-3">
          <input
            type="date"
            className="form-control"
            value={currentDate.toLocaleDateString("sv-SE")}
            onChange={(e) => setCurrentDate(new Date(e.target.value))}
          />
        </div>
        <div className="col-4 col-sm-3 text-end">
          <button className="btn btn-outline-dark" onClick={goToPreviousWeek}>
            {t("teacher.attendance.previousWeek") || "이전 주"}
          </button>
        </div>
        <div className="col-4 col-sm-3 text-start">
          <button className="btn btn-outline-dark" onClick={goToNextWeek}>
            {t("teacher.attendance.nextWeek") || "다음 주"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangeDate;
