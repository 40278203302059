import React, { useEffect, useState } from "react";
import SideMenu from "../createActivities/components/SideMenu";
import DropdownsToFilter from "../createActivities/components/DropdownsToFilter";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useEducationalActivities from "../../hooks/useEducationalActivities";
import useOrganizationSetting from "../../hooks/useOrganizationSetting";
import AwardActivity from "./components/AwardActivity";
import AwardStudents from "./components/AwardStudents";
import CareerActivity from "./components/CareerActivity";
import CareerStudents from "./components/CareerStudents";
import UniDivider from "../../unicomponents/UniDivider";
import useEduActiStudents from "../../hooks/useEduActiStudents";
import VoluntaryActivity from "./components/VoluntaryActivity";
import VoluntaryStudents from "./components/VoluntaryStudents";
import ClubActivity from "./components/ClubActivity";
import ClubStudents from "./components/ClubStudents";
import EbomActivitiesByFieldsActivity from "./extraComponents/EbomActivitiesByFieldsActivity";
import EbomActivitiesByFieldsStudents from "./extraComponents/EbomActivitiesByFieldsStudents";
import useTermPeriod from "../../hooks/useTermPeriod";
import { useUIContext } from "../../context/UIContext";
import LoadingInside from "../../unicomponents/LoadingInside";
import SimpleAlertMessageBlock from "../../unicomponents/SimpleAlertMessageBlock";
import ActivitiesByFieldsActivity from "./components/ActivitiesByFieldsActivity";
import ActivitiesByFieldsStudents from "./components/ActivitiesByFieldsStudents";
import { useTranslation } from "react-i18next";

export default function DoActivities() {
  const { t } = useTranslation();
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = {
    schoolid: schoolId,
    entity_type: "educational_activities",
  };

  const [conditionsEduActivities, setConditionsEduActivities] = useState({
    schoolid: schoolId,
    activity_type_id: "",
  });

  const [eduActiStudentsConditions, setEduActiStudentsConditions] = useState({
    schoolid: schoolId,
    school_year: "",
    activity_type_id: "",
  });

  const { user, hasPermission } = useAuthContext();
  const { handleToastCenterBottom } = useUIContext();

  const typeConditions = { ...conditions, type: "true" };
  const {
    educationalActivitiesTypeQuery: { data: educationalActivitiesTypeData },
  } = useEducationalActivities(typeConditions);

  const {
    educationalActivitiesQuery: {
      isLoading: isLoadingTwo,
      data: educationalActivitiesData,
    },
  } = useEducationalActivities(conditionsEduActivities);

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  // 수정된 조건을 훅에 전달합니다.

  const {
    eduActiStudentsQuery: { isLoading, data: eduActiStudentsDataEx },
    createEduActiStudents,
    createEduActiStudentsBulk,
  } = useEduActiStudents(eduActiStudentsConditions);

  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod({ schoolid: schoolId });

  const [rows, setRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [schoolYear, setSchoolYear] = useState(null);
  const [schoolYearId, setSchoolYearId] = useState("all");
  const [schoolStageId, setSchoolStageId] = useState("");
  const [schoolStageOrganPath, setSchoolStageOrganPath] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedActivity, setSelectedActivity] = useState({});
  const [studentDataForDuplicateCheck, setStudentDataForDuplicateCheck] =
    useState([]);
  const [hierarchyTwoId, setHierarchyTwoId] = useState("");
  const [hierarchyThreeId, setHierarchyThreeId] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [filters, setFilters] = useState({});

  const [activeMenu, setActiveMenu] = useState({
    activity_type: "",
    activity_type_id: "",
    options: [],
    schoolid: schoolId,
  });

  const [selectedTerm, setSelectedTerm] = useState("");

  const [eduActiStudentsData, setEduActiStudentsData] = useState([]);

  useEffect(() => {
    if (educationalActivitiesTypeData) {
      setConditionsEduActivities((prev) => ({
        ...prev,
        activity_type_id: educationalActivitiesTypeData[0].id,
      }));

      setEduActiStudentsConditions((prev) => ({
        ...prev,
        activity_type_id: educationalActivitiesTypeData[0].id,
      }));

      const filteredOption =
        educationalActivitiesTypeData?.[0]?.options?.filter(
          (item) => schoolId in item
        ) || [];

      setActiveMenu({
        activity_type: educationalActivitiesTypeData[0].activity_type,
        activity_type_id: educationalActivitiesTypeData[0].id,
        options: filteredOption,
        schoolid: schoolId,
        activity_name: educationalActivitiesTypeData[0].activity_name,
      });
    }
  }, [educationalActivitiesTypeData, schoolId]);

  // 서버로부터 가져온 데이터를 rows 상태에 저장
  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;
    let initialSchoolStageId = null;
    let initialSchoolStageOrganPath = null;
    let initialUsername = null;
    let filteredData = [];

    if (educationalActivitiesData && settingData && settingData.length > 0) {
      const enrichedRows = educationalActivitiesData.map((activity) => {
        // settings 배열에서 activity의 hierarchy_one과 일치하는 id를 찾음
        const matchingSetting = settingData.find(
          (setting) =>
            setting.id.toString() === activity.hierarchy_one.toString()
        );

        // 찾은 setting이 있다면 group_name을 사용하고, 없다면 "통합"을 기본값으로 사용
        const groupName = matchingSetting
          ? matchingSetting.group_name
          : t("common.labels.whole");

        // activity 객체에 group_name을 추가하여 반환
        return {
          ...activity,
          schoolStage: groupName,
        };
      });

      if (schoolYear === null && user) {
        const sortedData = settingData.sort(
          (a, b) => b.school_year - a.school_year
        );

        const initialData = sortedData[0];
        const hierarchyLevelOneFiltered = sortedData.filter(
          (r) => r.hierarchy_level.toString() === "1"
        );

        initialSchoolYear = initialData.school_year;
        initialSchoolYearId = initialData.id;
        initialSchoolStageId = hierarchyLevelOneFiltered[0].id;
        initialSchoolStageOrganPath =
          hierarchyLevelOneFiltered[0].organization_path +
          "/" +
          initialSchoolStageId;
        initialUsername = user.username;
        setSchoolYear(initialSchoolYear);
        setSchoolYearId(initialSchoolYearId);
        setSchoolStageId(initialSchoolStageId);
        setSchoolStageOrganPath(initialSchoolStageOrganPath);

        filteredData = enrichedRows.filter(
          (row) =>
            row.school_year_id.toString() === initialSchoolYearId.toString() &&
            row.creator_username === initialUsername
        );

        if (hasPermission("", "can_read")) {
          setRows(filteredData);
          setOriginalData(
            enrichedRows.filter(
              (row) =>
                row.school_year_id.toString() === initialSchoolYearId.toString()
            )
          );
        } else {
          setRows(
            filteredData.filter((r) => r.creator_username === user?.username)
          );
          setOriginalData(
            filteredData.filter((r) => r.creator_username === initialUsername)
          );

          handleToastCenterBottom(t("common.messages.ownerReadPermission"));
        }
        setEduActiStudentsConditions((prev) => ({
          ...prev,
          school_year: initialSchoolYear || "",
        }));

        if (termPeriodData) {
          const filteredTermPeriod = termPeriodData.filter(
            (r) =>
              r.school_year_id.toString() === initialSchoolYearId.toString()
          );

          const sortedTerms = filteredTermPeriod.sort(
            (a, b) => new Date(a.start_date) - new Date(b.start_date)
          );
          setSelectedTerm(sortedTerms[0]?.term_name || "1");
        }
      } else {
        setEduActiStudentsConditions((prev) => ({
          ...prev,
          school_year: schoolYear || "",
        }));

        const filteredRows = filterData(enrichedRows, {
          schoolYearId,
          schoolStageId,
          selectedUser,
        });

        //이거 왜 만들었었지? 이거 없으면 오류 생기나?
        // if (filteredRows.length > 0) {
        // console.log("filteredRows", filteredRows);
        if (hasPermission("", "can_read")) {
          setRows(filteredRows);
          setOriginalData(
            enrichedRows.filter(
              (r) =>
                schoolStageId === "0" ||
                r.hierarchy_one.toString() === schoolStageId.toString()
            )
          );
        } else {
          setRows(
            filteredRows.filter((r) => r.creator_username === user?.username)
          );
          setOriginalData(
            enrichedRows.filter((r) => r.creator_username === user?.username)
          );
        }

        // setSelectedActivity(filteredRows[0]);
        // setFilters((filters) => ({
        //   ...filters,
        //   educational_activities_id: filteredRows[0].id,
        // }));
        // } else {
        //   setRows([]);
        // }
      }
    }
  }, [
    educationalActivitiesData,
    schoolYearId,
    settingData,
    schoolYear,
    schoolStageId,
    selectedUser,
    user,
    termPeriodData,
    hasPermission,
    handleToastCenterBottom,
    t,
  ]);

  useEffect(() => {
    if (eduActiStudentsDataEx && eduActiStudentsDataEx.length > 0) {
      if (hasPermission("", "can_read")) {
        setEduActiStudentsData(eduActiStudentsDataEx);
      } else {
        setEduActiStudentsData(
          eduActiStudentsDataEx.filter(
            (r) => r?.educational_activity?.creator_username === user?.username
          )
        );
        handleToastCenterBottom(t("common.messages.ownerReadPermission"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eduActiStudentsDataEx]);

  //아래 hierarchy 들 그냥 object로 해서 한 state로 하는게 나을 뻔 했는데...
  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    const organPath =
      e.target.selectedOptions[0].getAttribute("data-name") + "/" + value;

    if (name === "0") {
      setSchoolYear(selectedOptionText);
      setSchoolYearId(value);
      setSchoolStageId("0");
    }

    if (name === "1") {
      setSchoolStageId(value);
      setSchoolStageOrganPath(organPath);
      setHierarchyTwoId("");
      setHierarchyThreeId("");
      setSelectedStudent("");

      if (value === "0") {
        setFilters((filters) => {
          const {
            hierarchy_one,
            hierarchy_two,
            hierarchy_three,
            ...remainingFilters
          } = filters;
          return remainingFilters;
        });
      } else {
        setFilters((filters) => ({
          hierarchy_one: value,
        }));
      }
    }

    if (name === "users") {
      setSelectedUser(value);
    }

    if (name === "termName") {
      setSelectedTerm(value);
      setSelectedActivity("");
    }

    if (name === "2") {
      setHierarchyTwoId(value);
      setHierarchyThreeId("");
      setSelectedStudent("");

      if (value === "0") {
        setFilters((filters) => {
          const { hierarchy_two, hierarchy_three, ...remainingFilters } =
            filters;
          return remainingFilters;
        });
      } else {
        setFilters((filters) => ({
          ...filters,
          hierarchy_two: value,
        }));
      }
    }

    if (name === "3") {
      setHierarchyThreeId(value);
      setSelectedStudent("");

      if (value === "0") {
        setFilters((filters) => {
          const { hierarchy_three, ...remainingFilters } = filters;
          return remainingFilters;
        });
      } else {
        setFilters((filters) => ({
          ...filters,
          hierarchy_three: value,
        }));
      }
    }

    if (name === "students") {
      setSelectedStudent(value);
    }
  };

  const handleQueryCondition = (item) => {
    if (item?.id) {
      setRows([]);
      setOriginalData([]);

      setConditionsEduActivities((prev) => ({
        ...prev,
        activity_type_id: item.id,
      }));

      setEduActiStudentsConditions((prev) => ({
        ...prev,
        activity_type_id: item.id,
      }));

      setSelectedActivity({});
    }
  };

  function renderComponent() {
    switch (activeMenu.activity_type) {
      case "awards":
        return (
          <>
            <AwardActivity
              rows={rows}
              selectedActivity={selectedActivity}
              setSelectedActivity={setSelectedActivity}
              schoolStageId={schoolStageId}
              schoolStageOrganPath={schoolStageOrganPath}
              user={user}
              setFilters={setFilters}
              studentDataForDuplicateCheck={studentDataForDuplicateCheck}
              schoolYear={schoolYear}
              createEduActiStudents={createEduActiStudents}
              createEduActiStudentsBulk={createEduActiStudentsBulk}
            />
            <UniDivider />
            <AwardStudents
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYear={schoolYear}
              hierarchyOneId={schoolStageId}
              hierarchyTwoId={hierarchyTwoId}
              hierarchyThreeId={hierarchyThreeId}
              filters={filters}
              selectedUser={selectedStudent}
              selectedActivity={selectedActivity}
              setStudentDataForDuplicateCheck={setStudentDataForDuplicateCheck}
              schoolStageId={schoolStageId}
              eduActiStudentsData={eduActiStudentsData}
              isLoading={isLoading}
              eduActiStudentsConditions={eduActiStudentsConditions}
              studentDataForDuplicateCheck={studentDataForDuplicateCheck}
            />
          </>
        );
      case "careers":
        return (
          <>
            <CareerActivity
              rows={rows}
              selectedActivity={selectedActivity}
              setSelectedActivity={setSelectedActivity}
              schoolStageId={schoolStageId}
              schoolStageOrganPath={schoolStageOrganPath}
              user={user}
              setFilters={setFilters}
              studentDataForDuplicateCheck={studentDataForDuplicateCheck}
              schoolYear={schoolYear}
              createEduActiStudents={createEduActiStudents}
              createEduActiStudentsBulk={createEduActiStudentsBulk}
            />
            <UniDivider />
            <CareerStudents
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYear={schoolYear}
              hierarchyOneId={schoolStageId}
              hierarchyTwoId={hierarchyTwoId}
              hierarchyThreeId={hierarchyThreeId}
              filters={filters}
              selectedUser={selectedStudent}
              selectedActivity={selectedActivity}
              setStudentDataForDuplicateCheck={setStudentDataForDuplicateCheck}
              schoolStageId={schoolStageId}
              eduActiStudentsData={eduActiStudentsData}
              isLoading={isLoading}
              eduActiStudentsConditions={eduActiStudentsConditions}
            />
          </>
        );
      case "voluntary":
        return (
          <>
            <VoluntaryActivity
              rows={rows}
              selectedActivity={selectedActivity}
              setSelectedActivity={setSelectedActivity}
              schoolStageId={schoolStageId}
              schoolStageOrganPath={schoolStageOrganPath}
              user={user}
              setFilters={setFilters}
              studentDataForDuplicateCheck={studentDataForDuplicateCheck}
              schoolYear={schoolYear}
              createEduActiStudents={createEduActiStudents}
              createEduActiStudentsBulk={createEduActiStudentsBulk}
            />
            <UniDivider />
            <VoluntaryStudents
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYear={schoolYear}
              hierarchyOneId={schoolStageId}
              hierarchyTwoId={hierarchyTwoId}
              hierarchyThreeId={hierarchyThreeId}
              filters={filters}
              selectedUser={selectedStudent}
              selectedActivity={selectedActivity}
              setStudentDataForDuplicateCheck={setStudentDataForDuplicateCheck}
              schoolStageId={schoolStageId}
              eduActiStudentsData={eduActiStudentsData}
              isLoading={isLoading}
              eduActiStudentsConditions={eduActiStudentsConditions}
            />
          </>
        );
      case "clubs":
        return (
          <>
            <ClubActivity
              rows={rows}
              selectedActivity={selectedActivity}
              setSelectedActivity={setSelectedActivity}
              schoolStageId={schoolStageId}
              schoolStageOrganPath={schoolStageOrganPath}
              user={user}
              setFilters={setFilters}
              studentDataForDuplicateCheck={studentDataForDuplicateCheck}
              schoolYear={schoolYear}
              createEduActiStudents={createEduActiStudents}
              createEduActiStudentsBulk={createEduActiStudentsBulk}
            />
            <UniDivider />
            <ClubStudents
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYear={schoolYear}
              hierarchyOneId={schoolStageId}
              hierarchyTwoId={hierarchyTwoId}
              hierarchyThreeId={hierarchyThreeId}
              filters={filters}
              selectedUser={selectedStudent}
              selectedActivity={selectedActivity}
              setStudentDataForDuplicateCheck={setStudentDataForDuplicateCheck}
              schoolStageId={schoolStageId}
              eduActiStudentsData={eduActiStudentsData}
              isLoading={isLoading}
              eduActiStudentsConditions={eduActiStudentsConditions}
            />
          </>
        );
      case "ebomAddActivitiesByFields":
        return (
          <>
            <EbomActivitiesByFieldsActivity
              rows={rows}
              selectedActivity={selectedActivity}
              setSelectedActivity={setSelectedActivity}
              schoolStageId={schoolStageId}
              schoolStageOrganPath={schoolStageOrganPath}
              user={user}
              setFilters={setFilters}
              studentDataForDuplicateCheck={studentDataForDuplicateCheck}
              schoolYear={schoolYear}
              createEduActiStudents={createEduActiStudents}
              createEduActiStudentsBulk={createEduActiStudentsBulk}
              selectedTerm={selectedTerm}
            />
            <UniDivider />
            <EbomActivitiesByFieldsStudents
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYear={schoolYear}
              hierarchyOneId={schoolStageId}
              hierarchyTwoId={hierarchyTwoId}
              hierarchyThreeId={hierarchyThreeId}
              filters={filters}
              selectedUser={selectedStudent}
              selectedActivity={selectedActivity}
              setStudentDataForDuplicateCheck={setStudentDataForDuplicateCheck}
              schoolStageId={schoolStageId}
              eduActiStudentsData={eduActiStudentsData}
              isLoading={isLoading}
              eduActiStudentsConditions={eduActiStudentsConditions}
            />
          </>
        );
      case "addActivitiesByFields":
        return (
          <>
            <ActivitiesByFieldsActivity
              rows={rows}
              selectedActivity={selectedActivity}
              setSelectedActivity={setSelectedActivity}
              schoolStageId={schoolStageId}
              schoolStageOrganPath={schoolStageOrganPath}
              user={user}
              setFilters={setFilters}
              studentDataForDuplicateCheck={studentDataForDuplicateCheck}
              schoolYear={schoolYear}
              createEduActiStudents={createEduActiStudents}
              createEduActiStudentsBulk={createEduActiStudentsBulk}
              selectedTerm={selectedTerm}
              activeMenu={activeMenu}
              conditions={conditions}
            />
            <UniDivider />
            <ActivitiesByFieldsStudents
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYear={schoolYear}
              hierarchyOneId={schoolStageId}
              hierarchyTwoId={hierarchyTwoId}
              hierarchyThreeId={hierarchyThreeId}
              filters={filters}
              selectedUser={selectedStudent}
              selectedActivity={selectedActivity}
              setStudentDataForDuplicateCheck={setStudentDataForDuplicateCheck}
              schoolStageId={schoolStageId}
              eduActiStudentsData={eduActiStudentsData}
              isLoading={isLoading}
              eduActiStudentsConditions={eduActiStudentsConditions}
              activeMenu={activeMenu}
              conditions={conditions}
            />
          </>
        );
      default:
        return (
          <>
            <AwardActivity
              rows={rows}
              selectedActivity={selectedActivity}
              setSelectedActivity={setSelectedActivity}
              schoolStageId={schoolStageId}
              schoolStageOrganPath={schoolStageOrganPath}
              user={user}
              setFilters={setFilters}
              studentDataForDuplicateCheck={studentDataForDuplicateCheck}
              schoolYear={schoolYear}
              createEduActiStudents={createEduActiStudents}
              createEduActiStudentsBulk={createEduActiStudentsBulk}
            />
            <UniDivider />
            <AwardStudents
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYear={schoolYear}
              hierarchyOneId={schoolStageId}
              hierarchyTwoId={hierarchyTwoId}
              hierarchyThreeId={hierarchyThreeId}
              filters={filters}
              selectedUser={selectedStudent}
              selectedActivity={selectedActivity}
              setStudentDataForDuplicateCheck={setStudentDataForDuplicateCheck}
              schoolStageId={schoolStageId}
              eduActiStudentsData={eduActiStudentsData}
              isLoading={isLoading}
              eduActiStudentsConditions={eduActiStudentsConditions}
              studentDataForDuplicateCheck={studentDataForDuplicateCheck}
            />
          </>
        );
    }
  }

  if (isLoadingTwo) {
    return <LoadingInside />;
  }

  if (!hasPermission(null, "can_access")) {
    return (
      <SimpleAlertMessageBlock
        message={t("common.messages.noAccessPermission")}
      />
    );
  }

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <SideMenu
              educationalActivitiesTypeData={educationalActivitiesTypeData}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              extraFunction={handleQueryCondition}
              schoolId={schoolId}
            />
          </div>
          <div className="col-12 col-md-10">
            <div className="row">
              <div className="col-8 offset-4">
                <DropdownsToFilter
                  schoolYear={schoolYear}
                  schoolYearId={schoolYearId}
                  schoolStageId={schoolStageId}
                  handleDropdownChange={handleDropdownChange}
                  user={user}
                  settingData={settingData}
                  rows={originalData}
                  selectedUser={selectedUser}
                  activeMenu={activeMenu}
                  termPeriodData={termPeriodData}
                  selectedTerm={selectedTerm}
                />
              </div>
            </div>
            {renderComponent()}
          </div>
        </div>
      </div>
    </div>
  );
}

// 필터링 함수
function filterData(data, { schoolYearId, schoolStageId, selectedUser }) {
  return data.filter((item) => {
    const matchesSchoolYearId =
      schoolYearId === "all" ||
      item.school_year_id.toString() === schoolYearId.toString();
    const matchesSchoolStageId =
      schoolStageId === "0" ||
      item.hierarchy_one.toString() === schoolStageId.toString();
    const matchesSelectedUser =
      selectedUser === "" || item.creator_username === selectedUser;

    return matchesSchoolYearId && matchesSchoolStageId && matchesSelectedUser;
  });
}
