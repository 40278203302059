import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { useAuthContext } from "../../../../context/AuthContext";
import { checkHrteachers } from "../../../../util/checkPermissions";
import { useUIContext } from "../../../../context/UIContext";
import { useTranslation } from "react-i18next";
import useJubileeAward from "../../../../hooks/useJubileeAward";
import useFlexibleData from "../../../../hooks/useFlexibleData";

function JubileeAwardTable({
  rows,
  weekStartEndDates,
  idsForKey,
  jubileeAwardRecord = [],
  setIsUpLoading,
  schoolId,
  refetchData,
  setJubileeAwardRecord,
  saveJubileeAward,
  setSaveJubileeAward,
  hrTeachersData,
  termInfo,
  termId,
}) {
  const { t } = useTranslation();

  const [isSaving, setIsSaving] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  // 현재 주의 시작일과 종료일 계산
  const [weekDateRange, setWeekDateRange] = useState("");
  const [filteredAwardRecord, setFilteredAwardRecord] = useState("");
  // 통계 데이터 상태
  const [statistics, setStatistics] = useState({});

  const { createJubileeAward } = useJubileeAward();
  const { handleToastCenterTop } = useUIContext();
  const { hasPermission } = useAuthContext();

  const {
    flexibleDataQuery: { data: flexibleData },
    createOrUpdateFlexibleData,
  } = useFlexibleData({
    schoolid: schoolId,
    main_category: "jubileeAward",
    sub_category: "max",
  });

  const [isEditingMax, setIsEditingMax] = useState(false);
  const [maxValues, setMaxValues] = useState({});

  useEffect(() => {
    if (weekStartEndDates) {
      const startDate = moment(weekStartEndDates.startDate).format("YY.MM.DD");
      const endDate = moment(weekStartEndDates.endDate).format("YY.MM.DD");
      setWeekDateRange(`${startDate} ~ ${endDate}`);

      const filteredRecord = jubileeAwardRecord.filter((record) => {
        return record.date === weekStartEndDates.startDate;
      });

      setFilteredAwardRecord(filteredRecord);
    }
  }, [weekStartEndDates, jubileeAwardRecord]);

  // categories 배열을 useMemo로 감싸기
  const categories = useMemo(
    () => [
      { key: "R", field: "r_raz", defaultValue: "1", showAllButton: true },
      { key: "L", field: "l_library", defaultValue: "1", showAllButton: true },
      {
        key: "J",
        field: "j_privilege",
        defaultValue: "1",
        showAllButton: true,
      },
      {
        key: "P",
        field: "p_privilege",
        defaultValue: "1",
        showAllButton: true,
      },
      {
        key: "S",
        field: "s_privilege",
        defaultValue: "1",
        showAllButton: true,
      },
      { key: "F", field: "f_family", defaultValue: "7", showAllButton: true },
      { key: "C", field: "c_church", defaultValue: "1", showAllButton: true },
      { key: "D", field: "d_demerit", defaultValue: "1", showAllButton: false },
      {
        key: "E",
        field: "eco_mileage",
        defaultValue: "1",
        showAllButton: false,
        minWidth: "60px",
      },
    ],
    []
  ); // 빈 의존성 배열

  useEffect(() => {
    if (flexibleData && flexibleData[0]?.data_value) {
      setMaxValues(flexibleData[0].data_value);
    } else {
      // 기본값으로 초기화
      const defaultMax = {};
      categories.forEach((cat) => {
        defaultMax[cat.key] = cat.defaultValue;
      });
      setMaxValues(defaultMax);
    }
  }, [flexibleData, categories]);

  // 통계 계산 함수
  const calculateStatistics = () => {
    const stats = {};

    if (!rows || rows.length === 0 || !jubileeAwardRecord) return;

    rows.forEach((student) => {
      const studentStats = {
        R: 0,
        L: 0,
        J: 0,
        P: 0,
        S: 0,
        F: 0,
        C: 0,
        D: 0,
        E: 0,
        total: 0,
      };

      // 학생별 기록 찾기 - 학기 전체 데이터 사용
      const studentRecords = jubileeAwardRecord.filter(
        (record) => record.username === student.username
      );

      // console.log(`${student.fullname}의 학기 전체 기록:`, studentRecords);

      // 각 카테고리 합계 계산
      studentRecords.forEach((record) => {
        // 각 카테고리의 필드명을 사용하여 값 추출
        categories.forEach((cat) => {
          const value = parseInt(record[cat.field]) || 0;
          studentStats[cat.key] += value;
        });
      });

      // 총합 계산 (total 필드 자체는 제외)
      studentStats.total =
        studentStats.R +
        studentStats.L +
        studentStats.J +
        studentStats.P +
        studentStats.F +
        studentStats.C +
        studentStats.D +
        studentStats.E;

      stats[student.username] = studentStats;
    });

    setStatistics(stats);
    // console.log("학기 전체 통계:", stats);
  };

  // 컴포넌트 마운트 시와 jubileeAwardRecord 변경 시 통계 계산
  useEffect(() => {
    calculateStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jubileeAwardRecord, rows]);

  const handleChange = (e, username, category) => {
    const value = e.target.value;
    const date = weekStartEndDates.startDate; // 주간의 월요일 날짜 사용

    if (!termInfo || Object.keys(termInfo).length === 0) {
      handleToastCenterTop("학기를 선택 후 입력해 주세요.");
      return;
    }
    // 기존 레코드 찾기
    const existingRecord = jubileeAwardRecord.find(
      (record) =>
        record.username === username &&
        new Date(record.date).toLocaleDateString("sv-SE") === date
    );
    const newData = {
      schoolid: schoolId,
      username,
      date,
      ...(existingRecord || {}),
      [category]: value,
      id: existingRecord ? existingRecord.id : "",
    };

    setJubileeAwardRecord((prevData) => {
      const index = prevData.findIndex(
        (record) =>
          record.username === username &&
          new Date(record.date).toLocaleDateString("sv-SE") === date
      );

      if (index !== -1) {
        // 기존 레코드 업데이트
        return prevData.map((record, i) =>
          i === index ? { ...record, [category]: value } : record
        );
      } else {
        // 새 레코드 추가
        return [...prevData, newData];
      }
    });

    // 저장할 데이터 업데이트 - 배열 형태로 수정
    setSaveJubileeAward((prevData) => {
      // 배열이 아닌 경우 빈 배열로 초기화
      const prevArray = Array.isArray(prevData) ? prevData : [];

      const index = prevArray.findIndex(
        (record) =>
          record.username === username &&
          new Date(record.date).toLocaleDateString("sv-SE") === date
      );

      if (index !== -1) {
        // 기존 항목 업데이트
        return prevArray.map((item, i) =>
          i === index ? { ...item, [category]: value } : item
        );
      } else {
        // 새 항목 추가
        return [...prevArray, newData];
      }
    });
  };

  // 학생별 저장 상태 관리
  const [studentSaving, setStudentSaving] = useState({});

  // 학생별 저장 함수
  const handleSaveStudent = (username) => {
    // 현재 날짜가 학기 기간 내에 있는지 확인

    if (
      !termId ||
      !termInfo.start_date ||
      !termInfo.end_date ||
      weekStartEndDates.endDate < termInfo.start_date ||
      weekStartEndDates.startDate > termInfo.end_date
    ) {
      handleToastCenterTop(
        "학기가 선택되지 않았거나, 선택된 날짜가 학기 기간 안에 있지 않아서 저장할 수 없습니다."
      );
      return;
    }

    // 해당 학생의 현재 주간 데이터만 필터링
    const studentData = saveJubileeAward?.filter(
      (item) =>
        item.username === username && item.date === weekStartEndDates.startDate
    );

    if (!studentData || studentData.length === 0) {
      handleToastCenterTop(
        t("common.messages.noChange") || "변동된 데이터가 없습니다."
      );
      return;
    }

    if (
      !hasPermission(
        null,
        "can_create",
        checkHrteachers(hrTeachersData, username, rows[0]?.school_year)
      )
    ) {
      handleToastCenterTop(
        t("common.messages.homeroomTeacherOnly") ||
          "학생의 담임과 권한이 있는 사람만 저장할 수 있습니다."
      );
      return;
    }

    // 저장 상태 업데이트
    setStudentSaving((prev) => ({ ...prev, [username]: true }));

    createJubileeAward.mutate(studentData, {
      onSuccess: () => {
        refetchData(termInfo.start_date, termInfo.end_date);
        setIsUpLoading(false);

        // 2초 후 저장 상태 초기화
        setTimeout(() => {
          setStudentSaving((prev) => ({ ...prev, [username]: false }));
        }, 2000);
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error);
        alert(t("common.messages.errorAlert") + error);
        setStudentSaving((prev) => ({ ...prev, [username]: false }));
      },
    });
  };

  // 모든 학생 데이터 저장 함수
  const handleSaveAllStudents = () => {
    if (
      !termId ||
      !termInfo.start_date ||
      !termInfo.end_date ||
      weekStartEndDates.endDate < termInfo.start_date ||
      weekStartEndDates.startDate > termInfo.end_date
    ) {
      handleToastCenterTop(
        "학기가 선택되지 않았거나, 선택된 날짜가 학기 기간 안에 있지 않아서 저장할 수 없습니다."
      );
      return;
    }

    // 모든 학생의 현재 주간 데이터 가져오기
    const allStudentData = saveJubileeAward?.filter(
      (item) => item.date === weekStartEndDates.startDate
    );

    if (!allStudentData || allStudentData.length === 0) {
      handleToastCenterTop(
        t("common.messages.noChange") || "변동된 데이터가 없습니다."
      );
      return;
    }

    // 권한 확인 (관리자 권한이 있는지 확인)
    if (!hasPermission(null, "can_create", true)) {
      handleToastCenterTop(
        t("common.messages.permissionDenied") || "권한이 없습니다."
      );
      return;
    }

    // 전체 저장 상태 업데이트
    setIsSaving((prev) => {
      const copy = [...prev];
      copy[0] = true;
      return copy;
    });

    createJubileeAward.mutate(allStudentData, {
      onSuccess: () => {
        refetchData(termInfo.start_date, termInfo.end_date);
        setIsUpLoading(false);

        // 2초 후 저장 상태 초기화
        setTimeout(() => {
          setIsSaving((prev) => {
            const copy = [...prev];
            copy[0] = false;
            return copy;
          });
        }, 2000);

        handleToastCenterTop(
          t("common.messages.savedSuccessfully") ||
            "모든 데이터가 성공적으로 저장되었습니다."
        );
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error);
        alert(t("common.messages.errorAlert") + error);

        setIsSaving((prev) => {
          const copy = [...prev];
          copy[0] = false;
          return copy;
        });
      },
    });
  };

  // 카테고리 전체 값 설정 함수
  const handleSetAllForCategory = (categoryField) => {
    const date = weekStartEndDates.startDate;

    // 카테고리에 해당하는 기본값 찾기
    const category = categories.find((cat) => cat.field === categoryField);
    const defaultValue = category ? category.defaultValue : "1";

    // jubileeAwardRecord 업데이트
    const updatedRecords = [...jubileeAwardRecord];

    rows.forEach((student) => {
      const username = student.username;
      const existingRecordIndex = updatedRecords.findIndex(
        (record) =>
          record.username === username &&
          new Date(record.date).toLocaleDateString("sv-SE") === date
      );

      if (existingRecordIndex !== -1) {
        updatedRecords[existingRecordIndex] = {
          ...updatedRecords[existingRecordIndex],
          [categoryField]: defaultValue,
        };
      } else {
        updatedRecords.push({
          schoolid: schoolId,
          username,
          date,
          [categoryField]: defaultValue,
        });
      }
    });

    setJubileeAwardRecord(updatedRecords);

    // saveJubileeAward 업데이트
    const updatedSaveData = Array.isArray(saveJubileeAward)
      ? [...saveJubileeAward]
      : [];

    rows.forEach((student) => {
      const username = student.username;
      const existingIndex = updatedSaveData.findIndex(
        (record) => record.username === username && record.date === date
      );

      if (existingIndex !== -1) {
        updatedSaveData[existingIndex] = {
          ...updatedSaveData[existingIndex],
          [categoryField]: defaultValue,
        };
      } else {
        updatedSaveData.push({
          schoolid: schoolId,
          username,
          date,
          [categoryField]: defaultValue,
        });
      }
    });

    setSaveJubileeAward(updatedSaveData);
  };

  // max 값 변경 핸들러
  const handleMaxValueChange = (category, value) => {
    setMaxValues((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  // max 값 저장 핸들러
  const handleSaveMaxValues = () => {
    const dataToSave = {
      schoolid: schoolId,
      main_category: "jubileeAward",
      sub_category: "max",
      data_value: maxValues,
      id: flexibleData && flexibleData[0]?.id ? flexibleData[0].id : "",
    };

    setStudentSaving((prev) => ({ ...prev, max: true }));
    createOrUpdateFlexibleData.mutate(dataToSave, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        // 2초 후 저장 상태 초기화
        setTimeout(() => {
          setStudentSaving((prev) => ({ ...prev, max: false }));
        }, 2000);
        handleToastCenterTop(t("common.messages.savedSuccessfully"));
        setIsEditingMax(false);
      },
      onError: (error) => {
        // 2초 후 저장 상태 초기화
        setTimeout(() => {
          setStudentSaving((prev) => ({ ...prev, max: false }));
        }, 2000);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  return (
    <table
      className="table table-striped table-bordered border text-center"
      style={{ minWidth: "700px", fontSize: "13px" }}
    >
      <thead>
        <tr>
          <th colSpan={5}></th>
          <th colSpan={10} style={{ minWidth: "400px" }}>
            선택된 날짜 {weekDateRange}
          </th>
          <th colSpan={9} style={{ minWidth: "320px" }}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mx-auto">
                {termInfo.school_year &&
                  termInfo.term_name &&
                  `${termInfo.school_year}-${termInfo.term_name}`}
                학기 통계
              </div>
              {hasPermission(null, "is_admin") && (
                <div>
                  {isEditingMax ? (
                    <button
                      className="btn btn-success btn-sm p-1 m-0"
                      onClick={handleSaveMaxValues}
                      style={{ fontSize: "12px" }}
                    >
                      {t("common.labels.save") || "저장"}
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-dark btn-sm p-1 m-0"
                      onClick={() => setIsEditingMax(true)}
                      style={{ fontSize: "12px" }}
                    >
                      {t("common.labels.modify") || "수정"}
                    </button>
                  )}
                </div>
              )}
            </div>
          </th>
        </tr>
        <tr>
          <th style={{ minWidth: "40px" }}>No.</th>

          {idsForKey &&
            Object.keys(idsForKey).map((item) => {
              if (item === "0") return null;
              return (
                <th key={item} style={{ minWidth: "65px" }}>
                  {idsForKey[item][0]}
                </th>
              );
            })}
          <th style={{ minWidth: "80px" }}>
            {t("common.user.fullname") || "이름"}
          </th>
          {categories.map((cat) => (
            <th
              key={cat.key}
              className="p-1 pt-0 pb-0 align-middle"
              style={cat.minWidth ? { minWidth: cat.minWidth } : {}}
            >
              {cat.showAllButton && (
                <button
                  className="btn btn-sm btn-outline-primary p-0 m-0"
                  style={{
                    fontSize: "12px",
                    width: "25px",
                    height: "20px",
                    lineHeight: "1",
                  }}
                  onClick={() => handleSetAllForCategory(cat.field)}
                >
                  All
                </button>
              )}
              <br />
              {cat.key}
            </th>
          ))}
          <th className="p-0 align-middle" style={{ minWidth: "70px" }}>
            <button
              className="btn btn-primary btn-sm p-1 m-0"
              onClick={handleSaveAllStudents}
              disabled={isSaving[0]}
              style={{ fontSize: "13px" }}
            >
              {isSaving[0]
                ? t("common.labels.complete") || "완료"
                : t("common.labels.saveAll") || "모두저장"}
            </button>
          </th>

          {categories.map((cat) => (
            <th key={`stat-${cat.key}`} className="p-0 align-middle">
              {cat.key}
              <br />
              {isEditingMax ? (
                <input
                  type="number"
                  className="form-control form-control-sm p-0 text-center"
                  style={{ fontSize: "12px", height: "20px" }}
                  value={maxValues[cat.key] || cat.defaultValue}
                  onChange={(e) =>
                    handleMaxValueChange(cat.key, e.target.value)
                  }
                />
              ) : (
                <small className="text-muted">
                  {flexibleData && flexibleData[0]?.data_value
                    ? `${
                        flexibleData[0].data_value[cat.key] || cat.defaultValue
                      }점`
                    : `${cat.defaultValue}점`}
                </small>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows &&
          rows.map((student, index) => (
            <tr key={index}>
              <td className="align-middle">{index + 1}</td>
              {Object.keys(idsForKey).map((item, itemIndex) => {
                if (item === "0") return null;
                return (
                  <td className="align-middle" key={`${index}-${itemIndex}`}>
                    {student[item]}
                  </td>
                );
              })}
              <td className="align-middle">{student.fullname}</td>

              {/* 입력 필드 */}
              {categories.map((cat) => (
                <td key={`input-${cat.key}`} className="p-1 align-middle">
                  {(() => {
                    // 학생별 레코드를 한 번만 찾음
                    const studentRecord =
                      filteredAwardRecord &&
                      filteredAwardRecord.find(
                        (record) => record.username === student.username
                      );

                    // console.log("filteredAwardRecord", filteredAwardRecord);
                    // console.log("studentRecord", studentRecord);

                    // 찾은 레코드에서 필드 값 사용
                    return (
                      <input
                        type="number"
                        className="input-like-span"
                        value={
                          studentRecord ? studentRecord[cat.field] || "" : ""
                        }
                        onChange={(e) =>
                          handleChange(e, student.username, cat.field)
                        }
                      />
                    );
                  })()}
                </td>
              ))}

              {/* 저장 버튼 */}
              <td className="align-middle p-0">
                <button
                  className="btn btn-primary btn-sm p-1 m-0"
                  onClick={() => handleSaveStudent(student.username)}
                  disabled={studentSaving[student.username]}
                  style={{ fontSize: "13px" }}
                >
                  {studentSaving[student.username]
                    ? t("common.labels.complete") || "완료"
                    : t("common.labels.save") || "저장"}
                </button>
              </td>

              {/* 통계 표시 */}
              {categories.map((cat) => (
                <td key={`stat-${cat.key}`}>
                  {statistics[student.username]
                    ? statistics[student.username][cat.key]
                    : 0}
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default JubileeAwardTable;
