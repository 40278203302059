import React, { useState } from "react";
import AddSchool from "./AddSchool";
import SchoolTr from "./SchoolTr";
import useDeveloper from "../../../hooks/useDeveloper";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import LoadingModal from "../../../unicomponents/LoadingModal";

export default function DevSchools() {
  const { t } = useTranslation();

  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const developerLink = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = { developerLink: developerLink };

  const {
    schoolsQuery: { isLoading, error, data: schoolData },
  } = useDeveloper(conditions);

  // console.log("schoolData", schoolData);

  const [isUploading, setIsUploading] = useState(false);
  const [success, setSuccess] = useState();
  const [successTr, setSuccessTr] = useState();
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setSchoolData(data);
  // }, [developerService, data]);

  // 만들어야 하는 것
  // 1. 테이블에 data 수 만큼 반복해서 나오게 하기
  // 2. 등록하기  - 여기 까지 했다.
  // 3. 업데이트 하기 - 여기 까지 했다. 이제 삭제만 하면 됨.
  // 4. 삭제하기 - 여기까지 하긴 했는데... 만들자 마자 삭제를 빠르게 누르면, 삭제가 db에서는 되는데, 화면에서 안 없어져서 다시 한번 누르면 에러가 발생한다...
  // 일단 넘어가자. 나중에 해결

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      <div>
        <div className="container">
          <div className="row mb-4">
            <h3 className="fw-bold mt-3">새로운 학교 등록</h3>
            {success && <p>✅{success}</p>}
            <AddSchool
              isUploading={isUploading}
              setSuccess={setSuccess}
              setIsUploading={setIsUploading}
              // setLoading={setLoading}
            />
          </div>
          <div className="row table-responsive">
            {successTr && <p>✅{successTr}</p>}
            <table className="table table-bordered">
              <colgroup>
                <col style={{ width: "4%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "9%" }} />
                <col style={{ width: "14%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "8%" }} />
              </colgroup>

              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">학교아이디</th>
                  <th scope="col">학교이름</th>
                  <th scope="col">관리자이름</th>
                  <th scope="col">관리자아이디</th>
                  <th scope="col">{t("common.user.password") || "비밀번호"}</th>
                  <th scope="col">상태</th>
                  <th scope="col">사용자그룹</th>
                  <th scope="col">계약시작일</th>
                  <th scope="col">저장/삭제</th>
                </tr>
              </thead>
              <tbody>
                {schoolData &&
                  schoolData.map((school, index) => (
                    <SchoolTr
                      key={school.id}
                      school={school}
                      index={index}
                      setIsUploading={setIsUploading}
                      setSuccessTr={setSuccessTr}
                      // setLoading={setLoading}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <LoadingModal show={isUploading} /> */}
    </>
  );
}
