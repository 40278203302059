import React, { useEffect, useState } from "react";
import useVariousStudentRecords from "../../../../hooks/useVariousStudentRecords";
import { useUIContext } from "../../../../context/UIContext";
import DropdownSchoolYear from "../../../../unicomponents/Dropdowns/DropdownSchoolYear";
import DropdownHierarchyOne from "../../../../unicomponents/Dropdowns/DropdownHierarchyOne";
import DropdownHierarchyTwo from "../../../../unicomponents/Dropdowns/DropdownHierarchyTwo";
import DropdownHierarchyThree from "../../../../unicomponents/Dropdowns/DropdownHierarchyThree";
import RegistrationVariousRecordInputTable from "../../../../unicomponents/registrationVariousRecordInputTable/RegistrationVariousRecordInputTable";
import { useTranslation } from "react-i18next";

export default function ParentEducation({
  settingData,
  inputData,
  schoolId,
  inputQueryConditions,
}) {
  const { t } = useTranslation();

  const [filters, setFilters] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    organPathForSort: "N/A",
    group: "",
  });

  const [queryConditions, setQueryConditions] = useState({
    schoolid: schoolId,
    school_year_id: "",
    record_list_id: inputQueryConditions?.record_list_id,
  });
  const [dataToSave, setDataToSave] = useState([]);
  const [savedRows, setSavedRows] = useState([]);
  const [savedBulk, setSavedBulk] = useState(false);

  const {
    variousStudentRecordsQuery: { data: variousStudentData },
    createOrUpdateVariousStudentRecords,
    createOrUpdateVariousStudentRecordsBulk,
  } = useVariousStudentRecords(queryConditions);

  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;

    if (filters.schoolYear === null && settingData && settingData.length > 0) {
      const initialData = settingData.sort(
        (a, b) => b.school_year - a.school_year
      )[0];

      initialSchoolYear = initialData.school_year;
      initialSchoolYearId = initialData.id;
      setFilters((filters) => ({
        ...filters,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
      }));

      setQueryConditions((prev) => ({
        ...prev,
        school_year_id: initialSchoolYearId,
        record_list_id: inputQueryConditions?.record_list_id,
      }));
    } else {
      setQueryConditions((prev) => ({
        ...prev,
        school_year_id: filters.schoolYearId,
        record_list_id: inputQueryConditions?.record_list_id,
      }));
    }
  }, [
    filters.schoolYear,
    filters.schoolYearId,
    inputQueryConditions?.record_list_id,
    settingData,
  ]);

  useEffect(() => {
    if (variousStudentData) {
      const studentDataFilteredByGroup = variousStudentData.filter(
        (r) => r.group === filters.group
      );
      setDataToSave(studentDataFilteredByGroup);
    }
  }, [filters.group, variousStudentData]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    const dataName = e.target.selectedOptions[0].getAttribute("data-name");
    let organPath = "";
    if (!dataName) {
      organPath = "N/A";
    } else {
      organPath = dataName + "/" + value;
    }

    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "",
        organPathForSort: "N/A",
      }));
      setQueryConditions((prev) => ({ ...prev, school_year_id: value }));
    }

    if (name === "1") {
      setFilters((filters) => ({
        ...filters,
        hierarchyOneId: value,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
        organPathForSort: organPath,
      }));
    }

    if (name === "2") {
      if (value === "0") {
        organPath = "/" + filters.schoolYearId + "/" + filters.hierarchyOneId;
      }

      setFilters((filters) => ({
        ...filters,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
        organPathForSort: organPath,
      }));
    }

    if (name === "3") {
      if (value === "0") {
        organPath =
          "/" +
          filters.schoolYearId +
          "/" +
          filters.hierarchyOneId +
          "/" +
          filters.hierarchyTwoId;
      }

      setFilters((filters) => ({
        ...filters,
        hierarchyThreeId: value,
        organPathForSort: organPath,
      }));
    }
  };

  const handleSaveState = (e, username, inputs_id) => {
    // console.log("dataToSave", dataToSave);
    // console.log(username);
    let value = e.target.value;
    let name = e.target.name;

    setDataToSave((prevData) => {
      const existingItem = prevData.find((item) => item.username === username);

      if (existingItem) {
        let updatedValueObject = {
          ...existingItem.value_object,
          [name]: value,
        };

        return prevData.map((item) =>
          item.username === username
            ? {
                ...item,
                value_object: updatedValueObject,
                isModified: true,
              }
            : item
        );
      } else {
        let valueObject = { [name]: value };
        return [
          ...prevData,
          {
            schoolid: schoolId,
            school_year_id: filters.schoolYearId, // 적절한 기본값을 설정합니다.
            record_list_id: inputQueryConditions?.record_list_id,
            group: filters.group, // 적절한 기본값을 설정합니다.
            username,
            value_object: valueObject,
            isModified: true,
            // 기본값을 설정합니다. 필요에 따라 다른 기본값을 설정할 수 있습니다.
          },
        ];
      }
    });
  };

  const handleSaveClick = (row) => {
    // console.log("row", row);
    // console.log("dataToSave", dataToSave);

    const trimmedDataToSave = dataToSave.map((item) => {
      const trimmedValueObject = Object.fromEntries(
        Object.entries(item.value_object).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim() : value,
        ])
      );
      return { ...item, value_object: trimmedValueObject };
    });

    const toSave = trimmedDataToSave.filter((r) => r.username === row.username);
    // console.log("toSave", toSave);

    // console.log("toSave.isModified", toSave.isModified);

    if (toSave.length < 1) {
      handleToastCenterTop(t("common.messages.notModifed"));

      return;
    }

    if (!toSave[0].isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop(t("common.messages.notModifed"));
      return;
    }

    setIsLoadingModal(true);
    createOrUpdateVariousStudentRecords.mutate(toSave[0], {
      onSuccess: (successMessage) => {
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, toSave[0].username]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);

        // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
        setDataToSave((currentDataSets) =>
          currentDataSets.map((dataSet) =>
            dataSet.username === toSave[0].username
              ? { ...dataSet, isModified: false }
              : dataSet
          )
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleSaveAll = () => {
    const trimmedDataToSave = dataToSave.map((item) => {
      const trimmedValueObject = Object.fromEntries(
        Object.entries(item.value_object).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim() : value,
        ])
      );
      return { ...item, value_object: trimmedValueObject };
    });

    const modifiedData = trimmedDataToSave.filter((r) => r.isModified === true);
    if (modifiedData.length < 1) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop(t("common.messages.notModifed"));
      return;
    }
    // 변경된 데이터 세트 처리 로직...
    // console.log("제출된 데이터 세트:", dataArray);
    setIsLoadingModal(true);
    createOrUpdateVariousStudentRecordsBulk.mutate(modifiedData, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedBulk(true);
        setTimeout(() => {
          setSavedBulk(false);
        }, 1000);

        // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
        setDataToSave((currentDataSets) =>
          currentDataSets.map((data) =>
            data.isModified === true ? { ...data, isModified: false } : data
          )
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  //이건 나중에 사용자가 설정할 수 있도록 db에서 가져오는 것이 좋다. setting page 만들면...
  const statistics = ["부", "모", "부모", "보호자", "출석인정"];

  return (
    <>
      <div className="row mt-3">
        <div className="col-1"></div>
        <div className="col">
          <DropdownSchoolYear
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYearId={filters.schoolYearId}
            allYears={false}
          />
        </div>
        <div className="col">
          <DropdownHierarchyOne
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyOneId={filters.hierarchyOneId}
          />
        </div>
        <div className="col">
          <DropdownHierarchyTwo
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyOneId={filters.hierarchyOneId}
            hierarchyTwoId={filters.hierarchyTwoId}
          />
        </div>
        <div className="col">
          <DropdownHierarchyThree
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyTwoId={filters.hierarchyTwoId}
            hierarchyThreeId={filters.hierarchyThreeId}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-auto">
          <h3 className="fw-bold">
            학부모 교육 출석기록
            {/* <HelpHyperLink link="" /> */}
          </h3>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col" style={{ minWidth: "800px", overflowX: "auto" }}>
          <RegistrationVariousRecordInputTable
            organSettingInfo={filters}
            inputData={inputData}
            studentInputData={dataToSave}
            handleSaveState={handleSaveState}
            handleSaveClick={handleSaveClick}
            handleSaveAll={handleSaveAll}
            savedRows={savedRows}
            savedBulk={savedBulk}
            addfontSize="1"
            addMinWidth="-5"
            statistics={statistics}
            extraSettings="termName"
            exportable={true}
            exportFileName="학부모교육 출석기록"
            exportButtonLabel="엑셀 다운로드"
          />
        </div>
      </div>
    </>
  );
}
