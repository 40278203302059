import React from "react";
import styles from "./CSS/SideMenu.module.css";
import { useTranslation } from "react-i18next";
//받을 props는 setState(해당 컴포넌트로 바꿔주는) 1개와
//리스트할 data(이동할 컴포넌트의 name과 화면에 표시될 label)만 있으면 된다.
export default function LeftMenuSidebar({
  activeMenu,
  setActiveMenu,
  menuData,
  extraFunction,
  itemName = "record_name",
  itemLabel = "record_label",
}) {
  const { t } = useTranslation();
  return (
    <div className={`align-items-start bg-secondary-subtle ${styles.sideMenu}`}>
      <div className="p-2 w-100">
        <h4 className="text-center mt-3">
          {t("common.uniComponents.selectItem") || "항목 선택"}
        </h4>
        {menuData?.map((item) => (
          <button
            key={item[itemName]} // Key should be unique for each sibling.
            className={`btn my-2 w-100 ${
              activeMenu.menu_name === item[itemName] ? "btn-info" : "btn-light"
            }`} // Conditional class name
            type="button"
            onClick={() => {
              setActiveMenu({
                menu_name: item[itemName],
                menu_id: item.id,
                description: item.description,
              }); // Correctly setting the active menu.

              if (extraFunction) {
                extraFunction(item);
              }
            }}
          >
            {item[itemLabel]}
          </button>
        ))}
      </div>
    </div>
  );
}
