import React, { useEffect, useState } from "react";
import useUsers from "../../hooks/useUsers";
import { useLocation } from "react-router-dom";
import UniTable from "../../unicomponents/UniTable";
import { MdCheck, MdSave } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import { convertToYearMonthDayFormat } from "../../util/formatdate";
import AddStudent from "./components/AddStudent";
import HelpHyperLink from "../../unicomponents/HelpHyperLink";
import { useAuthContext } from "../../context/AuthContext";
import { useUIContext } from "../../context/UIContext";
import SimpleAlertMessageBlock from "../../unicomponents/SimpleAlertMessageBlock";
import LoadingInside from "../../unicomponents/LoadingInside";
import HandleLinkRequestModal from "./components/HandleLinkRequestModal";
import { useTranslation } from "react-i18next";
import useStudentInfo from "../../hooks/useStudentInfo";

export default function BasicInfo() {
  const { t } = useTranslation();
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = { schoolid: schoolId, role: "student" };
  const [rows, setRows] = useState([]);
  const {
    // usersQuery: { isLoading, data: userData },
    updateUser,
    deleteUser,
  } = useUsers(conditions);

  const {
    studentInfoQuery: { isLoading, data: userData },
  } = useStudentInfo(conditions);

  useEffect(() => {
    const copiedData = { ...userData };
    const arrayData = Object.keys(copiedData).map((key) => ({
      id: parseInt(key),
      ...copiedData[key],
    }));
    setRows(arrayData);
  }, [userData]);

  // console.log("userData", userData);

  const [isUploading, setIsUploading] = useState(false);
  const [success, setSuccess] = useState("");
  const [successSave, setSuccessSave] = useState();
  const [isSaved, setIsSaved] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const [rowModesModel, setRowModesModel] = useState({});

  const [linkRequestInfo, setLinkRequestInfo] = useState({});

  const { hasPermission } = useAuthContext();

  const { handleToastCenterTop, setIsLoadingModal } = useUIContext();

  const updateData = (updatedRow) => {
    setIsUploading(true);

    let updatedUserData = { ...updatedRow };
    if (updatedRow.birthday) {
      let formattedValue = convertToYearMonthDayFormat(updatedRow.birthday);
      updatedUserData = { ...updatedUserData, birthday: formattedValue };
    }

    updateUser.mutate(updatedUserData, {
      onSuccess: (successMessage) => {
        setSuccessSave(successMessage);
        setIsSaved((prevState) => ({ ...prevState, [updatedRow.id]: true }));
        setTimeout(() => {
          setSuccessSave(null);
          setIsSaved((prevState) => ({ ...prevState, [updatedRow.id]: false }));
        }, 4000);
        setIsUploading(false);
      },
      onError: (error) => {
        setIsUploading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleSaveClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
    // console.log("updatedData", id);
  };

  const handleDeleteClick = (id) => {
    // Confirmation prompt
    if (window.confirm(t("common.messages.deleteAlert"))) {
      setIsUploading(true);
      deleteUser.mutate(id, {
        onSuccess: (successMessage) => {
          setRows(rows.filter((row) => row.id !== id));
          setIsUploading(false);
        },
        onError: (error) => {
          setIsUploading(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert("에러: " + error.message);
        },
      });
    } else {
      // User clicked 'Cancel', do nothing
      console.log("취소되었습니다.");
    }
  };

  const handleLinkRequest = (row) => {
    // console.log("보호자 연결 관리 모달", row);

    if (!hasPermission(null, "can_update")) {
      handleToastCenterTop(t("common.messages.ownerUpdatePermission"));
      return;
    }

    setLinkRequestInfo(row);
    setModalOpen(true);
  };

  const columns = [
    {
      field: "username",
      headerAlign: "center",
      headerName: t("common.user.username"),
      align: "left",
      width: 240,
    },
    {
      field: "password",
      headerAlign: "center",
      headerName: t("common.user.password") || "비밀번호",
      width: 140,
      align: "center",
      type: "password",
      valueGetter: (params) => {
        return params.value ? "*******" : "*******";
      },
      editable: true,
    },
    {
      field: "fullname",
      headerAlign: "center",
      headerName: t("common.user.fullname"),
      align: "center",
      editable: true,
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      cellClassName: "datagrid-wrap-cell",
    },
    ...(rows.some((row) => row.other_name)
      ? [
          {
            field: "other_name",
            headerAlign: "center",
            headerName: t("common.user.otherName"),
            align: "center",
            editable: true,
            flex: 1,
            minWidth: 100,
            maxWidth: 150,
            cellClassName: "datagrid-wrap-cell",
          },
        ]
      : []),
    {
      field: "gender",
      headerAlign: "center",
      headerName: t("common.user.gender") || "성별",
      align: "center",
      width: 80,
      type: "singleSelect",
      valueOptions: ["남", "여"],
      editable: true,
    },
    {
      field: "birthday",
      headerAlign: "center",
      headerName: t("common.user.birthday"),
      align: "center",
      width: 190,
      type: "date",
      valueGetter: (params) => {
        return params.value ? new Date(params.value) : null;
      },
      editable: true,
    },
    {
      field: "idatschool",
      headerAlign: "center",
      headerName: t("common.user.idatschool"),
      align: "center",
      width: 140,
      editable: true,
    },
    {
      field: "guardian",
      headerAlign: "center",
      headerName: t("common.user.guardian"),
      align: "center",
      width: 100,
      renderCell: (params) => {
        return (
          <button
            className={`btn btn-sm ${
              params?.value?.some((item) => item.status === "pending")
                ? "btn-warning"
                : "btn-outline-dark"
            } p-1 m-1`}
            style={{
              "--bs-btn-font-size": ".80rem",
            }}
            onClick={() => {
              handleLinkRequest(params.row); // 권한이 있을 경우만 실행
            }}
          >
            {params?.value
              ?.map((item) =>
                item.status === "pending"
                  ? t("common.status.pending")
                  : item.fullname
              )
              .join(", ") || ""}
          </button>
        );
      },
    },
    {
      field: "status",
      headerAlign: "center",
      headerName: t("common.user.status"),
      align: "center",
      width: 90,
      type: "singleSelect",
      getOptionValue: (value) => value.status,
      getOptionLabel: (value) => value.statusName,
      valueOptions: [
        { status: 1, statusName: t("common.status.active") },
        { status: 0, statusName: t("common.status.inactive") },
      ],
      valueFormatter: (params) =>
        params.value === 1
          ? t("common.status.active")
          : t("common.status.inactive"),
      editable: true,
    },
    {
      field: "saveDelete",
      headerAlign: "center",
      headerName: t("common.labels.save_delete"),
      align: "center",
      width: 100,
      type: "actions",
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={isSaved[id] ? <MdCheck size={20} /> : <MdSave size={20} />}
          label={t("common.labels.save")}
          className="saveDataBtn"
          onClick={() => {
            if (!hasPermission(null, "can_update")) {
              handleToastCenterTop(t("common.messages.noUpdatePermission"));
              return;
            }
            handleSaveClick(id); // 권한이 있을 경우만 실행
          }}
        />,
        <GridActionsCellItem
          icon={<MdDelete size={20} />}
          label={t("common.labels.delete")}
          onClick={() => {
            if (!hasPermission(null, "can_delete")) {
              handleToastCenterTop(t("common.messages.noDeletePermission"));
              return;
            }
            handleDeleteClick(id); // 권한이 있을 경우만 실행
          }}
        />,
      ],
    },
  ];

  if (isLoading) {
    return <LoadingInside />;
  }

  if (!hasPermission(null, "can_access")) {
    return (
      <SimpleAlertMessageBlock
        message={t("common.messages.noAccessPermission")}
      />
    );
  }

  return (
    <>
      <div className="ms-3 pe-3" style={{ width: "96vw" }}>
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="d-flex">
              <h3 className="fw-bold mt-3">{t("teacher.basicinfo.title")}</h3>
              <HelpHyperLink link={"https://youtu.be/Ro3PpsdRAik"} />
            </div>
            {hasPermission(null, "can_create") ? (
              <AddStudent
                isUploading={isUploading}
                setSuccess={setSuccess}
                setIsUploading={setIsUploading}
                rows={rows}
              />
            ) : (
              <SimpleAlertMessageBlock
                message={t("common.messages.noCreatePermission")}
              />
            )}
            {success && <p>✅{success}</p>}
          </div>
          {modalOpen && (
            <HandleLinkRequestModal
              setModalOpen={setModalOpen}
              linkRequestInfo={linkRequestInfo}
              setLinkRequestInfo={setLinkRequestInfo}
              setIsLoadingModal={setIsLoadingModal}
            />
          )}

          <div style={{ width: "94vw", overflowX: "auto", maxWidth: "1536px" }}>
            {successSave && <p>✅{successSave}</p>}
            {hasPermission(null, "can_read") ? (
              userData?.length > 0 ? (
                <UniTable
                  columns={columns}
                  rows={rows}
                  exportedFileName="학생명단_studentList"
                  rowModesModel={rowModesModel}
                  setRowModesModel={setRowModesModel}
                  setRows={setRows}
                  updateData={updateData}
                />
              ) : (
                <SimpleAlertMessageBlock
                  message={t("common.messages.noData")}
                />
              )
            ) : (
              <SimpleAlertMessageBlock
                message={t("common.messages.noReadPermission")}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
