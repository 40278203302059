import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import useAllForms from "../../../../hooks/useAllForms";
import useComponentsToForms from "../../../../hooks/useComponentsToForms";
import useOrganizationSetting from "../../../../hooks/useOrganizationSetting";
import useTermPeriod from "../../../../hooks/useTermPeriod";
import useRegistrationInfo from "../../../../hooks/useRegistrationInfo";
import useDocumentForms from "../../../../hooks/useDocumentForms";
import useApprovalRequests from "../../../../hooks/useApprovalRequest";
import { useAuthContext } from "../../../../context/AuthContext";
import { useUIContext } from "../../../../context/UIContext";
import { setTermIdByToday } from "../../../../util/dateRelated";
import { generateRegistrationRows } from "../../../../util/dealingArrObj";
import LoadingInside from "../../../../unicomponents/LoadingInside";
import FiltersBar from "../../../../unicomponents/Dropdowns/FilterBar";
import DynamicComponentsRenderer from "../../../../unicomponents/formComponents/DynamicComponentsRenderer";
import LeftMenuSidebar from "../../../../unicomponents/LeftMenuSidebar";
import { filterSettingsByStudentsPath } from "../../../../util/specialCase";
import SimpleAlertMessageBlock from "../../../../unicomponents/SimpleAlertMessageBlock";
import { useTranslation } from "react-i18next";

const PageHeader = () => (
  <div className="header">
    <h1> </h1>
  </div>
);

const PageFooter = () => (
  <div className="footer">
    <p className="page-number"></p>
  </div>
);

export default function StuReportCard() {
  const { t } = useTranslation();
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];
  const [filters, setFilters] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    levelOneName: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    organPathForSort: "/",
    termId: "",
    selectedLessonId: "",
  });
  const [termId, setTermId] = useState("");
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [keyData, setKeyData] = useState({});
  const [registrationInfoData, setRegistrationInfoData] = useState([]);
  const [newSetting, setNewSetting] = useState([]);
  const [selectedMenuOption, setSelectedMenuOption] = useState("");

  // const [validateInput, setValidateInput] = useState({});
  const [studentApprovalRequests, setStudentApprovalRequests] = useState([]);

  const [activeMenu, setActiveMenu] = useState({
    menu_name: "",
    menu_id: "",
    options: {},
  });

  const conditions = {
    schoolid: schoolId,
    form_category: "reportCard",
  };

  const [formQueryConditions, setFormQueryConditions] = useState({
    schoolid: schoolId,
    form_id: "",
  });

  const [approvalData, setApprovalData] = useState({
    schoolid: schoolId,
    document_type: "fixedForm",
    document_category: "reportCard",
    document_name: "",
  });

  const requestType = "schoolTerm";

  const [documentFormData, setDocumentFormData] = useState([]);

  const {
    allFormsQuery: { data: allFormsData },
  } = useAllForms(conditions);

  const {
    componentsToFormsQuery: { data: componentsToFormsData = [], isLoading },
  } = useComponentsToForms(formQueryConditions);

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting({ schoolid: schoolId });

  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod({ schoolid: schoolId });

  const { getRegistrationInfoByUsername } = useRegistrationInfo();
  const getRegistrationInfoByUsernameRef = useRef(
    getRegistrationInfoByUsername
  );

  const { getDocumentForms } = useDocumentForms();
  const getDocumentFormsRef = useRef(getDocumentForms);

  const { getApprovalRequestsByUserId } = useApprovalRequests();
  const getApprovalRequestsByUserIdRef = useRef(getApprovalRequestsByUserId);

  const { user } = useAuthContext();

  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();

  const now = new Date().toLocaleDateString("en-CA", {
    timeZone: "Asia/Seoul",
  });

  useEffect(() => {
    if (allFormsData) {
      setFormQueryConditions((prev) => ({
        ...prev,
        form_id: allFormsData[0].id,
      }));

      // console.log("allFormsData[0].form_name", allFormsData);
      setActiveMenu({
        menu_name: allFormsData[0].form_name,
        menu_id: allFormsData[0].id,
        options: allFormsData[0].options,
      });
      setApprovalData((prev) => ({
        ...prev,
        document_name: allFormsData[0].form_name,
      }));
    }
  }, [allFormsData]);

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;
    let initialHierarchyOneId = "";
    let initialHierarchyTwoId = "";
    let initialHierarchyThreeId = "";
    let initialData = [];
    if (
      filters.schoolYear === null &&
      newSetting &&
      newSetting.length > 0 &&
      registrationInfoData
    ) {
      initialData = newSetting
        .sort((a, b) => a.hierarchy_level - b.hierarchy_level)
        .sort((a, b) => b.school_year - a.school_year);

      // console.log("initialData", initialData);
      if (initialData.length < 1) {
        alert(t("common.messages.registrationError"));
        return;
      }

      initialSchoolYear = initialData[0].school_year;
      initialSchoolYearId = initialData[0].id;
      initialHierarchyOneId = initialData.find(
        (r) => r.school_year === initialSchoolYear && r.hierarchy_level === 1
      );
      initialHierarchyTwoId = initialData.find(
        (r) => r.school_year === initialSchoolYear && r.hierarchy_level === 2
      );
      initialHierarchyThreeId = initialData.find(
        (r) => r.school_year === initialSchoolYear && r.hierarchy_level === 3
      );

      if (!initialHierarchyThreeId?.organization_path) {
        alert(t("common.messages.registrationError"));
        return;
      }

      setFilters((filters) => ({
        ...filters,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
        hierarchyOneId: initialHierarchyOneId?.id,
        levelOneName: initialHierarchyOneId?.group_name,
        hierarchyTwoId: initialHierarchyTwoId?.id,
        hierarchyThreeId: initialHierarchyThreeId?.id,
        selectedStudent: user?.username,
        organPathForSort:
          initialHierarchyThreeId.organization_path +
          "/" +
          initialHierarchyThreeId.id,
      }));

      //  이게 term이 과정이나 별로 다를때 오류를 일으켜서 일단 주석처리

      if (termPeriodData) {
        setTermIdByToday(
          termPeriodData,
          setTermId,
          initialSchoolYearId,
          initialHierarchyOneId?.id,
          initialHierarchyTwoId?.id
        );
      }

      // if (termPeriodData) {
      //   setTermIdByToday(termPeriodData, setTermId, initialSchoolYearId);
      // }

      if (initialSchoolYear) {
        //학생 리스트
        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          newSetting,
          initialSchoolYear,
          filters.organPathForSort
        );
        setStudentList(sortedRows);
      }
    } else {
      //rows 데이터처리
      if (registrationInfoData && newSetting) {
        // console.log("registrationInfoData", registrationInfoData);
        // console.log("filters", filters);

        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          newSetting,
          filters.schoolYear,
          filters.organPathForSort
        );
        setStudentList(sortedRows);
      }
    }
  }, [
    filters.organPathForSort,
    filters.schoolYear,
    newSetting,
    registrationInfoData,
    termPeriodData,
    user?.username,
    t,
  ]);

  useEffect(() => {
    if (user?.username && settingData) {
      const forWhere = {
        schoolid: schoolId,
        username: user.username,
      };

      getRegistrationInfoByUsernameRef.current.mutate(forWhere, {
        onSuccess: (data) => {
          //   console.log("lessonList", data);

          setRegistrationInfoData(data);
          //   console.log("data", data);

          setNewSetting(filterSettingsByStudentsPath(data, settingData));
        },
        onError: (error) => {
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(t("common.messages.errorAlert") + error.message);
        },
      });
    }
  }, [schoolId, settingData, user?.username, t]);

  useEffect(() => {
    if (termPeriodData) {
      const nowPeriod = termPeriodData.filter(
        (r) => r.id.toString() === termId.toString()
      )[0];

      if (nowPeriod) {
        // console.log("nowPeriod", nowPeriod);
        setCurrentPeriod(nowPeriod);
      } else {
        setCurrentPeriod({});
      }
    }
  }, [termId, termPeriodData]);

  useEffect(() => {
    if (studentList && filters.selectedStudent) {
      const selectedObject = studentList.find(
        (r) => r.username === filters.selectedStudent
      );

      if (currentPeriod && Object.keys(currentPeriod).length > 0) {
        setKeyData({
          ...selectedObject,
          currentPeriod: currentPeriod,
        });

        const makeConditions = {
          schoolid: schoolId,
          target_username: filters.selectedStudent,
          document_id: documentFormData[0]?.id || "",
          request_type: requestType,
          term_id: currentPeriod?.id,
        };

        if (makeConditions.document_id) {
          getApprovalRequestsByUserIdRef.current.mutate(makeConditions, {
            onSuccess: (data) => {
              // console.log("data", data);
              setStudentApprovalRequests(data);

              // console.log("data22", data);
            },
            onError: (error) => {
              console.error(error); // 일단 콘솔에 에러를 출력합니다.
              console.error("위와 같은 에러가 났으니 고치삼");
              alert(t("common.messages.errorAlert") + error.message);
            },
          });
        }
      } else {
        setKeyData(selectedObject);
      }
    } else {
      setKeyData({});
    }
  }, [
    currentPeriod,
    documentFormData,
    filters.selectedStudent,
    schoolId,
    studentList,
    t,
  ]);

  useEffect(() => {
    if (approvalData && approvalData.document_name.length > 0) {
      getDocumentFormsRef.current.mutate(approvalData, {
        onSuccess: (data) => {
          setDocumentFormData(data);
        },
        onError: (error) => {
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(t("common.messages.errorAlert") + error.message);
        },
      });
    }
  }, [approvalData, t]);

  const handleQueryCondition = (item) => {
    if (item?.id) {
      setFormQueryConditions((prev) => ({
        ...prev,
        form_id: item.id,
      }));

      setApprovalData((prev) => ({
        ...prev,
        document_name: item.form_name,
      }));
      if (item?.options?.selected) {
        setSelectedMenuOption(item.options.selected);
      } else {
        setSelectedMenuOption("");
      }
    }
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    const dataName = e.target.selectedOptions[0].getAttribute("data-name");
    let organPath = "";
    if (!dataName) {
      organPath = "N/A";
    } else {
      organPath = dataName + "/" + value;
    }
    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      setTermIdByToday(termPeriodData, setTermId, value);
    }

    if (name === "1") {
      setFilters((filters) => ({
        ...filters,
        hierarchyOneId: value,
        levelOneName: selectedOptionText,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      setTermIdByToday(termPeriodData, setTermId, filters.schoolYearId, value);
    }

    if (name === "2") {
      setFilters((filters) => ({
        ...filters,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      setTermIdByToday(
        termPeriodData,
        setTermId,
        filters.schoolYearId,
        filters.hierarchyOneId,
        value
      );
    }

    if (name === "3") {
      setFilters((filters) => ({
        ...filters,
        hierarchyThreeId: value,
        organPathForSort: organPath,
        selectedStudent: "",
      }));
    }

    if (name === "students") {
      setFilters((filters) => ({
        ...filters,
        selectedStudent: value,
      }));
    }

    if (name === "termDate") {
      setTermId(value);
    }
  };

  const triggerPrint = useReactToPrint({
    content: () => {
      // addPageNumbers();
      return contentToPrint.current;
    },
    documentTitle: `${keyData.school_year}-${keyData?.currentPeriod?.term_name}-${keyData[1]}-${keyData[2]}-${keyData[3]}-${keyData.fullname}`,
    onBeforePrint: () => {
      const content = contentToPrint.current;
      if (content) {
        content.style.width = "210mm";
        content.style.height = "auto";
      }
    },
    removeAfterPrint: true,
    pageStyle: `
    @media print {
      @page {
        size: A4;
        margin: 10mm;
      }
      .print-page {
        display: block;
        width: 210mm;
        margin: auto;
      }
    }
  `,
  });

  const contentToPrint = useRef(null);
  const handlePrint = () => {
    // console.log("keyData", keyData);
    if (
      keyData.school_year &&
      keyData[1] &&
      keyData[2] &&
      keyData[3] &&
      keyData.currentPeriod?.term_name &&
      keyData.fullname
    ) {
      triggerPrint();
    } else {
      console.log(
        "keyData is incomplete. Please ensure all data is available."
      );
      handleToastCenterTop(t("common.messages.printAfterSelectStudent"));
    }
  };

  if (isLoading) {
    return <LoadingInside />;
  }

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <LeftMenuSidebar
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              menuData={allFormsData}
              extraFunction={handleQueryCondition}
              itemName="form_name"
              itemLabel="form_label"
            />
          </div>
          <div className="col-12 col-md-10">
            <div className="d-flex justify-content-end flex-wrap mt-2">
              <FiltersBar
                settingData={newSetting}
                handleDropdownChange={handleDropdownChange}
                filters={filters}
                studentList={studentList}
                // validateInput={validateInput}
                termPeriodData={termPeriodData}
                termId={termId}
                currentPeriod={currentPeriod}
              />
            </div>
            <div className="d-flex justify-content-end flex-wrap mb-3 mt-2">
              <div style={{ width: "70px" }} className="me-1 text-center">
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={() => {
                    handlePrint(null, () => contentToPrint.current);
                  }}
                >
                  {t("common.labels.print")}
                </button>
              </div>
            </div>

            {selectedMenuOption !== "" &&
            selectedMenuOption !== (filters?.levelOneName || "") ? (
              <SimpleAlertMessageBlock
                message={t("student.reportCard.chooseRightSchoolStage")}
              />
            ) : !studentApprovalRequests.length ? (
              <SimpleAlertMessageBlock
                message={t("student.reportCard.noApprovalInfo")}
              />
            ) : studentApprovalRequests[0]?.status !== "finalized" ? (
              <SimpleAlertMessageBlock
                message={t("student.reportCard.approvalNotFinished")}
              />
            ) : studentApprovalRequests[0]?.release_date > now ? (
              <SimpleAlertMessageBlock
                message={`${t("student.reportCard.releaseDateNotYet")} ${
                  studentApprovalRequests[0]?.release_date || "unknown"
                } ${t("student.reportCard.notReleased")}`}
              />
            ) : (
              <div
                id="gradeReportPrintArea"
                className="d-flex justify-content-center print-page"
                ref={contentToPrint}
              >
                <table className="" style={{ width: "210mm" }}>
                  <thead>
                    <tr>
                      <th colSpan="3">
                        <PageHeader />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="1"></td>
                      <td colSpan="1" className="p-2">
                        {!isLoading &&
                          componentsToFormsData &&
                          componentsToFormsData.map((item, index) => (
                            // <div key={`${item.id}-${item.order}-${index}`}>
                            <DynamicComponentsRenderer
                              components={item.components}
                              customizedContents={item.customized_contents}
                              keyData={keyData}
                              setIsLoadingModal={setIsLoadingModal}
                              key={`${item.id}-${item.order}-${index}`}
                              componentsToFormId={item.id}
                              approvalData={studentApprovalRequests}
                            />
                            // </div>
                          ))}
                      </td>
                      <td colSpan="1"></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="3">
                        <PageFooter />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
