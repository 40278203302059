import React from "react";

const UniDivider = ({ height, color, margin }) => {
  const style = {
    height: height || "3px", // 기본 높이는 1px
    backgroundColor: color || "#ddd", // 기본 색상은 #ddd
    width: "100%", // 기본 너비는 100%
    margin: margin || "20px 0", // 위아래 여백 설정
  };

  return <div style={style} />;
};

export default UniDivider;
