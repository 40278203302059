import React, { useEffect, useState } from "react";
import DropdownHierarchyOne from "../../../unicomponents/Dropdowns/DropdownHierarchyOne";
import DropdownHierarchyTwo from "../../../unicomponents/Dropdowns/DropdownHierarchyTwo";
import { useUIContext } from "../../../context/UIContext";
import useTermPeriod from "../../../hooks/useTermPeriod";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function TermPeriod({
  settingData,
  handleSchoolYearDropdownChange,
  schoolYearInfo,
  setSchoolYearInfo,
  schoolId,
  conditions,
}) {
  const { setIsLoadingModal } = useUIContext();
  const { t } = useTranslation();
  const {
    termPeriodQuery: { data: termPeriodData },
    createTermPeriod,
    updateTermPeriod,
    deleteTermPeriod,
  } = useTermPeriod(conditions);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (termPeriodData && termPeriodData.length > 0) {
      const filtered = termPeriodData.filter(
        (r) =>
          r.school_year_id.toString() === schoolYearInfo.schoolYearId.toString()
      );

      setDatesRows(filtered);
      setOrganSettingInfo(
        filtered.map((row) => ({
          schoolYear: null,
          schoolYearId: row.school_year_id,
          hierarchyOneId: row.applied_hierarchy_one,
          hierarchyTwoId: row.applied_hierarchy_two,
          organPathForSort: "/",
        }))
      );
    }
  }, [schoolYearInfo.schoolYearId, termPeriodData]);

  const [datesRows, setDatesRows] = useState([]);
  const [savedRows, setSavedRows] = useState([]);
  const [addTr, setAddTr] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState([]);
  const [organSettingInfo, setOrganSettingInfo] = useState([]);

  const [createData, setCreateData] = useState({
    schoolid: "",
    school_year_id: "",
    term_name: "",
    term_category: t("common.labels.termName"),
    start_date: "",
    end_date: "",
    class_days: "",
    applied_hierarchy_one: null,
    applied_hierarchy_two: null,
  });

  const handleDropdownChange = (rowIndex, e) => {
    const { name, value } = e.target;

    // console.log("rowIndex, e", rowIndex, name, value);

    if (name === "1") {
      setOrganSettingInfo((organSettingInfo) =>
        organSettingInfo.map((setting, index) =>
          index === rowIndex
            ? {
                ...setting,
                hierarchyOneId: value === 0 || value === "0" ? null : value,
                hierarchyTwoId: "",
              }
            : setting
        )
      );

      const updatedRows = datesRows.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              applied_hierarchy_one:
                value === 0 || value === "0" ? null : value,
              applied_hierarchy_two: null,
              isModified: true,
            }
          : row
      );
      // console.log("updatedRows tr", updatedRows);
      setDatesRows(updatedRows);
    }

    if (name === "2") {
      setOrganSettingInfo((organSettingInfo) =>
        organSettingInfo.map((setting, index) =>
          index === rowIndex
            ? {
                ...setting,
                hierarchyTwoId: value === 0 || value === "0" ? null : value,
              }
            : setting
        )
      );

      const updatedRows = datesRows.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              applied_hierarchy_two:
                value === 0 || value === "0" ? null : value,
              isModified: true,
            }
          : row
      );
      // console.log("updatedRows tr", updatedRows);
      setDatesRows(updatedRows);
    }
  };

  const handleSubmit = (index) => {
    let dataSet = datesRows[index];
    if (!dataSet.isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      return;
    }

    dataSet = {
      ...dataSet,
      applied_hierarchy_one: /^\d+$/.test(dataSet.applied_hierarchy_one)
        ? parseInt(dataSet.applied_hierarchy_one, 10)
        : null,
      applied_hierarchy_two: /^\d+$/.test(dataSet.applied_hierarchy_two)
        ? parseInt(dataSet.applied_hierarchy_two, 10)
        : null,
    };

    if (!dataSet.term_name.trim()) {
      // 여기서 dataSet.start_date가 유효하지 않은 날짜일 경우의 로직을 처리합니다.
      alert(t("admin.periodSetting.writePeriodName"));
      return;
    }

    if (!dataSet.term_category.trim()) {
      // 여기서 dataSet.start_date가 유효하지 않은 날짜일 경우의 로직을 처리합니다.
      alert(t("admin.periodSetting.writePeriodCategory"));
      return;
    }

    if (!dataSet.start_date || isNaN(new Date(dataSet.start_date).getTime())) {
      // 여기서 dataSet.start_date가 유효하지 않은 날짜일 경우의 로직을 처리합니다.
      alert(t("admin.periodSetting.invalidStartDate"));
      return;
    }

    if (!dataSet.end_date || isNaN(new Date(dataSet.end_date).getTime())) {
      alert(t("admin.periodSetting.invalidEndDate"));
      return;
    }

    const duplicate = termPeriodData.find(
      (r) =>
        r.school_year_id === dataSet.school_year_id &&
        r.term_name === dataSet.term_name &&
        r.term_category === dataSet.term_category &&
        r.applied_hierarchy_one === dataSet.applied_hierarchy_one &&
        r.applied_hierarchy_two === dataSet.applied_hierarchy_two &&
        r.id !== dataSet.id // 고유 ID를 사용하여 현재 데이터와 기존 데이터를 비교
    );

    if (duplicate) {
      alert(t("admin.periodSetting.duplicateDate"));
      return;
    }

    setIsLoadingModal(true);
    updateTermPeriod.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, dataSet.id]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
    // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
    setDatesRows((currentDataSets) =>
      currentDataSets.map((dataSet, dataSetIndex) =>
        dataSetIndex === index ? { ...dataSet, isModified: false } : dataSet
      )
    );
  };

  // 일반적인 row 데이터 변경을 처리하는 함수
  const handleTrChange = (rowIndex, event) => {
    const { value } = event.target;
    const field = event.target.name;
    const updatedRows = datesRows.map((row, index) =>
      index === rowIndex ? { ...row, [field]: value, isModified: true } : row
    );
    // console.log("updatedRows tr", updatedRows);
    setDatesRows(updatedRows);
  };

  const handleAddTr = () => {
    setAddTr((pre) => !pre);
    setCreateData({
      schoolid: "",
      school_year_id: "",
      term_name: "",
      term_category: t("common.labels.termName"),
      start_date: "",
      end_date: "",
      class_days: "",
      applied_hierarchy_one: null,
      applied_hierarchy_two: null,
    });

    setSchoolYearInfo((schoolYearInfo) => ({
      ...schoolYearInfo,
      hierarchyOneId: null,
      hierarchyTwoId: null,
    }));
  };

  const handleCreateTr = (e) => {
    const { value, name } = e.target;

    setCreateData((createData) => ({ ...createData, [name]: value }));
  };

  const submitCreateTr = () => {
    const dataSet = {
      ...createData,
      schoolid: schoolId,
      school_year_id: schoolYearInfo.schoolYearId,
      applied_hierarchy_one: /^\d+$/.test(schoolYearInfo.hierarchyOneId)
        ? parseInt(schoolYearInfo.hierarchyOneId, 10)
        : null,
      applied_hierarchy_two: /^\d+$/.test(schoolYearInfo.hierarchyTwoId)
        ? parseInt(schoolYearInfo.hierarchyTwoId, 10)
        : null,
    };

    if (!dataSet.term_name.trim()) {
      // 여기서 dataSet.start_date가 유효하지 않은 날짜일 경우의 로직을 처리합니다.
      alert(t("admin.periodSetting.writePeriodName"));
      return;
    }

    if (!dataSet.term_category.trim()) {
      // 여기서 dataSet.start_date가 유효하지 않은 날짜일 경우의 로직을 처리합니다.
      alert(t("admin.periodSetting.writePeriodCategory"));
      return;
    }

    if (!dataSet.start_date || isNaN(new Date(dataSet.start_date).getTime())) {
      // 여기서 dataSet.start_date가 유효하지 않은 날짜일 경우의 로직을 처리합니다.
      alert(t("admin.periodSetting.invalidStartDate"));
      return;
    }

    if (!dataSet.end_date || isNaN(new Date(dataSet.end_date).getTime())) {
      alert(t("admin.periodSetting.invalidEndDate"));
      return;
    }

    const duplicate = termPeriodData.find(
      (r) =>
        r.school_year_id === dataSet.school_year_id &&
        r.term_name === dataSet.term_name &&
        r.term_category === dataSet.term_category &&
        r.applied_hierarchy_one === dataSet.applied_hierarchy_one &&
        r.applied_hierarchy_two === dataSet.applied_hierarchy_two
    );

    if (duplicate) {
      alert(t("admin.periodSetting.duplicateDate"));
      return;
    }

    setIsLoadingModal(true);
    createTermPeriod.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setAddTr(false);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleConfirmDelete = (row) => {
    setConfirmDelete((prev) => [...prev, row.id]);
  };

  const handleCancelDelete = () => {
    setConfirmDelete([]);
  };

  const handleDelete = (row) => {
    const id = row.id;
    // console.log("delete id", id);
    setIsLoadingModal(true);
    deleteTermPeriod.mutate(id, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setConfirmDelete([]);
        queryClient.invalidateQueries(["termPeriod"]);
        setDatesRows((rows) => rows.filter((row) => row.id !== id));
      },
      onError: (error) => {
        setIsLoadingModal(false);
        setConfirmDelete([]);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <h3>
            {t("admin.periodSetting.schoolTerm")}(Term){" "}
            {t("admin.periodSetting.dateSetting")}
          </h3>
        </div>
      </div>
      <table className="table table-bordered text-center">
        <thead>
          <tr className="table-secondary">
            <th style={{ width: "9%" }}>
              {t("admin.periodSetting.applied")}
              <br />
              {t("common.labels.schoolYear")}
            </th>
            <th style={{ width: "10%" }}>
              {t("admin.periodSetting.periodName")}
            </th>
            <th style={{ width: "10%" }}>
              {t("admin.periodSetting.periodCategory")}
            </th>
            <th style={{ width: "12%" }}>{t("common.labels.startDate")}</th>
            <th style={{ width: "12%" }}>{t("common.labels.endDate")}</th>
            <th style={{ width: "10%" }}>
              {t("admin.periodSetting.classDays")}
              <br />({t("common.labels.select")})
            </th>
            <th style={{ width: "12%" }}>
              {t("admin.periodSetting.appliedSchoolStage")}
              <br />({t("admin.periodSetting.level1")})
            </th>
            <th style={{ width: "12%" }}>
              {t("admin.periodSetting.appliedStudentYear")}
              <br />({t("admin.periodSetting.level2")})
            </th>
            <th style={{ width: "13%" }}>
              {addTr ? (
                <button className="btn btn-warning" onClick={handleAddTr}>
                  {t("common.labels.cancel") || "취소"}
                </button>
              ) : (
                <button className="btn btn-primary" onClick={handleAddTr}>
                  {t("common.labels.add")}
                </button>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {addTr && (
            <tr>
              <td>{schoolYearInfo?.schoolYear}</td>
              <td>
                <input
                  className="input-like-span"
                  required
                  type="text"
                  placeholder="1, 2"
                  name="term_name"
                  value={createData?.term_name}
                  onChange={(e) => handleCreateTr(e)}
                />
              </td>
              <td>
                <input
                  className="input-like-span"
                  required
                  type="text"
                  placeholder={t("common.labels.termName")}
                  name="term_category"
                  value={createData?.term_category}
                  onChange={(e) => handleCreateTr(e)}
                />
              </td>
              <td>
                <input
                  className="input-like-span"
                  required
                  type="date"
                  name="start_date"
                  value={createData?.start_date}
                  onChange={(e) => handleCreateTr(e)}
                />
              </td>
              <td>
                <input
                  className="input-like-span"
                  required
                  type="date"
                  name="end_date"
                  value={createData?.end_date}
                  onChange={(e) => handleCreateTr(e)}
                />
              </td>
              <td>
                <input
                  className="input-like-span"
                  type="number"
                  name="class_days"
                  value={createData?.class_days}
                  onChange={(e) => handleCreateTr(e)}
                />
              </td>
              <td>
                <DropdownHierarchyOne
                  settingData={settingData}
                  handleDropdownChange={handleSchoolYearDropdownChange}
                  schoolYear={schoolYearInfo.schoolYear}
                  hierarchyOneId={schoolYearInfo.hierarchyOneId}
                  hierarchyOneTitle={t("common.labels.all")}
                />
              </td>
              <td>
                <DropdownHierarchyTwo
                  settingData={settingData}
                  handleDropdownChange={handleSchoolYearDropdownChange}
                  schoolYear={schoolYearInfo.schoolYear}
                  hierarchyOneId={schoolYearInfo.hierarchyOneId}
                  hierarchyTwoId={schoolYearInfo.hierarchyTwoId}
                  hierarchyTwoTitle={t("common.labels.all")}
                />
              </td>
              <td>
                <button
                  type="button"
                  onClick={submitCreateTr}
                  className="btn btn-sm p-1 me-1 btn-primary"
                >
                  {t("common.labels.add")}
                </button>
              </td>
            </tr>
          )}
          {datesRows &&
            datesRows.map((row, index) => (
              <tr key={row.id}>
                <td>{row?.school_year}</td>
                <td>
                  <input
                    className="input-like-span"
                    required
                    type="text"
                    name="term_name"
                    value={row?.term_name}
                    onChange={(e) => handleTrChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    className="input-like-span"
                    required
                    type="text"
                    name="term_category"
                    value={row?.term_category}
                    onChange={(e) => handleTrChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    className="input-like-span"
                    required
                    type="date"
                    name="start_date"
                    value={row?.start_date}
                    onChange={(e) => handleTrChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    className="input-like-span"
                    required
                    type="date"
                    name="end_date"
                    value={row?.end_date}
                    onChange={(e) => handleTrChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    className="input-like-span"
                    type="number"
                    name="class_days"
                    value={row?.class_days}
                    onChange={(e) => handleTrChange(index, e)}
                  />
                </td>
                <td>
                  <DropdownHierarchyOne
                    settingData={settingData}
                    handleDropdownChange={(e) => handleDropdownChange(index, e)}
                    schoolYear={schoolYearInfo?.schoolYear}
                    hierarchyOneId={organSettingInfo[index]?.hierarchyOneId}
                    hierarchyOneTitle={t("common.labels.all")}
                  />
                </td>
                <td>
                  <DropdownHierarchyTwo
                    settingData={settingData}
                    handleDropdownChange={(e) => handleDropdownChange(index, e)}
                    schoolYear={schoolYearInfo?.schoolYear}
                    hierarchyOneId={organSettingInfo[index]?.hierarchyOneId}
                    hierarchyTwoId={organSettingInfo[index]?.hierarchyTwoId}
                    hierarchyTwoTitle={t("common.labels.all")}
                  />
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => handleSubmit(index)}
                    className="btn btn-sm p-1 me-1 btn-primary"
                  >
                    {savedRows.includes(row.id)
                      ? t("common.labels.savedWell") || "저장완료"
                      : t("common.labels.save") || "저장"}
                  </button>
                  {confirmDelete.includes(row.id) ? (
                    <>
                      <button
                        type="button"
                        onClick={() => handleDelete(row)}
                        className="btn btn-sm p-1 btn-danger"
                      >
                        {t("common.labels.confirm") || "확인"}
                      </button>
                      <button
                        type="button"
                        onClick={() => handleCancelDelete()}
                        className="btn btn-sm p-1 btn-secondary"
                      >
                        {t("common.labels.cancel") || "취소"}
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleConfirmDelete(row)}
                      className="btn btn-sm p-1 btn-warning"
                    >
                      {t("common.labels.delete") || "삭제"}
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
