import React, { useState } from "react";
import UniTable from "../../../unicomponents/UniTable";
import { Button } from "@mui/material";
import useDeveloper from "../../../hooks/useDeveloper";
import AddAuthority from "./AddAuthority";
import { useTranslation } from "react-i18next";

function saveRow(row) {
  // Implement your save logic here
  console.log("Saving row", row);
}

function deleteRow(row) {
  // Implement your delete logic here
  console.log("Deleting row", row);
}

export default function AuthorityMaking() {
  const { t } = useTranslation();
  const menuNamesData = [
    { id: 1, categoryname: "학생" },
    { id: 2, categoryname: "기본정보" },
    { id: 3, categoryname: "재적" },
  ];

  const columns = [
    {
      field: "no",
      headerAlign: "center",
      headerName: "No.",
      align: "center",
      flex: 0.5,
      renderCell: (params) => params.id,
    },
    {
      field: "schools_status",
      headerAlign: "center",
      headerName: "패키지단계",
      align: "center",
      flex: 1,
      type: "singleSelect",
      valueOptions: [1, 2, 3, 4, 5],
      editable: true,
    },
    {
      field: "schools_usergroup",
      headerAlign: "center",
      headerName: "유저그룹",
      align: "center",
      flex: 1,
      type: "singleSelect",
      valueOptions: ["teacher", "student", "parent", "admin"],
      editable: true,
    },
    {
      field: "menuname",
      headerAlign: "center",
      headerName: "페이지명",
      align: "center",
      flex: 1,
      type: "singleSelect",
      renderCell: (params) => {
        const value = params.value; // Access the nested property value
        return value ? value.categoryname : ""; // Return the categoryname or an empty string if it doesn't exist
      },
      getOptionValue: (value) => value.id,
      getOptionLabel: (value) => value.categoryname,
      valueOptions: menuNamesData,
      editable: true,
    },
    {
      field: "authority",
      headerAlign: "center",
      headerName: "권한단계",
      align: "center",
      flex: 1,
      type: "singleSelect",
      valueOptions: ["접근", "읽기", "쓰기", "수정", "삭제"],
      editable: true,
    },
    {
      field: "description",
      headerAlign: "center",
      headerName: t("common.labels.description") || "설명",
      editable: true,
      flex: 2.5,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "schools_id",
      headerAlign: "center",
      headerName: "맞춤학교",
      align: "center",
      flex: 1,
      editable: true,
      type: "singleSelect",
      getOptionValue: (value) => value.schoolid,
      getOptionLabel: (value) => value.schools_id,
      valueOptions: [
        { schoolid: "1", schools_id: "밀알두레학교" },
        { schoolid: "2", schools_id: "에듀씨드" },
        { schoolid: "3", schools_id: "이봄학교" },
        { schoolid: "4", schools_id: "두레줄기학교" },
      ],
      /*      valueOptions 는 외부 데이터에서 가공 후 정의해서 여기 넣으면 되고,
      getOptionValue: (value) => value.schoolid, 이거는 선택된 옵션의 value (여기선 schoolid로 됨)를 가져올 수 있는 것이다.
      아래와 같은 이런 식
      const [selectedOption, setSelectedOption] = useState(null);
      const handleChange = (selectedOption) => {
       setSelectedOption(selectedOption);
      console.log(`Selected: ${selectedOption.value}`); */
    },
    {
      field: "saveDelete",
      headerAlign: "center",
      headerName: "저장/삭제",
      align: "center",
      flex: 1.5,
      // type: "actions",
      // getActions: (params) => [
      //   <GridActionsCellItem
      //     icon={<GridAddIcon />}
      //     label="저장"
      //     onClick={saveRow(params.row)}
      //   />,
      //   <GridActionsCellItem
      //     icon={<GridDeleteIcon />}
      //     label="삭제"
      //     onClick={deleteRow(params.row)}
      //   />,
      // ],

      renderCell: (params) => (
        <div
          className="text-center"
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              fontSize: "0.7rem",
              padding: "3px 6px",
            }}
            onClick={() => saveRow(params.row)}
          >
            {t("common.labels.save")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            style={{ fontSize: "0.7rem", padding: "3px 6px" }}
            onClick={() => deleteRow(params.row)}
          >
            {t("common.labels.delete") || "삭제"}
          </Button>
        </div>
      ),
    },
  ];

  const fieldArray = [
    "description",
    "schools_usergroup",
    "schools_status",
    "menu_id",
    "schools_id",
    "schoolid",
    "authorities",
  ];

  const {
    authorityQuery: { isLoading, error, data: authorityData },
  } = useDeveloper();

  const arrayData = { ...authorityData };

  const rows = Object.keys(arrayData).map((key) => ({
    id: parseInt(key), // Use parseInt to convert the key to a number
    ...arrayData[key], // Spread the properties of the original object
  }));

  // console.log("rows", rows);

  const [isUploading, setIsUploading] = useState(false);
  const [success, setSuccess] = useState();

  return (
    <div>
      {isLoading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      <div className="container-fluid">
        <div className="row mb-4 m-auto">
          <h3 className="fw-bold mt-3">권한 등록</h3>
          {success && <p>✅{success}</p>}
          <AddAuthority
            isUploading={isUploading}
            setSuccess={setSuccess}
            setIsUploading={setIsUploading}
            menuNamesData={menuNamesData}
          />
        </div>
        <div style={{ width: "95vw", overflowX: "auto", maxWidth: "1536px" }}>
          <UniTable columns={columns} rows={rows} fieldArray={fieldArray} />
        </div>
      </div>
    </div>
  );
}
