import React, { useState } from "react";
import { MdAdd, MdCheck, MdDelete, MdSave } from "react-icons/md";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import { validateObject } from "../../../util/validate";
import { findDuplicateData } from "../../../util/dealingArrObj";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

function UnitTr({
  unit,
  unit: {
    id,
    hierarchy_level,
    level_name,
    group_name,
    order,
    hr_teacher_username,
    description,
    level_name_new,
  },
  setIsUpLoading,
  setUnitInfo,
  setModalOpen,
  settingData,
  hideIcons,
  setHrTeacherModalOpen,
}) {
  const [saveWell, setSaveWell] = useState(false);
  const [deleteWell, setDeleteWell] = useState(false);
  const [updatedUnit, setUpdatedUnit] = useState(unit);

  const { t } = useTranslation();
  const { updateOrganizationUnit, deleteOrganizationUnit } =
    useOrganizationSetting();

  const queryClient = useQueryClient();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "hierarchy_level") {
      if (isNaN(Number(value))) {
        alert(t("admin.organizationSetting.alertHierarchyLevel"));
        return;
      } else {
        formattedValue = Number(value);
      }
    }
    setUpdatedUnit((updatedUnit) => ({
      ...updatedUnit,
      [name]: formattedValue,
    }));
    // console.log("updatedUnit", updatedUnit);
  };

  const handleInput = (e) => {
    const name = e.target.getAttribute("data-name");
    const { textContent } = e.target;
    setUpdatedUnit((updatedUnit) => ({ ...updatedUnit, [name]: textContent }));
    // console.log("updatedUnit", updatedUnit);
  };

  const handleModalClick = () => {
    if (hierarchy_level < 3) {
      setUnitInfo(unit);
      setModalOpen(true);
    }
  };

  const handleHrTeacherModalClick = () => {
    setUnitInfo(unit);
    setHrTeacherModalOpen(true);
  };

  const handleClick = () => {
    setIsUpLoading(true);

    const fieldsToValidate = [
      // { key: "level_name", required: true },
      { key: "group_name", required: true },
      { key: "order", required: true },
    ];

    // console.log("updatedUnit", updatedUnit);

    const errors = validateObject(updatedUnit, fieldsToValidate);
    if (errors.length > 0) {
      alert(t("common.messages.checkInput") + errors);
      setIsUpLoading(false);

      return;
    }

    const isDuplicate = findDuplicateData(settingData, updatedUnit, [
      "school_year",
      "parent_id",
      "group_name",
    ]);

    // console.log("settingData", settingData);

    // console.log("isDuplicate", isDuplicate);

    if (isDuplicate) {
      alert(t("admin.organizationSetting.alertGroupDuplicate"));
      setIsUpLoading(false);
      return;
    }

    updateOrganizationUnit.mutate(updatedUnit, {
      onSuccess: () => {
        queryClient.invalidateQueries(["settingConditions"]);
        setSaveWell(true);
        setIsUpLoading(false);
        setTimeout(() => {
          setSaveWell(false);
        }, 3000);
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(error.message);
      },
    });
  };

  const handleDelete = () => {
    // console.log(id);
    // Confirmation prompt
    if (window.confirm(t("common.messages.deleteAlert"))) {
      setIsUpLoading(true);
      const hasChild = settingData.some((item) => item.parent_id === id);

      if (hasChild) {
        alert(t("teacher.lessonRelated.deleteMessage"));
        setIsUpLoading(false);
      } else {
        deleteOrganizationUnit.mutate(id, {
          onSuccess: (successMessage) => {
            queryClient.invalidateQueries(["settingConditions"]);
            setDeleteWell(true);
            setTimeout(() => {
              setDeleteWell(false);
            }, 3000);
            setIsUpLoading(false);
          },
          onError: (error) => {
            setIsUpLoading(false);
            console.error(error); // 일단 콘솔에 에러를 출력합니다.
            console.error("위와 같은 에러가 났으니 고치삼");
            alert(error.message);
          },
          onSettled: () => {
            setIsUpLoading(false); // 로딩 상태를 mutation 완료 후 항상 변경
          },
        });
      }
    } else {
      // User clicked 'Cancel', do nothing
      console.log("삭제가 취소됨. 휴....");
    }
  };

  return (
    <tr className="resultRow">
      <th scope="row" className="text-center">
        <input
          type="text"
          className="input-like-span"
          defaultValue={hierarchy_level || ""}
          name="hierarchy_level"
          disabled
        />
      </th>
      <td
        className="changePaddings d-flex"
        style={{ paddingLeft: `${hierarchy_level * 35}px` }}
      >
        <div
          contentEditable
          suppressContentEditableWarning
          data-name="group_name"
          onInput={handleInput}
          style={{ minWidth: "15px" }}
          className="bg-body-tertiary me-1"
        >
          {group_name && group_name}
        </div>
        <div
          data-name="level_name"
          style={{ minWidth: "15px" }}
          className="bg-body-tertiary"
        >
          {level_name_new && level_name_new}
        </div>
      </td>
      {hierarchy_level < 3 ? (
        <td className="text-center align-middle p-0 m-0">
          {!hideIcons && (
            <MdAdd
              className="p-0 m-0"
              role="button"
              size={25}
              onClick={handleModalClick}
            />
          )}
        </td>
      ) : (
        <td className="text-center align-middle p-0 m-0"></td>
      )}
      <td>
        <input
          type="number"
          className="input-like-span"
          defaultValue={order}
          name="order"
          onChange={handleChange}
        />
      </td>
      <td>
        {hr_teacher_username?.map((user, index) => (
          <span key={index}>{user.tname} </span>
        ))}
      </td>
      <td>
        {hierarchy_level > 2 ? (
          <button
            className="btn btn-outline-secondary btn-sm pt-0 pb-0"
            onClick={handleHrTeacherModalClick}
          >
            {t("common.labels.assign")}
          </button>
        ) : (
          ""
        )}
      </td>
      <td>
        {!hideIcons && (
          <React.Fragment>
            {saveWell ? (
              <MdCheck className="me-3" size={20} />
            ) : (
              <MdSave
                className="me-3"
                size={20}
                role="button"
                onClick={handleClick}
              />
            )}
            {deleteWell ? (
              <MdCheck size={20} />
            ) : (
              <MdDelete size={20} role="button" onClick={handleDelete} />
            )}
          </React.Fragment>
        )}
      </td>
    </tr>
  );
}

export default UnitTr;
