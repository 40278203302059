import React, { useEffect, useRef, useState } from "react";
import DynamicComponentsRenderer from "../../../unicomponents/formComponents/DynamicComponentsRenderer";
import LeftMenuSidebar from "../../../unicomponents/LeftMenuSidebar";
import { useReactToPrint } from "react-to-print";
import { generateRegistrationRows } from "../../../util/dealingArrObj";
import { useUIContext } from "../../../context/UIContext";
import useRegistrationInfo from "../../../hooks/useRegistrationInfo";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import useComponentsToForms from "../../../hooks/useComponentsToForms";
import useAllForms from "../../../hooks/useAllForms";
import { useLocation } from "react-router-dom";
import FiltersBar from "../../../unicomponents/Dropdowns/FilterBar";
import RightControlSidebar from "../../../unicomponents/RightControlSidebar";
import useTermPeriod from "../../../hooks/useTermPeriod";
import { setTermIdByToday } from "../../../util/dateRelated";
import { useTranslation } from "react-i18next";

const PageHeader = () => (
  <div className="header">
    <h1> </h1>
  </div>
);

const PageFooter = () => (
  <div className="footer">
    <p className="page-number"></p>
  </div>
);

export default function OfficialDocuments() {
  const { t } = useTranslation();
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];
  const [filters, setFilters] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    organPathForSort: "/",
    termId: "",
    selectedTeacherId: "",
    selectedLessonId: "",
  });

  const [termId, setTermId] = useState("");
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [keyData, setKeyData] = useState({});
  // const [validateInput, setValidateInput] = useState({});
  const [modifyMode, setModifyMode] = useState(false);

  const [activeMenu, setActiveMenu] = useState({
    menu_name: "",
    menu_id: "",
  });

  const conditions = {
    schoolid: schoolId,
    form_category: "officialDocuments",
  };

  const [formQueryConditions, setFormQueryConditions] = useState({
    schoolid: schoolId,
    form_id: "",
  });

  const [styleSettings, setStyleSettings] = useState({});

  const {
    allFormsQuery: { data: allFormsData },
  } = useAllForms(conditions);

  const {
    componentsToFormsQuery: { data: componentsToFormsData = [], isLoading },
  } = useComponentsToForms(formQueryConditions);

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting({ schoolid: schoolId });

  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod({ schoolid: schoolId });

  const {
    registrationInfoQuery: { data: registrationInfoData },
  } = useRegistrationInfo({ schoolid: schoolId });

  const { setIsLoadingModal, handleToastCenterTop, handleToastCenterBottom } =
    useUIContext();

  useEffect(() => {
    if (allFormsData) {
      setFormQueryConditions((prev) => ({
        ...prev,
        form_id: allFormsData[0].id,
      }));

      setActiveMenu({
        menu_name: allFormsData[0].form_name,
        menu_id: allFormsData[0].id,
      });

      setStyleSettings(allFormsData[0]?.options || {});
    }
  }, [allFormsData]);

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;

    if (
      filters.schoolYear === null &&
      settingData &&
      settingData.length > 0 &&
      registrationInfoData
    ) {
      const initialData = settingData.sort(
        (a, b) => b.school_year - a.school_year
      )[0];

      initialSchoolYear = initialData.school_year;
      initialSchoolYearId = initialData.id;
      setFilters((filters) => ({
        ...filters,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
      }));

      if (termPeriodData) {
        setTermIdByToday(termPeriodData, setTermId, initialSchoolYearId);
      }

      //학생 리스트
      if (initialSchoolYear) {
        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          initialSchoolYear,
          filters.organPathForSort
        );

        setStudentList(sortedRows);
      }
    } else {
      //rows 데이터처리
      if (registrationInfoData && settingData) {
        // console.log("registrationInfoData", registrationInfoData);
        // console.log("filters", filters);

        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          filters.schoolYear,
          filters.organPathForSort
        );
        setStudentList(sortedRows);
      }
    }
  }, [
    filters,
    filters.schoolYear,
    registrationInfoData,
    settingData,
    termPeriodData,
  ]);

  useEffect(() => {
    if (termPeriodData) {
      const nowPeriod = termPeriodData.filter(
        (r) => r.id.toString() === termId.toString()
      )[0];

      if (nowPeriod) {
        // console.log("nowPeriod", nowPeriod);
        setCurrentPeriod(nowPeriod);
      } else {
        setCurrentPeriod({});
      }
    }
  }, [termId, termPeriodData]);

  useEffect(() => {
    if (studentList && filters.selectedStudent) {
      const selectedObject = studentList.find(
        (r) => r.username === filters.selectedStudent
      );
      if (currentPeriod && Object.keys(currentPeriod).length > 0) {
        setKeyData({
          ...selectedObject,
          currentPeriod: currentPeriod,
        });
      } else {
        setKeyData(selectedObject);
      }
    } else {
      setKeyData({});
    }
  }, [currentPeriod, filters.selectedStudent, schoolId, studentList]);

  const handleQueryCondition = (item) => {
    if (item?.id) {
      setFormQueryConditions((prev) => ({
        ...prev,
        form_id: item.id,
      }));

      setStyleSettings(item.options || {});
    }
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    const dataName = e.target.selectedOptions[0].getAttribute("data-name");
    let organPath = "";
    if (!dataName) {
      organPath = "N/A";
    } else {
      organPath = dataName + "/" + value;
    }
    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      setTermIdByToday(termPeriodData, setTermId, value);
    }

    if (name === "1") {
      setFilters((filters) => ({
        ...filters,
        hierarchyOneId: value,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      setTermIdByToday(termPeriodData, setTermId, filters.schoolYearId, value);
    }

    if (name === "2") {
      setFilters((filters) => ({
        ...filters,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      setTermIdByToday(
        termPeriodData,
        setTermId,
        filters.schoolYearId,
        filters.hierarchyOneId,
        value
      );
    }

    if (name === "3") {
      setFilters((filters) => ({
        ...filters,
        hierarchyThreeId: value,
        organPathForSort: organPath,
        selectedStudent: "",
      }));
    }

    if (name === "students") {
      setFilters((filters) => ({
        ...filters,
        selectedStudent: value,
      }));
    }

    if (name === "termDate") {
      setTermId(value);
    }
  };

  const handleModifyMode = (e) => {
    e.preventDefault();

    if (
      keyData.school_year &&
      keyData[1] &&
      keyData[2] &&
      keyData[3] &&
      keyData.currentPeriod?.term_name &&
      keyData.fullname
    ) {
      const onOff = e.target.name;
      if (onOff === "modifyOn") {
        setModifyMode(false);
      } else {
        setModifyMode(true);
        handleToastCenterBottom(
          t("teacher.printingRelated.officialDocumentToastStyle"),
          4000
        );
      }
    } else {
      console.log(
        "keyData is incomplete. Please ensure all data is available."
      );
      handleToastCenterTop(t("teacher.printingRelated.canModifyAfterSelect"));
    }
  };

  const triggerPrint = useReactToPrint({
    content: () => {
      // addPageNumbers();
      return contentToPrint.current;
    },
    documentTitle: `학부모교육증-${keyData.school_year}-${keyData?.currentPeriod?.term_name}-${keyData[1]}-${keyData[2]}-${keyData[3]}-${keyData.fullname}`,
    onBeforePrint: () => console.log("before printing..."),
    // onAfterPrint: () => {
    //   console.log("after printing...");
    //   // Cleanup the page numbers after printing
    //   document.querySelectorAll(".page-footer").forEach((el) => el.remove());
    // },
    removeAfterPrint: true,
    pageStyle: `@media print {
      .noPrint {
          display: none !important;
        }
      @page {
        size: A4;
        margin: 10mm;
      }


    }`,
  });

  const contentToPrint = useRef(null);
  const handlePrint = () => {
    // console.log("keyData", keyData);
    if (
      keyData.school_year &&
      keyData[1] &&
      keyData[2] &&
      keyData[3] &&
      keyData.fullname
    ) {
      triggerPrint();
    } else {
      console.log(
        "keyData is incomplete. Please ensure all data is available."
      );
      handleToastCenterTop(t("common.messages.printAfterSelectStudent"));
    }
  };

  const handleStyleSettings = (e) => {
    const { name, value, classList } = e.target;
    setStyleSettings((prev) => {
      // 글씨체 변경 처리 (classList에 'fontFamily' 포함된 경우)
      if (classList.contains("fontFamily")) {
        return {
          ...prev,
          fontFamily: value,
        };
      }

      // 폰트 크기 변경 처리 (classList에 'fontSizes' 포함된 경우)
      if (classList.contains("fontSizes")) {
        const updatedFontSizes = prev.fontSizes.map((option) =>
          option.name === name ? { ...option, value } : option
        );

        return {
          ...prev,
          fontSizes: updatedFontSizes,
        };
      }

      // 기본적으로 이전 상태를 반환
      return prev;
    });
  };

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <LeftMenuSidebar
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              menuData={allFormsData}
              extraFunction={handleQueryCondition}
              itemName="form_name"
              itemLabel="form_label"
            />
          </div>
          <div className={`col-12 col-md-${modifyMode ? "8" : "10"}`}>
            <div className="d-flex justify-content-end flex-wrap mt-2">
              <FiltersBar
                settingData={settingData}
                handleDropdownChange={handleDropdownChange}
                filters={filters}
                studentList={studentList}
                // validateInput={validateInput}
                modifyMode={modifyMode}
                disabled={false}
                termPeriodData={termPeriodData}
                termId={termId}
                currentPeriod={currentPeriod}
              />
            </div>
            <div className="d-flex justify-content-end flex-wrap mb-3 mt-2">
              <div style={{ width: "100px" }} className="me-1 text-center">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleModifyMode}
                  name={modifyMode ? "modifyOn" : "modifyOff"}
                >
                  {modifyMode
                    ? t("teacher.printingRelated.modifyOff")
                    : t("teacher.printingRelated.modifyOn")}
                </button>
              </div>
              <div style={{ width: "70px" }} className="me-1 text-center">
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={() => {
                    handlePrint(null, () => contentToPrint.current);
                  }}
                  // disabled={modifyMode}
                >
                  {t("common.labels.print")}
                </button>
              </div>
            </div>
            <div
              id="officialDocumentPrintArea"
              className="d-flex justify-content-center print-page"
              ref={contentToPrint}
            >
              <table className="" style={{ width: "210mm" }}>
                <thead>
                  <tr>
                    <th colSpan="3">
                      <PageHeader />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="1"></td>
                    <td
                      colSpan="1"
                      className="p-2"
                      style={{
                        maxWidth: "200mm",
                        fontFamily: styleSettings.fontFamily
                          ? styleSettings.fontFamily
                          : "",
                      }}
                    >
                      {styleSettings?.outLine &&
                      Object.keys(styleSettings.outLine).length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "190mm",
                              height: "240mm",
                              maxWidth: "100%",
                              margin: "70px 70px 50px 70px",
                              ...styleSettings.outLine,
                            }}
                          >
                            {!isLoading &&
                              componentsToFormsData &&
                              componentsToFormsData.map((item, index) => (
                                <DynamicComponentsRenderer
                                  components={item.components}
                                  customizedContents={item.customized_contents}
                                  keyData={keyData}
                                  setIsLoadingModal={setIsLoadingModal}
                                  key={`${item.id}-${item.order}-${index}`}
                                  componentsToFormId={item.id}
                                  modifyMode={modifyMode}
                                  styleSettings={styleSettings}
                                />
                              ))}
                          </div>
                        </div>
                      ) : (
                        !isLoading &&
                        componentsToFormsData &&
                        componentsToFormsData.map((item, index) => (
                          <DynamicComponentsRenderer
                            components={item.components}
                            customizedContents={item.customized_contents}
                            keyData={keyData}
                            setIsLoadingModal={setIsLoadingModal}
                            key={`${item.id}-${item.order}-${index}`}
                            componentsToFormId={item.id}
                            modifyMode={modifyMode}
                            styleSettings={styleSettings}
                          />
                        ))
                      )}
                    </td>
                    <td colSpan="1"></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3">
                      <PageFooter />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          {modifyMode && (
            <div className="col-12 col-md-2">
              <RightControlSidebar
                styleSettings={styleSettings}
                handleStyleSettings={handleStyleSettings}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
