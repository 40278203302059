import React, { useEffect, useState } from "react";
import DropdownsToFilter from "./components/DropdownsToFilter";
import { useLocation } from "react-router-dom";
import AddAwardActivity from "./components/AddAwardActivity";
import { useAuthContext } from "../../context/AuthContext";
import AwardTable from "./components/AwardTable";
import useEducationalActivities from "../../hooks/useEducationalActivities";
import useOrganizationSetting from "../../hooks/useOrganizationSetting";
import SideMenu from "./components/SideMenu";
import CareerTable from "./components/CareerTable";
import useTagList from "../../hooks/useTagList";
import AddCareerActivity from "./components/AddCareerActivity";
import AddVoluntaryActivity from "./components/AddVoluntaryActivity";
import VoluntaryTable from "./components/VoluntaryTable";
import AddClubActivity from "./components/AddClubActivity";
import ClubTable from "./components/ClubTable";
import useUsers from "../../hooks/useUsers";
import EbomAddActivitiesByFields from "./extraComponents/EbomAddActivitiesByFields";
import EbomActivitiesByFieldsTable from "./extraComponents/EbomActivitiesByFieldsTable";
import useLessonSetting from "../../hooks/useLessonSetting";
import useTermPeriod from "../../hooks/useTermPeriod";
import LoadingInside from "../../unicomponents/LoadingInside";
import SimpleAlertMessageBlock from "../../unicomponents/SimpleAlertMessageBlock";
import { useUIContext } from "../../context/UIContext";
import AddActivitiesByFields from "./components/AddActivitiesByFields";
import ActivitiesByFieldsTable from "./components/ActivitiesByFieldsTable";
import { useTranslation } from "react-i18next";

//이제 tag list만 하면 된다.

export default function CreateActivities() {
  const { t } = useTranslation();
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = {
    schoolid: schoolId,
    entity_type: "educational_activities",
  };

  const [conditionsEduActivities, setConditionsEduActivities] = useState({
    schoolid: schoolId,
    activity_type_id: "",
  });

  const { user, hasPermission } = useAuthContext();
  const { handleToastCenterBottom } = useUIContext();

  const {
    usersQuery: { data: userData },
  } = useUsers({ schoolid: schoolId }, { role: "teacher", status: 1 });

  const typeConditions = { ...conditions, type: "true" };
  const {
    educationalActivitiesTypeQuery: { data: educationalActivitiesTypeData },
  } = useEducationalActivities(typeConditions);

  const {
    educationalActivitiesQuery: { isLoading, data: educationalActivitiesData },
  } = useEducationalActivities(conditionsEduActivities);

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    tagListByEntityQuery: { data: tagList },
  } = useTagList(conditions);

  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod({ schoolid: schoolId });

  const {
    lessonSettingQuery: { data: lessonSettingData },
  } = useLessonSetting({ schoolid: schoolId });

  const [rows, setRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [schoolYear, setSchoolYear] = useState(null);
  const [schoolYearId, setSchoolYearId] = useState("all");
  const [schoolStageId, setSchoolStageId] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [activeMenu, setActiveMenu] = useState({
    activity_type: "",
    activity_type_id: "",
    options: [],
    schoolid: schoolId,
  });

  const [thisYearTerm, setThisYearTerm] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState("");
  const [subjectSorts, setSubjectSorts] = useState([]);

  useEffect(() => {
    if (educationalActivitiesTypeData) {
      setConditionsEduActivities((prev) => ({
        ...prev,
        activity_type_id: educationalActivitiesTypeData[0].id,
      }));

      const filteredOption =
        educationalActivitiesTypeData?.[0]?.options?.filter(
          (item) => schoolId in item
        ) || [];

      setActiveMenu({
        activity_type: educationalActivitiesTypeData[0].activity_type,
        activity_type_id: educationalActivitiesTypeData[0].id,
        options: filteredOption,
        schoolid: schoolId,
        activity_name: educationalActivitiesTypeData[0].activity_name,
      });
    }
  }, [educationalActivitiesTypeData, schoolId]);

  // 서버로부터 가져온 데이터를 rows 상태에 저장
  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;
    let initialSchoolStageId = null;
    let initialUsername = null;
    let filteredData = [];

    if (educationalActivitiesData && settingData && settingData.length > 0) {
      const enrichedRows = educationalActivitiesData.map((activity) => {
        // settings 배열에서 activity의 hierarchy_one과 일치하는 id를 찾음
        const matchingSetting = settingData.find(
          (setting) =>
            setting.id.toString() === activity.hierarchy_one.toString()
        );

        // 찾은 setting이 있다면 group_name을 사용하고, 없다면 "통합"을 기본값으로 사용
        const groupName = matchingSetting
          ? matchingSetting.group_name
          : t("common.labels.whole");

        // activity 객체에 group_name을 추가하여 반환
        return {
          ...activity,
          schoolStage: groupName,
        };
      });

      if (schoolYear === null && user) {
        //초기 데이터를 학년도로 필터해서 제공해야 하기 때문에 오름차순 제일 마지막 걸로
        const sortedData = settingData.sort(
          (a, b) => b.school_year - a.school_year
        );

        const initialData = sortedData[0];
        const hierarchyLevelOneFiltered = sortedData.filter(
          (r) => r.hierarchy_level.toString() === "1"
        );

        initialSchoolYear = initialData.school_year;
        initialSchoolYearId = initialData.id;
        initialSchoolStageId = hierarchyLevelOneFiltered[0]?.id || "";
        initialUsername = user.username;
        setSchoolYear(initialSchoolYear);
        setSchoolYearId(initialSchoolYearId);
        setSchoolStageId(initialSchoolStageId);
        // setSelectedUser(initialUsername);

        filteredData = enrichedRows.filter(
          (row) =>
            row.school_year_id.toString() === initialSchoolYearId.toString()
        );

        if (hasPermission("", "can_read")) {
          setRows(filteredData);
          setOriginalData(filteredData);
        } else {
          setRows(
            filteredData.filter((r) => r.creator_username === initialUsername)
          );
          setOriginalData(
            filteredData.filter((r) => r.creator_username === initialUsername)
          );

          handleToastCenterBottom(t("common.messages.ownerReadPermission"));
        }

        if (termPeriodData) {
          const filteredTermPeriod = termPeriodData.filter(
            (r) =>
              r.school_year_id.toString() === initialSchoolYearId.toString()
          );

          const sortedTerms = filteredTermPeriod.sort(
            (a, b) => new Date(a.start_date) - new Date(b.start_date)
          );
          setThisYearTerm(sortedTerms);
          setSelectedTerm(sortedTerms[0]?.term_name || "1");
        }
      } else {
        const filteredRows = filterData(enrichedRows, {
          schoolYearId,
          schoolStageId,
          selectedUser,
        });

        if (hasPermission("", "can_read")) {
          setRows(filteredRows);
          setOriginalData(
            enrichedRows.filter(
              (r) =>
                schoolStageId === "0" ||
                r.hierarchy_one.toString() === schoolStageId.toString()
            )
          );
        } else {
          setRows(
            filteredRows.filter((r) => r.creator_username === user?.username)
          );
          setOriginalData(
            enrichedRows.filter((r) => r.creator_username === user?.username)
          );
        }

        if (termPeriodData) {
          const filteredTermPeriod = termPeriodData
            .filter(
              (r) => r.school_year_id.toString() === schoolYearId.toString()
            )
            .sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

          const uniqueTerms = [];
          const seen = new Set();

          filteredTermPeriod.forEach((item) => {
            if (!seen.has(item.term_name)) {
              seen.add(item.term_name);
              uniqueTerms.push(item);
            }
          });

          setThisYearTerm(uniqueTerms);
        }

        const optionTermName =
          activeMenu?.options?.[0]?.[schoolId]?.termName || "";

        if (optionTermName && selectedTerm) {
          setRows(
            filteredRows.filter(
              (r) => r.extra_attributes.termName === selectedTerm
            )
          );

          setOriginalData(
            enrichedRows.filter(
              (r) => r.extra_attributes.termName === selectedTerm
            )
          );
        }
      }
    }
  }, [
    educationalActivitiesData,
    schoolYearId,
    settingData,
    schoolYear,
    schoolStageId,
    selectedUser,
    user,
    termPeriodData,
    selectedTerm,
    hasPermission,
    handleToastCenterBottom,
    activeMenu?.options,
    schoolId,
    t,
  ]);

  //ebom Fields 때문에 만드는 것
  useEffect(() => {
    if (lessonSettingData && termPeriodData) {
      let thisTermEndDate;

      /*
1. school_year_id로 term_dates를 가져와서 수업세팅에 적용할 것을 적용하자.
2. 그렇게 가져온 term_dates에서 term_name을 가져와서, 아래 옵션에 사용하자.
3. 그리고 start_date 날짜로도 사용하자. 
4. 수업세팅의 item_code가 subjectSort의 detailed_setting을 가져와서 영역 선택옵션으로 사용하자
*/

      // termId가 null 혹은 undefined일 경우, termPeriodData 전체에서 가장 늦은 end_date를 찾습니다.
      thisTermEndDate = new Date(
        Math.max(
          ...termPeriodData.map((period) => new Date(period.end_date).getTime())
        )
      );

      if (thisTermEndDate) {
        const filteredAndSortedLessonSetting = lessonSettingData
          .filter((r) => new Date(r.start_date) <= thisTermEndDate) // start_date가 thisTermEndDate 이전인 항목을 필터링
          .sort((a, b) => new Date(b.start_date) - new Date(a.start_date)); // end_date 기준으로 내림차순 정렬

        // Use a reducer to keep only the first occurrence of each item_code
        const uniqueLessonSetting = filteredAndSortedLessonSetting.reduce(
          (acc, current) => {
            const x = acc.find((item) => item.item_code === current.item_code);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          },
          []
        );

        const fields = uniqueLessonSetting.find(
          (r) => r.item_code === "subjectSort"
        );
        setSubjectSorts(fields?.detailed_setting || []);
      }
    }
  }, [lessonSettingData, termPeriodData]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;

    if (name === "0") {
      setSchoolYear(selectedOptionText);
      setSchoolYearId(value);
      setSchoolStageId("0");
    }

    if (name === "1") {
      setSchoolStageId(value);
    }

    if (name === "users") {
      setSelectedUser(value);
    }

    if (name === "termName") {
      setSelectedTerm(value);
    }
  };

  const handleQueryCondition = (item) => {
    if (item?.id) {
      setConditionsEduActivities((prev) => ({
        ...prev,
        activity_type_id: item.id,
      }));
    }
  };

  function renderComponent() {
    switch (activeMenu.activity_type) {
      case "awards":
        return (
          <>
            <AddAwardActivity
              conditions={conditions}
              schoolYear={schoolYear}
              schoolYearId={schoolYearId}
              schoolStageId={schoolStageId}
              setSchoolStageId={setSchoolStageId}
              user={user}
              tagList={tagList}
              activeMenu={activeMenu}
            />
            <AwardTable
              rows={rows}
              setRows={setRows}
              tagList={tagList}
              activeMenu={activeMenu}
            />
          </>
        );
      case "careers":
        return (
          <>
            <AddCareerActivity
              conditions={conditions}
              schoolYear={schoolYear}
              schoolYearId={schoolYearId}
              schoolStageId={schoolStageId}
              setSchoolStageId={setSchoolStageId}
              user={user}
              tagList={tagList}
              activeMenu={activeMenu}
            />
            <CareerTable
              rows={rows}
              setRows={setRows}
              tagList={tagList}
              activeMenu={activeMenu}
            />
          </>
        );
      case "voluntary":
        return (
          <>
            <AddVoluntaryActivity
              conditions={conditions}
              schoolYear={schoolYear}
              schoolYearId={schoolYearId}
              schoolStageId={schoolStageId}
              setSchoolStageId={setSchoolStageId}
              user={user}
              tagList={tagList}
              activeMenu={activeMenu}
            />
            <VoluntaryTable
              rows={rows}
              setRows={setRows}
              tagList={tagList}
              activeMenu={activeMenu}
            />
          </>
        );

      // 다른 것들과 차별점은 개설자(교사)를 선택할 수 있다는 것이다.
      case "clubs":
        return (
          <>
            <AddClubActivity
              conditions={conditions}
              schoolYear={schoolYear}
              schoolYearId={schoolYearId}
              schoolStageId={schoolStageId}
              setSchoolStageId={setSchoolStageId}
              user={user}
              tagList={tagList}
              userData={userData}
              activeMenu={activeMenu}
            />
            <ClubTable
              rows={rows}
              setRows={setRows}
              tagList={tagList}
              userData={userData}
              activeMenu={activeMenu}
            />
          </>
        );
      case "ebomAddActivitiesByFields":
        return (
          <>
            <EbomAddActivitiesByFields
              conditions={conditions}
              schoolYear={schoolYear}
              schoolYearId={schoolYearId}
              schoolStageId={schoolStageId}
              setSchoolStageId={setSchoolStageId}
              user={user}
              tagList={tagList}
              activeMenu={activeMenu}
              thisYearTerm={thisYearTerm}
              subjectSorts={subjectSorts}
              selectedTerm={selectedTerm}
            />
            <EbomActivitiesByFieldsTable
              rows={rows}
              setRows={setRows}
              tagList={tagList}
              activeMenu={activeMenu}
              thisYearTerm={thisYearTerm}
              subjectSorts={subjectSorts}
              selectedTerm={selectedTerm}
            />
          </>
        );
      case "addActivitiesByFields":
        return (
          <>
            <AddActivitiesByFields
              conditions={conditions}
              schoolYearId={schoolYearId}
              schoolStageId={schoolStageId}
              setSchoolStageId={setSchoolStageId}
              user={user}
              tagList={tagList}
              activeMenu={activeMenu}
              thisYearTerm={thisYearTerm}
              selectedTerm={selectedTerm}
            />
            <ActivitiesByFieldsTable
              conditions={conditions}
              rows={rows}
              setRows={setRows}
              tagList={tagList}
              activeMenu={activeMenu}
              thisYearTerm={thisYearTerm}
              selectedTerm={selectedTerm}
            />
          </>
        );
      default:
        return (
          <>
            <AddAwardActivity
              conditions={conditions}
              schoolYear={schoolYear}
              schoolYearId={schoolYearId}
              schoolStageId={schoolStageId}
              setSchoolStageId={setSchoolStageId}
              user={user}
              tagList={tagList}
              activeMenu={activeMenu}
            />
            <AwardTable
              rows={rows}
              setRows={setRows}
              tagList={tagList}
              activeMenu={activeMenu}
            />
          </>
        );
    }
  }

  if (isLoading) {
    return <LoadingInside />;
  }

  if (!hasPermission(null, "can_access")) {
    return (
      <SimpleAlertMessageBlock
        message={t("common.messages.noAccessPermission")}
      />
    );
  }

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <SideMenu
              educationalActivitiesTypeData={educationalActivitiesTypeData}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              extraFunction={handleQueryCondition}
              schoolId={schoolId}
            />
          </div>
          <div className="col-12 col-md-10">
            <div className="row">
              <div className="col-8 offset-4">
                <DropdownsToFilter
                  schoolYear={schoolYear}
                  schoolYearId={schoolYearId}
                  schoolStageId={schoolStageId}
                  user={user}
                  settingData={settingData}
                  rows={originalData}
                  selectedUser={selectedUser}
                  handleDropdownChange={handleDropdownChange}
                  activeMenu={activeMenu}
                  termPeriodData={termPeriodData}
                  selectedTerm={selectedTerm}
                />
              </div>
            </div>
            {renderComponent()}
          </div>
        </div>
      </div>
    </div>
  );
}

// 필터링 함수
function filterData(data, { schoolYearId, schoolStageId, selectedUser }) {
  return data.filter((item) => {
    const matchesSchoolYearId =
      schoolYearId === "all" ||
      item.school_year_id.toString() === schoolYearId.toString();
    const matchesSchoolStageId =
      schoolStageId === "0" ||
      item.hierarchy_one.toString() === schoolStageId.toString();
    const matchesSelectedUser =
      selectedUser === "" || item.creator_username === selectedUser;

    return matchesSchoolYearId && matchesSchoolStageId && matchesSelectedUser;
  });
}
