import React, { useState } from "react";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import { MdOutlineCancel } from "react-icons/md";
import useUsers from "../../../hooks/useUsers";
import { useTranslation } from "react-i18next";

export default function AddHrTeacherModal({
  unit,
  setHrTeacherModalOpen,
  levelNameData,
  conditions,
  setIsUpLoading,
}) {
  // console.log("unit", unit);
  const { t } = useTranslation();

  const [addHrTeacher, setAddHrTeacher] = useState({
    ...unit,
  });

  const [filteredTeachers, setFilteredTeachers] = useState([]);

  const { addHrTeacherToOrganUnit } = useOrganizationSetting();

  const {
    usersQuery: { data: userData },
  } = useUsers(conditions);

  const handleChange = (e) => {
    const { value } = e.target;
    // const selectedOptionText = e.target.selectedOptions[0].text;
    if (value.length > 0) {
      const selectedOptionText = e.target.selectedOptions[0].text;

      setAddHrTeacher((addHrTeacher) => {
        const hr_teacher_username = addHrTeacher.hr_teacher_username || [];
        const exists = hr_teacher_username.some(
          (r) => r.username === selectedOptionText
        );

        if (!exists) {
          const newTeacher = { username: selectedOptionText, tname: value };
          return {
            ...addHrTeacher,
            hr_teacher_username: [...hr_teacher_username, newTeacher],
          };
        }

        return addHrTeacher;
      });
    }
    // console.log("addHrTeacher", addHrTeacher);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsUpLoading(true);
    addHrTeacherToOrganUnit.mutate(addHrTeacher, {
      onSuccess: () => {
        setIsUpLoading(false);
        setHrTeacherModalOpen(false);
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error);
      },
    });
  };

  function simpleFilterForLevelName(levelNameData, unit) {
    const filtered = levelNameData.filter(
      (r) =>
        r.school_year === unit.school_year &&
        r.hierarchy_level === unit.hierarchy_level
    );

    return filtered[0].level_name;
  }

  const handleTeacherNameChange = (e) => {
    const { value } = e.target;
    const filtered = userData.filter((user) => {
      return (
        user.fullname.toLowerCase() === value.toLowerCase() &&
        user.role === "teacher" &&
        user.status === 1
      );
    });
    setFilteredTeachers(filtered);
  };

  const handleClick = (hr_teacher) => {
    setAddHrTeacher((addHrTeacher) => ({
      ...addHrTeacher,
      hr_teacher_username: addHrTeacher.hr_teacher_username.filter(
        (user) => user.username !== hr_teacher
      ),
    }));
  };

  return (
    <>
      <div className="modal-container">
        <div className="modalForUnit">
          <div className="modal-header mb-3">
            <h3>{t("admin.organizationSetting.addHrTeacher")}</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <div className="row mb-2">
                <div className="col-3">
                  <label htmlFor="hierarchy_level" className="col-form-label">
                    {t("admin.organizationSetting.hierarchyLevel")}
                  </label>
                </div>
                <div className="col-auto" style={{ paddingTop: "6px" }}>
                  <span className="align-bottom">
                    {unit?.hierarchy_level}{" "}
                    {t("admin.organizationSetting.level")}
                  </span>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-3">
                  <label htmlFor="group_name" className="col-form-label">
                    {t("admin.organizationSetting.levelName")}
                  </label>
                </div>
                <div className="col-auto" style={{ paddingTop: "6px" }}>
                  <span className="align-bottom">
                    {levelNameData &&
                      simpleFilterForLevelName(levelNameData, unit)}
                  </span>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-3">
                  <label htmlFor="group_name" className="col-form-label">
                    {t("admin.organizationSetting.groupName")}
                  </label>
                </div>
                <div className="col-auto" style={{ paddingTop: "6px" }}>
                  <span className="align-bottom">{unit?.group_name}</span>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-3">
                  <label htmlFor="teacher" className="col-form-label">
                    {t("admin.organizationSetting.searchTeacher")}
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="teacher"
                    className="form-control"
                    onChange={handleTeacherNameChange}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-3">
                  <label
                    htmlFor="hr_teacher_username"
                    className="col-form-label"
                  >
                    {t("common.user.teacher") || "교사"}{" "}
                    {t("common.labels.id") || "아이디"}
                  </label>
                </div>
                <div className="col-8">
                  <select
                    name="hr_teacher_username"
                    className="form-control"
                    onChange={handleChange}
                  >
                    <option value="">
                      {t("common.labels.select") || "선택"}
                    </option>
                    {filteredTeachers.map((user) => (
                      <option key={user.id} value={user.fullname}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-3">
                  <label htmlFor="tname" className="col-form-label">
                    {t("admin.organizationSetting.assignedTeacher")}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-8 border text-nowrap m-1 p-1 ms-3">
                  {addHrTeacher?.hr_teacher_username?.map((user, index) => (
                    <span key={index}>
                      {user.tname}
                      <MdOutlineCancel
                        role="button"
                        onClick={() => {
                          handleClick(user.username);
                        }}
                      />
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setHrTeacherModalOpen(false);
                }}
              >
                {t("common.labels.cancel") || "취소"}
              </button>
              <button type="submit" className="btn btn-primary">
                {t("admin.organizationSetting.assign")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
