import React, { useEffect, useRef, useState } from "react";
import LeftMenuSidebar from "../../../../unicomponents/LeftMenuSidebar";
import useAllForms from "../../../../hooks/useAllForms";
import { useLocation } from "react-router-dom";
import DropdownSchoolYear from "../../../../unicomponents/Dropdowns/DropdownSchoolYear";
import DropdownTermDate from "../../../../unicomponents/Dropdowns/DropdownTermDate";
import useOrganizationSetting from "../../../../hooks/useOrganizationSetting";
import useTermPeriod from "../../../../hooks/useTermPeriod";
import useRegistrationInfo from "../../../../hooks/useRegistrationInfo";
import { useAuthContext } from "../../../../context/AuthContext";
import { setTermIdByToday } from "../../../../util/dateRelated";
import { generateRegistrationRows } from "../../../../util/dealingArrObj";
import { filterSettingsByStudentsPath } from "../../../../util/specialCase";
import { useTranslation } from "react-i18next";
import StudentAttendance from "./studentAttendance/StudentAttendance";
import StudentGrades from "./studentGrades/StudentGrades";

export default function StudentRecords() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];

  const conditions = {
    schoolid: schoolId,
    form_category: "studentRecords",
  };

  const [filters, setFilters] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    levelOneName: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    organPathForSort: "/",
    termId: "",
    selectedLessonId: "",
  });
  const [termId, setTermId] = useState("");
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [registrationInfoData, setRegistrationInfoData] = useState([]);
  const [newSetting, setNewSetting] = useState([]);

  const [activeMenu, setActiveMenu] = useState({
    menu_name: "",
    menu_id: "",
    options: {},
  });

  const {
    allFormsQuery: { data: allFormsData },
  } = useAllForms(conditions);

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting({ schoolid: schoolId });

  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod({ schoolid: schoolId });

  const { getRegistrationInfoByUsername } = useRegistrationInfo();
  const getRegistrationInfoByUsernameRef = useRef(
    getRegistrationInfoByUsername
  );

  const { user } = useAuthContext();

  const { t } = useTranslation();

  useEffect(() => {
    if (allFormsData) {
      // console.log("allFormsData[0].form_name", allFormsData);
      setActiveMenu({
        menu_name: allFormsData[0].form_name,
        menu_id: allFormsData[0].id,
        options: allFormsData[0].options,
      });
    }
  }, [allFormsData]);

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;
    let initialData = [];
    if (
      filters.schoolYear === null &&
      newSetting &&
      newSetting.length > 0 &&
      registrationInfoData
    ) {
      initialData = newSetting
        .sort((a, b) => a.hierarchy_level - b.hierarchy_level)
        .sort((a, b) => b.school_year - a.school_year);

      // console.log("initialData", initialData);
      if (initialData.length < 1) {
        alert(t("common.messages.registrationError"));
        return;
      }

      initialSchoolYear = initialData[0].school_year;
      initialSchoolYearId = initialData[0].id;

      setFilters((filters) => ({
        ...filters,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
      }));

      if (termPeriodData) {
        setTermIdByToday(termPeriodData, setTermId, initialSchoolYearId);
      }

      if (initialSchoolYear) {
        //학생 리스트
        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          newSetting,
          initialSchoolYear,
          filters.organPathForSort
        );
        setStudentList(sortedRows);
      }
    } else {
      //rows 데이터처리
      if (registrationInfoData && newSetting) {
        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          newSetting,
          filters.schoolYear,
          filters.organPathForSort
        );
        setStudentList(sortedRows);
      }
    }
  }, [
    filters.schoolYear,
    filters.organPathForSort,
    registrationInfoData,
    newSetting,
    termPeriodData,
    user?.username,
    t,
  ]);

  useEffect(() => {
    if (user?.username && settingData) {
      const forWhere = {
        schoolid: schoolId,
        username: user.username,
      };

      getRegistrationInfoByUsernameRef.current.mutate(forWhere, {
        onSuccess: (data) => {
          //   console.log("lessonList", data);

          setRegistrationInfoData(data);

          setNewSetting(filterSettingsByStudentsPath(data, settingData));
        },
        onError: (error) => {
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(t("common.messages.errorAlert") + error.message);
        },
      });
    }
  }, [schoolId, settingData, user?.username, t]);

  useEffect(() => {
    if (termPeriodData) {
      const nowPeriod = termPeriodData.filter(
        (r) => r.id.toString() === termId.toString()
      )[0];

      if (nowPeriod) {
        setCurrentPeriod(nowPeriod);
      } else {
        setCurrentPeriod({});
      }
    }
  }, [termId, termPeriodData]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        organPathForSort: "/",
      }));

      setTermIdByToday(termPeriodData, setTermId, value);
    }

    if (name === "termDate") {
      setTermId(value);
    }
  };

  const renderComponent = () => {
    switch (activeMenu.menu_name) {
      case "attendance":
        return (
          <StudentAttendance
            keyData={studentList[0] || {}}
            currentPeriod={currentPeriod}
          />
        );
      case "studentGrades":
        return (
          <StudentGrades
            keyData={studentList[0] || {}}
            currentPeriod={currentPeriod}
          />
        );
      default:
        return (
          <StudentAttendance
            keyData={studentList[0] || {}}
            currentPeriod={currentPeriod}
          />
        );
    }
  };

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <LeftMenuSidebar
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              menuData={allFormsData}
              itemName="form_name"
              itemLabel="form_label"
            />
          </div>
          <div className="col-12 col-md-10">
            <div
              className="d-flex justify-content-end gap-3 flex-wrap mt-4"
              style={{ width: "75%" }}
            >
              <div style={{ width: "95px" }} className="me-1">
                <DropdownSchoolYear
                  settingData={newSetting}
                  handleDropdownChange={handleDropdownChange}
                  schoolYearId={filters.schoolYearId}
                  allYears={false}
                />
              </div>
              <div style={{ width: "95px" }}>
                <DropdownTermDate
                  termPeriodData={termPeriodData}
                  handleDropdownChange={handleDropdownChange}
                  termId={termId}
                  schoolYearId={filters.schoolYearId}
                />
              </div>
            </div>
            <div>
              {" "}
              {/*여기 아래가 바뀌어서 들어갈 곳 */}
              {renderComponent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
