import React, { useEffect, useState, useMemo, useCallback } from "react";
import DropdownSchoolYear from "../../../unicomponents/Dropdowns/DropdownSchoolYear";
import { useUIContext } from "../../../context/UIContext";
import useVariousStudentRecords from "../../../hooks/useVariousStudentRecords";
import { generateRegistrationRows } from "../../../util/dealingArrObj";
import DropdownHierarchyOne from "../../../unicomponents/Dropdowns/DropdownHierarchyOne";
import DropdownHierarchyTwo from "../../../unicomponents/Dropdowns/DropdownHierarchyTwo";
import DropdownHierarchyThree from "../../../unicomponents/Dropdowns/DropdownHierarchyThree";
import DropdownUniqueUsersInDisplayData from "../../../unicomponents/Dropdowns/DropdownUniqueUsersInDisplayData";
import useRegistrationInfo from "../../../hooks/useRegistrationInfo";
import { useAuthContext } from "../../../context/AuthContext";
import { checkHrteachers } from "../../../util/checkPermissions";
import SimpleAlertMessageBlock from "../../../unicomponents/SimpleAlertMessageBlock";
import { useTranslation } from "react-i18next";
import useUsers from "../../../hooks/useUsers";
import DynamicTable from "../../../unicomponents/Tables/DynamicTable";

/*
상단에 재적 dropdown + user의 수업 dropdown
하단에 기록할 내용 (various_record_inputs 에서 가져옴)
그 하단에 테이블로 저장한 것이 나옴 (various_record_inputs 에서 가져옴)
수정, 삭제 가능
*/

/*
다른 것 (독서기록 등)과 가장 큰 차이점은 중복 저장이 가능한 것이다. allow_duplicate를 true로 넣어야 한다.
그래서 중복 검사도 건너 뛴다.
*/

export default function CoommiMyGrowthReport({
  settingData,
  inputData,
  schoolId,
  inputQueryConditions,
  hrTeachersData,
}) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    filters: "",
    organPathForSort: "N/A",
    selectedStudent: "",
    selectedTeacher: "",
  });

  const [queryConditions, setQueryConditions] = useState({
    schoolid: schoolId,
    school_year_id: "",
    record_list_id: inputQueryConditions?.record_list_id,
  });

  const [studentList, setStudentList] = useState([]);
  const [dataToSave, setDataToSave] = useState([]);
  const [allStudentData, setAllStudentData] = useState([]);
  const [validateInput, setValidateInput] = useState({});
  const [savedWellByGroup, setSavedWellByGroup] = useState("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const {
    registrationInfoQuery: { data: registrationInfoData },
  } = useRegistrationInfo({ schoolid: schoolId });

  const { getVariousStudentDataWithRegi, createOrUpdateVariousStudentRecords } =
    useVariousStudentRecords(queryConditions);

  const {
    usersQuery: { data: teacherData },
  } = useUsers({ schoolid: schoolId }, { role: "teacher", status: 1 });

  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();
  const { user, hasPermission } = useAuthContext();

  // 테이블 설정
  const tableConfig = {
    style: { fontSize: "14px" }, // 폰트 크기만 커스텀
    headers: [
      {
        label: "번호",
        style: { width: "60px" },
        sortField: "number",
      },
      {
        label: "역량",
        style: { width: "140px" },
        sortField: "label",
      },
      {
        label: "봄학기",
        style: { width: "70px" },
        sortField: "input:봄",
      },
      {
        label: "여름학기",
        style: { width: "70px" },
        sortField: "input:여름",
      },
      {
        label: "가을학기",
        style: { width: "70px" },
        sortField: "input:가을",
      },
      {
        label: "겨울학기",
        style: { width: "70px" },
        sortField: "input:겨울",
      },
      {
        label: "종합의견",
        style: { minWidth: "200px" },
        sortField: "textarea:comment",
      },
    ],
    columns: [
      { valueKey: "number" }, // 기본 셀 스타일 사용
      { valueKey: "label" }, // 기본 셀 스타일 사용
      {
        key: "봄",
        type: "input",
        inputType: "number",
      },
      {
        key: "여름",
        type: "input",
        inputType: "number",
      },
      {
        key: "가을",
        type: "input",
        inputType: "number",
      },
      {
        key: "겨울",
        type: "input",
        inputType: "number",
      },
      { key: "comment", type: "textarea", rows: "2" },
    ],
    rows: [
      { number: 1, name: "withGod", label: "하나님과의 교제" },
      { number: 2, name: "selfReliance", label: "자립심" },
      { number: 3, name: "cooperation", label: "협동심" },
      { number: 4, name: "volunteerism", label: "봉사정신" },
      { number: 5, name: "discernment", label: "분별력" },
      { number: 6, name: "communication", label: "의사소통력" },
      { number: 7, name: "problemSolving", label: "문제해결력" },
    ],
  };

  // 테이블 설정
  const tableConfigTwo = {
    style: { fontSize: "14px" }, // 폰트 크기만 커스텀
    headers: [
      {
        label: "번호",
        style: { width: "60px" },
        sortField: "number",
      },
      {
        label: "역량",
        style: { width: "140px" },
        sortField: "label",
      },
      {
        label: "봄학기",
        style: { width: "70px" },
        sortField: "input:봄",
      },
      {
        label: "여름학기",
        style: { width: "70px" },
        sortField: "input:여름",
      },
      {
        label: "가을학기",
        style: { width: "70px" },
        sortField: "input:가을",
      },
      {
        label: "겨울학기",
        style: { width: "70px" },
        sortField: "input:겨울",
      },
    ],
    columns: [
      { valueKey: "number" }, // 기본 셀 스타일 사용
      { valueKey: "label" }, // 기본 셀 스타일 사용
      {
        key: "봄",
        type: "input",
        inputType: "number",
      },
      {
        key: "여름",
        type: "input",
        inputType: "number",
      },
      {
        key: "가을",
        type: "input",
        inputType: "number",
      },
      {
        key: "겨울",
        type: "input",
        inputType: "number",
      },
    ],
    rows: [
      { number: 1, name: "motivation", label: "학습동기" },
      { number: 2, name: "plan", label: "학습계획" },
      { number: 3, name: "execution", label: "학습실행" },
      { number: 4, name: "evaluation", label: "학습평가" },
      { number: 5, name: "reflection", label: "성찰반영" },
      { number: 6, name: "tools", label: "학습도구" },
      { number: 7, name: "trivium", label: "트리비움" },
      { number: 8, name: "metacognition", label: "메타인지" },
    ],
  };

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;

    if (
      filters.schoolYear === null &&
      registrationInfoData &&
      settingData &&
      settingData.length > 0
    ) {
      const initialData = settingData.sort(
        (a, b) => b.school_year - a.school_year
      )[0];

      initialSchoolYear = initialData.school_year;
      initialSchoolYearId = initialData.id;
      setFilters((filters) => ({
        ...filters,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
      }));

      setQueryConditions((prev) => ({
        ...prev,
        school_year_id: initialSchoolYearId,
        school_year: initialSchoolYear,
      }));

      if (initialSchoolYear) {
        //학생 리스트
        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          initialSchoolYear,
          filters.organPathForSort
        );
        // console.log("sortedRows", sortedRows);
        setStudentList(sortedRows);
      }
    } else {
      //rows 데이터처리
      if (registrationInfoData && settingData) {
        // console.log("registrationInfoData", registrationInfoData);
        // console.log("filters", filters);

        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          filters.schoolYear,
          filters.organPathForSort
        );
        // console.log("sortedRows2", sortedRows);
        setStudentList(sortedRows);
      }
    }
  }, [
    filters,
    filters.organPathForSort,
    filters.schoolYear,
    registrationInfoData,
    settingData,
  ]);

  useEffect(() => {
    if (hrTeachersData && filters.schoolYear)
      if (checkHrteachers(hrTeachersData, "", filters.schoolYear)) {
        const thisYearData = hrTeachersData.filter(
          (r) => r.schoolYear === filters.schoolYear
        );
        const splited = thisYearData[0].organization_path.split("/");

        setFilters((filters) => ({
          ...filters,
          hierarchyOneId: splited[2],
          hierarchyTwoId: splited[3],
          hierarchyThreeId: splited[4],
          organPathForSort: thisYearData[0].organization_path,
        }));

        // 담임교사 데이터가 있을 때 해당 경로로 필터링
        filterDataLocally(thisYearData[0].organization_path);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.schoolYear, hrTeachersData]);

  useEffect(() => {
    if (teacherData && user) {
      // console.log("teacherData", teacherData);
      if (user.role === "teacher") {
        setFilters((filters) => ({
          ...filters,
          selectedTeacher: user.username,
        }));
      }
    }
  }, [user, teacherData]);

  // 클라이언트 측 필터링 함수 추가
  const filterDataLocally = (organPath) => {
    if (!allStudentData || allStudentData.length === 0) return;
    const filteredData = allStudentData.filter((r) =>
      r.organization_path.startsWith(organPath)
    );

    setDataToSave(filteredData);
  };

  function queryVariousStudentDataWithRegi(forWhere, organPath = "") {
    getVariousStudentDataWithRegi.mutate(forWhere, {
      onSuccess: (data) => {
        if (settingData) {
          const settingMap = settingData.reduce((map, setting) => {
            map[setting.id] = setting.group_name;
            return map;
          }, {});

          const updatedData = data.map((studentData) => {
            const pathParts = studentData.organization_path.split("/");
            const stageId = pathParts[2]; // 과정
            const yearId = pathParts[3]; // 학년
            const classId = pathParts[4]; // 반

            const groupNameOne = settingMap[stageId];
            const groupNameTwo = settingMap[yearId];
            const groupNameThree = settingMap[classId];

            return {
              ...studentData,
              hierarchyOne: groupNameOne,
              hierarchyTwo: groupNameTwo,
              hierarchyThree: groupNameThree,
            };
          });

          // 전체 데이터 저장
          setAllStudentData(updatedData);

          // 현재 선택된 경로에 맞게 필터링
          if (organPath) {
            const filteredData = updatedData.filter((r) =>
              r.organization_path.startsWith(organPath)
            );
            setDataToSave(filteredData);
          } else {
            setDataToSave(updatedData);
          }
        }
      },
      onError: (error) => {
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
        return "";
      },
    });
  }

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    const dataName = e.target.selectedOptions[0].getAttribute("data-name");
    let organPath = "";
    if (!dataName) {
      // 기존 경로에서 마지막 부분만 제거
      if (filters.organPathForSort && filters.organPathForSort !== "N/A") {
        const pathParts = filters.organPathForSort.split("/");
        organPath = pathParts.slice(0, -1).join("/");
      } else {
        organPath = "N/A";
      }
    } else {
      organPath = dataName + "/" + value;
    }

    // console.log("dataToCreate", dataToCreate);

    // console.log("organPath", organPath);
    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      const newQueryConditions = {
        ...queryConditions,
        school_year_id: value,
        school_year: selectedOptionText,
      };

      setQueryConditions(newQueryConditions);
      setDataToSave([]);

      // 학년도 변경 시에만 서버에서 데이터 가져오기
      queryVariousStudentDataWithRegi(newQueryConditions, "");
    }

    if (name === "1") {
      setFilters((filters) => ({
        ...filters,
        hierarchyOneId: value,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
        organPathForSort: organPath,
        selectedStudent: "",
      }));

      if (allStudentData.length > 0) {
        // 클라이언트 측 필터링
        filterDataLocally(organPath);
      } else {
        // 초기 마운트 시 데이터 로드
        queryVariousStudentDataWithRegi(
          { ...queryConditions, school_year_id: filters.schoolYearId },
          organPath
        );
      }
    }

    if (name === "2") {
      setFilters((filters) => ({
        ...filters,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
        organPathForSort: organPath,
        selectedStudent: "",
      }));

      // 클라이언트 측 필터링
      filterDataLocally(organPath);
    }

    if (name === "3") {
      setFilters((filters) => ({
        ...filters,
        hierarchyThreeId: value,
        organPathForSort: organPath,
        selectedStudent: "",
      }));

      // 클라이언트 측 필터링
      filterDataLocally(organPath);
    }

    if (name === "students") {
      setFilters((filters) => ({
        ...filters,
        selectedStudent: value,
      }));
    }
  };

  // 현재 선택된 학생의 데이터 찾기 - useMemo로 최적화
  const selectedStudentData = useMemo(() => {
    if (!filters.selectedStudent || !dataToSave.length) return null;

    // 선택된 학생의 모든 그룹 데이터를 찾음
    const studentData = dataToSave.filter(
      (item) => item.username === filters.selectedStudent
    );

    if (!studentData.length) return null;

    // 각 그룹별 value_object를 반환
    return studentData.map((item) => ({
      group: item.group,
      value_object: item.value_object,
    }));
  }, [dataToSave, filters.selectedStudent]);

  // 데이터 변경 처리 함수 - useCallback으로 최적화
  const handleSaveState = useCallback(
    (e, username, objectKey, group) => {
      let value = e.target.value;
      let name = e.target.name; // 역량 ID (withGod, selfReliance 등)

      if (filters.selectedStudent === "") {
        handleToastCenterTop(t("common.messages.selectStudent"));
        return;
      }

      setDataToSave((prevData) => {
        // 해당 사용자와 그룹에 맞는 항목 찾기
        const existingItem = prevData.find(
          (item) => item.username === username && item.group === group
        );

        if (existingItem) {
          // 기존 항목 업데이트
          return prevData.map((item) => {
            if (item.username === username && item.group === group) {
              // value_object 복사
              const updatedValueObject = { ...item.value_object } || {};

              // name 키가 없으면 빈 객체로 초기화
              if (!updatedValueObject[name]) {
                // 그룹에 따라 초기 구조 다르게 설정
                if (group === "seven") {
                  updatedValueObject[name] = {
                    봄: "",
                    여름: "",
                    가을: "",
                    겨울: "",
                    comment: "",
                  };
                } else {
                  updatedValueObject[name] = {
                    봄: "",
                    여름: "",
                    가을: "",
                    겨울: "",
                  };
                }
              }

              // 해당 필드 업데이트
              updatedValueObject[name][objectKey] = value;

              return {
                ...item,
                value_object: updatedValueObject,
                isModified: true,
              };
            }
            return item;
          });
        } else {
          // 새 항목 생성
          // 전체 역량에 대한 초기 데이터 구조 생성
          const initialValueObject = {};

          // 그룹에 따라 적절한 테이블 설정 선택
          const tableRows =
            group === "seven" ? tableConfig.rows : tableConfigTwo.rows;

          tableRows.forEach((row) => {
            // 그룹에 따라 초기 구조 다르게 설정
            if (group === "seven") {
              initialValueObject[row.name] = {
                봄: "",
                여름: "",
                가을: "",
                겨울: "",
                comment: "",
              };
            } else {
              initialValueObject[row.name] = {
                봄: "",
                여름: "",
                가을: "",
                겨울: "",
              };
            }
          });

          // 현재 변경된 필드 업데이트
          initialValueObject[name][objectKey] = value;

          return [
            ...prevData,
            {
              schoolid: schoolId,
              school_year_id: filters.schoolYearId,
              record_list_id: inputQueryConditions?.record_list_id,
              group: group,
              username,
              value_object: initialValueObject,
              isModified: true,
              allow_duplicate: false,
            },
          ];
        }
      });
    },
    [
      filters.selectedStudent,
      filters.schoolYearId,
      schoolId,
      inputQueryConditions?.record_list_id,
      tableConfig.rows,
      tableConfigTwo.rows,
      t,
      handleToastCenterTop,
    ]
  );

  // 학생 선택 시 필요한 초기 데이터 생성
  useEffect(() => {
    if (filters.selectedStudent && dataToSave.length > 0) {
      // 이미 dataToSave에 해당 학생 데이터가 있는지 확인
      const existingDataSeven = dataToSave.find(
        (item) =>
          item.username === filters.selectedStudent && item.group === "seven"
      );

      const existingDataReportTwo = dataToSave.find(
        (item) =>
          item.username === filters.selectedStudent &&
          item.group === "reportTwo"
      );

      // 첫 번째 테이블 데이터 초기화
      if (!existingDataSeven) {
        // 새 학생이 선택되었고 데이터가 없는 경우, 초기 데이터 생성
        const initialValueObject = {};

        // 테이블 행 기반으로 초기 구조 생성
        tableConfig.rows.forEach((row) => {
          initialValueObject[row.name] = {
            봄: "",
            여름: "",
            가을: "",
            겨울: "",
            comment: "",
          };
        });

        // 초기 데이터 추가
        setDataToSave((prev) => [
          ...prev,
          {
            schoolid: schoolId,
            school_year_id: filters.schoolYearId,
            record_list_id: inputQueryConditions?.record_list_id,
            group: "seven",
            username: filters.selectedStudent,
            value_object: initialValueObject,
            isModified: false,
            allow_duplicate: false,
          },
        ]);
      }

      // 두 번째 테이블 데이터 초기화
      if (!existingDataReportTwo) {
        // 새 학생이 선택되었고 데이터가 없는 경우, 초기 데이터 생성
        const initialValueObject = {};

        // 테이블 행 기반으로 초기 구조 생성
        tableConfigTwo.rows.forEach((row) => {
          initialValueObject[row.name] = {
            봄: "",
            여름: "",
            가을: "",
            겨울: "",
          };
        });

        // 초기 데이터 추가
        setDataToSave((prev) => [
          ...prev,
          {
            schoolid: schoolId,
            school_year_id: filters.schoolYearId,
            record_list_id: inputQueryConditions?.record_list_id,
            group: "reportTwo",
            username: filters.selectedStudent,
            value_object: initialValueObject,
            isModified: false,
            allow_duplicate: false,
          },
        ]);
      }
    }
  }, [
    filters.selectedStudent,
    dataToSave,
    tableConfig.rows,
    tableConfigTwo.rows,
    schoolId,
    filters.schoolYearId,
    inputQueryConditions?.record_list_id,
  ]);

  // 저장 버튼 클릭 핸들러
  const handleSaveClick = (group) => {
    if (!filters.selectedStudent) {
      handleToastCenterTop(t("common.messages.selectStudent"));
      setValidateInput((prev) => ({ ...prev, user: true }));
      setTimeout(() => {
        setValidateInput((prev) => ({ ...prev, user: false }));
      }, 3000);
      return;
    }

    // 현재 선택된 학생의 데이터 찾기
    const studentToSave = dataToSave.find(
      (item) =>
        item.username === filters.selectedStudent && item.group === group
    );

    if (!studentToSave) {
      handleToastCenterTop(t("common.messages.notModifed"));
      return;
    }

    if (!studentToSave.isModified) {
      handleToastCenterTop(t("common.messages.notModifed"));
      return;
    }

    if (
      !hasPermission(
        null,
        "can_update",
        checkHrteachers(
          hrTeachersData,
          studentToSave.username,
          filters.schoolYear
        )
      )
    ) {
      handleToastCenterTop(t("common.messages.hoomroomTeacherModifyOnly"));
      return;
    }

    setIsSaveButtonDisabled(true);
    setIsLoadingModal(true);
    createOrUpdateVariousStudentRecords.mutate(studentToSave, {
      onSuccess: (successMessage) => {
        setIsLoadingModal(false);
        setIsSaveButtonDisabled(false);
        setSavedWellByGroup(group);
        setTimeout(() => {
          setSavedWellByGroup("");
        }, 1500);

        // 서버에서 반환된 ID로 로컬 데이터 업데이트
        if (successMessage.data && successMessage.data.id) {
          const recordId = successMessage.data.id;

          // 로컬 데이터 업데이트 (ID 포함)
          setDataToSave((currentDataSets) =>
            currentDataSets.map((dataSet) =>
              dataSet.username === studentToSave.username &&
              dataSet.group === group
                ? { ...dataSet, id: recordId, isModified: false }
                : dataSet
            )
          );

          // 전체 데이터도 업데이트
          setAllStudentData((currentAllData) =>
            currentAllData.map((dataSet) =>
              dataSet.username === studentToSave.username &&
              dataSet.group === group
                ? { ...dataSet, id: recordId, isModified: false }
                : dataSet
            )
          );
        }
      },
      onError: (error) => {
        setIsLoadingModal(false);
        setIsSaveButtonDisabled(false);
        console.error(error);
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-auto">
          <h3 className="fw-bold">나의 성장 보고서(중고등)</h3>
        </div>
        <div className="col">
          <DropdownSchoolYear
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYearId={filters.schoolYearId}
            allYears={false}
          />
        </div>
        <div className="col">
          <DropdownHierarchyOne
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyOneId={filters.hierarchyOneId}
          />
        </div>
        <div className="col">
          <DropdownHierarchyTwo
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyOneId={filters.hierarchyOneId}
            hierarchyTwoId={filters.hierarchyTwoId}
          />
        </div>
        <div className="col">
          <DropdownHierarchyThree
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyTwoId={filters.hierarchyTwoId}
            hierarchyThreeId={filters.hierarchyThreeId}
          />
        </div>
        <div className="col">
          <DropdownUniqueUsersInDisplayData
            displayData={studentList}
            displayKey={"fullname"}
            valueKey={"username"}
            filters={{ organization_path: filters.organPathForSort }}
            uniqueKey={"username"}
            sortKey={"studentno"}
            isDesc={false}
            handleDropdownChange={handleDropdownChange}
            selectedUser={filters.selectedStudent}
            selectName={"students"}
            styleClass={"form-control"}
            dropdownTitle={t("common.user.student") || "학생"}
            required={true}
            highLight={validateInput?.user}
          />
          <input
            readOnly
            type="text"
            className="input-like-span p-1"
            value={filters.selectedStudent || ""}
          />
        </div>
      </div>
      <div className="row mt-3">
        {dataToSave.length > 0 &&
        !hasPermission(
          null,
          "can_read",
          checkHrteachers(
            hrTeachersData,
            filters.selectedStudent,
            filters.schoolYear
          )
        ) ? (
          <SimpleAlertMessageBlock
            message={t("common.messages.homeroomAccessOnly")}
          />
        ) : (
          <>
            <div
              className="col"
              style={{ minWidth: "800px", overflowX: "auto" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h4>보고서 1</h4>
                </div>
                <button
                  className={`btn ${
                    savedWellByGroup === "seven" ? "btn-success" : "btn-primary"
                  }`}
                  onClick={() => handleSaveClick("seven")}
                  disabled={isSaveButtonDisabled}
                >
                  {savedWellByGroup === "seven"
                    ? t("common.status.saving")
                    : t("common.labels.save")}
                </button>
              </div>
              <DynamicTable
                tableConfig={tableConfig}
                selectedItem={filters.selectedStudent}
                itemData={
                  selectedStudentData?.find((item) => item.group === "seven")
                    ?.value_object
                }
                dataStructure="nested"
                handleChange={handleSaveState}
                group="seven"
                tableStyle="default"
                // 페이지네이션 활성화
                // pagination={true}
                // pageSize={5} // 페이지당 5개 항목 표시
                // initialPage={1} // 초기 페이지 설정
                // 정렬 활성화
                // sortable={true}
                // initialSortField="number"
                // initialSortDirection="asc"
                // 검색 기능 활성화
                // searchable={true}
                // searchPlaceholder="번호, 역량 검색..."
                // searchFields={[
                //   "input:겨울", // input 필드
                //   "textarea:comment",
                // ]}
                // Excel 내보내기 기능 활성화
                // exportable={true}
                // exportFileName="학생_성장보고서"
                // exportButtonLabel=""
              />
            </div>
            <div
              className="col mt-3 mb-3"
              style={{ minWidth: "800px", overflowX: "auto" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h4>보고서 2</h4>
                </div>

                <button
                  className={`btn ${
                    savedWellByGroup === "reportTwo"
                      ? "btn-success"
                      : "btn-primary"
                  }`}
                  onClick={() => handleSaveClick("reportTwo")}
                  disabled={isSaveButtonDisabled}
                >
                  {savedWellByGroup === "reportTwo"
                    ? t("common.status.saving")
                    : t("common.labels.save")}
                </button>
              </div>
              <DynamicTable
                tableConfig={tableConfigTwo}
                selectedItem={filters.selectedStudent}
                itemData={
                  selectedStudentData?.find(
                    (item) => item.group === "reportTwo"
                  )?.value_object
                }
                dataStructure="nested"
                handleChange={handleSaveState}
                group="reportTwo"
                tableStyle="default"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
