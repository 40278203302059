import React, { useState } from "react";
import styles from "./css/ApprovalProcess.module.css";
import { MdClose } from "react-icons/md";
import { useAuthContext } from "../../context/AuthContext";
import useApprovalRequests from "../../hooks/useApprovalRequest";
import { useUIContext } from "../../context/UIContext";
import { useTranslation } from "react-i18next";

/*생각해 보자.
props로 받아와야 할 것
document_forms table에서
schoolid, name으로 찾아서 
가져올 때 default_approver_group_id로 join 해서 가져오고
document_type: fixedFormApproval, 

keyData에서
username, fullname
학년도, 학기, 과정, 학년, 반 
제목 (합성)
발행일(종강일)을 가져오고 (변경 가능)


일자는 여기서 만들어내고
documnet_id, requester_id, + username, condition_type, condition_value 로 찾아서, 
approval_request id로 조인해서 온 approval_actions 정보로 결재단계별 결재여부를 나타낸다.

requests에 db가 없으면, 신규 결재로 하고

db가 있으면, 첫번째 결재 체크 상태로, 취소, 반려 같은 것을 나타낸다.

*/
export default function ApprovalProcess({
  isModalOpen,
  handleCloseModal,
  documentData,
  approvalData,
  requestType,
  requestTitle,
  studentListForApproval,
  documentFormData,
  approvalLines,
  handleExtraFunction = () => {},
}) {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();

  const [canceledWell, setCanceledWell] = useState(false);
  const [approvedWell, setApprovedWell] = useState(false);

  const [currentDate, setCurrentDate] = useState(new Date()); // 현재 날짜
  const [checkedStudents, setCheckedStudents] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [releaseDate, setReleaseDate] = useState(
    documentData?.currentPeriod?.end_date || ""
  );

  const approvalRequestConditions = {
    schoolid: approvalData?.schoolid,
    username: user?.username,
    document_id: documentFormData[0]?.id,
    request_type: requestType,
    term_id: documentData?.currentPeriod?.id,
  };

  const {
    approvalRequestsQuery: { data: approvalRequestData },
    createOrUpdateApprovalRequestsBulk,
  } = useApprovalRequests(approvalRequestConditions);

  const handleApprove = () => {
    // 체크되어 있는 학생들
    const studentsToApprove = studentListForApproval.filter((student) =>
      checkedStudents.includes(student.username)
    );

    const approverStageList = approvalLines.filter(
      (obj) => obj.username === user.username
    );

    let approverStage;
    if (approverStageList.length > 1) {
      const stageOptions = approverStageList.map((obj) => obj.stage).join(", ");
      const selectedStage = prompt(
        `권한이 여러 개입니다. 어떤 권한을 선택하시겠습니까? 가능한 단계: ${stageOptions}`
      );
      approverStage = approverStageList.find(
        (obj) => obj.stage.toString() === selectedStage
      )?.stage;
    } else {
      approverStage = approverStageList[0]?.stage;
    }

    if (!approverStage) {
      alert("올바른 권한 단계를 선택해주세요.");
      return;
    }

    // approvalRequestData가 없을 경우 대비
    const existingRequests = approvalRequestData || [];
    const studentsToExclude = []; // 이미 결재된 학생 목록
    const studentsToProcess = []; // 결재할 학생 목록
    const requestsToUpdate = []; // 업데이트할 요청 목록
    const requestsToCreate = []; // 생성할 요청 목록

    studentsToApprove.forEach((student) => {
      /* 조건을 잘 생각해 보자.
1. 기안자(stage 1)가 처음 올릴 때는 해당 target.username으로 일치하는 것이 없거나, 있어도 canceled 상태일 것이다. 
또한 극한 케이스로 1사람이 전결하면 finalized 겠지만... 
2. 2번째 이상 결재자는... 자신보다 
... 그냥 stage가 자신 것으로 되어 있으면 결재 할 수 있고, 아니면 못하는 거 아냐?
finalized 되어 있으면 결재 못하고...



*/
      const existingRequest = existingRequests.find(
        (request) => request.target_username === student.username
      );

      if (existingRequest && existingRequest.status === "finalized") {
        studentsToExclude.push(student.fullname);
        return;
      }

      if (
        (!existingRequest && approverStage.toString() === "1") ||
        (existingRequest &&
          existingRequest.stage.toString() === approverStage.toString())
      ) {
        const requestToUpdate = existingRequests.find(
          (request) => request.target_username === student.username
        );

        if (requestToUpdate) {
          requestToUpdate.status =
            approverStage === approvalLines.length ? "finalized" : "inProgress";
          requestToUpdate.stage = approverStage + 1;
          requestToUpdate.release_date = releaseDate;
          requestToUpdate.approver_stage = approverStage;
          requestToUpdate.approver_username = user.username;
          requestToUpdate.action = "approve";

          requestsToUpdate.push(requestToUpdate);
        } else {
          requestsToCreate.push({
            schoolid: approvalData.schoolid,
            requester_username: approvalLines[0].username,
            document_id: documentFormData[0].id,
            title: requestTitle,
            status:
              approverStage === approvalLines.length
                ? "finalized"
                : "inProgress",
            stage: approverStage + 1,
            request_type: requestType,
            target_username: student.username,
            school_year: documentData.school_year,
            term_id: documentData.currentPeriod.id,
            release_date: releaseDate,
            approval_lines: approvalLines,
            approver_stage: approverStage,
            approver_username: user.username,
            action: "approve",
          });
        }
        studentsToProcess.push(student.fullname); // 결재할 학생 목록에 추가
      } else {
        studentsToExclude.push(student.fullname);
        return;
      }
    });

    if (studentsToProcess.length === 0) {
      handleToastCenterTop(t("common.uniComponents.noStudentToApprove"));
      return;
    }

    if (studentsToExclude.length > 0) {
      const excludeMessage = `${t(
        "common.uniComponents.studentsExcluded"
      )}:\n- ${studentsToExclude.join(", ")}`;
      handleToastCenterTop(excludeMessage, 5000);
    }

    const requestsToSave = [...requestsToCreate, ...requestsToUpdate];

    setIsLoadingModal(true);
    createOrUpdateApprovalRequestsBulk.mutate(requestsToSave, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setApprovedWell(true);
        handleExtraFunction();
        setTimeout(() => {
          setApprovedWell(false);
        }, 2000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error(
          "위와 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요."
        );
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleCancel = () => {
    const studentsToApprove = studentListForApproval.filter((student) =>
      checkedStudents.includes(student.username)
    );

    const approverStageList = approvalLines.filter(
      (obj) => obj.username === user.username
    );

    let approverStage;
    if (approverStageList.length > 1) {
      const stageOptions = approverStageList.map((obj) => obj.stage).join(", ");
      const selectedStage = prompt(
        `${t("common.uniComponents.multiplePermissions")} ${stageOptions}`
      );
      approverStage = approverStageList.find(
        (obj) => obj.stage.toString() === selectedStage
      )?.stage;
    } else {
      approverStage = approverStageList[0]?.stage;
    }

    if (!approverStage) {
      alert(t("common.uniComponents.selectRightStage"));
      return;
    }

    const existingRequests = approvalRequestData || [];
    const studentsToExclude = []; // 이미 결재된 학생 목록
    const studentsToProcess = []; // 결재할 학생 목록
    const requestsToUpdate = []; // 업데이트할 요청 목록

    studentsToApprove.forEach((student) => {
      const lowerRequest = existingRequests.find(
        (request) =>
          request.target_username === student.username &&
          request.stage <= approverStage &&
          request.status === ("inProgress" || "finalized")
      );

      if (lowerRequest) {
        studentsToExclude.push(student.fullname);
        return;
      }

      const higherStageApproved = existingRequests.find(
        (request) =>
          request.target_username === student.username &&
          request.stage > approverStage + 1 &&
          request.status !== "canceled"
      );

      if (higherStageApproved) {
        studentsToExclude.push(student.fullname);
        return;
      }
      const existingRequest = existingRequests.find(
        (request) =>
          request.target_username === student.username &&
          request.stage === approverStage + 1 &&
          (request.status === "inProgress" || request.status === "finalized")
      );

      if (existingRequest) {
        existingRequest.status =
          approverStage === 1 ? "canceled" : "inProgress";
        existingRequest.stage = approverStage;
        existingRequest.release_date = releaseDate;
        existingRequest.approver_stage = approverStage;
        existingRequest.approver_username = user.username;
        existingRequest.action = "cancel";
        requestsToUpdate.push(existingRequest);
        studentsToProcess.push(student.fullname); // 취소할 학생 목록에 추가
      }
    });

    if (studentsToProcess.length === 0) {
      handleToastCenterTop(t("common.uniComponents.noStudentToCancel"));
      return;
    }

    if (studentsToExclude.length > 0) {
      const excludeMessage = `${t(
        "common.uniComponents.studentsExcluded"
      )}:\n- ${studentsToExclude.join(", ")}`;
      handleToastCenterTop(excludeMessage, 5000);
    }

    const requestsToSave = requestsToUpdate;

    setIsLoadingModal(true);
    createOrUpdateApprovalRequestsBulk.mutate(requestsToSave, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setCanceledWell(true);
        handleExtraFunction();
        setTimeout(() => {
          setCanceledWell(false);
        }, 2000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error(
          "위와 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요."
        );
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "release_date") {
      setReleaseDate(value);
    }
  };

  const handleCheckboxChange = (username) => {
    setCheckedStudents((prev) =>
      prev.includes(username)
        ? prev.filter((name) => name !== username)
        : [...prev, username]
    );
  };

  const handleAllCheckboxChange = () => {
    if (isAllChecked) {
      setCheckedStudents([]);
    } else {
      const allStudentUsernames = studentListForApproval.map(
        (student) => student.username
      );
      setCheckedStudents(allStudentUsernames);
    }
    setIsAllChecked(!isAllChecked);
  };

  const getApprovalStatus = (username, stage) => {
    const action = approvalRequestData?.find(
      (action) => action.target_username === username
    );
    if (!action) {
      return "";
    }
    if (action.stage > stage || action.status === "finalized") {
      return "결재";
    } else {
      return "";
    }
  };

  if (!isModalOpen) return null;

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <h4>{t("common.uniComponents.approvalDocument")}</h4>
          <button className={styles.closeButton} onClick={handleCloseModal}>
            <MdClose />
          </button>
        </div>
        <div className={styles.modalBody}>
          <div className={styles.infoSection}>
            <div className={styles.infoRow}>
              <div className={styles.label}>
                {t("common.uniComponents.date")}
              </div>
              <div>
                <input
                  type="date"
                  value={currentDate.toLocaleDateString("sv-SE")}
                  onChange={(e) => setCurrentDate(new Date(e.target.value))}
                  readOnly
                />
              </div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.label}>
                {t("common.uniComponents.title")}
              </div>
              <div className={styles.value}>
                <input type="text" value={requestTitle} readOnly />
              </div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.label}>
                {t("common.uniComponents.approvalGroup")}
              </div>
              <div className={styles.value}>
                {approvalLines?.length > 0 &&
                  approvalLines.map((member, index) => (
                    <button
                      className="btn btn-sm btn-outline-dark pt-0 pb-0 p-1 ms-2 me-2"
                      key={index + "-" + member.stage}
                      style={{
                        "--bs-btn-font-size": ".80rem",
                      }}
                    >
                      {member.stage}
                      {t("common.uniComponents.approvalStage")}
                      {member.fullname}
                    </button>
                  ))}
              </div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.label}>
                {t("common.uniComponents.target")}
              </div>
              <div className={styles.value}>
                {studentListForApproval?.map((r) => r.fullname + " ")}
              </div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.label}>
                {t("common.uniComponents.releaseDate")}
              </div>
              <div>
                <input
                  type="date"
                  name="release_date"
                  value={documentData?.currentPeriod?.end_date}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.approvalTable}>
              <thead>
                <tr>
                  <th className="text-center">
                    <div className={styles.checkboxContainer}>
                      {t("common.labels.all")}
                      <input
                        type="checkbox"
                        checked={isAllChecked}
                        onChange={handleAllCheckboxChange}
                      />
                    </div>
                  </th>
                  <th>{t("common.labels.schoolStage")}</th>
                  <th>{t("common.labels.studentYear")}</th>
                  <th>{t("common.labels.class")}</th>
                  <th>{t("common.user.fullname") || "이름"}</th>
                  <th>{t("common.labels.id") || "아이디"}</th>
                  {approvalLines?.length > 0 &&
                    approvalLines.map((member, index) => (
                      <th key={member.fullname + "-" + index}>
                        {member.stage}
                        {t("common.uniComponents.approvalStage")}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {studentListForApproval?.map((student) => (
                  <tr key={student.id}>
                    <td>
                      <div className={styles.checkboxContainer}>
                        <input
                          name={student.username}
                          type="checkbox"
                          checked={checkedStudents.includes(student.username)}
                          onChange={() =>
                            handleCheckboxChange(student.username)
                          }
                        />
                      </div>
                    </td>
                    <td>{student[1]}</td>
                    <td>{student[2]}</td>
                    <td>{student[3]}</td>
                    <td>{student.fullname}</td>
                    <td>{student.username}</td>
                    {approvalLines?.length > 0 &&
                      approvalLines.map((member, index) => (
                        <td key={member.stage + "-" + index}>
                          {member.stage &&
                            getApprovalStatus(student.username, member.stage)}
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <div></div>
          </div>
          <div className={styles.buttonGroup}>
            <button className="btn btn-primary" onClick={handleApprove}>
              {approvedWell
                ? t("common.uniComponents.approvedWell")
                : t("common.uniComponents.approve")}
            </button>
            <button className="btn btn-warning" onClick={handleCancel}>
              {canceledWell
                ? t("common.uniComponents.canceledWell")
                : t("common.uniComponents.cancelApproval")}
            </button>
            <button className="btn btn-secondary" onClick={handleCloseModal}>
              {t("common.labels.close") || "닫기"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
