import React, { useEffect, useMemo, useState } from "react";
import HelpHyperLink from "../../../unicomponents/HelpHyperLink";
import LessonsTable from "./components/LessonsTable";
import CreateUpdateLessonModal from "./components/CreateUpdateLessonModal";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import { useLocation } from "react-router-dom";
import DropdownSchoolYear from "../../../unicomponents/Dropdowns/DropdownSchoolYear";
import DropdownHierarchyOne from "../../../unicomponents/Dropdowns/DropdownHierarchyOne";
import DropdownHierarchyTwo from "../../../unicomponents/Dropdowns/DropdownHierarchyTwo";
import DropdownHierarchyThree from "../../../unicomponents/Dropdowns/DropdownHierarchyThree";
import DropdownUniqueUsersInDisplayData from "../../../unicomponents/Dropdowns/DropdownUniqueUsersInDisplayData";
import useLessons from "../../../hooks/useLessons";
import DropdownTermDate from "../../../unicomponents/Dropdowns/DropdownTermDate";
import useTermPeriod from "../../../hooks/useTermPeriod";
import useLessonSetting from "../../../hooks/useLessonSetting";
import useUsers from "../../../hooks/useUsers";
import { useAuthContext } from "../../../context/AuthContext";
import { setTermIdByToday } from "../../../util/dateRelated";
import SettingEvalItemsModal from "./components/SettingEvalItemsModal";
import { useUIContext } from "../../../context/UIContext";
import useSettingEvalItems from "../../../hooks/useSettingEvalItems";
import { arrangeDataByHierarchy } from "../../../util/specialCase";
import LoadingInside from "../../../unicomponents/LoadingInside";
import SimpleAlertMessageBlock from "../../../unicomponents/SimpleAlertMessageBlock";
import { useTranslation } from "react-i18next";

export default function CreateLessons() {
  const { t } = useTranslation();

  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = {
    schoolid: schoolId,
  };

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    lessonsQuery: { isLoading, data: lessonsData },
  } = useLessons(conditions);

  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod(conditions);

  const {
    lessonSettingQuery: { data: lessonSettingData },
  } = useLessonSetting(conditions);

  const {
    usersQuery: { data: userData },
  } = useUsers(conditions, { role: "teacher", status: 1 });

  const { getEvalItemsByLessonId } = useSettingEvalItems(conditions);

  const { user, hasPermission, isAuthLoading } = useAuthContext();

  const { handleToastCenterTop, handleToastCenterBottom } = useUIContext();

  const [organSettingInfo, setOrganSettingInfo] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    organPathForSort: "/",
  });

  // 얼추 된 것 같군... 이제 create 만들면 되겠어. 그 다음 update 그 다음 삭제

  const [rows, setRows] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  //   const [selectedUserModal, setSelectedUserModal] = useState("");
  const [termId, setTermId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [evalItemModalOpen, setEvalItemModalOpen] = useState(false);
  const [lessonSetting, setLessonSetting] = useState([]);
  const [createOrUpdate, setCreateOrUpdate] = useState("create");
  const [termIdModal, setTermIdModal] = useState("");
  const [evalItemsData, setEvalItemsData] = useState([]);

  const [creUpdateLesson, setCreUpdateLesson] = useState({
    eval_area_weights: null,
    evaluation_type: "",
    extra_attributes: null,
    hierarchy_one: "",
    hierarchy_two: "",
    hierarchy_three: "",
    lesson_hours: "",
    school_year_id: "",
    schoolid: schoolId,
    subject_name: "",
    tags: [],
    teachers: [],
    term_id: "",
  });

  const [lessonInfo, setLessonInfo] = useState({
    id: "",
    evaluation_type: "",
    hierarchyOne: "",
    hierarchyTwo: "",
    hierarchyThree: "",
    school_year: "",
    schoolid: "",
    subject_name: "",
    teachers: [],
    termName: "",
  });

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;
    // let initialUsername = null;

    //초기에 schoolYear 정보가 아직 없을 때, 최신 순서로 schoolYear를 만들어 준다.
    //사실 완전 최신이 아닌 것 같다. 그냥 정렬한 거지. 나중에 누군가 school_year을 2025 이런 식으로 규칙적으로 안 적으면 순서 꼬임
    if (
      organSettingInfo.schoolYear === null &&
      lessonsData &&
      settingData &&
      settingData.length > 0
    ) {
      const initialData = settingData.sort(
        (a, b) => b.school_year - a.school_year
      )[0];

      initialSchoolYear = initialData.school_year;
      initialSchoolYearId = initialData.id;
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
      }));

      const sortedRows = lessonsData.filter(
        (r) => r.school_year_id === initialSchoolYearId
      );

      if (isAuthLoading) {
        setRows([]);
      } else if (hasPermission("", "can_read")) {
        setRows(sortedRows);
      } else {
        const filtered = sortedRows.filter((r) =>
          r.teachers.some((teacher) => teacher?.username === user?.username)
        );
        setRows(filtered);
        // console.log("filtered", filtered);
        handleToastCenterBottom(t("common.messages.ownerReadPermission"));
      }

      //   console.log("sortedRows", sortedRows);

      if (termPeriodData) {
        setTermIdByToday(termPeriodData, setTermId, initialSchoolYearId);
      }
    }
  }, [
    handleToastCenterBottom,
    hasPermission,
    lessonsData,
    organSettingInfo,
    organSettingInfo.schoolYear,
    selectedUser,
    settingData,
    termId,
    termPeriodData,
    user?.username,
    t,
    isAuthLoading,
  ]);

  useEffect(() => {
    if (lessonsData) {
      const sortedRows = filterDataByOrganSettingDropdown(
        lessonsData,
        organSettingInfo,
        termId,
        selectedUser
      );
      setRows(sortedRows);
    }
  }, [lessonsData, organSettingInfo, selectedUser, termId]);

  useEffect(() => {
    if (lessonSettingData && termPeriodData) {
      let thisTermEndDate;

      if (termId) {
        // termId가 있을 경우, 해당 termId의 end_date를 찾습니다.
        const termData = termPeriodData.find((period) => period.id === termId);
        thisTermEndDate = termData ? new Date(termData.end_date) : undefined;
      } else {
        // termId가 null 혹은 undefined일 경우, termPeriodData 전체에서 가장 늦은 end_date를 찾습니다.
        thisTermEndDate = new Date(
          Math.max(
            ...termPeriodData.map((period) =>
              new Date(period.end_date).getTime()
            )
          )
        );
      }

      if (thisTermEndDate) {
        const filteredAndSortedLessonSetting = lessonSettingData
          .filter((r) => new Date(r.start_date) <= thisTermEndDate) // start_date가 thisTermEndDate 이전인 항목을 필터링
          .sort((a, b) => new Date(b.start_date) - new Date(a.start_date)); // end_date 기준으로 내림차순 정렬

        // Use a reducer to keep only the first occurrence of each item_code
        const uniqueLessonSetting = filteredAndSortedLessonSetting.reduce(
          (acc, current) => {
            const x = acc.find((item) => item.item_code === current.item_code);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          },
          []
        );
        setLessonSetting(uniqueLessonSetting);
      }
    }
  }, [lessonSettingData, termId, termPeriodData]);

  useEffect(() => {
    if (userData && user) {
      if (user.role === "teacher") {
        setSelectedUser(user.username);
      }
    }
  }, [user, userData]);

  function filterDataByOrganSettingDropdown(
    arrayDataToBeFiltered,
    organSettingObj,
    termId,
    selectedUser
  ) {
    /* 
필터해야 하는 것이
school_year_id = 이건 필수로 id(숫자)로 하지만.. 아래는 id이지만 문자이고, 0은 통합으로 쓰임 
hierarchy_one
hierarchy_two
hierarchy_three
term_id - 이건 아이디 숫자임, termDropdown의 id로 필터
-   const [termId, setTermId] = useState(""); 이거로 따로 있음
teachers: [{tname: "김샘플", username:"sample@sampleschool"}] 이런식으로 되어 있음. username으로 필터해야 함
 -   selectedUser, setSelectedUser = useState(""); 이거로 따로 있음
*/
    if (!arrayDataToBeFiltered) return [];

    const toBeRows = arrayDataToBeFiltered.filter((record) => {
      const matchesSchoolYearId = organSettingObj.schoolYearId
        ? String(record.school_year_id) === String(organSettingObj.schoolYearId)
        : true;
      const matchesHierarchyOne = organSettingObj.hierarchyOneId
        ? record.hierarchy_one === organSettingObj.hierarchyOneId
        : true;
      const matchesHierarchyTwo = organSettingObj.hierarchyTwoId
        ? record.hierarchy_two === organSettingObj.hierarchyTwoId
        : true;
      const matchesHierarchyThree = organSettingObj.hierarchyThreeId
        ? record.hierarchy_three === organSettingObj.hierarchyThreeId
        : true;
      const matchesTermId = termId
        ? String(record.term_id) === String(termId)
        : true;

      const matchesSelectedUser = selectedUser
        ? record.teachers.some((teacher) => teacher.username === selectedUser)
        : true;

      return (
        matchesSchoolYearId &&
        matchesHierarchyOne &&
        matchesHierarchyTwo &&
        matchesHierarchyThree &&
        matchesTermId &&
        matchesSelectedUser
      );
    });

    return toBeRows;

    /* 이게 내 오리지널이고 이해하기 쉽던데...(String 부분 제외) 챗지피티는 위에 것이 더 효율적이라고 함. 
   let toBeRows = organSettingObj.schoolYearId ? toBeRows.filter(r => r.school_year_id === organSettingObj.schoolYearId) : toBeRows;
   toBeRows = organSettingObj.hierarchyOneId ? toBeRows.filter(r => r.hierarchy_one === organSettingObj.hierarchyOneId) : toBeRows;
   toBeRows = organSettingObj.hierarchyTwoId ? toBeRows.filter(r => r.hierarchy_two === organSettingObj.hierarchyTwoId) : toBeRows;
   toBeRows = organSettingObj.hierarchyThreeId ? toBeRows.filter(r => r.hierarchy_three === organSettingObj.hierarchyThreeId) : toBeRows;
   toBeRows = termId ? toBeRows.filter(r => r.term_id === termId) : toBeRows;
   toBeRows = selectedUser ? toBeRows.filter(r => r.username === selectedUser) : toBeRows;
    return toBeRows;
    */
  }

  //   const handleTeacherNameChange = (e) => {
  //     const { value } = e.target;
  //     const filtered = userData.filter((user) => {
  //       return (
  //         user.fullname.toLowerCase() === value.toLowerCase() &&
  //         user.role === "teacher" &&
  //         user.status === 1
  //       );
  //     });
  //     setFilteredTeachers(filtered);
  //   };

  const configMap = useMemo(() => {
    const map = {};
    lessonSetting.forEach((item) => {
      map[item.item_code] = item.saved_setting;
    });
    return map;
  }, [lessonSetting]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;

    if (name === "0") {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "",
      }));

      setTermIdByToday(termPeriodData, setTermId, value);
    }

    if (name === "1") {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        hierarchyOneId: value,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
      }));

      setTermIdByToday(
        termPeriodData,
        setTermId,
        organSettingInfo.schoolYearId,
        value
      );
    }

    if (name === "2") {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
      }));

      setTermIdByToday(
        termPeriodData,
        setTermId,
        organSettingInfo.schoolYearId,
        organSettingInfo.hierarchyOneId,
        value
      );
    }

    if (name === "3") {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        hierarchyThreeId: value,
      }));
    }

    if (name === "termDate") {
      setTermId(value);
    }

    // 아... 역시 교사를 가용한 모든 교사로 바꾸어야겠군 -> 바꾸었음

    if (name === "teachers") {
      setSelectedUser(value);
    }
  };

  const handleCreateModalOpen = () => {
    setCreUpdateLesson({
      eval_area_weights: null,
      evaluation_type: "",
      extra_attributes: null,
      lesson_hours: "",
      subject_name: "",
      tags: [],
      teachers: [],
      schoolid: schoolId,
      term_id: termId,
      school_year: organSettingInfo.schoolYear,
      school_year_id: organSettingInfo.schoolYearId,
      hierarchy_one: organSettingInfo.hierarchyOneId,
      hierarchy_two: organSettingInfo.hierarchyTwoId,
      hierarchy_three: organSettingInfo.hierarchyThreeId,
    });
    setTermIdModal(termId);
    setCreateOrUpdate("create");
    setModalOpen(true);
  };

  const handleUpdateClick = (row) => {
    const checkOwner = row.teachers.some(
      (teacher) => teacher.username === user.username
    );
    if (!checkOwner && !hasPermission(null, "can_update")) {
      handleToastCenterTop(t("common.messages.ownerUpdatePermission"));
      return;
    }

    setCreUpdateLesson({
      id: row.id,
      eval_area_weights: row.eval_area_weights,
      evaluation_type: row.evaluation_type,
      extra_attributes: row.extra_attributes,
      hierarchy_one: row.hierarchy_one,
      hierarchy_two: row.hierarchy_two,
      hierarchy_three: row.hierarchy_three,
      lesson_hours: row.lesson_hours,
      school_year: organSettingInfo.schoolYear,
      school_year_id: row.school_year_id,
      schoolid: row.schoolid,
      subject_name: row.subject_name,
      tags: row.tags,
      teachers: row.teachers,
      term_id: row.term_id,
    });
    setTermIdModal(row.term_id);
    setCreateOrUpdate("update");
    setModalOpen(true);
  };

  const handleSetEvalItem = (row) => {
    if (row.evaluation_type === "평가항목별") {
      const checkOwner = row.teachers.some(
        (teacher) => teacher.username === user.username
      );
      if (!checkOwner && !hasPermission(null, "can_update")) {
        handleToastCenterTop(t("common.messages.ownerUpdatePermission"));
        return;
      }

      setLessonInfo({
        id: row.id,
        evaluation_type: row.evaluation_type,
        hierarchyOne: row.hierarchyOne,
        hierarchyTwo: row.hierarchyTwo,
        hierarchyThree: row.hierarchyThree,
        school_year: row.school_year,
        schoolid: row.schoolid,
        subject_name: row.subject_name,
        teachers: row.teachers,
        termName: row.termName,
      });

      const forWhere = {
        schoolid: schoolId,
        lesson_id: row.id,
      };

      getEvalItemsByLessonId.mutate(forWhere, {
        onSuccess: (data) => {
          const processedData = arrangeDataByHierarchy([...data]);
          setEvalItemsData(processedData);
        },
        onError: (error) => {
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(t("common.messages.errorAlert") + error.message);
        },
      });

      setEvalItemModalOpen(true);
    } else {
      handleToastCenterTop(
        '평가제를 "평가항목별"로 선택한 과목만 설정할 수 있습니다.'
      );
    }
  };

  if (isLoading) {
    return <LoadingInside />;
  }

  if (!hasPermission(null, "can_access")) {
    return (
      <SimpleAlertMessageBlock
        message={t("common.messages.noAccessPermission")}
      />
    );
  }

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        {modalOpen && (
          <CreateUpdateLessonModal
            settingData={settingData}
            termPeriodData={termPeriodData}
            userData={userData}
            schoolId={schoolId}
            lessonSetting={lessonSetting}
            setModalOpen={setModalOpen}
            configMap={configMap}
            creUpdateLesson={creUpdateLesson}
            setCreUpdateLesson={setCreUpdateLesson}
            createOrUpdate={createOrUpdate}
            termIdModal={termIdModal}
            setTermIdModal={setTermIdModal}
          />
        )}
        {evalItemModalOpen && (
          <SettingEvalItemsModal
            lessonInfo={lessonInfo}
            setLessonInfo={setLessonInfo}
            setEvalItemModalOpen={setEvalItemModalOpen}
            lessonSetting={lessonSetting}
            evalItemsData={evalItemsData}
            setEvalItemsData={setEvalItemsData}
            getEvalItemsByLessonId={getEvalItemsByLessonId}
          />
        )}

        <div className="d-flex justify-content-end flex-wrap">
          <div style={{ width: "95px" }} className="me-1">
            <DropdownSchoolYear
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYearId={organSettingInfo.schoolYearId}
              allYears={false}
            />
          </div>
          <div style={{ width: "95px" }} className="me-1">
            <DropdownHierarchyOne
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYear={organSettingInfo.schoolYear}
              hierarchyOneId={organSettingInfo.hierarchyOneId}
              hierarchyOneTitle={t("common.labels.whole")}
              extraOption={true}
              extraOptionTitle={t("common.labels.schoolStage")}
            />
          </div>
          <div style={{ width: "95px" }} className="me-1">
            <DropdownHierarchyTwo
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYear={organSettingInfo.schoolYear}
              hierarchyOneId={organSettingInfo.hierarchyOneId}
              hierarchyTwoId={organSettingInfo.hierarchyTwoId}
              hierarchyTwoTitle={t("common.labels.whole")}
              extraOption={true}
              extraOptionTitle={t("common.labels.studentYear")}
            />
          </div>
          <div style={{ width: "95px" }} className="me-1">
            <DropdownHierarchyThree
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYear={organSettingInfo.schoolYear}
              hierarchyTwoId={organSettingInfo.hierarchyTwoId}
              hierarchyThreeId={organSettingInfo.hierarchyThreeId}
              hierarchyThreeTitle={t("common.labels.whole")}
              extraOption={true}
              extraOptionTitle={t("common.labels.class")}
            />
          </div>
          <div style={{ width: "95px" }} className="me-1">
            <DropdownTermDate
              termPeriodData={termPeriodData}
              handleDropdownChange={handleDropdownChange}
              termId={termId}
              schoolYearId={organSettingInfo.schoolYearId}
              hierarchyOneId={organSettingInfo.hierarchyOneId}
              hierarchyTwoId={organSettingInfo.hierarchyTwoId}
            />
          </div>
          <div style={{ width: "95px" }}>
            <DropdownUniqueUsersInDisplayData
              displayData={userData}
              displayKey={"fullname"}
              valueKey={"username"}
              uniqueKey={"username"}
              sortKey={"fullname"}
              isDesc={false}
              handleDropdownChange={handleDropdownChange}
              selectedUser={selectedUser}
              selectName="teachers"
              dropdownTitle={t("teacher.lessonRelated.selectTeacher")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-auto">
            <h3 className="fw-bold">
              {t("teacher.lessonRelated.createLesson")}
              <HelpHyperLink link={"https://youtu.be/8F7LNNT7NcM"} />
            </h3>
          </div>
          <div className="col-auto">
            <button className="btn btn-primary" onClick={handleCreateModalOpen}>
              {t("common.labels.create")}
            </button>
          </div>
        </div>
        {rows?.length > 0 ? (
          <LessonsTable
            rows={rows}
            handleUpdateClick={handleUpdateClick}
            handleSetEvalItem={handleSetEvalItem}
            lessonSetting={lessonSetting}
            configMap={configMap}
          />
        ) : (
          <p className="m-3">{t("teacher.lessonRelated.noLessonCreated")}</p>
        )}
      </div>
    </div>
  );
}
