import React from "react";
import { useUIContext } from "../../../context/UIContext";
import useUsers from "../../../hooks/useUsers";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function HandleLinkRequestModal({
  setModalOpen,
  linkRequestInfo,
  setLinkRequestInfo,
  setIsLoadingModal,
}) {
  const { t } = useTranslation();

  const { cancelLinkRequest, linkRequest } = useUsers();
  const { handleToastPromptModal, handleToastCenterTop } = useUIContext();

  const queryClient = useQueryClient();

  const handleAccept = (username) => {
    try {
      const formData = {
        username: linkRequestInfo.username,
        parentUsername: username,
        status: "linked",
      };
      setIsLoadingModal(true);
      //username 존재 체크용으로 잘 쓰여야겠는데
      linkRequest.mutate(formData, {
        onSuccess: async (success) => {
          queryClient.invalidateQueries(["studentInfo"]);
          setIsLoadingModal(false);
          setLinkRequestInfo((prevInfo) => ({
            ...prevInfo,
            guardian: prevInfo.guardian.map((user) =>
              user.username === username ? { ...user, status: "linked" } : user
            ),
          }));
        },
        onError: (error) => {
          alert(error);
          setIsLoadingModal(false);
        },
      });
    } catch (error) {
      handleToastCenterTop(error.message || "연결 요청에 실패했습니다.");
    }
  };

  const handleCancel = (username) => {
    handleToastPromptModal(
      t("teacher.basicinfo.cancelLinkQ") || "연결을 취소하시겠습니까?",
      () => {
        const deleteData = {
          username: linkRequestInfo.username,
          parentUsername: username,
        };

        try {
          setIsLoadingModal(true);

          //username 존재 체크용으로 잘 쓰여야겠는데
          cancelLinkRequest.mutate(deleteData, {
            onSuccess: async () => {
              queryClient.invalidateQueries(["usersConditions"]);
              setLinkRequestInfo((prevInfo) => ({
                ...prevInfo,
                guardian: prevInfo.guardian.filter(
                  (user) => user.username !== username
                ),
              }));
              setIsLoadingModal(false);
            },
            onError: (error) => {
              alert(error);
              setIsLoadingModal(false);
            },
          });
        } catch (error) {
          handleToastCenterTop(error.message || "연결 요청에 실패했습니다.");
        }
      },
      () => {
        return;
      }
    );
  };

  return (
    <>
      <div className="modal-container">
        <div
          className="modalForUnit"
          style={{ width: "750px", maxHeight: "650px", overflowY: "auto" }}
        >
          <div className="modal-header mb-3">
            <h3>
              {t("teacher.basicinfo.guardianRequest") || "보호자 요청 관리"}
            </h3>
          </div>
          <div className="modal-content">
            <div className="mt-2">
              {(!linkRequestInfo?.guardian ||
                linkRequestInfo.guardian.length === 0) && (
                <p>
                  {t("teacher.basicinfo.noLinkedGuardian") ||
                    "연결된 보호자가 없습니다."}
                </p>
              )}
              {linkRequestInfo?.guardian?.some(
                (g) => g.status === "pending"
              ) && (
                <div className="alert alert-info">
                  <p className="mb-0">
                    {t("teacher.basicinfo.pendingLink") || "연결 대기중:"}
                    <span className="ms-2" style={{ fontSize: "12px" }}>
                      기본정보에 기록된 정보와 맞는지 확인 후 수락하세요.
                    </span>
                  </p>
                  <ul className="list-unstyled mb-0 ms-2">
                    {linkRequestInfo?.guardian
                      ?.filter((user) => user.status === "pending")
                      .map((user) => (
                        <React.Fragment key={user.username}>
                          <li
                            className="d-flex justify-content-between align-items-center p-1 px-2 mt-1 rounded hover-shadow"
                            style={{
                              backgroundColor: "#f8f9fa",
                              transition: "all 0.2s ease",
                              border: "1px solid #dee2e6",
                            }}
                          >
                            <span>
                              {user.fullname} ({user.username} / {user.mobile}){" "}
                              <span style={{ fontSize: "12px" }}>
                                {t("teacher.basicinfo.requestDate") || "요청일"}
                                :{user.request_date}
                              </span>
                            </span>
                            <div>
                              <button
                                className="btn btn-sm btn-primary m-1"
                                type="button"
                                onClick={() => handleAccept(user.username)}
                              >
                                {t("teacher.basicinfo.accept") || "수락"}
                              </button>
                              <button
                                className="btn btn-sm btn-danger m-1"
                                type="button"
                                onClick={() => handleCancel(user.username)}
                              >
                                {t("teacher.basicinfo.reject") || "반려"}
                              </button>
                            </div>
                          </li>
                          <li
                            key={user.username + "studentInfo"}
                            className="p-1 px-2 mb-1 rounded hover-shadow"
                            style={{
                              backgroundColor: "#FFFFF0",
                              transition: "all 0.2s ease",
                              border: "1px solid #dee2e6",
                              fontSize: "12px",
                              marginLeft: "8px",
                              marginRight: "8px",
                            }}
                          >
                            [기록된 기본정보]
                            <span className="ms-2 me-2">
                              부이름:{" "}
                              {linkRequestInfo?.studentinfos?.[0]
                                ?.father_name || ""}{" "}
                            </span>
                            <span className="me-2">
                              부연락처:{" "}
                              {linkRequestInfo?.studentinfos?.[0]
                                ?.father_phone || ""}{" "}
                            </span>
                            <span className="me-2">
                              모이름:{" "}
                              {linkRequestInfo?.studentinfos?.[0]
                                ?.mother_name || ""}{" "}
                            </span>
                            <span>
                              모연락처:{" "}
                              {linkRequestInfo?.studentinfos?.[0]
                                ?.mother_phone || ""}
                            </span>
                          </li>
                        </React.Fragment>
                      ))}
                  </ul>
                </div>
              )}

              {linkRequestInfo?.guardian?.some(
                (g) => g.status === "linked"
              ) && (
                <div className="alert alert-success mb-2">
                  <p>{t("teacher.basicinfo.linkComplete") || "연결 완료:"}</p>
                  <ul className="list-unstyled mb-0 ms-2">
                    {linkRequestInfo?.guardian
                      ?.filter((user) => user.status === "linked")
                      .map((user) => (
                        <li
                          key={user.username}
                          className="d-flex justify-content-between align-items-center p-1 px-2 mb-1 mt-1 rounded hover-shadow"
                          style={{
                            backgroundColor: "#f8f9fa",
                            transition: "all 0.2s ease",
                            border: "1px solid #dee2e6",
                          }}
                        >
                          <span>
                            {user.fullname} ({user.username} / {user.mobile}){" "}
                            <span style={{ fontSize: "12px" }}>
                              {t("teacher.basicinfo.requestDate") || "연결일"}:
                              {user.request_date}
                            </span>
                          </span>
                          <button
                            className="btn btn-sm btn-danger m-1"
                            type="button"
                            onClick={() => handleCancel(user.username)}
                          >
                            {t("teacher.basicinfo.unlinked") || "연결해제"}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => setModalOpen(false)}
            >
              {t("common.labels.close") || "닫기"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
