import React from "react";
import styles from "../../css/HavenExtraTable.module.css";
import { gradeToConvert100 } from "../../../../../util/specialCase";
import useFlexibleData from "../../../../../hooks/useFlexibleData";
import { useAuthContext } from "../../../../../context/AuthContext";
import { useUIContext } from "../../../../../context/UIContext";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";

function HavenExtraTable({
  lessonStudentsData,
  setLessonStudentsData,
  lessonSetting,
  updateLessonStudents,
  lessonInfo,
  evalItemsData,
}) {
  const {
    flexibleDataQuery: { data: flexibleData },
  } = useFlexibleData({
    schoolid: lessonSetting[0]?.schoolid,
    main_category: "convert100",
  });
  const { t } = useTranslation();

  const { user, hasPermission } = useAuthContext();
  const { setIsLoadingModal, handleToastCenterTop, setAlertModalState } =
    useUIContext();

  function calculateLevel1Score(student, level1Item, evalItemsData) {
    // 유효성 검사: 필수 매개변수 확인
    if (!student || !level1Item || !evalItemsData) {
      console.warn("calculateLevel1Score: 필수 매개변수가 누락되었습니다.");
      return 0;
    }

    const evalItemSetting = lessonSetting?.find(
      (item) => item.item_code === "evalItem"
    )?.detailed_setting;

    const covert100Setting = flexibleData?.find(
      (r) => r.sub_category === "평가항목별" && r.main_category === "convert100"
    )?.data_value;
    const excludeEmptyGrade = covert100Setting?.excludeEmptyGrade || false;

    // 항목 값이 비어있는지 확인하는 헬퍼 함수
    const isEmptyGrade = (gradeValue) => {
      return (
        gradeValue === "" || gradeValue === null || gradeValue === undefined
      );
    };

    const filteredEvalItems = excludeEmptyGrade
      ? evalItemsData.filter(
          (item) =>
            item.parent_id === level1Item.id &&
            !isEmptyGrade(student.eval_item_grades?.[item.id])
        )
      : evalItemsData.filter((item) => item.parent_id === level1Item.id);

    // 하위 항목이 없는 경우
    if (filteredEvalItems.length === 0) {
      const directScore = student.eval_item_grades?.[level1Item.id];
      const directTotal = level1Item.extra_data?.total;

      // excludeEmptyGrade가 true이고 점수가 비어있으면 계산에서 제외
      if (excludeEmptyGrade && isEmptyGrade(directScore)) {
        return "";
      }

      // 점수나 총점이 없으면 빈 문자열 반환
      if (!directScore || !directTotal) {
        return "";
      }

      // weight 유효성 검사
      const weight = level1Item.extra_data?.weight;
      if (!weight || isNaN(weight)) {
        console.warn(
          `Level1 항목 ${level1Item.id}의 가중치가 유효하지 않습니다.`
        );
        return "";
      }

      const percentage = (Number(directScore) / Number(directTotal)) * 100;
      return Math.round(((percentage * weight) / 100) * 100) / 100;
    }

    if (evalItemSetting?.includes("만점")) {
      // 하위 항목들의 점수 합산
      const totalScore = filteredEvalItems.reduce((sum, item) => {
        const score = student.eval_item_grades?.[item.id];
        // excludeEmptyGrade가 true이고 점수가 비어있으면 이 항목을 건너뜀

        // console.log("sum", sum);
        // console.log("item", item);
        // console.log("score", score);

        if (excludeEmptyGrade && isEmptyGrade(score)) {
          return sum;
        }

        // 빈 값이나 유효하지 않은 값 처리
        if (!score || score === "" || isNaN(score)) {
          return sum;
        }
        return sum + Number(score);
      }, 0);

      // 하위 항목들의 총점 합산
      const totalPossible = filteredEvalItems.reduce((sum, item) => {
        const total = item.extra_data?.total;
        // 총점이 유효하지 않은 경우 처리
        if (!total || isNaN(total)) {
          // console.warn(`평가항목 ${item.id}의 총점이 유효하지 않습니다.`);
          return sum;
        }
        return sum + Number(total);
      }, 0);

      // weight 유효성 검사
      const weight = level1Item.extra_data?.weight;
      if (!weight || isNaN(weight)) {
        console.warn(
          `Level1 항목 ${level1Item.id}의 가중치가 유효하지 않습니다.`
        );
        return "";
      }

      // 퍼센티지 계산 후 가중치 적용
      const percentage = totalPossible ? (totalScore / totalPossible) * 100 : 0;

      return Math.round(((percentage * weight) / 100) * 100) / 100;
    } else if (evalItemSetting?.includes("가중치")) {
      const totalScore = filteredEvalItems.reduce((sum, item) => {
        const studentScore = student.eval_item_grades?.[item.id];

        // excludeEmptyGrade가 true이고 점수가 비어있으면 이 항목을 건너뜀
        if (excludeEmptyGrade && isEmptyGrade(studentScore)) {
          return sum;
        }

        // 빈 값이나 유효하지 않은 값 처리
        if (!studentScore || studentScore === "" || isNaN(studentScore)) {
          return sum;
        }

        const weight = item.extra_data?.weight;
        // 가중치가 유효하지 않은 경우 처리
        if (!weight || isNaN(weight)) {
          return sum;
        }

        const score = (Number(studentScore) * Number(weight)) / 100;
        return sum + score;
      }, 0);

      // weight 유효성 검사
      const weight = level1Item.extra_data?.weight;
      if (!weight || isNaN(weight)) {
        console.warn(
          `Level1 항목 ${level1Item.id}의 가중치가 유효하지 않습니다.`
        );
        return "";
      }

      return Math.round(((totalScore * weight) / 100) * 100) / 100;
    }
  }

  const saveStudentExtraData = (student, value) => {
    const { id, extra_data } = student;

    const updatedExtraData = {
      ...extra_data,
      academicStatus: value === "--------" ? null : value,
    };

    const dataSet = {
      id,
      extra_data: updatedExtraData,
    };

    const checkOwner = lessonInfo.teachers.some(
      (teacher) => teacher.username === user.username
    );
    if (!checkOwner && !hasPermission(null, "can_update")) {
      handleToastCenterTop(t("common.messages.ownerUpdatePermission"));
      return;
    }

    setIsLoadingModal(true);
    updateLessonStudents.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setLessonStudentsData(
          lessonStudentsData.map((student) => {
            if (student.id === id) {
              return { ...student, extra_data: updatedExtraData };
            }
            return student;
          })
        );

        setIsLoadingModal(false);
        setAlertModalState({
          visible: true,
          message: t("teacher.lessonRelated.savedWellMessage"),
        });
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  /*아렇게 간단히 할 수 있는 것을 저 select 때문에 아래와 같이 해야 하다니... 
  const handleExcelDownload = () => {
    console.log("hello");

    const table = document.querySelector(`.${styles.gradeTable}`);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(table);
    XLSX.utils.book_append_sheet(wb, ws, "Grades");
    XLSX.writeFile(wb, `${lessonInfo.subject_name}_grades.xlsx`);
  };
*/

  const handleExcelDownload = () => {
    // 엑셀 데이터 구성
    const excelData = lessonStudentsData.map((student) => {
      const row = {
        "Student Number": student.username,
        Name: student.fullname,
      };

      // level1Items의 점수 추가
      level1Items.forEach((item) => {
        const score = calculateLevel1Score(student, item, evalItemsData);
        row[`${item.content} (${item?.extra_data?.weight}%)`] = score || "";
      });

      // 총점과 등급 추가
      const totalScore = level1Items.reduce((sum, item) => {
        const rawScore = calculateLevel1Score(student, item, evalItemsData);

        // 유효한 숫자인 경우에만 totalScore에 더함
        if (rawScore && !isNaN(rawScore)) {
          return sum + Number(rawScore);
        }

        return sum;
      }, 0);

      row["Total Score"] = totalScore > 0 ? totalScore : "";
      row["Grade"] = gradeToConvert100(
        totalScore,
        flexibleData?.find((r) => r.sub_category === "평가항목별")?.data_value
      );
      row["Incomplete or Withdrawn"] = student.extra_data?.academicStatus || "";

      return row;
    });

    // 워크북 생성
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, ws, "Grades");
    XLSX.writeFile(wb, `${lessonInfo.subject_name}_grades.xlsx`);
  };

  const level1Items = evalItemsData.filter(
    (item) => item.lesson_id === lessonInfo.id && item.hierarchy_level === 1
  );

  return (
    <div className={styles.tableContainer}>
      <div className="d-flex">
        <h3>{t("teacher.lessonRelated.resultOfConversion")}</h3>
        <button
          className="btn btn-success btn-sm"
          onClick={handleExcelDownload}
        >
          {t("common.labels.excelConversion")}
        </button>
      </div>
      <table className={styles.gradeTable}>
        <thead>
          <tr>
            <th>Student Number</th>
            <th>Name</th>

            {level1Items.map((item) => (
              <th key={item.id}>
                {item.content} ({item?.extra_data?.weight}%)
              </th>
            ))}
            <th>Total Score</th>
            <th>Grade</th>
            <th>Incomplete or Withdrawn</th>
          </tr>
        </thead>
        <tbody>
          {lessonStudentsData.map((student) => {
            let totalScore = 0;
            return (
              <tr key={student.id}>
                <td>{student.username}</td>
                <td>{student.fullname}</td>
                {level1Items.map((item) => {
                  const rawScore = calculateLevel1Score(
                    student,
                    item,
                    evalItemsData
                  );

                  // 유효한 숫자인 경우에만 totalScore에 더함
                  if (rawScore && !isNaN(rawScore)) {
                    totalScore += Number(rawScore);
                  }

                  return <td key={item.id}>{rawScore}</td>;
                })}
                <td>{totalScore > 0 ? totalScore : ""}</td>
                <td>
                  {gradeToConvert100(
                    totalScore,
                    flexibleData?.find((r) => r.sub_category === "평가항목별")
                      ?.data_value
                  )}
                </td>
                <td>
                  <select
                    value={student.extra_data?.academicStatus || "--------"}
                    onChange={(e) =>
                      saveStudentExtraData(student, e.target.value)
                    }
                  >
                    <option value="--------">--------</option>
                    <option value="incomplete">Incomplete</option>
                    <option value="withdrawn">Withdrawn</option>
                  </select>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default HavenExtraTable;
