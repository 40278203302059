import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import styles from "./GeneratedByData.module.css";

/* 
RegistrationVariousRecordInputTable 와의 큰 차이점 중 하나는...
SaveInputsTable의 설정과 연동한다는 점이고,
이 테이블에 나오는 tr은 모두 생성된 실 데이터를 가져오는 것이라서 모두 id가 있으면 id 기반으로 한다.

*/

/*
various_record_inputs 받아서 th, td 만들고
various_student_record 받아서 td 안에 채워넣고
꼭 위에 2개 아니라도 같은 형식이면 가능
handleTdChange 함수 받아서, 데이터 state에 저장했다가
handleSaveBtn 함수 받아서, 누르면 서버로 저장하고
handleDeleteBtn 함수 받아서, 누르면 서버에서 지우고
일단 이정도면 기본이 될 거고, 나중에 통계내거나 글자수를 하거나 그런 것은 필요하면 추가하자.
*/

export default function SaveInputsTable({
  inputData,
  userInputDataList = [],
  handleSaveState,
  handleSaveClick,
  handleSaveAll,
  handleDelete,
  handleConfirmDelete,
  handleCancelDelete,
  confirmDelete,
  savedRows,
  addfontSize = "",
  addMinWidth = "",
  groupOp = false,
  groupName = "그룹",
  savedBulk = false,
  displayMapping = {},
  // 정렬 기능 관련 props
  sortable = false,
  initialSortField = null,
  initialSortDirection = "asc",
  // 내보내기 기능 관련 props
  exportable = false,
  exportFileName = "데이터_내보내기",
  exportButtonLabel = "Excel",
}) {
  const { t } = useTranslation();

  // 정렬 상태 관리
  const [sortField, setSortField] = useState(initialSortField);
  const [sortDirection, setSortDirection] = useState(initialSortDirection);

  // 정렬 로직을 수행하는 함수
  const sortFunction = (data) => {
    // 정렬할 필드가 없거나 정렬 기능이 비활성화되면 원본 데이터 반환
    if (!sortable || !sortField) return data;

    return [...data].sort((a, b) => {
      let valueA, valueB;

      // value_object 내의 값을 사용하여 정렬
      if (sortField.startsWith("value_object.")) {
        const fieldName = sortField.split(".")[1];
        valueA = a.value_object?.[fieldName] || "";
        valueB = b.value_object?.[fieldName] || "";
      } else {
        // 일반 필드로 정렬
        valueA = a[sortField] || "";
        valueB = b[sortField] || "";
      }

      // 숫자인 경우 숫자 비교
      if (!isNaN(valueA) && !isNaN(valueB)) {
        return sortDirection === "asc"
          ? Number(valueA) - Number(valueB)
          : Number(valueB) - Number(valueA);
      }

      // 문자열 비교
      return sortDirection === "asc"
        ? String(valueA).localeCompare(String(valueB))
        : String(valueB).localeCompare(String(valueA));
    });
  };

  // 정렬 처리 함수
  const handleSort = (field) => {
    if (!sortable) return;

    // 같은 필드를 클릭하면 방향을 전환
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  // 정렬 아이콘 렌더링
  const renderSortIcon = (field) => {
    if (!sortable) return null;

    if (sortField === field) {
      return (
        <div className={styles.sortIconContainer}>
          {sortDirection === "asc" ? (
            <>
              <span className={styles.sortIconActive}>▲</span>
              <span className={styles.sortIconInactive}>▼</span>
            </>
          ) : (
            <>
              <span className={styles.sortIconInactive}>▲</span>
              <span className={styles.sortIconActive}>▼</span>
            </>
          )}
        </div>
      );
    }

    return (
      <div className={styles.sortIconContainer}>
        <span className={styles.sortIconInactive}>▲</span>
        <span className={styles.sortIconInactive}>▼</span>
      </div>
    );
  };

  // Excel 내보내기 함수
  const exportToExcel = () => {
    if (!exportable || userInputDataList.length === 0) return;

    // 내보낼 데이터 준비
    const exportData = sortFunction(userInputDataList).map((row) => {
      const exportRow = {
        [t("common.labels.schoolStage")]: row.hierarchyOne,
        [t("common.labels.studentYear")]: row.hierarchyTwo,
        [t("common.labels.class")]: row.hierarchyThree,
        [t("common.user.fullname")]: row.fullname,
      };

      if (groupOp) {
        exportRow[groupName] = row.group;
      }

      // inputData의 각 항목에 대한 값 추가
      inputData.forEach((input) => {
        const value = row.value_object?.[input.name] ?? "";
        const displayValue =
          displayMapping && displayMapping[input.name]
            ? displayMapping[input.name]
            : value;
        exportRow[input.title] = displayValue;
      });

      return exportRow;
    });

    // XLSX 객체 생성
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    // 파일 다운로드
    XLSX.writeFile(workbook, `${exportFileName}.xlsx`);
  };

  // 내보내기 버튼 렌더링
  const renderExportButton = () => {
    if (!exportable) return null;

    return (
      <button
        className="btn btn-sm btn-success ms-2"
        onClick={exportToExcel}
        disabled={userInputDataList.length === 0}
      >
        <i className="bi bi-file-excel me-1"></i>
        {exportButtonLabel}
      </button>
    );
  };

  const calculateStyle = (baseStyle, addValue) => {
    const baseSize = parseFloat(baseStyle) || 0;
    const addSize = parseFloat(addValue) || 0;
    return `${baseSize + addSize}px`;
  };

  function renderInput(r, row, value, handleSaveState) {
    switch (r.inputs) {
      case "select":
        return (
          <select
            className="select-like-span"
            onChange={(e) => handleSaveState(e, row.username, row.id)}
            value={value}
            name={r.name}
          >
            {r.options &&
              r.options.map((option, index) => (
                <option key={`${index}-${row.id}`} value={option?.value || ""}>
                  {option?.label || ""}
                </option>
              ))}
          </select>
        );
      case "textarea":
        return (
          <textarea
            className="input-like-span"
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, row.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
            rows={r?.options[0]?.row || 5}
            style={{ width: `${r?.options?.[0]?.width || 200}px` }}
          />
        );
      case "empty":
        const mappedValue =
          displayMapping && displayMapping[r.name]
            ? displayMapping[r.name]
            : value;

        return value ? mappedValue : "";
      case "text":
        return (
          <input
            className="input-like-span"
            type="text"
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, row.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
          />
        );
      case "number":
        return (
          <input
            className="input-like-span"
            type="number"
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, row.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
            style={{ width: `${r?.options?.[0]?.width || 60}px` }}
          />
        );
      case "date":
        return (
          <input
            className="input-like-span"
            type="date"
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, row.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
            style={{ width: `${r?.options?.[0]?.width || 120}px` }}
          />
        );
      default:
        return (
          <input
            className="input-like-span"
            type={r.inputs || "text"}
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, row.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
          />
        );
    }
  }

  return (
    <div>
      {exportable && (
        <div className="d-flex justify-content-end mb-2">
          {renderExportButton()}
        </div>
      )}
      <table
        className="table table-bordered text-center"
        style={{ fontSize: "13px" }}
      >
        <thead>
          <tr className="table-secondary p-1">
            <th
              className="p-1 align-middle"
              style={{
                minWidth: "50px",
                cursor: sortable ? "pointer" : "default",
              }}
              onClick={() => handleSort("hierarchyOne")}
            >
              {t("common.labels.schoolStage")}
              {renderSortIcon("hierarchyOne")}
            </th>
            <th
              className="p-1 align-middle"
              style={{
                minWidth: "50px",
                cursor: sortable ? "pointer" : "default",
              }}
              onClick={() => handleSort("hierarchyTwo")}
            >
              {t("common.labels.studentYear")}
              {renderSortIcon("hierarchyTwo")}
            </th>
            <th
              className="p-1 align-middle"
              style={{
                minWidth: "50px",
                cursor: sortable ? "pointer" : "default",
              }}
              onClick={() => handleSort("hierarchyThree")}
            >
              {t("common.labels.class")}

              {renderSortIcon("hierarchyThree")}
            </th>
            <th
              className="p-1 align-middle"
              style={{
                minWidth: "60px",
                cursor: sortable ? "pointer" : "default",
              }}
              onClick={() => handleSort("fullname")}
            >
              {t("common.user.fullname") || "이름"}
              {renderSortIcon("fullname")}
            </th>
            {groupOp && (
              <th
                className="p-1 align-middle"
                style={{
                  minWidth: "60px",
                  width: "100px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => handleSort("group")}
              >
                {groupName}
                {renderSortIcon("group")}
              </th>
            )}
            {inputData &&
              inputData.map((r) => (
                <th
                  className="align-middle p-0"
                  style={{
                    minWidth: calculateStyle("60px", addMinWidth),
                    fontSize: calculateStyle("11px", addfontSize),
                    cursor: sortable ? "pointer" : "default",
                  }}
                  key={`th-${r.id}`}
                  onClick={() => handleSort(`value_object.${r.name}`)}
                >
                  {r.title} <br />
                  {renderSortIcon(`value_object.${r.name}`)}
                </th>
              ))}
            <th className="align-middle p-1" style={{ minWidth: "72px" }}>
              {handleSaveAll && (
                <button
                  className="btn btn-sm btn-primary p-0 pe-1 ps-1"
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    handleSaveAll();
                  }}
                >
                  {savedBulk
                    ? t("common.labels.complete") || "완료"
                    : t("common.labels.saveAll")}
                </button>
              )}
            </th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "13px" }} className="align-middle">
          {sortFunction(userInputDataList)?.map((row) => (
            <tr key={row.id}>
              <td className="p-1">{row.hierarchyOne}</td>
              <td className="p-1">{row.hierarchyTwo}</td>
              <td className="p-1">{row.hierarchyThree}</td>
              <td className="p-1">{row.fullname}</td>
              {groupOp && <td className="p-1">{row.group}</td>}

              {inputData &&
                inputData.map((r) => {
                  const value = row?.value_object?.[r.name] ?? "";

                  return (
                    <td key={`th-${row.id}-${r.id}`} className="p-1">
                      {renderInput(r, row, value, handleSaveState)}
                    </td>
                  );
                })}
              <td className="p-1">
                <button
                  className="btn btn-sm p-0 pe-1 ps-1 btn-primary"
                  style={{ fontSize: "12px" }}
                  onClick={() => {
                    handleSaveClick(row);
                  }}
                >
                  {savedRows.includes(row.id)
                    ? t("common.labels.complete") || "완료"
                    : t("common.labels.save") || "저장"}
                </button>
                {confirmDelete.includes(row.id) ? (
                  <>
                    <button
                      type="button"
                      style={{ fontSize: "12px" }}
                      onClick={() => handleDelete(row)}
                      className="btn btn-sm p-0 pe-1 ps-1 btn-danger"
                    >
                      {t("common.labels.confirm") || "확인"}
                    </button>
                    <button
                      type="button"
                      style={{ fontSize: "12px" }}
                      onClick={() => handleCancelDelete()}
                      className="btn btn-sm p-0 pe-1 ps-1 btn-secondary"
                    >
                      {t("common.labels.cancel") || "취소"}
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    style={{ fontSize: "12px" }}
                    onClick={() => handleConfirmDelete(row)}
                    className="btn btn-sm p-0 pe-1 ps-1 btn-warning"
                  >
                    {t("common.labels.delete") || "삭제"}
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
