import React from "react";

// array 안에 object 가 있는 것에서 특정 key의 값들을 합쳐서 새로운 키에 할당함. 그냥 남길 것도 지정
// 이렇게 쓰면 됨
// const newArray = combineTwoValuesInObjOfArray(data, ['group_name', 'level_name'], 'name', ['id']);
export function combineTwoValuesInObjOfArray(
  array,
  combineKeys,
  newKey,
  retainKeys
) {
  return array.map((item) => {
    let newItem = {};

    // retainKeys에 있는 키를 유지
    retainKeys.forEach((key) => {
      newItem[key] = item[key];
    });

    // combineKeys에 있는 키를 결합하여 newKey에 할당
    newItem[newKey] = combineKeys.reduce((acc, key) => acc + item[key], "");

    return newItem;
  });
}

//객체를 갖고 있는 array 데이터의 원하는 키값으로 select의 option을 unique하게 넣기 위해 쓰는 함수
//idName을 넣으면 id를 key 값으로 사용 할 수 있고 아니면 index를 키 값으로 사용함.
/*  이렇게 쓰면 됨. (id는 옵셔널이라 빼도 됨.)
const idsForKey = uniqueOptionsKeys(data, "group", "id");

return (
  <select onChange={onChange}>
    {Object.keys(idsForKey).map(option => (
      <option key={idsForKey[option][0]} value={option}>{option}</option>
    ))}
  </select>
);
*/
export function uniqueDataOptionsKeys(data, keyName, idName = null) {
  const uniqueOptions = Array.from(new Set(data.map((item) => item[keyName])));
  const idsForKey = {};

  uniqueOptions.forEach((option, index) => {
    if (idName) {
      idsForKey[option] = data
        .filter((item) => item[keyName] === option)
        .map((item) => item[idName]);
    } else {
      idsForKey[option] = [index];
    }
  });

  return idsForKey;
}

//앗싸리 그냥 options을 바로 가져오는 걸로 만들었다. 정렬도 옵션으로 넣음
/* 그냥 이렇게 쓰면 됨.
<select onChange={onChange}>
    {uniqueOptionsKeys(data, "group", "id", "desc")}
  </select>
*/
export function uniqueOptionsKeys(data, keyName, idName = null, order = "asc") {
  const uniqueOptions = Array.from(new Set(data.map((item) => item[keyName])));

  if (order === "asc") {
    uniqueOptions.sort((a, b) => (a > b ? 1 : -1));
  } else if (order === "desc") {
    uniqueOptions.sort((a, b) => (a < b ? 1 : -1));
  }

  const idsForKey = {};

  uniqueOptions.forEach((option, index) => {
    if (idName) {
      idsForKey[option] = data
        .filter((item) => item[keyName] === option)
        .map((item) => item[idName]);
    } else {
      idsForKey[option] = [index];
    }
  });
  // console.log(uniqueOptions);

  // Object.keys 대신 uniqueOptions 배열을 바로 사용합니다.
  return uniqueOptions.map((option) => (
    <option key={idsForKey[option][0]} value={option}>
      {option}
    </option>
  ));
}

//필요의 의해서 unique 데이터만 array로 꺼내는 것. 존재하느냐 안 존재하느냐 체크용으로...
export function uniqueValues(data, keyName, order = "asc") {
  // console.log("data", data);
  const uniqueOptions = Array.from(new Set(data.map((item) => item[keyName])));

  if (order === "asc") {
    uniqueOptions.sort((a, b) => (a > b ? 1 : -1));
  } else if (order === "desc") {
    uniqueOptions.sort((a, b) => (a < b ? 1 : -1));
  }

  // console.log(uniqueOptions);

  // Object.keys 대신 uniqueOptions 배열을 바로 사용합니다.
  // console.log("uniqueOptions", uniqueOptions);
  return uniqueOptions;
}

/* 중복된 데이터를 찾는 함수. 이런식으로 쓰면 됨
const keys = ["schoolid", "schoolyear", "level", "group"];
const isDuplicate = findDuplicateData(data, {
  schoolid: "sampleschool",
  schoolyear: 2024,
  level: 3,
  level_name: "반",
  group: "은비",
  parent_id: 7,
  order: 1,
  description: "샘플7",
}, keys);

if (isDuplicate) {
  console.error("중복된 데이터가 존재합니다!");
} else {
  console.log("중복된 데이터가 없습니다.");
}
*/

export function findDuplicateData(data, targetData, keys) {
  const filteredData = data.filter((item) => {
    const changedKeys = keys.filter((key) => key !== "id");
    if (item.id === targetData.id) {
      return changedKeys.length === 0;
    }
    return changedKeys.every((key) => item[key] === targetData[key]);
  });

  return filteredData.length > 0;
}

/* 데이터를 넣으면 보이는 것, value로 되는 것, key로 되는 것, 필터하고, sort 해서 option 배출함. 이렇게 쓰면 됨 
const data = [
  { id: 1, username: 'user1', fullname: 'User One', gender: 'female', age: 25 },
  { id: 2, username: 'user2', fullname: 'User Two', gender: 'male', age: 30 },
  { id: 3, username: 'user3', fullname: 'User Three', gender: 'female', age: 20 },
  { id: 4, username: 'user4', fullname: 'User Four', gender: 'male', age: 35 },
];

const { arrayData, displayKey, valueKey, filters, idKey, sortKey, isDesc } = {
    arrayData: settingData,
    displayKey: "school_year",
    valueKey: "school_year",
    filters: { hierarchy_level: 0, parent_id: 0 },
    idKey: "id",
    sortKey: "school_year",
    isDesc: true,
  };

  const options = useMemo(() => {
    return createFilteredAndSortedOptions({
      arrayData,
      displayKey,
      valueKey,
      filters,
      idKey,
      sortKey,
      isDesc,
    });
  }, [arrayData, displayKey, valueKey, filters, idKey, sortKey, isDesc]);

  이건 빼도 됨. filters, idKey: 'id', sortKey: null,  isDesc: false 
return (
  <select>
                {settingData?.length > 0 && options}
  </select>
);

// options는 아래와 같은 형태의 <option> 요소를 포함하는 배열입니다:
// <option key={1} value={'user1'}>{'User One'}</option>
// <option key={3} value={'user3'}>{'User Three'}</option>
*/
export function createFilteredAndSortedOptions({
  arrayData,
  displayKey,
  valueKey,
  idKey = "id",
  filters = {},
  sortKey = null,
  isDesc = false,
  dataName = "",
} = {}) {
  // Step 1: 필터 적용
  let filteredData = arrayData;

  if (filters) {
    Object.keys(filters).forEach((filterKey) => {
      const filterValue = filters[filterKey];
      if (filterValue === undefined) return; // undefined 값 무시
      if (typeof filterValue === "function") {
        // filterValue가 함수인 경우, 해당 함수로 필터링
        filteredData = filteredData.filter((item) =>
          filterValue(item[filterKey])
        );
      } else {
        // 그렇지 않은 경우, 동등 비교로 필터링
        filteredData = filteredData.filter(
          (item) =>
            (item[filterKey]?.toString() || "") ===
            (filterValue?.toString() || "")
        );
      }
    });
  }

  // Step 2: 정렬
  if (sortKey) {
    filteredData.sort((a, b) => {
      const aValue = a[sortKey];
      const bValue = b[sortKey];
      // 문자열이 숫자로만 구성되어 있는지 확인
      const isNumericValue = /^\d+$/.test(aValue) && /^\d+$/.test(bValue);

      if (isNumericValue) {
        // 모두 숫자로만 구성된 문자열인 경우, 숫자로 변환하여 비교
        return isDesc
          ? parseInt(bValue, 10) - parseInt(aValue, 10)
          : parseInt(aValue, 10) - parseInt(bValue, 10);
      } else if (typeof aValue === "string" && typeof bValue === "string") {
        // 문자열인 경우
        return isDesc
          ? bValue.localeCompare(aValue)
          : aValue.localeCompare(bValue);
      } else {
        // 혹시 다른 타입의 값이 들어올 경우를 대비해 기본 문자열 비교로 처리
        return isDesc
          ? String(bValue).localeCompare(String(aValue))
          : String(aValue).localeCompare(String(bValue));
      }
    });
  }

  // Step 3: 옵션 생성
  const options = filteredData.map((item) => ({
    key: item[idKey],
    value: item[valueKey] ?? "",
    display: item[displayKey] ?? "Error",
    dataName: item[dataName] ?? "",
  }));

  // console.log("options", options);

  // Step 4: 반환
  return options.map((option) => (
    <option key={option.key} value={option.value} data-name={option.dataName}>
      {option.display}
    </option>
  ));
}

//필터하고 정렬하고 유니크한 옵션 만들기
export function createFilteredAndSortedUniqueOptions({
  arrayData,
  displayKey,
  valueKey,
  uniqueKey = "id",
  filters = {},
  sortKey = null,
  isDesc = false,
} = {}) {
  // Step 1: 필터 적용

  // console.log("arrayData", arrayData);
  // console.log("filters", filters);
  let filteredData = arrayData;
  if (filters) {
    Object.keys(filters).forEach((filterKey) => {
      const filterValue = filters[filterKey];
      if (filterValue === undefined) return; // undefined 값 무시
      if (typeof filterValue === "function") {
        // filterValue가 함수인 경우, 해당 함수로 필터링
        filteredData = filteredData.filter((item) =>
          filterValue(item[filterKey])
        );
      } else {
        // 그렇지 않은 경우, 동등 비교로 필터링
        filteredData = filteredData.filter(
          (item) =>
            (item[filterKey]?.toString() || "") ===
            (filterValue?.toString() || "")
        );
      }
    });
  }

  // console.log("filteredData", filteredData);
  // console.log("sortKey", sortKey);
  // Step 2: 정렬
  if (sortKey) {
    filteredData.sort((a, b) => {
      const aValue = a[sortKey];
      const bValue = b[sortKey];
      // 문자열이 숫자로만 구성되어 있는지 확인
      const isNumericValue = /^\d+$/.test(aValue) && /^\d+$/.test(bValue);

      if (isNumericValue) {
        // 모두 숫자로만 구성된 문자열인 경우, 숫자로 변환하여 비교
        return isDesc
          ? parseInt(bValue, 10) - parseInt(aValue, 10)
          : parseInt(aValue, 10) - parseInt(bValue, 10);
      } else if (typeof aValue === "string" && typeof bValue === "string") {
        // 문자열인 경우
        return isDesc
          ? bValue.localeCompare(aValue)
          : aValue.localeCompare(bValue);
      } else {
        // 혹시 다른 타입의 값이 들어올 경우를 대비해 기본 문자열 비교로 처리
        return isDesc
          ? String(bValue).localeCompare(String(aValue))
          : String(aValue).localeCompare(String(bValue));
      }
    });
  }

  // Step 3: 유니크하게 만듬
  const uniqueData = Array.from(
    new Map(filteredData.map((item) => [item[uniqueKey], item])).values()
  );

  // Step 4: 옵션 생성
  const options = uniqueData.map((item) => ({
    key: item[uniqueKey],
    value: item[valueKey] ?? "",
    display: item[displayKey] ?? "에러",
  }));

  // Step 5: 반환
  return options.map((option) => (
    <option key={option.key} value={option.value}>
      {option.display}
    </option>
  ));
}

//settingData와 registrationInfoData를 조합해서,
//schoolYear(2024 이런 식)과 organPathForSort(/1/2/3 이런식) 으로 filter해서 나오게 하는 함수
//사실 Mui dataGrid row용으로 만든 건데, 유용해서 계속 쓰자.
// [] 안에 obj가 들어간다. 이런 데이터다.
/* 
[{
  school: "샘플학교",
  educationLevel: "초등",
  grade: "1",
  class: "은비",
  attendanceRemark: null,
  createdAt: "2024-02-12T09:25:00.000Z",
  fullname: "김샘플",
  futurePlan: null,
  gender: "남",
  hrTeacher: "",
  hrTeacherUsername: null,
  id: 1,
  lastAttended: null,
  order: {
    order0: 1,
    order1: 1,
    order2: 1,
    order3: 1,
  },
  organizationPath: "/1/2/16/17",
  schoolYear: "2024",
  schoolId: "sampleschool",
  status: "재학중",
  studentNo: "1",
  superkey: "sampleschool-2024-sample.kim@sampleschool",
  updatedAt: "2024-02-12T09:25:00.000Z",
  username: "sample.kim@sampleschool"
}, ...];
*/
export function generateRegistrationRows(
  registrationInfoData,
  settingData,
  schoolYear,
  organPathForSort = "/"
) {
  let toBeRows = registrationInfoData
    .filter((r) => r.school_year === schoolYear)
    .map((row) => {
      const { organization_path, user, ...otherFields } = row;
      const paths = organization_path.split("/").filter((path) => path);
      const firstPathId = Number(paths[0]);
      const secondPathId = Number(paths[1]);
      const thirdPathId = Number(paths[2]);

      // 첫 번째 필터링: firstPathId로 schoolYearDates 찾기
      const firstGroupData = settingData.find((r) => r.id === firstPathId);
      let schoolYearDates = firstGroupData?.schoolYearDates || [];

      // 두 번째 필터링: length가 2 이상이고 secondPathId가 존재하면 applied_hierarchy_one으로 필터
      if (schoolYearDates.length >= 2 && secondPathId) {
        const filteredDates = schoolYearDates.filter(
          (date) => date.applied_hierarchy_one === secondPathId
        );
        // 필터 결과가 비어있으면 applied_hierarchy_one이 null인 항목들을 반환
        schoolYearDates =
          filteredDates.length > 0
            ? filteredDates
            : schoolYearDates.filter(
                (date) => date.applied_hierarchy_one === null
              );
      }

      // 세 번째 필터링: 여전히 length가 2 이상이고 thirdPathId가 존재하면 applied_hierarchy_two로 필터
      if (schoolYearDates.length >= 2 && thirdPathId) {
        const filteredDates = schoolYearDates.filter(
          (date) => date.applied_hierarchy_two === thirdPathId
        );
        // 필터 결과가 비어있으면 applied_hierarchy_two이 null인 항목들을 반환
        schoolYearDates =
          filteredDates.length > 0
            ? filteredDates
            : schoolYearDates.filter(
                (date) => date.applied_hierarchy_two === null
              );
      }

      const pathFields = paths.reduce((acc, curr, index) => {
        const thisGroup = settingData.find((r) => r.id === Number(curr));

        if (thisGroup) {
          return {
            ...acc,
            [index]: thisGroup.group_name,
            ["order" + index]: thisGroup.order,
            hr_teacher_username: thisGroup.hr_teacher_username,
          };
        } else {
          console.error(`No group found with id: ${curr}`);
          return acc;
        }
      }, {});

      let hr_teacher = "";
      if (pathFields.hr_teacher_username) {
        hr_teacher = pathFields.hr_teacher_username
          .map((item) => item.tname)
          .join(", ");
      }
      const userObject = {
        fullname: user?.fullname || "",
        otherName: user?.other_name || "",
        gender: user?.gender || "",
        birthday: user?.birthday || "",
      };

      return {
        ...otherFields,
        ...pathFields,
        ...userObject,
        hr_teacher,
        organization_path,
        schoolYearDates,
      };
    })
    .sort((a, b) => {
      const aValue = parseInt(a.studentno, 10);
      const bValue = parseInt(b.studentno, 10);

      if (isNaN(aValue) && isNaN(bValue)) {
        return 0;
      }
      if (isNaN(aValue)) {
        return 1;
      }
      if (isNaN(bValue)) {
        return -1;
      }
      return aValue - bValue;
    });

  if (organPathForSort !== "/" && !organPathForSort.startsWith("nu")) {
    toBeRows = toBeRows.filter((row) =>
      row.organization_path.includes(organPathForSort)
    );
  }

  const sortedArray = toBeRows.sort((a, b) => {
    const orderKeysA = Object.keys(a)
      .filter((key) => key.startsWith("order"))
      .sort();
    const orderKeysB = Object.keys(b)
      .filter((key) => key.startsWith("order"))
      .sort();

    for (let i = 0; i < orderKeysA.length; i++) {
      if (a[orderKeysA[i]] !== b[orderKeysB[i]]) {
        return a[orderKeysA[i]] - b[orderKeysB[i]];
      }
    }

    return 0;
  });

  // console.log("sortedArray", sortedArray);

  return sortedArray;
}

/*
//재적에 등록된 모든 학생을 settingData와 혼합해서 row로 만들기 생기부용
export function generateAllRegisteredStudents(
  registrationInfoData,
  settingData
) {
  let toBeRows = registrationInfoData
    .map((row) => {
      const { organization_path, user, ...otherFields } = row;
      const paths = organization_path.split("/").filter((path) => path);
      const pathFields = paths.reduce((acc, curr, index) => {
        const thisGroup = settingData.find((r) => r.id === Number(curr));

        if (thisGroup) {
          return {
            ...acc,
            [index]: thisGroup.group_name,
            ["order" + index]: thisGroup.order,
            hr_teacher_username: thisGroup.hr_teacher_username,
            schoolYearDates: thisGroup.schoolYearDates || [],
          };
        } else {
          console.error(`No group found with id: ${curr}`);
          return acc;
        }
      }, {});

      let hr_teacher = "";
      if (pathFields.hr_teacher_username) {
        hr_teacher = pathFields.hr_teacher_username
          .map((item) => item.tname)
          .join(", ");
      }
      const userObject = { fullname: user.fullname, gender: user.gender };

      return {
        ...otherFields,
        ...pathFields,
        ...userObject,
        hr_teacher,
        organization_path,
      };
    })
    .sort((a, b) => {
      const aValue = parseInt(a.studentno, 10);
      const bValue = parseInt(b.studentno, 10);

      if (isNaN(aValue) && isNaN(bValue)) {
        return 0;
      }
      if (isNaN(aValue)) {
        return 1;
      }
      if (isNaN(bValue)) {
        return -1;
      }
      return aValue - bValue;
    });

  const sortedArray = toBeRows.sort((a, b) => {
    const orderKeysA = Object.keys(a)
      .filter((key) => key.startsWith("order"))
      .sort();
    const orderKeysB = Object.keys(b)
      .filter((key) => key.startsWith("order"))
      .sort();

    for (let i = 0; i < orderKeysA.length; i++) {
      if (a[orderKeysA[i]] !== b[orderKeysB[i]]) {
        return a[orderKeysA[i]] - b[orderKeysB[i]];
      }
    }

    return 0;
  });

  // console.log("sortedArray", sortedArray);

  return sortedArray;
}
*/

// export function generateAllRegisteredStudents(
//   registrationInfoData,
//   settingData
// ) {
//   // 먼저 조건에 맞는 schoolYearDates 데이터를 추출합니다.
//   const schoolYearDatesData = settingData
//     .filter((group) => group.organization_path === "0" && group.parent_id === 0)
//     .flatMap((group) => group.schoolYearDates || []); // 조건에 맞는 group에서 schoolYearDates 추출

//   let toBeRows = registrationInfoData
//     .map((row) => {
//       const { organization_path, user, ...otherFields } = row;
//       const paths = organization_path.split("/").filter((path) => path);
//       const pathFields = paths.reduce((acc, curr, index) => {
//         const thisGroup = settingData.find((r) => r.id === Number(curr));

//         // console.log("paths", paths);

//         if (thisGroup) {
//           const groupData = {
//             ...acc,
//             [index]: thisGroup.group_name,
//             ["order" + index]: thisGroup.order,
//             hr_teacher_username: thisGroup.hr_teacher_username,
//           };

//           // schoolYearDates가 존재하고 길이가 0보다 클 때만 추가
//           if (thisGroup.schoolYearDates?.length > 0) {
//             groupData.schoolYearDates = thisGroup.schoolYearDates;
//           }

//           return groupData;

//           //   return {
//           //     ...acc,
//           //     [index]: thisGroup.group_name,
//           //     ["order" + index]: thisGroup.order,
//           //     hr_teacher_username: thisGroup.hr_teacher_username,
//           // if(thisGroup.schoolYearDates.length > 0){ schoolYearDates: thisGroup.schoolYearDates }

//           //   };
//         } else {
//           console.error(`No group found with id: ${curr}`);
//           return acc;
//         }
//       }, {});

//       // console.log("pathFields", pathFields);

//       let hr_teacher = "";
//       if (pathFields.hr_teacher_username) {
//         hr_teacher = pathFields.hr_teacher_username
//           .map((item) => item.tname)
//           .join(", ");
//       }

//       const userObject = { fullname: user.fullname, gender: user.gender };

//       return {
//         ...otherFields,
//         ...pathFields,
//         ...userObject,
//         hr_teacher,
//         organization_path,
//       };
//     })
//     .sort((a, b) => {
//       const aValue = parseInt(a.studentno, 10);
//       const bValue = parseInt(b.studentno, 10);

//       if (isNaN(aValue) && isNaN(bValue)) {
//         return 0;
//       }
//       if (isNaN(aValue)) {
//         return 1;
//       }
//       if (isNaN(bValue)) {
//         return -1;
//       }
//       return aValue - bValue;
//     });

//   const sortedArray = toBeRows.sort((a, b) => {
//     const orderKeysA = Object.keys(a)
//       .filter((key) => key.startsWith("order"))
//       .sort();
//     const orderKeysB = Object.keys(b)
//       .filter((key) => key.startsWith("order"))
//       .sort();

//     for (let i = 0; i < orderKeysA.length; i++) {
//       if (a[orderKeysA[i]] !== b[orderKeysB[i]]) {
//         return a[orderKeysA[i]] - b[orderKeysB[i]];
//       }
//     }

//     return 0;
//   });

//   // schoolYearDates 정보를 포함한 sortedArray 반환
//   return sortedArray.map((row) => {
//     // 조건에 맞는 schoolYearDates를 포함합니다.
//     return {
//       ...row,
//       schoolYearDates: schoolYearDatesData, // 조건을 만족하는 schoolYearDates 추가
//     };
//   });
// }

export function generateAllRegisteredStudents(
  registrationInfoData,
  settingData
) {
  let toBeRows = registrationInfoData.map((row) => {
    const { organization_path, user, ...otherFields } = row;
    const paths = organization_path.split("/").filter((path) => path);
    const firstPathId = Number(paths[0]); // organization_path의 첫 번째 숫자 추출

    const pathFields = paths.reduce((acc, curr, index) => {
      const thisGroup = settingData.find((r) => r.id === Number(curr));

      if (thisGroup) {
        // 조건부로 schoolYearDates 추가
        const groupData = {
          ...acc,
          [index]: thisGroup.group_name,
          ["order" + index]: thisGroup.order,
          hr_teacher_username: thisGroup.hr_teacher_username,
        };

        // schoolYearDates가 존재하고 길이가 0보다 클 때만 추가
        if (thisGroup.schoolYearDates?.length > 0) {
          groupData.schoolYearDates = thisGroup.schoolYearDates.filter(
            (date) => date.school_year_id === firstPathId
          );
        }

        return groupData;
      } else {
        console.error(`No group found with id: ${curr}`);
        return acc;
      }
    }, {});

    let hr_teacher = "";
    if (pathFields.hr_teacher_username) {
      hr_teacher = pathFields.hr_teacher_username
        .map((item) => item.tname)
        .join(", ");
    }

    const userObject = { fullname: user.fullname, gender: user.gender };

    return {
      ...otherFields,
      ...pathFields,
      ...userObject,
      hr_teacher,
      organization_path,
    };
  });

  // 학번 순으로 정렬
  const sortedArray = toBeRows.sort((a, b) => {
    const aValue = parseInt(a.studentno, 10);
    const bValue = parseInt(b.studentno, 10);

    if (isNaN(aValue) && isNaN(bValue)) {
      return 0;
    }
    if (isNaN(aValue)) {
      return 1;
    }
    if (isNaN(bValue)) {
      return -1;
    }
    return aValue - bValue;
  });

  // Order 필드를 기준으로 추가 정렬
  const finalSortedArray = sortedArray.sort((a, b) => {
    const orderKeysA = Object.keys(a)
      .filter((key) => key.startsWith("order"))
      .sort();
    const orderKeysB = Object.keys(b)
      .filter((key) => key.startsWith("order"))
      .sort();

    for (let i = 0; i < orderKeysA.length; i++) {
      if (a[orderKeysA[i]] !== b[orderKeysB[i]]) {
        return a[orderKeysA[i]] - b[orderKeysB[i]];
      }
    }

    return 0;
  });

  // 필터링된 schoolYearDates 포함
  const rowsWithSchoolYearDates = finalSortedArray.map((row) => {
    return {
      ...row,
      schoolYearDates: row.schoolYearDates || [], // 필터링된 schoolYearDates 반환
    };
  });

  // schoolYearDates[0].start_date 기준으로 정렬
  const sortedByStartDate = rowsWithSchoolYearDates.sort((a, b) => {
    const startDateA = a.schoolYearDates?.[0]?.start_date;
    const startDateB = b.schoolYearDates?.[0]?.start_date;

    if (startDateA && startDateB) {
      return new Date(startDateA) - new Date(startDateB);
    } else if (startDateA) {
      return -1;
    } else if (startDateB) {
      return 1;
    } else {
      return 0;
    }
  });

  return sortedByStartDate;
}

//보시다시피.. object의 모든 값을 더하는 것이다. 기본적으로 trim 감사하고 Number()로 했을 때 숫자로 표현 가능한 것만 합산한다. Number() 했을 때 NaN이면 "숫자만가능" 이란 결과가 나온다. 결과값은 두번째 인자로 변경 가능
export function sumAllNumbersOfObject(object, dealingText = "숫자만 가능") {
  if (typeof object !== "object" || object === null) {
    throw new TypeError("객체 인자 필요");
  }

  const values = Object.values(object);
  let allNumbers = true; // Track if all values are numbers

  const result = values.reduce((sum, value) => {
    const trimmedValue = String(value).trim();
    const numericValue = Number(trimmedValue);
    if (isNaN(numericValue)) {
      allNumbers = false;
      return sum;
    }

    return sum + numericValue;
  }, 0);
  return allNumbers ? result : dealingText;
}

//평균내는 것이다.
export function averageAllNumbersOfObject(object, dealingText = "숫자만 가능") {
  if (typeof object !== "object" || object === null) {
    throw new TypeError("객체 인자 필요");
  }

  const values = Object.values(object);
  let allNumbers = true; // Track if all values are numbers
  let count = 0; // Track the number of numeric values

  const sum = values.reduce((sum, value) => {
    if (value === null || value === "") {
      return sum; // Skip null or empty string values
    }

    const trimmedValue = String(value).trim();
    const numericValue = Number(trimmedValue);

    if (isNaN(numericValue)) {
      allNumbers = false;
      return sum;
    }

    count += 1;
    return sum + numericValue;
  }, 0);

  if (count === 0) {
    return dealingText; // If there are no numeric values, return dealingText
  }

  const average = sum / count;
  return allNumbers ? average : dealingText;
}

//object 안에 값을 trim 하는 함수다.
export function trimObjectValues(obj) {
  const trimmedObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      trimmedObj[key] = typeof value === "string" ? value.trim() : value;
    }
  }

  return trimmedObj;
}
