import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // 백엔드 플러그인 사용
  // /public/locales/{lng}/translation.json 파일 로드
  .use(Backend)
  // 브라우저 언어 감지 플러그인
  .use(LanguageDetector)
  // react-i18next 초기화
  .use(initReactI18next)
  .init({
    // 기본 언어
    fallbackLng: "ko",

    // 허용할 언어 목록
    supportedLngs: ["ko", "en"],

    // 감지 옵션
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
    },

    // 백엔드 옵션
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },

    // 네임스페이스
    defaultNS: "translation",
    ns: ["translation"],

    // 개발 시 디버그 모드
    debug: process.env.NODE_ENV === "development",

    // 인터폴레이션 설정
    interpolation: {
      escapeValue: false, // React에서는 이미 XSS를 방지하므로 false로 설정
    },

    // 복수형 처리
    pluralSeparator: "_",
    contextSeparator: "_",

    // 리액트 설정
    react: {
      useSuspense: true, // Suspense 사용 여부
      bindI18n: "languageChanged loaded", // 언어 변경 시 리렌더링 트리거
      bindI18nStore: "added removed", // 번역 추가/제거 시 리렌더링 트리거
      transEmptyNodeValue: "", // 빈 값 처리
    },
  });

export default i18n;
