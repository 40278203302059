import React from "react";
import { useLocation } from "react-router-dom";
import EvalItemTable from "./EvalItemTable";
import { useTranslation } from "react-i18next";

export default function SettingEvalItemsModal({
  lessonInfo,
  setLessonInfo,
  setEvalItemModalOpen,
  lessonSetting,
  evalItemsData,
  setEvalItemsData,
  getEvalItemsByLessonId,
}) {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const { t } = useTranslation();

  const handleClose = () => {
    setEvalItemModalOpen(false);
    setLessonInfo({
      id: "",
      evaluation_type: "",
      hierarchyOne: "",
      hierarchyTwo: "",
      hierarchyThree: "",
      school_year: "",
      schoolid: "",
      subject_name: "",
      teachers: [],
      termName: "",
    });
  };

  return (
    <>
      <div className="modal-container">
        <div
          className="modalForUnit"
          style={{
            width: "1200px",
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          <div className="modal-header mb-3 d-flex justify-content-between">
            <h3>{t("teacher.lessonRelated.evalItemSetting")}</h3>
            <button
              type="button"
              className="btn btn-secondary me-3"
              onClick={handleClose}
            >
              {t("common.labels.close") || "닫기"}
            </button>
          </div>
          <div className="modal-content">
            <table className="table text-center align-middle">
              <tbody>
                <tr>
                  <th style={{ width: "15%" }}>
                    {t("common.labels.termName")}
                  </th>
                  <th style={{ width: "10%" }}>
                    {t("common.labels.schoolStage")}
                  </th>
                  <th style={{ width: "10%" }}>
                    {t("common.labels.studentYear")}
                  </th>
                  <th style={{ width: "10%" }}>{t("common.labels.class")}</th>
                  <th style={{ width: "40%" }}>
                    {t("teacher.lessonRelated.subjectTitle")}
                  </th>
                  <th style={{ width: "15%" }}>{t("common.user.teacher")}</th>
                </tr>
                <tr>
                  <td>
                    {lessonInfo &&
                      lessonInfo.school_year + "-" + lessonInfo.termName}
                  </td>
                  <td>{lessonInfo && lessonInfo.hierarchyOne}</td>
                  <td>{lessonInfo && lessonInfo.hierarchyTwo}</td>
                  <td>{lessonInfo && lessonInfo.hierarchyThree}</td>
                  <td>{lessonInfo && lessonInfo.subject_name}</td>
                  <td>
                    {lessonInfo && Array.isArray(lessonInfo?.teachers)
                      ? lessonInfo?.teachers
                          ?.map((user) => user.tname.toString())
                          .join(",")
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>

            <EvalItemTable
              conditions={{ schoolid: schoolId, lesson_id: lessonInfo.id }}
              lessonSetting={lessonSetting}
              evalItemsData={evalItemsData}
              setEvalItemsData={setEvalItemsData}
              getEvalItemsByLessonId={getEvalItemsByLessonId}
            />
          </div>
        </div>
      </div>
    </>
  );
}
