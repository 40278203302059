import React, { useState } from "react";
import { convertToYearMonthDayFormat } from "../../../util/formatdate";
import { useLocation } from "react-router-dom";
import useUsers from "../../../hooks/useUsers";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import useGroupsMembers from "../../../hooks/useGroupsMembers";
import { useQueryClient } from "@tanstack/react-query";
import { useUIContext } from "../../../context/UIContext";
import { useTranslation } from "react-i18next";

export default function AddTeacher({
  setIsUploading,
  isUploading,
  setSuccess,
  setModalOpen,
  userGroups,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];
  const [userInfo, setUserInfo] = useState({
    username: "",
    schoolid: schoolId,
    role: "teacher",
    status: 1,
  });

  const { createUser } = useUsers();

  const { createGroupsMembers } = useGroupsMembers();
  const { handleToastCenterTop } = useUIContext();

  const queryClient = useQueryClient();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "birthday") {
      formattedValue = convertToYearMonthDayFormat(value);
    } else if (name === "username") {
      formattedValue = value + `@${schoolId}`;
    }
    setUserInfo((userInfo) => ({ ...userInfo, [name]: formattedValue }));
    // console.log(userInfo);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const regex = /^[A-Za-z0-9~`!@#$%^&*()_+={}[\]|\\:;"'<,>.?/-]*$/;

    if (!regex.test(userInfo.password)) {
      setErrorMessage(t("common.messages.passwordRule"));
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }

    const usernameRegex = /^[a-z0-9~`!@#$%^&*()_+={}|\\:;"'<,>.?/-]*$/;

    if (!usernameRegex.test(userInfo.username)) {
      setErrorMessage(t("common.messages.usernameRule"));
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }

    if (!userGroups || userGroups.length < 1) {
      handleToastCenterTop(t("admin.teacherInfo.groupMandatory"));
      return;
    }

    setIsUploading(true);

    createUser.mutate(userInfo, {
      onSuccess: (username) => {
        setSuccess(username.username + t("admin.teacherInfo.teacherSuccess"));

        handleAddUserToGroups(username.username);
        setTimeout(() => {
          setSuccess(null);
        }, 4000);
        setIsUploading(false);
      },
      onError: (error) => {
        setIsUploading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        alert(error.message);
        setSuccess(null);
      },
    });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleModal = () => {
    setModalOpen(true);
  };

  const handleAddUserToGroups = (user) => {
    // console.log("user", user);

    userGroups.forEach((group) => {
      handleAddClick({ username: user, groups_id: group.id, role: group.role });
    });
  };

  // 기존의 handleAddClick 함수를 수정해서 user 객체에서 groups_id를 가져오도록 합니다.
  const handleAddClick = (groupMember) => {
    const addDataSet = {
      schoolid: schoolId,
      groups_id: groupMember.groups_id, // 전달된 그룹 ID 사용
      username: groupMember.username,
      role: groupMember.role || "member",
    };

    setIsUploading(true);
    createGroupsMembers.mutate(addDataSet, {
      onSuccess: (successMessage) => {
        // console.log(successMessage);
        queryClient.invalidateQueries(["groupsMembersAll"]);
        setIsUploading(false);
      },
      onError: (error) => {
        setIsUploading(false);
        console.error(error);
        alert("에러 발생: " + error.message);
      },
    });
  };

  return (
    <form className="row g-2" onSubmit={handleSubmit}>
      <div className="col-auto">
        <label htmlFor="fullname" className="form-label ms-2 fw-bold">
          {t("admin.teacherInfo.teacherName")}
        </label>
        <input
          type="text"
          className="form-control m-2"
          name="fullname"
          required
          onChange={handleChange}
        />
      </div>

      <div className="col-auto">
        <label htmlFor="gender" className="form-label ms-2 fw-bold">
          {t("common.user.gender") || "성별"}
        </label>
        <select
          className="form-control m-2"
          name="gender"
          required
          onChange={handleChange}
        >
          <option value="">{t("common.labels.select") || "선택"}</option>
          <option value="남">{t("common.labels.male") || "남"}</option>
          <option value="여">{t("common.labels.female") || "여"}</option>
        </select>
      </div>

      <div className="col-auto">
        <label htmlFor="birthday" className="form-label ms-2 fw-bold">
          {t("common.user.birthday") || "생년월일"}
        </label>
        <input
          type="date"
          className="form-control m-2"
          name="birthday"
          onChange={handleChange}
        />
      </div>

      <div className="col-auto">
        <label htmlFor="username" className="form-label ms-2 fw-bold">
          {t("common.user.username") || "사용자계정"}
        </label>
        <div className="input-group mb-3 mt-2 ps-2">
          <input
            type="text"
            className="form-control border border-end-0 text-end pe-0 me-0"
            name="username"
            required
            onChange={handleChange}
            aria-describedby="basic-addon2"
            style={{ width: "140px" }}
          />
          <span
            className="input-group-text bg-transparent ps-0"
            id="basic-addon2"
          >
            @{schoolId}
          </span>
        </div>
      </div>

      <div className="col-auto">
        <label htmlFor="password" className="form-label ms-2 fw-bold">
          {t("common.user.password") || "비밀번호"}
        </label>
        <div className="input-group mb-3 mt-2">
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            name="password"
            required
            onChange={handleChange}
          />
          <button
            type="button" // This prevents form submission
            className="btn btn-outline-secondary"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <MdOutlineVisibilityOff size={20} />
            ) : (
              <MdOutlineVisibility size={20} />
            )}
          </button>
        </div>
        {errorMessage && <p className="text-danger fw-bold">{errorMessage}</p>}
      </div>

      <div className="col-auto">
        <label htmlFor="idatschool" className="form-label ms-2 fw-bold mb-3">
          {t("admin.teacherInfo.authorityGroup")}
          <button
            className="btn btn-secondary btn-sm pb-0 pt-0 ms-2"
            type="button"
            onClick={handleModal}
          >
            {t("common.labels.assign")}
          </button>
        </label>
        <div
          style={{
            display: "flex",
            gap: "4px",
            flexWrap: "wrap",
            alignItems: "center",
            maxWidth: "200px", // 원하는 최대 너비 설정
            overflow: "hidden",
            border: "1px solid lightgray",
            minHeight: "38px",
            borderRadius: "8px",
          }}
        >
          {userGroups.map((group) => (
            <span
              key={group.id}
              style={{
                backgroundColor: "#e9ecef",
                padding: "4px 6px",
                borderRadius: "12px",
                fontSize: "12px",
                color: "#495057",
              }}
            >
              {group.group_name}
            </span>
          ))}
        </div>
      </div>

      <div className="col-auto">
        <label htmlFor="addTeacherBtn" className="form-label ms-2 fw-bold">
          {t("common.user.faculty") || "교직원"}
        </label>
        <div>
          <button
            className="btn btn-primary text-nowrap m-2"
            name="addTeacherBtn"
            type="submit"
            disabled={isUploading}
          >
            {isUploading ? "Uploading..." : t("common.labels.create") || "생성"}
          </button>
        </div>
      </div>
      {errorMessage && (
        <p className="text-danger fw-bold ms-5">{errorMessage}</p>
      )}
    </form>
  );
}
