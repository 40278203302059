import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import MenuDropdown from "./MenuDropdown";
import MyProfile from "./MyProfile";
import LanguageSelector from "../LanguageSelector";
// import Notifications from "./Notifications";
import useUniComponent from "../../hooks/useUniComponent";
import { useAuthContext } from "../../context/AuthContext";
import { useUIContext } from "../../context/UIContext";
import { useTranslation } from "react-i18next";

export default function MainSidebar() {
  const { user, selectedChild, handleSelectedChild } = useAuthContext();
  const { t } = useTranslation();
  const {
    commonMenus: allMenus,
    isLoading,
    error,
    userRole,
  } = useUniComponent();

  const location = useLocation();

  const { handleToastCenterTop } = useUIContext();

  const messagePopup = () => {
    handleToastCenterTop(t("common.uniComponents.contactAdmin"));
    setTimeout(() => {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSev67sNIFU22Ze3ADyb27mTBnKql_pOri8SyObqlnIH4lJw0A/viewform?usp=sf_link",
        "_blank"
      );
    }, 2000);
  };

  useEffect(() => {
    setSidebarVisible(false);
  }, [location]);

  // State to track sidebar visibility
  const [sidebarVisible, setSidebarVisible] = useState(false);

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <>
      <aside
        className={`sidebar bg-light vh-100 ${
          sidebarVisible ? "" : ""
        } d-sm-none`}
        style={{
          width: sidebarVisible ? "150px" : "0px",
          padding: sidebarVisible ? "10px" : "0px",
          position: "fixed",
          zIndex: 2,
        }}
      >
        <div
          className="sidebar-toggle"
          style={{ position: "absolute", top: 10, left: 10 }}
        >
          <button onClick={toggleSidebar} className="sidebar-toggle-btn">
            <FaBars />
          </button>
        </div>
        <div
          className={`sidebar-contents h-100 d-flex flex-column justify-content-around ${
            sidebarVisible ? "" : "d-none"
          }`}
        >
          <div
            className="sidebar-header text-center mt-3 mb-3 me-5 d-flex flex-column justify-content-around"
            style={{
              height: "140px",
            }}
          >
            <MyProfile subMenus={allMenus} user={user} />
            {/* <Notifications subMenus={allMenus} user={user} /> */}
          </div>

          <div className="sidebar-content flex-grow-1">
            {isLoading && <p>Loading...</p>}
            {error && <p>{error.message || error}</p>}
            {allMenus &&
              allMenus
                .filter(
                  (menu) =>
                    menu.components === "Appbar" &&
                    menu.parentcategory === "parent"
                )
                .sort((a, b) => a.categoryorder - b.categoryorder)
                .map((menu) => (
                  <MenuDropdown
                    key={menu.id}
                    parentMenu={menu}
                    subMenus={allMenus}
                    user={user}
                  />
                ))}
            <div>
              <button
                className="btn btn-sm"
                type="button"
                key="feedback"
                onClick={messagePopup}
              >
                {t("common.uniComponents.feedback")}
              </button>
            </div>
          </div>
          <div className="sidebar-footer text-center mt-3 mb-5">
            {user?.role === "parent" && (
              <div className="text-center mb-2">
                <label htmlFor="childSelect" style={{ fontSize: "12px" }}>
                  {t("common.uniComponents.selectChild")}
                </label>
                <select
                  id="childSelect"
                  value={selectedChild}
                  onChange={(e) => handleSelectedChild(e.target.value)}
                  className="form-control p-1 mt-0"
                  style={{ fontSize: "13px" }}
                >
                  {user?.guardian?.map((child) => (
                    <option key={child.username} value={child.username}>
                      {child.fullname || child.username || ""}
                    </option>
                  ))}
                  <option value="linkRequest">
                    {t("common.uniComponents.linkChild")}
                  </option>
                </select>
              </div>
            )}
            <LanguageSelector />

            <Link
              to={
                user?.schoolid ? `/${user.schoolid}/${userRole}/dashboard` : "/"
              }
            >
              <img alt="에듀씨드" src="/images/eduseedLogo.png" height="50px" />
            </Link>
          </div>
        </div>
      </aside>
      {sidebarVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
          onClick={toggleSidebar}
        />
      )}
    </>
  );
}
