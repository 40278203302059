import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUIContext } from "../../../context/UIContext";
import useLessonSetting from "../../../hooks/useLessonSetting";
import HelpHyperLink from "../../../unicomponents/HelpHyperLink";
import moment from "moment";
import { MdOutlineCancel } from "react-icons/md";
import {
  createFilteredAndSortedUniqueOptions,
  uniqueValues,
} from "../../../util/dealingArrObj";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function LessonSetting() {
  const { t } = useTranslation();

  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = {
    schoolid: schoolId,
  };

  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();

  const {
    lessonSettingQuery: { isLoading, data: lessonSettingData },
    createLessonSetting,
    deleteLessonSetting,
    updateLessonSetting,
  } = useLessonSetting(conditions);

  const queryClient = useQueryClient();

  const [formValues, setFormValues] = useState({});
  const [createStartDate, setCreateStartDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [isCreated, setIsCreated] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (lessonSettingData) {
      const initialDate = uniqueValues(
        lessonSettingData,
        "start_date",
        "desc"
      )[0];

      if (!initialDate) {
        setFormValues({});
      } else {
        // lessonSettingData에서 initialDate와 일치하는 데이터만 필터링
        const filteredData = lessonSettingData.filter(
          (item) => item.start_date === initialDate
        );

        // 필터링된 데이터를 바탕으로 formattedData 생성

        const formattedData = filteredData.reduce(
          (acc, item) => ({
            ...acc,
            [item.item_code]: {
              saved_setting: item.saved_setting || "",
              detailed_setting: item.detailed_setting
                ? item.detailed_setting.join(", ")
                : "",
              id: item.id,
              schoolid: item.schoolid,
            },
          }),
          {}
        );
        setFormValues(formattedData);
        setSelectedDate(initialDate);
      }
    }
  }, [lessonSettingData]);

  const handleDateChange = (e) => {
    const { value } = e.target;
    const startDate = moment(value).format("YYYY-MM-DD");
    setCreateStartDate(startDate);
  };

  const handleSelectedDateChange = (e) => {
    const { value } = e.target;

    const filteredData = lessonSettingData.filter(
      (item) => item.start_date === value
    );

    // 필터링된 데이터를 바탕으로 formattedData 생성

    const formattedData = filteredData.reduce(
      (acc, item) => ({
        ...acc,
        [item.item_code]: {
          saved_setting: item.saved_setting || "",
          detailed_setting: item.detailed_setting
            ? item.detailed_setting.join(", ")
            : "",
          id: item.id, // 서버에서 받은 데이터에 item_name이 포함되어 있다고 가정
        },
      }),
      {}
    );
    setFormValues(formattedData);
    setSelectedDate(value);
  };

  const addLessonSetting = (e) => {
    e.preventDefault();

    const initialFormat = [
      {
        schoolid: schoolId,
        start_date: createStartDate,
        item_name: "수업코드",
        item_code: "subjectCode",
        saved_setting: "미사용",
      },
      {
        schoolid: schoolId,
        start_date: createStartDate,
        item_name: t("common.labels.category"),
        item_code: "subjectSort",
        saved_setting: "미사용",
      },
      {
        schoolid: schoolId,
        start_date: createStartDate,
        item_name: "과목분류",
        item_code: "subjectCategory",
        saved_setting: "미사용",
      },
      {
        schoolid: schoolId,
        start_date: createStartDate,
        item_name: "과목순서",
        item_code: "subjectOrder",
        saved_setting: "미사용",
      },
      {
        schoolid: schoolId,
        start_date: createStartDate,
        item_name: "과목공통입력",
        item_code: "subjectContent",
        saved_setting: "미사용",
      },
      {
        schoolid: schoolId,
        start_date: createStartDate,
        item_name: "평가제",
        item_code: "evalMethod",
      },
      {
        schoolid: schoolId,
        start_date: createStartDate,
        item_name: "100점제 평가영역",
        item_code: "evalArea100",
        saved_setting: "실점수기록",
        detailed_setting: ["전체"],
      },
      {
        schoolid: schoolId,
        start_date: createStartDate,
        item_name: "평가항목별옵션",
        item_code: "evalItem",
        detailed_setting: [],
      },
      {
        schoolid: schoolId,
        start_date: createStartDate,
        item_name: "학점",
        item_code: "creditHour",
        saved_setting: "미사용",
      },
    ];

    setIsLoadingModal(true);
    createLessonSetting.mutate(initialFormat, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setIsCreated(true);
        setTimeout(() => {
          setIsCreated(false);
        }, 1500);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleDelete = () => {
    if (selectedDate.length > 0) {
      const processedValues = Object.keys(formValues).map((key) => ({
        id: formValues[key].id,
      }));

      setIsLoadingModal(true);
      deleteLessonSetting.mutate(processedValues, {
        onSuccess: (successMessage) => {
          console.log(successMessage);
          setIsLoadingModal(false);
          setConfirmDelete(false);
          queryClient.invalidateQueries(["lessonSetting"]);
        },
        onError: (error) => {
          setIsLoadingModal(false);
          setConfirmDelete(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(t("common.messages.errorAlert") + error.message);
        },
      });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();

    const valuesToUpdate = formValues;

    if (
      !valuesToUpdate?.evalMethod?.detailed_setting ||
      valuesToUpdate?.evalMethod?.detailed_setting?.length < 1
    ) {
      handleToastCenterTop("최소 1개의 평가제를 선택해 주세요.");
      return;
    }

    const processedValues = Object.keys(valuesToUpdate).map((key) => ({
      id: valuesToUpdate[key].id,
      schoolid: schoolId,
      saved_setting: valuesToUpdate[key].saved_setting,
      detailed_setting: valuesToUpdate[key].detailed_setting
        ? valuesToUpdate[key].detailed_setting
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        : [],
      // id가 없는 경우 필수 필드들을 추가
      ...(!valuesToUpdate[key].id && {
        start_date: selectedDate,
        item_name: key === "creditHour" ? "학점" : "평가항목별옵션",
        item_code: key,
      }),
    }));

    setIsLoadingModal(true);
    updateLessonSetting.mutate(processedValues, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
        }, 1500);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleLessonSettingForm = (e) => {
    const { name, value, type } = e.target;
    const itemCode = e.target.getAttribute("data-item-code");

    setFormValues((prev) => ({
      ...prev,
      [itemCode]: {
        ...prev[itemCode],
        [name]: value,
      },
    }));

    // 입력 요소가 textarea일 경우 높이 자동 조정
    if (type === "textarea") {
      const textarea = e.target;
      textarea.style.height = "auto"; // 높이를 자동으로 재설정
      textarea.style.height = `${textarea.scrollHeight}px`; // 스크롤 높이로 높이 설정
    }
  };

  const handleEvalMethodChange = (e) => {
    const { value } = e.target;
    // const selectedOptionText = e.target.selectedOptions[0].text;
    if (value.length > 0) {
      setFormValues((prev) => {
        // 빈 값이거나 undefined일 경우 빈 배열 반환
        const evalMethodItems = prev.evalMethod.detailed_setting
          ? prev.evalMethod.detailed_setting
              .split(",")
              .map((item) => item.trim())
              .filter((item) => item !== "")
          : [];

        const exists = evalMethodItems.includes(value);

        if (!exists) {
          const updatedItems = [...evalMethodItems, value];
          return {
            ...prev,
            evalMethod: {
              ...prev.evalMethod,
              detailed_setting: updatedItems.join(", "),
            },
          };
        }
        return prev;
      });
    }
  };

  const handleUpdateEvalMethod = (updatedItems) => {
    const itemCode = "evalMethod";

    setFormValues((prev) => ({
      ...prev,
      [itemCode]: {
        ...prev[itemCode],
        detailed_setting: updatedItems,
      },
    }));
  };

  function EvalMethodComponent(evalMethodString, handleUpdateEvalMethod) {
    // 문자열을 콤마로 구분하여 배열로 변환
    if (evalMethodString) {
      const evalMethodItems = evalMethodString
        .split(",")
        .map((item) => item.trim());

      //   아이템 삭제 핸들러
      const handleRemoveItem = (index) => {
        // 지정된 인덱스의 아이템을 제거
        const updatedItems = evalMethodItems.filter((_, idx) => idx !== index);
        // 부모 컴포넌트나 상태 업데이트 로직에 업데이트된 배열 전달
        handleUpdateEvalMethod(updatedItems.join(", "));
      };

      return (
        <div>
          {evalMethodItems.map((item, index) => (
            <span key={index}>
              {item}
              <MdOutlineCancel
                role="button"
                onClick={() => handleRemoveItem(index)}
              />
            </span>
          ))}
        </div>
      );
    }
  }

  const handleEvalItemChange = (e) => {
    const { value } = e.target;
    // const selectedOptionText = e.target.selectedOptions[0].text;
    if (value.length > 0) {
      setFormValues((prev) => {
        // 빈 값이거나 undefined일 경우 빈 배열 반환
        const evalItemItems =
          prev.evalItem && prev.evalItem.detailed_setting
            ? prev.evalItem.detailed_setting
                .split(",")
                .map((item) => item.trim())
                .filter((item) => item !== "")
            : [];

        const exists = evalItemItems.includes(value);

        if (!exists) {
          const updatedItems = [...evalItemItems, value];
          return {
            ...prev,
            evalItem: {
              ...prev.evalItem,
              detailed_setting: updatedItems.join(", "),
            },
          };
        }
        return prev;
      });
    }
  };

  const handleUpdateEvalItem = (updatedItems) => {
    const itemCode = "evalItem";

    setFormValues((prev) => ({
      ...prev,
      [itemCode]: {
        ...prev[itemCode],
        detailed_setting: updatedItems,
      },
    }));
  };

  function EvalItemComponent(evalItemString, handleUpdateEvalItem) {
    // 문자열을 콤마로 구분하여 배열로 변환
    if (evalItemString) {
      const evalItemItems = evalItemString
        .split(",")
        .map((item) => item.trim());

      //   아이템 삭제 핸들러
      const handleRemoveItem = (index) => {
        // 지정된 인덱스의 아이템을 제거
        const updatedItems = evalItemItems.filter((_, idx) => idx !== index);
        // 부모 컴포넌트나 상태 업데이트 로직에 업데이트된 배열 전달
        handleUpdateEvalItem(updatedItems.join(", "));
      };

      return (
        <div>
          {evalItemItems.map((item, index) => (
            <span key={index}>
              {item}
              <MdOutlineCancel
                role="button"
                onClick={() => handleRemoveItem(index)}
              />
            </span>
          ))}
        </div>
      );
    }
  }

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="basicWidth mt-4">
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h3 className="fw-bold">
              수업설정 <HelpHyperLink link={"https://youtu.be/m-sc31nWO24"} />
            </h3>
          </div>
          <div className="col-8">
            <form
              onSubmit={addLessonSetting}
              className="row align-items-center"
            >
              <div className="col-auto">
                <input
                  type="date"
                  name="createDate"
                  className="form-control"
                  onChange={handleDateChange}
                  style={{ width: "auto" }}
                  required
                />
              </div>
              <div className="col-auto">
                <span>부터 적용되는 수업설정</span>
              </div>
              <div className="col-auto">
                {isCreated ? (
                  <button className="btn btn-success" disabled>
                    성공!
                  </button>
                ) : (
                  <button className="btn btn-primary">만들기</button>
                )}
              </div>
            </form>
          </div>
        </div>
        <form onSubmit={handleSave}>
          <div className="row mt-3 align-items-center">
            <div className="col-auto">
              <select
                className="form-control"
                name="startDateSelect"
                value={selectedDate}
                onChange={handleSelectedDateChange}
              >
                {lessonSettingData && lessonSettingData.length > 0 ? (
                  createFilteredAndSortedUniqueOptions({
                    arrayData: lessonSettingData,
                    displayKey: "start_date",
                    valueKey: "start_date",
                    uniqueKey: "start_date",
                    filters: {},
                    sortKey: "start_date",
                    isDesc: true,
                  })
                ) : (
                  <option>없음</option>
                )}
              </select>
            </div>
            <div className="col-auto">부터 적용되는 수업설정</div>
            <div className="col-auto">
              {confirmDelete ? (
                <React.Fragment>
                  <button
                    type="button"
                    className="btn btn-danger me-1"
                    onClick={handleDelete}
                  >
                    진행
                  </button>
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => setConfirmDelete(false)}
                  >
                    {t("common.labels.cancel") || "취소"}
                  </button>
                </React.Fragment>
              ) : (
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={() => setConfirmDelete(true)}
                >
                  {t("common.labels.delete") || "삭제"}
                </button>
              )}
            </div>
            <div className="col-auto">
              {isSaved ? (
                <button className="btn btn-success" disabled>
                  성공!
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  {t("common.labels.save")}
                </button>
              )}
            </div>
          </div>
          {Object.keys(formValues).length > 0 && (
            <table className="table border mt-3">
              <thead>
                <tr>
                  <th style={{ width: "120px" }}>항목</th>
                  <th style={{ width: "130px" }}>설정</th>
                  <th style={{ width: "250px" }}>세부설정</th>
                  <th>{t("common.labels.description") || "설명"}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>수업코드</td>
                  <td>
                    <select
                      className="select-like-span"
                      data-item-code="subjectCode"
                      name="saved_setting"
                      value={formValues?.subjectCode?.saved_setting || ""}
                      onChange={handleLessonSettingForm}
                    >
                      <option value="미사용">미사용</option>
                      <option value="권장">권장</option>
                      <option value="필수">필수</option>
                    </select>
                  </td>
                  <td>
                    <textarea
                      className="input-like-span"
                      rows={2}
                      name="detailed_setting"
                      data-item-code="subjectCode"
                      placeholder="쉼표로 구분 작성"
                      value={formValues?.subjectCode?.detailed_setting || ""}
                      onChange={handleLessonSettingForm}
                    />
                  </td>
                  <td>
                    학교에서 사용하는 수업코드가 있다면 쉼표로 구분하여 등록해
                    주세요.
                    <br />
                    예시: ENG101, MATH201
                  </td>
                </tr>
                <tr>
                  <td>{t("common.labels.category")}</td>
                  <td>
                    <select
                      className="select-like-span"
                      data-item-code="subjectSort"
                      name="saved_setting"
                      value={formValues?.subjectSort?.saved_setting || ""}
                      onChange={handleLessonSettingForm}
                    >
                      <option value="미사용">미사용</option>
                      <option value="권장">권장</option>
                      <option value="필수">필수</option>
                    </select>
                  </td>
                  <td>
                    <textarea
                      className="input-like-span"
                      rows={2}
                      name="detailed_setting"
                      data-item-code="subjectSort"
                      placeholder="쉼표로 구분 작성"
                      value={formValues?.subjectSort?.detailed_setting || ""}
                      onChange={handleLessonSettingForm}
                    />
                  </td>
                  <td>
                    수업을 구분하는 옵션을 넣을 수 있습니다. <br />
                    예시: 필수, 선택
                  </td>
                </tr>
                <tr>
                  <td>과목분류</td>
                  <td>
                    <select
                      className="select-like-span"
                      data-item-code="subjectCategory"
                      name="saved_setting"
                      value={formValues?.subjectCategory?.saved_setting || ""}
                      onChange={handleLessonSettingForm}
                    >
                      <option value="미사용">미사용</option>
                      <option value="권장">권장</option>
                      <option value="필수">필수</option>
                    </select>
                  </td>
                  <td>
                    <textarea
                      className="input-like-span"
                      rows={2}
                      name="detailed_setting"
                      data-item-code="subjectCategory"
                      placeholder="쉼표로 구분 작성"
                      value={
                        formValues?.subjectCategory?.detailed_setting || ""
                      }
                      onChange={handleLessonSettingForm}
                    />
                  </td>
                  <td>
                    과목을 분류하는 옵션을 넣을 수 있습니다. <br />
                    예시: 국어, 과학, 신앙 등
                  </td>
                </tr>
                <tr>
                  <td>과목순서</td>
                  <td>
                    <select
                      className="select-like-span"
                      data-item-code="subjectOrder"
                      name="saved_setting"
                      value={formValues?.subjectOrder?.saved_setting || ""}
                      onChange={handleLessonSettingForm}
                    >
                      <option value="미사용">미사용</option>
                      <option value="권장">권장</option>
                      <option value="필수">필수</option>
                    </select>
                  </td>
                  <td></td>
                  <td>
                    과목에 순서를 매길 수 있습니다. 성적표 등 출력할 때 원하는
                    순서대로 출력하고 싶다면 이 기능을 활성화 하세요. 그렇지
                    않으면 기본 정렬로 출력됩니다.
                  </td>
                </tr>
                <tr>
                  <td>과목공통입력</td>
                  <td>
                    <select
                      className="select-like-span"
                      data-item-code="subjectContent"
                      name="saved_setting"
                      value={formValues?.subjectContent?.saved_setting || ""}
                      onChange={handleLessonSettingForm}
                    >
                      <option value="미사용">미사용</option>
                      <option value="권장">권장</option>
                      <option value="필수">필수</option>
                    </select>
                  </td>
                  <td></td>
                  <td>
                    과목에 대한 공통을 기록하여, 성적표 등에 공통 입력한 내용이
                    들어가길 원한다면 활성화 하세요.
                  </td>
                </tr>
                <tr>
                  <td>{t("teacher.lessonRelated.evaluationType")}</td>
                  <td>
                    <select
                      className="select-like-span"
                      data-item-code="evalMethod"
                      name="saved_setting"
                      onChange={handleEvalMethodChange}
                    >
                      <option value="">
                        {t("common.labels.select") || "선택"}
                      </option>
                      <option value="100점제">100점제</option>
                      <option value="이수제">이수제</option>
                      <option value="ABC">ABC</option>
                      <option value="직접입력">직접입력</option>
                      <option value="서술형">서술형</option>
                      <option value="3단평가">3단평가</option>
                      <option value="5단평가">5단평가</option>
                      <option value="평가항목별">평가항목별</option>
                      <option value="커스텀">커스텀</option>
                    </select>
                  </td>
                  <td>
                    {EvalMethodComponent(
                      formValues?.evalMethod?.detailed_setting,
                      handleUpdateEvalMethod
                    ) || ""}
                  </td>
                  <td>
                    학교에서 사용하는 평가제를 옵션으로 추가하세요. 교사가
                    수업생성시 추가된 옵션 중에 선택할 수 있습니다.
                  </td>
                </tr>
                <tr>
                  <td>
                    100점제
                    <br />
                    평가영역
                  </td>
                  <td>
                    <select
                      className="select-like-span"
                      data-item-code="evalArea100"
                      name="saved_setting"
                      value={formValues?.evalArea100?.saved_setting || ""}
                      onChange={handleLessonSettingForm}
                    >
                      <option value="실점수기록">실점수기록</option>
                      <option value="원점수기록">원점수기록</option>
                    </select>
                  </td>
                  <td>
                    <textarea
                      className="input-like-span"
                      rows={3}
                      name="detailed_setting"
                      data-item-code="evalArea100"
                      placeholder="쉼표로 구분 작성. 구분 없을시 '전체'로 입력해주세요."
                      value={formValues?.evalArea100?.detailed_setting || ""}
                      onChange={handleLessonSettingForm}
                      required
                    />
                  </td>
                  <td>
                    100점제 사용시 모든 수업에 기록하는 고정적인 평가영역이
                    있다면 입력해 주세요. <br />
                    예시: 중간, 기말, 수행 과목의 평가영역별 가중치(100%기준)를
                    설정할 수 있습니다.
                    <br />
                    <strong>실점수기록</strong>: 100점제의 실제 들어가는 점수를
                    입력하고, 가중치를 곱하지 않습니다. 예시: 중간고사 가중치가
                    30%인데, 학생이 90점을 받았으면, 선생님은 점수 입력시 27점을
                    입력해야 함.
                    <br />
                    <strong>원점수기록</strong>: 해당 항목의 원래 점수를
                    입력하면 가중치를 곱해서 계산됩니다. 예시: 중간고사 가중치가
                    30%인데, 학생이 90점을 받았으면, 선생님이 90점 그대로
                    입력하면 총점 계산시 실점수 27점으로 환산되어 합산됨.
                  </td>
                </tr>
                <tr>
                  <td>
                    평가항목별
                    <br />
                    옵션추가
                  </td>
                  <td>
                    <select
                      className="select-like-span"
                      data-item-code="evalItem"
                      name="saved_setting"
                      onChange={handleEvalItemChange}
                    >
                      <option value="">
                        {t("common.labels.select") || "선택"}
                      </option>
                      <option value="피드백">피드백</option>
                      <option value="가중치">가중치</option>
                      <option value="만점">만점</option>
                      <option value="메모">메모</option>
                    </select>
                  </td>
                  <td>
                    {EvalItemComponent(
                      formValues?.evalItem?.detailed_setting,
                      handleUpdateEvalItem
                    ) || ""}
                  </td>
                  <td>
                    평가항목별 옵션을 추가할 수 있습니다. <br />
                    항목별로 교사가 피드백을 입력할 수 있습니다. <br />
                    항목별로 교사가 가중치, 만점, 설명을 설정할 수 있습니다.
                  </td>
                </tr>
                <tr>
                  <td>학점</td>
                  <td>
                    <select
                      className="select-like-span"
                      data-item-code="creditHour"
                      name="saved_setting"
                      value={formValues?.creditHour?.saved_setting || ""}
                      onChange={handleLessonSettingForm}
                    >
                      <option value="미사용">미사용</option>
                      <option value="권장">권장</option>
                      <option value="필수">필수</option>
                    </select>
                  </td>
                  <td></td>
                  <td>
                    수업 기본 설정에 시수가 있어, 학점으로 취급됩니다. 시수와
                    학점이 다를 경우에만 사용하세요.
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </form>
      </div>
    </div>
  );
}
