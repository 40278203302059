import React from "react";
import { useTranslation } from "react-i18next";

export default function ChangeMonth({ preNextMonth }) {
  const { t } = useTranslation();
  const today = new Date();
  const dateStr = `${today.getFullYear()}.${String(
    today.getMonth() + 1
  ).padStart(2, "0")}.${String(today.getDate()).padStart(2, "0")}`;

  return (
    <div className="row">
      <div className="col-6 text-center d-flex align-items-center justify-content-center">
        {t("teacher.attendanceStatistics.todayDate")} {dateStr}
      </div>
      <div className="col-3 text-end">
        <button
          className="btn btn-outline-dark"
          onClick={() => preNextMonth("pre")}
        >
          {t("teacher.attendanceStatistics.preMonth")}
        </button>
      </div>
      <div className="col-3 text-start">
        <button
          className="btn btn-outline-dark"
          onClick={() => preNextMonth("next")}
        >
          {t("teacher.attendanceStatistics.nextMonth")}
        </button>
      </div>
    </div>
  );
}
