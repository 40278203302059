import React, { useMemo } from "react";
import { createFilteredAndSortedOptions } from "../../util/dealingArrObj";
import { useTranslation } from "react-i18next";

/*
이 드랍다운을 사용하기 위한 props는 아래와 같이 불러온다.

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

*/

export default function DropdownSchoolYear({
  settingData,
  handleDropdownChange,
  schoolYearId,
  allYears = true,
  disabled = false,
  title = "",
}) {
  const options = useMemo(() => {
    return createFilteredAndSortedOptions({
      arrayData: settingData,
      displayKey: "school_year",
      valueKey: "id",
      filters: { hierarchy_level: 0, parent_id: 0 },
      idKey: "id",
      sortKey: "school_year",
      isDesc: true,
      dataName: "organization_path",
    });
  }, [settingData]);

  const { t } = useTranslation();

  return (
    <select
      className="form-control"
      name="0"
      onChange={handleDropdownChange}
      value={schoolYearId || ""}
      disabled={disabled}
    >
      {title && <option value="">{title}</option>}
      {settingData?.length > 0 && options}
      {allYears ? <option value="all">{t("common.labels.all")}</option> : ""}
    </select>
  );
}
