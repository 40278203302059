import React, { useEffect, useState } from "react";
import useCounseling from "../../../hooks/useCounseling";
import { useAuthContext } from "../../../context/AuthContext";
import { useUIContext } from "../../../context/UIContext";
import { useTranslation } from "react-i18next";

function AddCounseling({
  handleSearch,
  searchKey,
  addCounseling,
  setAddCounseling,
  setIsUpLoading,
  userData,
}) {
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [counselorName, setCounselorName] = useState("");
  const { t } = useTranslation();

  const { createCounseling } = useCounseling();

  const { user, hasPermission } = useAuthContext();

  const { handleToastCenterTop } = useUIContext();

  useEffect(() => {
    if (user) {
      setCounselorName(user.fullname);
      setFilteredTeachers([
        { id: user.id, username: user.username, fullname: user.fullname },
      ]);
      setAddCounseling((addCounseling) => ({
        ...addCounseling,
        counselor: user.username,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("user", user);

    // console.log("addCounseling", addCounseling);
    if (!addCounseling.username || addCounseling.username.length === 0) {
      alert(
        t("teacher.counseling.alertName") ||
          "학생이름이 올바르지 않습니다. 재적에 등록되어 있어야 합니다. 상단 우편에 이름이 나오면 재적에 등록된 학생입니다."
      );
      return;
    }

    if (!hasPermission(addCounseling.counselor, "can_create")) {
      handleToastCenterTop(
        t("common.messages.ownerPermission") ||
          "본인 것 외 생성은 권한이 있는 사람만 생성할 수 있습니다."
      );
      return;
    }

    setIsUpLoading(true);

    createCounseling.mutate(addCounseling, {
      onSuccess: () => {
        setIsUpLoading(false);
        setIsSaving(true);
        setTimeout(() => {
          setIsSaving(false);
        }, 2000);
        console.log("addCounseling", addCounseling);

        setAddCounseling((addCounseling) => ({
          ...addCounseling,
          category: "",
          content: "",
          counseling_date: "",
          schoolid: addCounseling.schoolid,
        }));
        setCounselorName("");
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error);
      },
    });
  };

  const handleFullNameChange = (e) => {
    const { value } = e.target;
    // console.log("userData", userData);
    setCounselorName(value);
    setFilteredTeachers([]);
    const filtered = userData.filter((user) => {
      return (
        user.fullname.toLowerCase() === value.toLowerCase() &&
        user.role === "teacher" &&
        user.status === 1
      );
    });
    if (filtered.length > 0) {
      setFilteredTeachers(filtered);
      setAddCounseling((addCounseling) => ({
        ...addCounseling,
        counselor: filtered[0].username,
      }));
    }
  };

  //{id: 1, fullname: "kim", hr_teacher_username: [{username: "hi@eduseed", tname:"이지아"},
  // {username: "hello@miraldure", tname: "유재석"}] }
  const handleInfoChange = (e) => {
    const { name, value } = e.target;

    setAddCounseling((addCounseling) => ({ ...addCounseling, [name]: value }));
    // console.log("addCounseling", addCounseling);
    // console.log("filteredStudents", filteredStudents);
    // console.log("value", value);
  };

  //학생검색하면 상단 재적 드랍다운에 학생이름에도 리스트가 나오고, 하단 테이블도 변경되기
  //상단 재적 드랍다운에 학생을 선택하면 학생검색의 학생도 나오기
  //상단 재적 드랍다운의 학생을 기준으로 저장됨.

  return (
    <form className="g-2" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-4 col-sm-3 col-md-2 col-xl-1">
          <label htmlFor="searchKeyword" className="form-label ms-2 fw-bold">
            {t("common.labels.search")}
          </label>
          <input
            type="text"
            id="searchKeyword"
            name="searchKeyword"
            className="form-control m-2"
            placeholder={t("common.user.studentName") || "학생이름"}
            required
            value={searchKey}
            onChange={handleSearch}
          />
        </div>

        <div className="col-3 col-sm-4 col-md-2 col-xl-1">
          <label htmlFor="category" className="form-label ms-2 fw-bold">
            {t("teacher.counseling.counselingSort") || "상담구분"}
          </label>
          <select
            className="form-control m-2"
            name="category"
            required
            value={addCounseling.category || ""}
            onChange={handleInfoChange}
          >
            <option value="">{t("common.labels.select") || "선택"}</option>
            <option value="생활">
              {t("teacher.counseling.life") || "생활"}
            </option>
            <option value="교과">
              {t("teacher.counseling.subject") || "교과"}
            </option>
            <option value="진로">
              {t("teacher.counseling.career") || "진로"}
            </option>
            <option value="학부모">
              {t("teacher.counseling.parent") || "학부모"}
            </option>
            <option value="가정방문">
              {t("teacher.counseling.homeVisit") || "가정방문"}
            </option>
            <option value="징계">
              {t("teacher.counseling.discipline") || "징계"}
            </option>
            <option value="전출">
              {t("teacher.counseling.transfer") || "전출"}
            </option>
            <option value="지도">{t("teacher.counseling.guidance")}</option>
            <option value="건의">{t("teacher.counseling.suggestion")}</option>
            <option value="기타">
              {t("teacher.counseling.etc") || "기타"}
            </option>
          </select>
        </div>

        <div className="col-4 col-sm-4 col-md-3 col-xl-2">
          <label htmlFor="counseling_date" className="form-label ms-2 fw-bold">
            {t("teacher.counseling.counselingDate") || "상담일자"}
          </label>
          <input
            type="date"
            className="form-control m-2"
            required
            name="counseling_date"
            onChange={handleInfoChange}
            value={addCounseling.counseling_date || ""}
          />
        </div>
        <div className="col-4 col-sm-4 col-md-2 col-xl-1">
          <label htmlFor="fullname" className="form-label ms-2 fw-bold">
            {t("teacher.counseling.counselor") || "상담자"}
          </label>
          <input
            type="text"
            className="form-control m-2"
            name="teacher"
            value={counselorName}
            required
            onChange={handleFullNameChange}
          />
        </div>

        <div className="col-6 col-sm-5 col-md-3 col-xl-2">
          <label htmlFor="counselor" className="form-label ms-2 fw-bold">
            {t("common.user.teacher") || "교사"}{" "}
            {t("common.labels.id") || "아이디"}
          </label>
          <select
            className="form-control m-2"
            name="counselor"
            required
            value={addCounseling.counselor || ""}
            onChange={handleInfoChange}
          >
            {filteredTeachers.map((user) => (
              <option key={user.id} value={user.fullname}>
                {user.username}
              </option>
            ))}
          </select>
        </div>

        <div className="col-9 col-sm-9 col-md-8 col-xl-4">
          <textarea
            className="form-control"
            name="content"
            onChange={handleInfoChange}
            placeholder={t("teacher.counseling.content") || "상담내용"}
            required
            value={addCounseling.content}
            rows={3}
          />
        </div>

        <div className="col-2 col-sm-3 col-md-2 col-xl-1">
          <label htmlFor="saveBtn" className="form-label ms-2 fw-bold"></label>
          <div>
            <button className="btn btn-primary text-nowrap mt-3">
              {isSaving
                ? t("common.labels.savedWell") || "저장완료"
                : t("common.labels.save") || "저장"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddCounseling;
