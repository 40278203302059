import React, { useState } from "react";
import formatDate from "../../../util/formatdate";
import useDeveloper from "../../../hooks/useDeveloper";
import { useTranslation } from "react-i18next";

export default function AddSchool({ setIsUploading, isUploading, setSuccess }) {
  const [schoolInfo, setSchoolInfo] = useState({});
  const { t } = useTranslation();

  const { registerSchool } = useDeveloper();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "startdate") {
      formattedValue = formatDate(value);
    }
    setSchoolInfo((schoolInfo) => ({ ...schoolInfo, [name]: formattedValue }));

    // console.log(schoolInfo);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsUploading(true);

    registerSchool.mutate(schoolInfo, {
      onSuccess: (schoolid) => {
        setSuccess(
          schoolid.schoolid + "아이디의 학교가 성공적으로 등록되었습니다."
        );
        setTimeout(() => {
          setSuccess(null);
        }, 4000);
        setIsUploading(false);
      },
      onError: (error) => {
        // error는 서버에서 반환한 에러 객체입니다.
        // error.message를 통해 에러 메시지를 확인할 수 있습니다.
        // 이 메시지를 사용자에게 보여주거나, 다른 작업을 수행할 수 있습니다.
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        setIsUploading(false);
      },
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form className="row g-2" onSubmit={handleSubmit}>
      <div className="col-auto">
        <input
          type="text"
          className="form-control m-2"
          placeholder="학교아이디"
          name="schoolid"
          required
          onChange={handleChange}
        />
      </div>
      <div className="col-auto">
        <input
          type="text"
          className="form-control m-2"
          placeholder="학교이름"
          name="schoolname"
          required
          onChange={handleChange}
        />
      </div>

      <div className="col-auto">
        <input
          type="text"
          className="form-control m-2"
          placeholder="관리자이름"
          name="schooladmin"
          required
          onChange={handleChange}
        />
      </div>

      <div className="col-auto">
        <input
          type="text"
          className="form-control m-2"
          placeholder="관리자아이디"
          name="adminid"
          required
          onChange={handleChange}
        />
      </div>

      <div className="col-auto">
        <div className="input-group m-2">
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            placeholder={t("common.user.password") || "비밀번호"}
            name="password"
            required
            onChange={handleChange}
          />
          <button
            type="button" // This prevents form submission
            className="btn btn-outline-secondary"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? "Hide" : "Show"}
          </button>
        </div>
      </div>

      <div className="col-auto">
        <input
          type="number"
          className="form-control m-2"
          placeholder="상태"
          name="status"
          required
          onChange={handleChange}
        />
      </div>

      <div className="col-auto">
        <select
          className="form-control m-2"
          name="usergroup"
          required
          onChange={handleChange}
        >
          <option value="">사용자그룹선택</option>
          <option value="교사">교사</option>
          <option value="학생">학생</option>
          <option value="부모">부모</option>
          <option value="교사학생">교사학생</option>
          <option value="교사학생부모">교사학생부모</option>
          <option value="교사부모">교사부모</option>
        </select>
      </div>

      <div className="col-auto">
        <input
          type="date"
          className="form-control m-2"
          placeholder="계약시작일"
          name="startdate"
          required
          onChange={handleChange}
        />
      </div>
      <div className="col-auto">
        <button
          className="btn btn-primary text-nowrap m-2"
          type="submit"
          disabled={isUploading}
        >
          {isUploading ? "Uploading..." : "등록하기"}
        </button>
      </div>
    </form>
  );
}
