import React, { useState } from "react";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import { findDuplicateData } from "../../../util/dealingArrObj";
import { useTranslation } from "react-i18next";

export default function AddUnitModal({
  unit,
  setModalOpen,
  settingData,
  levelNameData,
  conditions,
}) {
  // console.log("unit", unit);
  const { t } = useTranslation();
  const [addUnit, setAddUnit] = useState({
    ...unit,
    level_name: simpleFilterForLevelName(levelNameData, unit),
    hierarchy_level: unit.hierarchy_level + 1,
    parent_id: unit.id,
    description: "",
    hr_teacher: unit.hr_teacher,
    organization_path:
      unit.organization_path !== "0"
        ? unit.organization_path + "/" + unit.id
        : "/" + unit.id,
  });

  const { createOrganizationUnit } = useOrganizationSetting();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "hierarchy_level") {
      if (isNaN(Number(value))) {
        alert(t("admin.organizationSetting.alertHierarchyLevel"));
        return;
      } else {
        formattedValue = Number(value);
      }
    }
    setAddUnit((addUnit) => ({ ...addUnit, [name]: formattedValue }));
    // console.log("addUnit", addUnit);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // setIsUpLoading(true);
    if ("id" in addUnit) {
      // 'id' 속성이 newObj에 있는지 확인
      delete addUnit.id;
    }

    const isDuplicate = findDuplicateData(settingData, addUnit, [
      "school_year",
      "parent_id",
      "group_name",
    ]);

    if (isDuplicate) {
      alert(t("admin.organizationSetting.alertGroupDuplicate"));
      return;
    }

    createOrganizationUnit.mutate(addUnit, {
      onSuccess: () => {
        setModalOpen(false);
      },
      onError: (error) => {
        // setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error);
      },
    });
  };

  function simpleFilterForLevelName(levelNameData, unit) {
    const filtered = levelNameData.filter(
      (r) =>
        r.school_year === unit.school_year &&
        r.hierarchy_level === unit.hierarchy_level + 1
    );

    return filtered[0].level_name;
  }

  return (
    <>
      <div
        className="modal-container"
        // onClick={() => {
        //   setModalOpen(false);
        // }}
      >
        <div className="modalForUnit">
          <div
            className="modal-header mb-3"
            // onClick={() => {
            //   setModalOpen(false);
            // }}
          >
            <h3>{t("admin.organizationSetting.addChildGroup")}</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <div className="row mb-3">
                <div className="col-3">
                  <label htmlFor="parent_id" className="col-form-label">
                    {t("admin.organizationSetting.parentGroup")}
                  </label>
                </div>
                <div className="col-auto" style={{ paddingTop: "5px" }}>
                  <span className="align-bottom">
                    {unit?.group_name}
                    {unit?.level_name && unit?.level_name}
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3">
                  <label htmlFor="hierarchy_level" className="col-form-label">
                    {t("admin.organizationSetting.hierarchyLevel")}
                  </label>
                </div>
                <div className="col-auto" style={{ paddingTop: "5px" }}>
                  <span className="align-bottom">
                    {unit?.hierarchy_level + 1}{" "}
                    {t("admin.organizationSetting.level")}
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3">
                  <label htmlFor="group_name" className="col-form-label">
                    {t("admin.organizationSetting.levelName")}
                  </label>
                </div>
                <div className="col-auto" style={{ paddingTop: "5px" }}>
                  <span className="align-bottom">
                    {levelNameData &&
                      simpleFilterForLevelName(levelNameData, unit)}
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3">
                  <label htmlFor="group_name" className="col-form-label">
                    {t("admin.organizationSetting.groupName")}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-auto">
                  <input
                    required
                    type="text"
                    name="group_name"
                    className="form-control"
                    placeholder={t(
                      "admin.organizationSetting.groupNamePlaceholder"
                    )}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-3">
                  <label htmlFor="order" className="col-form-label">
                    {t("admin.organizationSetting.sortOrder")}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-auto">
                  <input
                    type="number"
                    name="order"
                    className="form-control"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                {t("common.labels.cancel") || "취소"}
              </button>
              <button type="submit" className="btn btn-primary">
                {t("admin.organizationSetting.doAdd")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
