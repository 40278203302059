import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { validateByField } from "../../../util/validate";
import HelpHyperLink from "../../../unicomponents/HelpHyperLink";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { FindIdModal } from "./FindIdModal";
import { ResetPasswordModal } from "./ResetPasswordModal";
import { useTranslation } from "react-i18next";
import { logEvent } from "../../../api/analytics";
// import {
//   googleLogin,
//   googleLogout,
//   onUserStateChange,
// } from "../../../api/firebase";

export default function LoginMain({ handleSignUp }) {
  // const [googleId, setGoogleId] = useState();
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [selectedRole, setSelectedRole] = useState("noSelected");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedUsername, setSelectedUsername] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showFindId, setShowFindId] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);

  useEffect(() => {
    const storedRole = localStorage.getItem("selectedRole");
    if (storedRole) {
      setSelectedRole(storedRole);
      setUserInfo((prev) => ({ ...prev, role: storedRole }));
    }

    const storedSelectedSchool = localStorage.getItem("selectedSchool");
    if (storedSelectedSchool) {
      setSelectedSchool(storedSelectedSchool);
      setUserInfo((prev) => ({ ...prev, schoolid: storedSelectedSchool }));
    }

    const storedUsername = localStorage.getItem("selectedUsername");
    if (storedUsername) {
      setSelectedUsername(storedUsername);
      setUserInfo((prev) => ({ ...prev, username: storedUsername }));
    }
  }, []); // Empty dependency array to run the effect only once on mount

  // 컴포넌트가 마운트될 때 배너 노출 이벤트 기록
  useEffect(() => {
    logEvent("Banner", "Login_Impression", "UseSchool Banner", 1);
  }, []);

  const navigate = useNavigate();
  const { login } = useAuthContext();

  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
    schoolid: "",
    role: "",
  });

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      setSelectedUsername(value);
    }

    if (name === "schoolid") {
      setSelectedSchool(value);
    }

    if (name === "role") {
      setSelectedRole(value);
    }

    setUserInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    // console.log("userInfo", userInfo);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (validateByField("input")) {
      try {
        await login(userInfo);
        // console.log("userInfoAfterLogin", userInfo);
        setSelectedRole(userInfo.role);
        localStorage.setItem("selectedRole", userInfo.role);
        localStorage.setItem("selectedUsername", userInfo.username);
        localStorage.setItem("selectedSchool", userInfo.schoolid);
        if (userInfo.role === "admin") {
          navigate(`/${userInfo.schoolid}/teacher/dashboard`);
        } else {
          navigate(`/${userInfo.schoolid}/${userInfo.role}/dashboard`);
        }
        setMessage(null);
      } catch (err) {
        console.log(err);
        if (err) {
          setMessage(err.toString());
        } else {
          setMessage(t("common.messages.unknownError"));
        }
      }
    } else {
      setMessage(t("common.messages.checkInput"));
    }
  };

  //보안상 이건 안 넣는게 좋겠다.

  /*
  const handleGoogleLogin = async (e) => {
    e.preventDefault();
    try {
      const userData = await byGoogleLogin();
      // console.log("userInfoAfterLogin", userInfo);
      setSelectedRole(userData.role);
      localStorage.setItem("googleLogin", userData.gmail);
      if (userData.role === "admin") {
        navigate(`/${userData.schoolid}/teacher/dashboard`);
      } else {
        navigate(`/${userData.schoolid}/${userData.role}/dashboard`);
      }
      setMessage(null);
    } catch (err) {
      console.log(err);
      if (err) {
        setMessage(err.toString());
      } else {
        setMessage(
          "알 수 없는 오류가 발생했습니다. 새로고침 후 다시 시도하고, 오류가 반복되면 관리자에게 문의하세요."
        );
      }
    }
  };
*/

  return (
    <div className="loginMain m-5 pt-5">
      <main className="form-signin">
        <form className="needs-validation" noValidate>
          <div className="d-flex">
            <h1 className="h3 mb-4 fw-normal">{t("common.login.title")}</h1>
            <HelpHyperLink link={"https://youtu.be/9l-KLc1bclM"} />
          </div>
          <div className="form-floating mb-2">
            <select
              value={selectedRole}
              className="form-control"
              id="role"
              name="role"
              placeholder={t("common.user.userType")}
              onChange={handleUserChange}
              required
            >
              <option value="noSelected" disabled>
                {t("common.user.userType")}
              </option>
              <option value="teacher">{t("common.user.faculty")}</option>
              <option value="student">{t("common.user.student")}</option>
              <option value="parent">{t("common.user.parent")}</option>
              <option value="admin">{t("common.user.admin")}</option>
            </select>
            <div className="valid-feedback">
              {t("common.login.selectUserType")}
            </div>
            <label>{t("common.user.userType")}</label>
          </div>
          <div className="form-floating mb-2">
            <input
              type="text"
              className="form-control"
              id="schoolid"
              name="schoolid"
              placeholder="myschool"
              value={selectedSchool}
              onChange={handleUserChange}
              required
            />
            <label>School ID</label>
          </div>

          <div className="form-floating mb-2">
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              placeholder={t("common.user.username") || "사용자계정"}
              value={selectedUsername}
              onChange={handleUserChange}
              required
            />
            <div className="valid-feedback">
              {t("common.login.inputUsername")}
            </div>
            <label>
              {t("common.user.user") || "사용자"}{" "}
              {t("common.labels.id") || "아이디"}
            </label>
          </div>
          <div className="form-floating mb-2">
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                name="password"
                onChange={handleUserChange}
                required
              />
              <button
                type="button" // This prevents form submission
                className="btn btn-outline-secondary"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <MdOutlineVisibilityOff size={20} />
                ) : (
                  <MdOutlineVisibility size={20} />
                )}
              </button>
            </div>
          </div>
          <button className="w-100 btn btn-primary" onClick={handleClick}>
            로그인(Login)
          </button>
          {message && (
            <p
              style={{ fontSize: "smaller" }}
              className="bg-warning mt-1 mb-0 p-1 rounded fw-bold text-center"
            >
              {message}
            </p>
          )}
          {/* <div className="text-end">
            <button
              className="w-100 btn btn-outline-dark mt-0"
              onClick={handleGoogleLogin}
            >
              구글아이디로 로그인
            </button>
          </div> */}
          <div
            className="d-flex justify-content-center gap-2"
            style={{ fontSize: "13px" }}
          >
            <span
              onClick={() => setShowFindId(true)}
              style={{ cursor: "pointer" }}
            >
              {t("common.login.findId")} |
            </span>
            <span
              onClick={() => setShowResetPassword(true)}
              style={{ cursor: "pointer" }}
            >
              {t("common.login.resetPassword")}
            </span>
            {selectedRole === "parent" && (
              <span type="button" onClick={handleSignUp}>
                | {t("common.login.signup")}
              </span>
            )}
          </div>
          <p className="mt-2 text-muted">
            <a
              href="https://www.eduseed.co.kr"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#0d6efd",
                textDecoration: "none",
                fontWeight: "bold",
                transition: "transform 0.2s ease-in-out",
                display: "inline-block",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
              EduSeed
            </a>{" "}
            Since 2019
          </p>
          <div className="mt-3 mb-3 d-flex justify-content-center">
            <div
              style={{
                fontSize: "14px",
                letterSpacing: "0.5px",
                color: "#495057",
                borderBottom: "2px solid #0d6efd",
                paddingBottom: "2px",
                paddingRight: "8px",
                paddingLeft: "8px",
                marginRight: "12px",
                fontWeight: "600",
              }}
            >
              후 원
              <br />기 관
            </div>
            <div className="d-flex justify-content-center">
              <a
                href="https://www.useschool.co.kr/AD/CPA/?b1=eduseed&b2=12822"
                target="_blank"
                rel="noopener noreferrer"
                className="d-block"
                onClick={() => {
                  logEvent("Banner", "banner_click", "UseSchool Banner", 1);
                }}
              >
                <img
                  src="/images/eduseed_useschool_logo.svg"
                  alt="유즈스쿨 로고"
                  style={{
                    width: "250px",
                    height: "46.875px", // 16:3 비율로 조정 (250 ÷ 16 × 3 = 46.875)
                    objectFit: "cover",
                    objectPosition: "center",
                    transition: "transform 0.2s ease-in-out",
                    display: "block",
                    borderRadius: "8px", // 라운드 처리 추가
                  }}
                  className="partner-banner"
                  onMouseOver={(e) =>
                    (e.currentTarget.style.transform = "scale(1.05)")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                />
              </a>
            </div>
          </div>
        </form>
        <FindIdModal isOpen={showFindId} onClose={() => setShowFindId(false)} />
        <ResetPasswordModal
          isOpen={showResetPassword}
          onClose={() => setShowResetPassword(false)}
        />
      </main>
    </div>
  );
}

// export default LoginMain;
