import React, { useState } from "react";

function LoginBoard() {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    window.open("https://www.eduseed.co.kr", "_blank");
  };

  return (
    <div className="loginBoard d-none d-sm-block text-center">
      <img
        alt="에듀씨드"
        src="/images/eduseedLogo.png"
        width={isHovered ? "390px" : "370px"}
        height={isHovered ? "390px" : "370px"}
        style={{
          cursor: "pointer",
          transition: "width 0.3s, height 0.3s",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
      />
    </div>
  );
}

export default LoginBoard;
