import React, { useRef, useState } from "react";
import { MdClose, MdOutlineCancel, MdOutlineClose } from "react-icons/md";
import useStudentInfo from "../../../hooks/useStudentInfo";
import DaumPostcodeEmbed from "react-daum-postcode";
import { convertToYearMonthDayFormat } from "../../../util/formatdate";
import styles from "../css/StudnetInfo.module.css";
import { useUIContext } from "../../../context/UIContext";
import { uploadImage } from "../../../api/uploader";
import { useTranslation } from "react-i18next";

//그 다음엔 1차적으로 끝이지만.. 여유가 된다면 재적으로도 검색할 수 있도록 만들자. (일단은 못 했음)

export default function UpdateStuInfoModal({
  setModalOpen,
  studentInfo,
  setStudentInfo,
  setIsUpLoading,
  studentData,
}) {
  const { t } = useTranslation();
  const [filteredStudentData, setFilteredStudentData] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [modifiedFields, setModifiedFields] = useState({});
  const [openAddSiblings, setOpenAddSiblings] = useState(false);
  const [openPostCode, setOpenPostCode] = useState(false);
  const [siblingKey, setSiblingKey] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [studentPhotoFile, setStudentPhotoFile] = useState("");

  const { handleToastCenterTop } = useUIContext();
  const textareaRefs = useRef([]);

  const { updateStudentInfo, createStudentInfo } = useStudentInfo();

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    const level = e.target.dataset.level;
    let formattedValue = value;
    if (
      name === "graduation_no" ||
      name === "academic_record" ||
      name === "special_record"
    ) {
      setStudentInfo((studentInfo) => ({
        ...studentInfo,
        [name]: {
          ...studentInfo[name], // 기존 값을 유지하면서
          [level]: value, // 특정 level에 해당하는 값을 업데이트
        },
      }));

      // modifiedFields 업데이트
      setModifiedFields((fields) => ({
        ...fields,
        [name]: {
          ...studentInfo[name], // studentInfo의 전체 필드를 복사
          [level]: value, // 특정 level에 해당하는 값을 업데이트
        },
      }));
      // 텍스트 영역의 높이 조정
      if (e.target.tagName.toLowerCase() === "textarea") {
        e.target.style.height = "auto"; // 높이를 초기화
        e.target.style.height = `${e.target.scrollHeight}px`; // 내용에 맞게 높이 조정
      }
    } else {
      if (name === "entrance_date") {
        formattedValue = convertToYearMonthDayFormat(value);
      }
      setStudentInfo((studentInfo) => ({
        ...studentInfo,
        [name]: formattedValue,
      }));

      // 변경된 필드 추적
      setModifiedFields((fields) => ({
        ...fields,
        [name]: formattedValue,
      }));
    }
  };

  const handlePostcode = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setStudentInfo((studentInfo) => ({
      ...studentInfo,
      zipcode: data.zonecode,
      address: fullAddress,
    }));

    // 변경된 필드 추적
    setModifiedFields((fields) => ({
      ...fields,
      zipcode: data.zonecode,
      address: fullAddress,
    }));

    setOpenPostCode(false);
    // console.log(fullAddress);
  };

  const handleSiblingSearch = (e) => {
    const { value } = e.target;
    setSiblingKey(value);
    if (value === "") {
      setFilteredStudentData([]);
    } else {
      const filteredData = studentData.filter(
        (row) => row.fullname.includes(value) && row.role === "student"
      );
      //   console.log("filteredRows", filteredRows);
      setFilteredStudentData(filteredData);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 변경된 부분이 없는지 확인
    if (Object.keys(modifiedFields).length === 0) {
      handleToastCenterTop(t("common.messages.noChange"));
      return;
    }

    setIsUpLoading(true);

    let dataToUpdate = {
      id: studentInfo.id,
      ...modifiedFields,
    };

    try {
      const folderSchool = studentInfo.schoolid + "/school-life-record";
      const folderUsername = studentInfo.username;
      const filePrefix = studentInfo.fullname + "_";

      // console.log("studentPhotoFile", studentPhotoFile);

      const studentPhotoLink = studentPhotoFile
        ? await uploadImage(
            studentPhotoFile,
            folderSchool,
            folderUsername,
            filePrefix
          )
        : "";

      // console.log("studentPhotoLink", studentPhotoLink);

      if (studentPhotoLink) {
        dataToUpdate = {
          ...dataToUpdate,
          studentPhoto_link: studentPhotoLink,
        };
      }

      const handleSuccess = (successMessage) => {
        console.log(successMessage);
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
          setModalOpen(false);
        }, 1000);
      };

      const handleError = (error) => {
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        alert(t("common.messages.errorAlert") + error.message);
      };

      // console.log("dataToUpdate", dataToUpdate);
      // console.log("modifiedFields2", modifiedFields);

      if (studentInfo.id) {
        updateStudentInfo.mutate(dataToUpdate, {
          onSuccess: handleSuccess,
          onError: handleError,
        });
      } else {
        createStudentInfo.mutate(
          { studentPhoto_link: studentPhotoLink, ...studentInfo },
          {
            onSuccess: handleSuccess,
            onError: handleError,
          }
        );
      }
    } catch (error) {
      console.error(error); // 일단 콘솔에 에러를 출력합니다.
      console.error("위와 같은 에러가 났으니 고치삼");
      alert(t("common.messages.errorAlert") + error.message);
    } finally {
      setIsUpLoading(false);
    }
  };

  const handleSiblingChange = (e) => {
    const { value } = e.target;

    if (value.length > 0) {
      const selectedOptionText = e.target.selectedOptions[0].text;

      setStudentInfo((studentInfo) => {
        const sibling_username = studentInfo.siblings || [];
        const exists = sibling_username.some(
          (r) => r.username === selectedOptionText
        );

        if (!exists) {
          const newSibling = { username: selectedOptionText, sname: value };
          const updatedSiblings = [...sibling_username, newSibling];

          // 변경된 필드 추적
          setModifiedFields((fields) => ({
            ...fields,
            siblings: updatedSiblings,
          }));

          return {
            ...studentInfo,
            siblings: updatedSiblings,
          };
        }

        return studentInfo;
      });
    }
  };

  const handleClick = (username) => {
    setStudentInfo((studentInfo) => {
      const updatedSiblings = studentInfo.siblings.filter(
        (sibling) => sibling.username !== username
      );

      // 변경된 필드 추적
      setModifiedFields((fields) => ({
        ...fields,
        siblings: updatedSiblings,
      }));

      return {
        ...studentInfo,
        siblings: updatedSiblings,
      };
    });
  };

  const handleImageChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (name === "studentPhotoImage") {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);

        setStudentPhotoFile(file);
        setModifiedFields((fields) => ({
          ...fields,
          studentPhoto_link: studentInfo?.studentPhoto_link
            ? studentInfo?.studentPhoto_link
            : "",
        }));
      } else {
        setPreviewImage(null);
      }
    }

    //그리고 여기서 setMyInfo()에도 넣어야겠다.
  };

  return (
    <>
      <div className="modal-container">
        <div
          className="modalForUnit"
          style={{ width: "90%", maxHeight: "95%", overflowY: "auto" }}
        >
          <div className="modal-header d-flex justify-content-between">
            <h3>{t("teacher.studentInfo.studentInfoModify")}</h3>
            <MdClose
              size={27}
              type="button"
              onClick={() => {
                setModalOpen(false);
              }}
            />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <div className="row mb-2 d-flex d-flex align-items-end">
                <div className="col-2">
                  <label htmlFor="studentPhotoImage" className="col-form-label">
                    {t("teacher.studentInfo.studentLifeRecordPhoto")}
                  </label>
                </div>
                <div className="col-6 ">
                  <input
                    type="file"
                    name="studentPhotoImage"
                    accept="image/*"
                    className="form-control"
                    onChange={handleImageChange}
                  />
                </div>
                <div className="col-3">
                  {previewImage ? (
                    <div className="d-flex justify-content-center">
                      <img
                        src={previewImage}
                        alt="preview"
                        style={{
                          width: "3.5cm",
                          height: "4.5cm",
                          maxWidth: "3.5cm",
                          maxHeight: "4.5cm",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  ) : studentInfo.studentPhoto_link ? (
                    studentInfo.studentPhoto_link && (
                      <div className="d-flex justify-content-center">
                        <img
                          src={studentInfo.studentPhoto_link}
                          alt="current studentPhoto"
                          style={{
                            width: "3.5cm",
                            height: "4.5cm",
                            maxWidth: "3.5cm",
                            maxHeight: "4.5cm",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    )
                  ) : (
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: "140px" }}
                    >
                      <p style={{ textAlign: "center", color: "gray" }}>
                        {t("teacher.studentInfo.photo")} <br />
                        3.5cm x 4.5cm
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-sm-2 col-4">
                  <label htmlFor="fullname" className="col-form-label">
                    {t("common.user.fullname") || "이름"}
                  </label>
                </div>
                <div className="col-sm-3 col-8">
                  <input
                    type="text"
                    name="fullname"
                    className="form-control"
                    value={studentInfo?.fullname}
                    disabled
                  />
                </div>

                <div className="col-sm-2 col-4">
                  <label htmlFor="username" className="col-form-label">
                    {t("common.user.username")}
                  </label>
                </div>
                <div className="col-sm-4 col-8">
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    value={studentInfo?.username}
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-sm-2 col-4">
                  <label htmlFor="korean_id_no" className="col-form-label">
                    {t("teacher.studentInfo.registerationNo")}
                  </label>
                </div>
                <div className="col-sm-3 col-8">
                  <input
                    type="text"
                    name="korean_id_no"
                    className="form-control"
                    value={studentInfo?.korean_id_no || ""}
                    onChange={handleModalChange}
                  />
                </div>

                <div className="col-sm-2 col-4">
                  <label htmlFor="entrance_date" className="col-form-label">
                    {t("teacher.studentInfo.entranceDate")}
                  </label>
                </div>
                <div className="col-sm-4 col-8">
                  <input
                    type="date"
                    name="entrance_date"
                    className="form-control"
                    value={studentInfo?.entrance_date || ""}
                    onChange={handleModalChange}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-sm-3 col-6">
                  <input
                    type="text"
                    name="zipcode"
                    className="form-control"
                    placeholder={t("teacher.studentInfo.zipcode")}
                    value={studentInfo?.zipcode || ""}
                    onChange={handleModalChange}
                  />
                </div>
                <div className="col-sm-3 col-6">
                  <input
                    type="button"
                    className="form-control bg-primary-subtle"
                    onClick={() => setOpenPostCode(true)}
                    value={t("teacher.studentInfo.zipcodeSearch")}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    placeholder={t("teacher.studentInfo.address")}
                    value={studentInfo?.address || ""}
                    onChange={handleModalChange}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    name="address_detail"
                    placeholder={t("teacher.studentInfo.addressDetail")}
                    className="form-control"
                    value={studentInfo?.address_detail || ""}
                    onChange={handleModalChange}
                  />
                </div>
              </div>

              {openPostCode && (
                <div className="row mb-2">
                  <div className="col-11 pe-0">
                    <DaumPostcodeEmbed
                      onComplete={handlePostcode}
                      style={{ width: "100%", height: 450, marginTop: 10 }}
                      className={"border"}
                      autoClose={false}
                    />
                  </div>
                  <div className="col-1 ps-1">
                    <MdOutlineClose
                      size={25}
                      role="button"
                      onClick={() => setOpenPostCode(false)}
                    />
                  </div>
                </div>
              )}
              <div className="row mb-2">
                <div className="col-sm-2 col-4">
                  <label htmlFor="phone_number" className="col-form-label">
                    {t("teacher.studentInfo.studentPhone")}
                  </label>
                </div>
                <div className="col-sm-3 col-8">
                  <input
                    type="text"
                    name="phone_number"
                    className="form-control"
                    value={studentInfo?.phone_number || ""}
                    onChange={handleModalChange}
                  />
                </div>

                <div className="col-sm-2 col-4 text-end">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => setOpenAddSiblings((open) => !open)}
                  >
                    {openAddSiblings
                      ? t("common.labels.close") || "닫기"
                      : t("teacher.studentInfo.siblings")}
                  </button>
                </div>
                <div className="col-sm-4 col-8">
                  <div className="border align-middle text-nowrap m-1 p-1">
                    {studentInfo?.siblings?.map((sibling, index) => (
                      <span key={index}>
                        {sibling.sname}
                        <MdOutlineCancel
                          role="button"
                          onClick={() => {
                            handleClick(sibling.username);
                          }}
                        />
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              {openAddSiblings && (
                <div className="row mb-2 justify-content-end pe-5 me-1">
                  <div className="col-sm-3 col-9">
                    <input
                      type="text"
                      name="sibling_fullname"
                      className="form-control"
                      onChange={handleSiblingSearch}
                      placeholder={t("teacher.studentInfo.inputSiblingName")}
                      value={siblingKey}
                    />
                  </div>
                  <div className="col-sm-4 col-9">
                    <select
                      className="form-control"
                      name="sibling_username"
                      onChange={handleSiblingChange}
                    >
                      <option value="">
                        {t("common.labels.select") || "선택"}
                      </option>
                      {filteredStudentData.length > 0 &&
                        filteredStudentData.map((user) => (
                          <option key={user.id} value={user.fullname}>
                            {user.username}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              )}

              <div className="row mb-2">
                <div className="col-sm-2 col-4">
                  <label htmlFor="father_name" className="col-form-label">
                    {t("teacher.studentInfo.fatherName")}
                  </label>
                </div>
                <div className="col-sm-3 col-8">
                  <input
                    type="text"
                    name="father_name"
                    className="form-control"
                    value={studentInfo?.father_name || ""}
                    onChange={handleModalChange}
                  />
                </div>

                <div className="col-sm-2 col-4">
                  <label htmlFor="father_phone" className="col-form-label">
                    {t("teacher.studentInfo.fatherPhone")}
                  </label>
                </div>
                <div className="col-sm-4 col-8">
                  <input
                    type="text"
                    name="father_phone"
                    className="form-control"
                    value={studentInfo?.father_phone || ""}
                    onChange={handleModalChange}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-sm-2 col-4">
                  <label htmlFor="mother_name" className="col-form-label">
                    {t("teacher.studentInfo.motherName")}
                  </label>
                </div>
                <div className="col-sm-3 col-8">
                  <input
                    type="text"
                    name="mother_name"
                    className="form-control"
                    value={studentInfo?.mother_name || ""}
                    onChange={handleModalChange}
                  />
                </div>

                <div className="col-sm-2 col-4">
                  <label htmlFor="mother_phone" className="col-form-label">
                    {t("teacher.studentInfo.motherPhone")}
                  </label>
                </div>
                <div className="col-sm-4 col-8">
                  <input
                    type="text"
                    name="mother_phone"
                    className="form-control"
                    value={studentInfo?.mother_phone || ""}
                    onChange={handleModalChange}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-sm-2 col-4">
                  <label htmlFor="memo" className="col-form-label">
                    {t("teacher.studentInfo.memo")}
                  </label>
                </div>
                <div className="col-sm-10 col-8">
                  <textarea
                    name="memo"
                    className="form-control"
                    rows="1"
                    value={studentInfo?.memo || ""}
                    onChange={handleModalChange}
                  />
                </div>
              </div>
              <div className="row mb-0 ms-1">
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th style={{ width: "13%" }}>
                        {t("teacher.studentInfo.forLifeRecord")}
                      </th>
                      <th style={{ width: "29%" }}>
                        {t("teacher.studentInfo.elementary")}
                      </th>
                      <th style={{ width: "29%" }}>
                        {t("teacher.studentInfo.middle")}
                      </th>
                      <th style={{ width: "29%" }}>
                        {t("teacher.studentInfo.high")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>{t("teacher.studentInfo.graduationNo")}</th>
                      <td>
                        <input
                          className="input-like-span"
                          type="text"
                          name="graduation_no"
                          data-level="elementary"
                          value={studentInfo?.graduation_no?.elementary || ""}
                          onChange={handleModalChange}
                        />
                      </td>
                      <td>
                        <input
                          className="input-like-span"
                          type="text"
                          data-level="middle"
                          name="graduation_no"
                          value={studentInfo?.graduation_no?.middle || ""}
                          onChange={handleModalChange}
                        />
                      </td>
                      <td>
                        <input
                          className="input-like-span"
                          type="text"
                          name="graduation_no"
                          data-level="high"
                          value={studentInfo?.graduation_no?.high || ""}
                          onChange={handleModalChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t("teacher.studentInfo.academicRecord")}</th>
                      <td>
                        <textarea
                          className="input-like-span"
                          ref={(el) => (textareaRefs.current[1] = el)}
                          name="academic_record"
                          data-level="elementary"
                          value={studentInfo?.academic_record?.elementary || ""}
                          onChange={handleModalChange}
                        />
                      </td>
                      <td>
                        <textarea
                          className="input-like-span"
                          ref={(el) => (textareaRefs.current[2] = el)}
                          name="academic_record"
                          data-level="middle"
                          value={studentInfo?.academic_record?.middle || ""}
                          onChange={handleModalChange}
                        />
                      </td>
                      <td>
                        <textarea
                          className="input-like-span"
                          ref={(el) => (textareaRefs.current[3] = el)}
                          name="academic_record"
                          data-level="high"
                          value={studentInfo?.academic_record?.high || ""}
                          onChange={handleModalChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t("common.labels.specialNote")}</th>
                      <td>
                        <textarea
                          className="input-like-span"
                          ref={(el) => (textareaRefs.current[4] = el)}
                          name="special_record"
                          data-level="elementary"
                          value={studentInfo?.special_record?.elementary || ""}
                          onChange={handleModalChange}
                        />
                      </td>
                      <td>
                        <textarea
                          className="input-like-span"
                          ref={(el) => (textareaRefs.current[5] = el)}
                          name="special_record"
                          data-level="middle"
                          value={studentInfo?.special_record?.middle || ""}
                          onChange={handleModalChange}
                        />
                      </td>
                      <td>
                        <textarea
                          className="input-like-span"
                          ref={(el) => (textareaRefs.current[6] = el)}
                          name="special_record"
                          data-level="high"
                          value={studentInfo?.special_record?.high || ""}
                          onChange={handleModalChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                {t("common.labels.cancel") || "취소"}
              </button>
              {isSaved ? (
                <button type="submit" className="btn btn-primary" disabled>
                  {t("common.labels.savedWell")}
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  {t("common.labels.doSave")}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
