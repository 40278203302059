import React from "react";
import { useTranslation } from "react-i18next";

export default function SchoolLifeRecordFormButtonBar({
  handleModifyMode,
  initiateApproval,
  handlePrint,
  adminMode,
  contentToPrint,
  modifyMode,
  showPageNumbers,
  setShowPageNumbers,
}) {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ width: "140px", fontSize: "13px" }} className="me-1">
        {/* 접속자: <span>테스트</span> <br /> 권한: <span>담임, 교감</span> */}
      </div>
      <div style={{ width: "110px" }} className="me-1 text-center">
        <button
          className="btn btn-warning d-none"
          type="button"
          onClick={adminMode}
          disabled={modifyMode}
        >
          {t("teacher.printingRelated.adminMode")}
        </button>
      </div>

      <div style={{ width: "100px" }} className="me-1 text-center">
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleModifyMode}
          name={modifyMode ? "modifyOn" : "modifyOff"}
        >
          {modifyMode
            ? t("teacher.printingRelated.modifyOff")
            : t("teacher.printingRelated.modifyOn")}
        </button>
      </div>
      {/* <div style={{ width: "100px" }} className="me-1 text-center">
        <button
          className="btn btn-secondary"
          type="button"
          name="approval"
          onClick={initiateApproval}
          disabled={modifyMode}
        >
          마감결재
        </button>
      </div>
      <div style={{ width: "130px" }} className="me-1 text-center">
        <button
          className="btn btn-secondary"
          type="button"
          name="bulkApproval"
          onClick={initiateApproval}
          disabled={modifyMode}
        >
          일괄마감결재
        </button>
      </div> */}
      <div style={{ width: "70px" }} className="me-1 text-center">
        <button
          className="btn btn-success"
          type="button"
          onClick={() => {
            handlePrint(null, () => contentToPrint.current);
          }}
          disabled={modifyMode}
        >
          {t("common.labels.print")}
        </button>
      </div>
      <div className="form-check d-flex flex-column align-items-center ps-0">
        <label className="form-check-label" htmlFor="pageNumbers">
          {t("teacher.printingRelated.pageNumbers")}
        </label>
        <input
          className="form-check-input m-auto"
          type="checkbox"
          id="pageNumbers"
          checked={showPageNumbers}
          onChange={(e) => setShowPageNumbers(e.target.checked)}
        />
      </div>
    </>
  );
}
