import React from "react";
import { useTranslation } from "react-i18next";

export default function AssignGroupsModal({
  setModalOpen,
  groupsData,
  setUserGroups,
  userGroups,
}) {
  const { t } = useTranslation();
  const handleGroupChange = (group) => {
    setUserGroups(
      (prevSelected) =>
        prevSelected.some((selectedGroup) => selectedGroup.id === group.id)
          ? prevSelected.filter(
              (selectedGroup) => selectedGroup.id !== group.id
            ) // 이미 선택된 경우 제거
          : [...prevSelected, { ...group, role: "member" }] // 선택되지 않은 경우 기본 역할 "member"로 추가
    );
  };

  const handleRoleChange = (groupId, role) => {
    setUserGroups((prevSelected) =>
      prevSelected.map((group) =>
        group.id === groupId ? { ...group, role } : group
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setModalOpen(false); // 모달 닫기
  };

  return (
    <div className="modal-container">
      <div
        className="modalForUnit"
        style={{ padding: "20px", maxWidth: "400px" }}
      >
        <div className="modal-header d-flex justify-content-between align-items-center">
          <h3 style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
            {t("common.uniComponents.assignGroups")}
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => setModalOpen(false)}
            style={{
              width: "20px", // 버튼 크기 조정
              height: "20px",
              padding: "0", // 내부 여백 제거
              marginTop: "-8px", // 오른쪽 마진을 조정해 제목과 더 가까워 보이도록
            }}
          ></button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            <p style={{ fontSize: "0.9rem", color: "#555" }}>
              {t("common.uniComponents.selectGroupAndRole")}
            </p>
            <ul className="list-unstyled">
              {groupsData.map((group) => (
                <li
                  key={group.id}
                  className="d-flex align-items-center mb-2"
                  style={{ gap: "10px" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`group-${group.id}`}
                    checked={userGroups.some(
                      (selectedGroup) => selectedGroup.id === group.id
                    )}
                    onChange={() => handleGroupChange(group)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`group-${group.id}`}
                    style={{ flexGrow: 1, marginBottom: "0" }}
                  >
                    {group.group_name}
                  </label>
                  <select
                    className="form-select form-select-sm"
                    style={{ width: "90px" }}
                    value={
                      userGroups.find(
                        (selectedGroup) => selectedGroup.id === group.id
                      )?.role || "member"
                    }
                    onChange={(e) => handleRoleChange(group.id, e.target.value)}
                    disabled={
                      !userGroups.some(
                        (selectedGroup) => selectedGroup.id === group.id
                      )
                    }
                  >
                    <option value="member">
                      {t("common.labels.member") || "멤버"}
                    </option>
                    <option value="leader">
                      {t("common.labels.leader") || "리더"}
                    </option>
                  </select>
                </li>
              ))}
            </ul>
          </div>
          <div className="modal-footer d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={() => setModalOpen(false)}
            >
              {t("common.labels.cancel") || "취소"}
            </button>
            <button type="submit" className="btn btn-primary">
              {t("admin.teacherInfo.assignGroup")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
