import React, { useEffect, useState } from "react";
import useEducationalActivities from "../../../hooks/useEducationalActivities";
import { useUIContext } from "../../../context/UIContext";
import { calculateTextNumber } from "../../../util/etCetera";
import { useTranslation } from "react-i18next";

export default function EbomAddActivitiesByFields({
  conditions,
  schoolYearId,
  schoolStageId,
  user,
  activeMenu,
  thisYearTerm,
  subjectSorts,
  selectedTerm,
}) {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    schoolid: conditions.schoolid,
    school_year_id: schoolYearId,
    hierarchy_one: "0",
    title: "",
    content: "",
    start_date: thisYearTerm[0]?.start_date || "",
    creator_username: user?.username,
    activity_type_id: 5,
    extra_attributes: {
      hours: "",
      textLength: "",
      termName: selectedTerm || "1",
      field: subjectSorts[0] || "",
    },
    tags: [],
  });
  //submit 누르면 로딩 뜨도록
  const { setIsLoadingModal } = useUIContext();

  //submit를 위한
  const { createEducationalActivities } = useEducationalActivities(conditions);

  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (selectedTerm) {
      setFormData((formData) => ({
        ...formData,
        extra_attributes: {
          ...formData.extra_attributes,
          termName: selectedTerm,
        },
      }));

      const thisDate = thisYearTerm.find(
        (r) => r.term_name.toString() === selectedTerm.toString()
      );

      setFormData((formData) => ({
        ...formData,
        start_date: thisDate?.start_date || "",
      }));
    }
  }, [selectedTerm, thisYearTerm]);

  const handleInputChange = (event) => {
    const { name, value, id } = event.target;

    if (name === "extra_attributes") {
      setFormData((formData) => ({
        ...formData,
        [name]: {
          ...formData[name],
          [id]: value,
        },
      }));

      // if (id === "termName") {
      //   const thisDate = thisYearTerm.find(
      //     (r) => r.term_name.toString() === value.toString()
      //   );

      //   setFormData((formData) => ({
      //     ...formData,
      //     start_date: thisDate?.start_date || "",
      //   }));
      // }
    } else {
      setFormData((formData) => ({
        ...formData,
        [name]: value,
      }));

      if (name === "content") {
        setFormData((formData) => ({
          ...formData,
          textLength: calculateTextNumber(value),
        }));
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Process the formData here

    const submitData = {
      ...formData,
      school_year_id: schoolYearId,
      hierarchy_one: schoolStageId,
    };

    setIsLoadingModal(true);
    createEducationalActivities.mutate(submitData, {
      onSuccess: () => {
        setIsLoadingModal(false);
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
        }, 2000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error?.message);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit} className="container-fluid">
      <h3 className="fw-bold">영역별 학습내용 생성</h3>
      <table
        className="table"
        style={
          activeMenu?.options?.length > 0 &&
          activeMenu?.options[0]?.[conditions.schoolid]
            ? { fontSize: "smaller" }
            : {}
        }
      >
        <thead>
          <tr className="table-secondary">
            <th style={{ width: "100px" }}>
              {t("teacher.createActivities.field")}
            </th>
            <th style={{ minWidth: "80px", width: "160px" }}>
              {t("common.labels.activityName")}
            </th>
            {/* <th style={{ minWidth: "60px", width: "70px" }}>학기</th> */}
            {/* <th style={{ width: "100px" }}>기준날짜</th> */}
            <th style={{ width: "80px" }}>{t("common.labels.hours")}</th>
            <th style={{ minWidth: "260px" }}>세부내용</th>
            <th style={{ width: "60px" }}>
              {t("common.labels.add") || "추가"}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <select
                className="select-like-span"
                name="extra_attributes"
                id="field"
                value={formData.extra_attributes?.field}
                onChange={handleInputChange}
              >
                {subjectSorts.map((field, key) => (
                  <option key={key} value={field}>
                    {field}
                  </option>
                ))}
              </select>
            </td>
            <td className="p-0">
              <textarea
                type="text"
                className="input-like-span"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                rows={4}
                required
              />
            </td>
            {/* <td>
              <select
                className="select-like-span"
                name="extra_attributes"
                id="termName"
                value={formData.extra_attributes?.termName}
                onChange={handleInputChange}
              >
                {thisYearTerm &&
                  thisYearTerm.map((arr, key) => (
                    <option key={key} value={arr.term_name}>
                      {arr.term_name}
                    </option>
                  ))}
              </select>
            </td> */}
            {/* <td>
              <input
                type="date"
                className="input-like-span"
                name="start_date"
                value={formData.start_date}
                required
                disabled
                style={{ width: "105px" }}
              />
            </td> */}
            <td>
              <input
                type="text"
                className="input-like-span"
                name="extra_attributes"
                id="hours"
                value={formData.extra_attributes?.hours}
                onChange={handleInputChange}
              />
            </td>

            <td className="p-0">
              <textarea
                type="text"
                className="input-like-span"
                name="content"
                value={formData.content}
                onChange={handleInputChange}
                rows={4}
              />
            </td>
            <td width={60} rowSpan={2} valign="middle">
              <button type="submit" className="btn btn-sm p-1 btn-primary">
                {isSaved
                  ? t("common.labels.complete") || "완료"
                  : t("common.labels.add") || "추가"}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
}
