import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import UserService from "../service/user";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";
import React from "react";

const baseURL = process.env.REACT_APP_BASE_URL;

//서버의 테이블이름을 기반으로 커스텀훅을 만들기로 했음.
//(근데 최초에 만든 useDeveloper와 useUniComponent는 제외)
//해당 테이블과 통신하는 것만 여기 들어감.
//조인할 경우에는 주 테이블이 있는 곳에 작성함. 중복을 피하기 위함.
export default function useUsers(conditions, filterConditions = null) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();
  const { user, address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const userService = new UserService(httpClient);

  let userId = {};

  if (user) {
    const { id } = user;
    userId = { id };
  }

  const usersQuery = useQuery(
    ["usersConditions", conditions || ""],
    () => userService.findAllUsersByConditions(conditions),
    {
      enabled: !!conditions?.schoolid,
      staleTime: 1000 * 60 * 1, //1분,
      select: filterConditions
        ? (data) =>
            data.filter(
              (user) =>
                user.role === filterConditions.role &&
                user.status === filterConditions.status
            )
        : (data) => data, // 필터링 조건이 있으면 필터 적용, 없으면 전체 데이터 반환
    }
  );

  const sortedData = React.useMemo(() => {
    if (usersQuery.data) {
      return [...usersQuery.data].sort((a, b) =>
        a.fullname.localeCompare(b.fullname)
      );
    }
    return [];
  }, [usersQuery.data]);

  // 보내는 데이터
  // {username, password, schoolid, role, fullname, birthday, gender, status }
  const createUser = useMutation(
    (userInfo) => userService.createUser(userInfo),
    {
      onSuccess: () => queryClient.invalidateQueries(["usersConditions"]),
    }
  );

  const signupUser = useMutation(
    (userInfo) => userService.signupUser(userInfo),
    {
      onSuccess: () => queryClient.invalidateQueries(["usersConditions"]),
    }
  );

  const updateUser = useMutation(
    (userInfo) => userService.updateUser(userInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["usersConditions"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating user:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const deleteUser = useMutation((id) => userService.deleteUser(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["usersConditions"]);
    },
    onError: (error) => {
      if (error.response?.status === 400) {
        alert(error.response.data.message);
      } else {
        alert(
          `사용자 삭제 중 오류가 발생했습니다. (error while deleting user): ${
            error.message || error
          }`
        );
      }
      console.error("Error deleting user:", error);
    },
  });

  //이거 users와 teacherinfo를 합쳐서 가져오는 것으로... (티처 인포 합치는 건 나중에 개발)
  const userTeacherInfoQuery = useQuery(
    ["userId"],
    () => userService.userTeacherInfoQuery(userId),
    {
      enabled: !!userId.id,
      // staleTime: 1000 * 30 * 1, //30초,
    }
  );

  // 새로운 username 중복 체크 mutation 추가
  const checkNewUsername = useMutation(
    (username) => userService.checkNewUsername(username),
    {
      onError: (error) => {
        console.error("사용자명 중복 확인 중 오류 발생:", error);
        throw error;
      },
    }
  );

  // 새로운 schoolid 중복 체크 mutation 추가
  const checkSchoolId = useMutation(
    (schoolid) => userService.checkSchoolId(schoolid),
    {
      onError: (error) => {
        console.error("학교 아이디 존재 여부 파악 중:", error);
        throw error;
      },
    }
  );

  const userGuardianQuery = useQuery(
    ["guardian"],
    () => userService.userGuardianQuery({ username: user.username }),
    {
      enabled: !!user?.username && !!conditions?.guardian,
      staleTime: 1000 * 30 * 1, //30초,
    }
  );

  const linkRequest = useMutation(
    (userInfo) => userService.linkRequest(userInfo),
    {
      onSuccess: () => queryClient.invalidateQueries(["guardian"]),
    }
  );

  const cancelLinkRequest = useMutation(
    (userInfo) => userService.cancelLinkRequest(userInfo),
    {
      onSuccess: () => queryClient.invalidateQueries(["guardian"]),
    }
  );

  const updateUserEmail = useMutation(
    (userInfo) => userService.updateUserEmail(userInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["usersConditions"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating user:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  // 이메일로 아이디 찾기
  const findUsernameByEmail = useMutation(
    (email) => userService.findUsernameByEmail(email),
    {
      onError: (error) => {
        console.error("아이디 찾기 중 오류 발생:", error);
        throw error;
      },
    }
  );

  const resetPassword = useMutation(
    (userInfo) => userService.resetPassword(userInfo),
    {
      onSuccess: () => queryClient.invalidateQueries(["usersConditions"]),
    }
  );

  return {
    usersQuery: { ...usersQuery, data: sortedData },
    createUser,
    updateUser,
    deleteUser,
    userTeacherInfoQuery,
    checkNewUsername,
    checkSchoolId,
    signupUser,
    userGuardianQuery,
    linkRequest,
    cancelLinkRequest,
    updateUserEmail,
    findUsernameByEmail,
    resetPassword,
  };
}
