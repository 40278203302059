import React, { useState } from "react";
import { findDuplicateData } from "../../../util/dealingArrObj";
import useRegistrationInfo from "../../../hooks/useRegistrationInfo";
import { useTranslation } from "react-i18next";

export default function UpdateRegiModal({
  setModalOpen,
  updateRegi,
  setUpdateRegi,
  idsForKey,
  filteredSetting,
  filterModal,
  setFilterModal,
  setIsUpLoading,
  flexibleData,
}) {
  const [isSaved, setIsSaved] = useState(false);
  const { t } = useTranslation();
  const { updateRegistrationInfo } = useRegistrationInfo();

  const flexibleDataValues = flexibleData[0]?.data_value || {};

  const handleModalChange = (e) => {
    // console.log(e);
    const { name, value } = e.target;
    // const selectedOptionText = e.target.selectedOptions[0].text;

    const keys = Object.keys(idsForKey);
    const maxKey = Math.max(...keys.map(Number));

    if (maxKey.toString() === name.toString()) {
      const organPath =
        e.target.selectedOptions[0].getAttribute("data-name") + "/" + value;

      setUpdateRegi((updateRegi) => ({
        ...updateRegi,
        organization_path: organPath,
      }));
    } else {
      setUpdateRegi((updateRegi) => ({ ...updateRegi, [name]: value }));
    }

    if (!isNaN(Number(name))) {
      setFilterModal((prevFilter) => {
        const newFilter = { ...prevFilter, [name]: value };
        //   console.log("newFilter", newFilter);
        Object.keys(newFilter).forEach((key) => {
          if (Number(key) > Number(name)) {
            delete newFilter[key];
          }
        });
        // console.log("newFilter", newFilter);
        return newFilter;
      });
    }

    // console.log("updateRegi", updateRegi);
    // console.log("filterModal", filterModal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsUpLoading(true);

    const isDuplicate = findDuplicateData(filteredSetting, updateRegi, [
      "school_year",
      "parent_id",
      "group_name",
    ]);

    if (isDuplicate) {
      alert(t("teacher.registrationInfo.alertDuplicate"));
      return;
    }

    updateRegistrationInfo.mutate(updateRegi, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
          setModalOpen(false);
        }, 1000);
        setIsUpLoading(false);
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  return (
    <>
      <div className="modal-container">
        <div
          className="modalForUnit"
          style={{ width: "600px", maxHeight: "700px", overflowY: "auto" }}
        >
          <div className="modal-header">
            <h3>{t("teacher.registrationInfo.modifyRegistration")}</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              {filteredSetting?.length > 0 &&
                Object.keys(idsForKey).map((item, index) => {
                  //   console.log(item);
                  if (item === "0") return null;
                  return (
                    <div className="row mb-1" key={item}>
                      <div className="col-3">
                        <label htmlFor="group_name" className="col-form-label">
                          {idsForKey[item][0]}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <select
                          key={item}
                          name={item}
                          className="form-control"
                          onChange={handleModalChange}
                          defaultValue={filterModal[item]}
                        >
                          <option value="" data-name="">
                            {idsForKey[item][0]}
                          </option>
                          {filteredSetting
                            .filter(
                              (r) =>
                                r.parent_id ===
                                Number(
                                  filterModal[(Number(item) - 1).toString()]
                                )
                            )
                            .sort((a, b) => {
                              const aValue = a["order"];
                              const bValue = b["order"];
                              if (
                                typeof aValue === "number" &&
                                typeof bValue === "number"
                              ) {
                                // 숫자인 경우
                                return aValue - bValue;
                              } else {
                                // 문자열인 경우
                                return aValue.localeCompare(bValue);
                              }
                            })
                            .map((option) => (
                              <option
                                key={option.id}
                                value={option.id}
                                data-name={option.organization_path}
                              >
                                {option.group_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  );
                })}

              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="fullname" className="col-form-label">
                    {t("common.user.fullname") || "이름"}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="fullname"
                    className="form-control"
                    value={updateRegi?.fullname || ""}
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="username" className="col-form-label">
                    {t("common.user.username") || "사용자계정"}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    value={updateRegi?.username || ""}
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="studentno" className="col-form-label">
                    {t("common.user.studentNo")}
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="studentno"
                    className="form-control"
                    onChange={handleModalChange}
                    defaultValue={updateRegi?.studentno}
                  />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="status" className="col-form-label">
                    {t("common.user.status")}
                  </label>
                </div>
                <div className="col-8">
                  <select
                    required
                    name="status"
                    className="form-control"
                    onChange={handleModalChange}
                    defaultValue={updateRegi?.status}
                  >
                    <option value="재학중">
                      {t("teacher.registrationInfo.inSchool")}
                    </option>
                    <option value="비재학중">
                      {t("teacher.registrationInfo.outSchool")}
                    </option>
                  </select>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="lastattended" className="col-form-label">
                    {t("teacher.registrationInfo.lastAttended")}
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="date"
                    name="lastattended"
                    className="form-control"
                    onChange={handleModalChange}
                    defaultValue={updateRegi?.lastattended}
                  />
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="attendance_remark" className="col-form-label">
                    {t("teacher.registrationInfo.attendanceRemark")}
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="attendance_remark"
                    className="form-control"
                    onChange={handleModalChange}
                    defaultValue={updateRegi?.attendance_remark}
                  />
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="future_plan" className="col-form-label">
                    {t("teacher.registrationInfo.futurePlan")}
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="future_plan"
                    className="form-control"
                    onChange={handleModalChange}
                    defaultValue={updateRegi?.future_plan}
                  />
                </div>
              </div>

              {flexibleDataValues?.extra_school_stage && (
                <div className="row mb-1">
                  <div className="col-3">
                    <label
                      htmlFor="extra_school_stage"
                      className="col-form-label"
                    >
                      표시용_과정
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name="extra_school_stage"
                      className="form-control"
                      onChange={handleModalChange}
                      defaultValue={updateRegi?.extra_school_stage}
                    />
                  </div>
                </div>
              )}

              {flexibleDataValues?.extra_student_year && (
                <div className="row mb-1">
                  <div className="col-3">
                    <label
                      htmlFor="extra_student_year"
                      className="col-form-label"
                    >
                      표시용_학년
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name="extra_student_year"
                      className="form-control"
                      onChange={handleModalChange}
                      defaultValue={updateRegi?.extra_student_year}
                    />
                  </div>
                </div>
              )}

              {flexibleDataValues?.extra_class && (
                <div className="row mb-1">
                  <div className="col-3">
                    <label htmlFor="extra_class" className="col-form-label">
                      표시용_반
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name="extra_class"
                      className="form-control"
                      onChange={handleModalChange}
                      defaultValue={updateRegi?.extra_class}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                {t("common.labels.cancel") || "취소"}
              </button>
              {isSaved ? (
                <button type="submit" className="btn btn-primary" disabled>
                  {t("common.labels.savedWell")}
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  {t("common.labels.doSave")}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
