import React, { useEffect, useRef, useState } from "react";
import useAttendance from "../../../../../hooks/useAttendance";
import { useTranslation } from "react-i18next";
import DynamicTable from "../../../../../unicomponents/Tables/DynamicTable";
export default function StudentAttendance({ keyData, currentPeriod }) {
  const [termAttendanceArray, setTermAttendanceArray] = useState([]);

  // console.log("keyData", keyData);
  // console.log("currentPeriod", currentPeriod);

  const [dataRow, setDataRow] = useState({
    attendedDays: "",
    sickAbsence: "",
    justAbsence: "",
    etcAbsence: "",
    sickTardiness: "",
    justTardiness: "",
    etcTardiness: "",
    sickLeave: "",
    justLeave: "",
    etcLeave: "",
    sickNoclass: "",
    justNoclass: "",
    etcNoclass: "",
  });

  const { attendanceQuery } = useAttendance();

  const attendanceQueryRef = useRef(attendanceQuery);

  const { t } = useTranslation();

  // 테이블 설정
  const tableConfig = {
    style: { fontSize: "14px" }, // 폰트 크기만 커스텀
    headers: [
      {
        label: t("student.attendance.date"),
        style: { width: "200px" },
        sortField: "date",
      },
      {
        label: t("student.attendance.attendance"),
        style: { width: "150px" },
        sortField: "attendance_status",
      },
    ],
    columns: [
      {
        valueKey: "date",
        valueFormatter: (value) => {
          // 날짜 형식 변환 (YYYY-MM-DD → YYYY.MM.DD)
          if (!value) return "";
          const date = new Date(value);
          if (isNaN(date.getTime())) return value; // 유효하지 않은 날짜면 원래 값 반환

          // YYYY.MM.DD 형식으로 변환
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          return `${year}.${month}.${day}`;
        },
        cellStyle: { textAlign: "center" },
      },
      {
        valueKey: "attendance_status",
        valueFormatter: (value) => {
          // 출석 상태 코드를 텍스트로 변환
          const statusMap = {
            "": t("common.messages.noData"),
            출석: t("student.attendance.present"),
            질결: t("student.attendance.sickAbsence"),
            무결: t("student.attendance.justAbsence"),
            기결: t("student.attendance.etcAbsence"),
            질지: t("student.attendance.sickTardiness"),
            무지: t("student.attendance.justTardiness"),
            기지: t("student.attendance.etcTardiness"),
            질조: t("student.attendance.sickLeave"),
            무조: t("student.attendance.justLeave"),
            기조: t("student.attendance.etcLeave"),
            질결과: t("student.attendance.sickNoClass"),
            무결과: t("student.attendance.justNoClass"),
            기결과: t("student.attendance.etcNoClass"),
            출인결: t("student.attendance.noAbsence"),
            출인지: t("student.attendance.noTardiness"),
            출인조: t("student.attendance.noLeave"),
            출인결과: t("student.attendance.noNoClass"),
          };

          return statusMap[value] || value;
        },
        cellStyle: { textAlign: "center" },
      },
    ],
    rows: termAttendanceArray,
  };

  useEffect(() => {
    if (
      keyData?.username &&
      currentPeriod &&
      Object.keys(currentPeriod).length > 0
    ) {
      const forWhere = {
        schoolid: keyData?.schoolid,
        username: keyData?.username,
        startdate: currentPeriod.start_date,
        enddate: currentPeriod.end_date,
      };

      attendanceQueryRef.current.mutate(forWhere, {
        onSuccess: (data) => {
          if (data && data.length > 0) {
            const filteredAttendance = data.filter(
              (r) => r.attendance_status !== ""
            );

            const totalClassDays = filteredAttendance.filter(
              (r) => r.attendance_status !== ""
            ).length;

            setTermAttendanceArray(filteredAttendance);

            const attendedDays = filteredAttendance.filter(
              (r) =>
                r.attendance_status !== "질결" &&
                r.attendance_status !== "무결" &&
                r.attendance_status !== "기결" &&
                r.attendance_status !== ""
            ).length;
            const sickAbsence = filteredAttendance.filter(
              (r) => r.attendance_status === "질결"
            ).length;
            const justAbsence = filteredAttendance.filter(
              (r) => r.attendance_status === "무결"
            ).length;
            const etcAbsence = filteredAttendance.filter(
              (r) => r.attendance_status === "기결"
            ).length;
            const sickTardiness = filteredAttendance.filter(
              (r) => r.attendance_status === "질지"
            ).length;
            const justTardiness = filteredAttendance.filter(
              (r) => r.attendance_status === "무지"
            ).length;
            const etcTardiness = filteredAttendance.filter(
              (r) => r.attendance_status === "기지"
            ).length;
            const sickLeave = filteredAttendance.filter(
              (r) => r.attendance_status === "질조"
            ).length;
            const justLeave = filteredAttendance.filter(
              (r) => r.attendance_status === "무조"
            ).length;
            const etcLeave = filteredAttendance.filter(
              (r) => r.attendance_status === "기조"
            ).length;
            const sickNoclass = filteredAttendance.filter(
              (r) => r.attendance_status === "질결과"
            ).length;
            const justNoclass = filteredAttendance.filter(
              (r) => r.attendance_status === "무결과"
            ).length;
            const etcNoclass = filteredAttendance.filter(
              (r) => r.attendance_status === "기결과"
            ).length;

            setDataRow({
              totalClassDays: totalClassDays,
              attendedDays: attendedDays,
              sickAbsence: sickAbsence,
              justAbsence: justAbsence,
              etcAbsence: etcAbsence,
              sickTardiness: sickTardiness,
              justTardiness: justTardiness,
              etcTardiness: etcTardiness,
              sickLeave: sickLeave,
              justLeave: justLeave,
              etcLeave: etcLeave,
              sickNoclass: sickNoclass,
              justNoclass: justNoclass,
              etcNoclass: etcNoclass,
            });
          } else {
            setTermAttendanceArray([]);
            setDataRow({
              totalClassDays: "",
              attendedDays: "",
              sickAbsence: "",
              justAbsence: "",
              etcAbsence: "",
              sickTardiness: "",
              justTardiness: "",
              etcTardiness: "",
              sickLeave: "",
              justLeave: "",
              etcLeave: "",
              sickNoclass: "",
              justNoclass: "",
              etcNoclass: "",
            });
          }
        },
        onError: (error) => {
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(t("common.messages.errorAlert") + error.message);
        },
      });
    } else {
      setTermAttendanceArray([]);
      setDataRow({
        totalClassDays: "",
        attendedDays: "",
        sickAbsence: "",
        justAbsence: "",
        etcAbsence: "",
        sickTardiness: "",
        justTardiness: "",
        etcTardiness: "",
        sickLeave: "",
        justLeave: "",
        etcLeave: "",
        sickNoclass: "",
        justNoclass: "",
        etcNoclass: "",
      });
    }
  }, [currentPeriod, keyData?.username, keyData?.schoolid, t]);

  return (
    <div style={{ maxWidth: "1000px" }}>
      <div className="ms-5">
        <h3>{t("student.attendance.title")}</h3>
      </div>

      <div className="row mt-5">
        <div className="col-6">
          <DynamicTable
            tableConfig={tableConfig}
            dataStructure="flat"
            tableStyle="cleanModern"
            // 페이지네이션 활성화
            // pagination={true}
            // pageSize={5} // 페이지당 5개 항목 표시
            // initialPage={1} // 초기 페이지 설정
            // 정렬 활성화
            sortable={true}
            initialSortField="date"
            initialSortDirection="asc"
            // 검색 기능 활성화
            // searchable={true}
            // searchPlaceholder="날짜, 출석 검색..."
            // searchFields={[
            //   "date", // input 필드
            //   "attendance_status",
            // ]}
            // Excel 내보내기 기능 활성화
            // exportable={true}
            // exportFileName="출석이당"
            // exportButtonLabel="Excel"
          />
        </div>
        <div className="col-5">
          <div className="card" style={{ fontSize: "14px" }}>
            <div className="card-header bg-primary text-white">
              <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                {t("student.attendance.statistics")}
              </span>
            </div>
            <div className="card-body p-0">
              <table className="table table-bordered mb-0">
                <tbody>
                  <tr>
                    <td className="text-center fw-bold bg-light">
                      {t("student.attendance.present")}
                    </td>
                    <td className="text-center">{dataRow.attendedDays || 0}</td>
                  </tr>
                  <tr>
                    <td className="text-center fw-bold bg-light">
                      {t("student.attendance.sickAbsence")}
                    </td>
                    <td className="text-center">{dataRow.sickAbsence || 0}</td>
                  </tr>
                  <tr>
                    <td className="text-center fw-bold bg-light">
                      {t("student.attendance.justAbsence")}
                    </td>
                    <td className="text-center">{dataRow.justAbsence || 0}</td>
                  </tr>
                  <tr>
                    <td className="text-center fw-bold bg-light">
                      {t("student.attendance.etcAbsence")}
                    </td>
                    <td className="text-center">{dataRow.etcAbsence || 0}</td>
                  </tr>
                  <tr>
                    <td className="text-center fw-bold bg-light">
                      {t("student.attendance.sickTardiness")}
                    </td>
                    <td className="text-center">
                      {dataRow.sickTardiness || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center fw-bold bg-light">
                      {t("student.attendance.justTardiness")}
                    </td>
                    <td className="text-center">
                      {dataRow.justTardiness || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center fw-bold bg-light">
                      {t("student.attendance.etcTardiness")}
                    </td>
                    <td className="text-center">{dataRow.etcTardiness || 0}</td>
                  </tr>
                  <tr>
                    <td className="text-center fw-bold bg-light">
                      {t("student.attendance.sickLeave")}
                    </td>
                    <td className="text-center">{dataRow.sickLeave || 0}</td>
                  </tr>
                  <tr>
                    <td className="text-center fw-bold bg-light">
                      {t("student.attendance.justLeave")}
                    </td>
                    <td className="text-center">{dataRow.justLeave || 0}</td>
                  </tr>
                  <tr>
                    <td className="text-center fw-bold bg-light">
                      {t("student.attendance.etcLeave")}
                    </td>
                    <td className="text-center">{dataRow.etcLeave || 0}</td>
                  </tr>
                  <tr>
                    <td className="text-center fw-bold bg-light">
                      {t("student.attendance.sickNoClass")}
                    </td>
                    <td className="text-center">{dataRow.sickNoclass || 0}</td>
                  </tr>
                  <tr>
                    <td className="text-center fw-bold bg-light">
                      {t("student.attendance.justNoClass")}
                    </td>
                    <td className="text-center">{dataRow.justNoclass || 0}</td>
                  </tr>
                  <tr>
                    <td className="text-center fw-bold bg-light">
                      {t("student.attendance.etcNoClass")}
                    </td>
                    <td className="text-center">{dataRow.etcNoclass || 0}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="card-footer bg-light text-center">
              <small className="text-muted">
                {t("student.attendance.tardinessLeaveIncluded")}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
