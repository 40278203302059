import React, { useEffect, useState } from "react";
import DropdownSchoolYear from "../../../unicomponents/Dropdowns/DropdownSchoolYear";
import DropdownUniqueItems from "../../../unicomponents/Dropdowns/DropdownUniqueItems";
import { useUIContext } from "../../../context/UIContext";
import { useAuthContext } from "../../../context/AuthContext";
import SimpleAlertMessageBlock from "../../../unicomponents/SimpleAlertMessageBlock";
import useFlexibleData from "../../../hooks/useFlexibleData";
import useTermPeriod from "../../../hooks/useTermPeriod";
import { calculateTextNumber } from "../../../util/etCetera";
import { useTranslation } from "react-i18next";

export default function PrincipalComment({
  settingData,
  schoolId,
  variousRecordInfo,
}) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    schoolYear: null,
    schoolYearId: "",
    termName: "",
  });
  const [rows, setRows] = useState({});
  const [hierarchyOneList, setHierarchyOneList] = useState([]);

  const {
    flexibleDataQuery: { data: flexibleData },
    createOrUpdateFlexibleData,
  } = useFlexibleData({
    schoolid: schoolId,
    main_category: "principalComment",
  });

  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod({ schoolid: schoolId });

  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();

  const { hasPermission } = useAuthContext();

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;

    if (filters.schoolYear === null && settingData && settingData.length > 0) {
      const initialData = settingData.sort(
        (a, b) => b.school_year - a.school_year
      )[0];

      initialSchoolYear = initialData.school_year;
      initialSchoolYearId = initialData.id;
      setFilters((filters) => ({
        ...filters,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
      }));

      const hierarchyOnes = settingData.filter(
        (r) => r.hierarchy_level === 1 && r.school_year === initialSchoolYear
      );
      setHierarchyOneList(hierarchyOnes);
    }
  }, [filters.schoolYear, settingData]);

  useEffect(() => {
    if (flexibleData) {
      const foundRow = flexibleData.find(
        (r) =>
          r.school_year_id === filters.schoolYearId &&
          r.sub_category === filters.termName
      );
      setRows(foundRow || {});
    }
  }, [filters.schoolYearId, filters.termName, flexibleData]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;

    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        termName: "",
      }));
      setRows({});
      const hierarchyOnes = settingData.filter(
        (r) => r.hierarchy_level === 1 && r.school_year === selectedOptionText
      );
      setHierarchyOneList(hierarchyOnes);
    }

    if (name === "termName") {
      setFilters((filters) => ({
        ...filters,
        termName: value,
      }));
      // const foundRow = flexibleData.find(
      //   (r) =>
      //     r.school_year_id === filters.schoolYearId && r.sub_category === value
      // );
      // setRows(foundRow || {});
    }
  };

  const handleSaveData = (e, row) => {
    if (filters.termName.length < 1) {
      handleToastCenterTop(t("teacher.variousRecords.selectTermToinput"));
      return;
    }

    const { name, value } = e.target; // input의 name과 value 가져오기

    setRows((prevRows) => {
      if (!prevRows.data_value) {
        // prevRows가 초기 상태인 경우 data_value 초기화
        return {
          ...prevRows,
          data_value: [{ hierarchyOne: row.id, [name]: value }],
        };
      }

      // data_value에서 hierarchyOne이 일치하는 항목 찾기
      const existingItem = prevRows.data_value.find(
        (item) => item.hierarchyOne === row.id
      );

      if (existingItem) {
        // 일치하는 항목이 있으면 업데이트
        return {
          ...prevRows,
          data_value: prevRows.data_value.map((item) =>
            item.hierarchyOne === row.id ? { ...item, [name]: value } : item
          ),
        };
      } else {
        // 일치하는 항목이 없으면 새 항목 추가
        return {
          ...prevRows,
          data_value: [
            ...prevRows.data_value,
            { hierarchyOne: row.id, [name]: value },
          ],
        };
      }
    });
  };

  const handleSaveClick = () => {
    // console.log("row", row);
    // console.log("dataToSave", dataToSave);

    if (filters.termName.length < 1) {
      handleToastCenterTop(t("teacher.variousRecords.selectTermToSave"));
      return;
    }

    if (rows.length < 1) {
      handleToastCenterTop(t("teacher.variousRecords.noContent"));
      return;
    }

    const trimmedDataToSave = rows?.data_value?.map((item) => {
      return {
        ...item,
        writer:
          typeof item.writer === "string" ? item.writer.trim() : item.writer,
      };
    });

    if (!hasPermission(null, "can_create")) {
      handleToastCenterTop(t("common.messages.noSavePermission"));
      return;
    }

    // 상위 수준 데이터 추가
    const finalDataToSave = {
      ...(rows.id ? { id: rows.id } : {}), // id가 있으면 포함 (update를 위한 데이터)
      schoolid: schoolId,
      school_year_id: filters.schoolYearId,
      main_category: "principalComment",
      sub_category: filters.termName,
      data_value: trimmedDataToSave,
    };

    setIsLoadingModal(true);
    createOrUpdateFlexibleData.mutate(finalDataToSave, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        handleToastCenterTop(t("common.messages.savedSuccessfully"));
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-6"></div>
        <div className="col">
          <DropdownSchoolYear
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYearId={filters.schoolYearId}
            allYears={false}
          />
        </div>
        <div className="col">
          {termPeriodData && (
            <DropdownUniqueItems
              displayData={termPeriodData}
              displayKey="term_name"
              valueKey="term_name"
              uniqueKey="term_name"
              sortKey="term_name"
              isDesc={false}
              handleDropdownChange={handleDropdownChange}
              selectedItem={filters.termName}
              selectName="termName"
              dropdownTitle={t("common.labels.termName")}
            />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className="d-flex justify-content-between">
          <h3 className="fw-bold">
            {variousRecordInfo && variousRecordInfo.record_label}
          </h3>
          <div
            className="ms-2"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {variousRecordInfo?.description &&
              ` (${variousRecordInfo.description})`}
          </div>
        </div>
        <div>
          <button className="btn btn-primary" onClick={handleSaveClick}>
            {t("common.labels.save")}
          </button>
        </div>
      </div>
      <div className="row mt-3">
        {!hasPermission(null, "can_read") ? (
          <SimpleAlertMessageBlock
            message={t("common.messages.noReadPermission")}
          />
        ) : (
          <div className="col" style={{ minWidth: "800px", overflowX: "auto" }}>
            <table
              className="table table-bordered text-center"
              style={{ fontSize: "13px" }}
            >
              <thead>
                <tr className="table-secondary">
                  <th className="p-1 align-middle" style={{ minWidth: "50px" }}>
                    {t("common.labels.schoolStage")}
                  </th>
                  <th
                    className="p-1 align-middle"
                    style={{ minWidth: "100px" }}
                  >
                    {t("common.labels.content")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {hierarchyOneList &&
                  hierarchyOneList.map((row) => {
                    // 캐싱: 해당 row.id에 해당하는 데이터 찾기
                    const currentRow =
                      rows?.data_value?.find(
                        (r) => r.hierarchyOne === row.id
                      ) || {};

                    return (
                      <tr key={row.id}>
                        <td
                          className="align-middle p-1"
                          style={{ minWidth: "50px" }}
                        >
                          {row.group_name || ""}
                        </td>
                        <td
                          className="align-middle p-1 text-end"
                          style={{ minWidth: "100px" }}
                        >
                          <div className="d-flex justify-content-between">
                            <div className="mb-2 ms-2">
                              {t("common.user.fullname") || "이름"}:{" "}
                              <input
                                type="text"
                                onChange={(e) => handleSaveData(e, row)}
                                value={currentRow.writer || ""}
                                name="writer"
                              />
                            </div>

                            <span className="me-2">
                              {t("common.labels.textLength")} :{" "}
                              {currentRow?.content &&
                                calculateTextNumber(currentRow.content)}
                            </span>
                          </div>
                          <textarea
                            className="form-control"
                            onChange={(e) => handleSaveData(e, row)}
                            value={currentRow.content || ""}
                            name="content"
                            rows={8}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}
