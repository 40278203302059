import React, { useEffect, useState } from "react";
import { convertToYearMonthDayFormat } from "../../../util/formatdate";
import { uploadImage } from "../../../api/uploader";
import useUsers from "../../../hooks/useUsers";
import LoadingModal from "../../../unicomponents/LoadingModal";
import { useQueryClient } from "@tanstack/react-query";
import { sendEmailVerification } from "../../../api/firebase";
import { useUIContext } from "../../../context/UIContext";
import UniDivider from "../../../unicomponents/UniDivider";
import { useTranslation } from "react-i18next";

export default function ModifyModal({ setModalOpen, userData }) {
  const [previewImage, setPreviewImage] = useState(null);
  const [previewImageTwo, setPreviewImageTwo] = useState(null);
  const [password, setPassword] = useState("*******");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [notValidPassword, setNotValidPassword] = useState(false);
  const [updateUserInfo, setUpdateUserInfo] = useState(
    userData ? { ...userData } : {}
  );
  const [handleEmail, setHandleEmail] = useState(
    userData ? userData.email : ""
  );
  const [profileFile, setProfileFile] = useState("");
  const [signatureFile, setSignatureFile] = useState("");
  const { t } = useTranslation();

  const [isUpLoading, setIsUpLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const { updateUser } = useUsers();
  const queryClient = useQueryClient();

  const { handleToastCenterTop, setIsLoadingModal } = useUIContext();

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        setNotValidPassword(false);
      } else {
        setNotValidPassword(true);
      }
    }
  }, [password, confirmPassword]);

  const handleImageChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (name === "profileImage") {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);

        setProfileFile(file);
      } else {
        setPreviewImage(null);
      }
    } else {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImageTwo(reader.result);
        };
        reader.readAsDataURL(file);

        setSignatureFile(file);
      } else {
        setPreviewImageTwo(null);
      }
    }

    //그리고 여기서 setMyInfo()에도 넣어야겠다.
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "password") {
      setPassword(e.target.value);
    }
    if (name === "birthday") {
      formattedValue = convertToYearMonthDayFormat(value);
    }
    setUpdateUserInfo((updateUserInfo) => ({
      ...updateUserInfo,
      [name]: formattedValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const folderSchool = updateUserInfo.schoolid;
    const folderUsername = updateUserInfo.username;
    const filePrefix = updateUserInfo.fullname + "_";

    if (updateUserInfo.password.length < 8) {
      alert("비밀번호는 최소 8자리여야 합니다.");
      return;
    }
    setIsUpLoading(true);

    try {
      const profileUploadPromise = profileFile
        ? uploadImage(profileFile, folderSchool, folderUsername, filePrefix)
        : Promise.resolve(updateUserInfo.profile_link);

      const signatureUploadPromise = signatureFile
        ? uploadImage(signatureFile, folderSchool, folderUsername, filePrefix)
        : Promise.resolve(updateUserInfo.signature_link);

      const [profileLink, signatureLink] = await Promise.all([
        profileUploadPromise,
        signatureUploadPromise,
      ]);

      const newUpdateUser = {
        ...updateUserInfo,
        profile_link: profileLink,
        signature_link: signatureLink,
      };

      updateUser.mutate(newUpdateUser, {
        onSuccess: () => {
          setIsSaved(true);
          setUpdateUserInfo(newUpdateUser);
          setTimeout(() => {
            setIsSaved(false);
            setModalOpen(false);
          }, 1000);
          setIsUpLoading(false);
          queryClient.invalidateQueries("userId");
        },
        onError: (error) => {
          setIsUpLoading(false);
          setModalOpen(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(t("common.messages.errorAlert") + error.message);
        },
      });
    } catch (error) {
      setIsUpLoading(false);
      console.error(error); // 일단 콘솔에 에러를 출력합니다.
      console.error("위와 같은 에러가 났으니 고치삼");
      alert(t("common.messages.errorAlert") + error.message);
    }
  };

  const getRoleName = (role) => {
    switch (role) {
      case "admin":
        return "관리자";
      case "teacher":
        return t("common.user.faculty") || "교직원";
      case "student":
        return t("common.user.student") || "학생";
      case "parent":
        return "학부모";
      default:
        return role;
    }
  };

  const handleEmailVerification = async (e) => {
    e.preventDefault();
    try {
      const email = handleEmail;
      if (!email) {
        handleToastCenterTop(t("common.login.inputEmail"));
        return;
      }

      if (email === updateUserInfo.email) {
        handleToastCenterTop(t("common.myInfo.alreadyUsedEmail"));
        return;
      }

      setIsLoadingModal(true);

      await sendEmailVerification(
        email,
        updateUserInfo.username,
        "email-verification"
      ); // Firebase 함수 호출
      setIsLoadingModal(false);

      handleToastCenterTop(
        `${email} ${t("common.myInfo.emailVerificationSent")}`
      );
    } catch (error) {
      console.error("Error sending verification email:", error);
      handleToastCenterTop(t("common.myInfo.emailTransportError"));
    }
  };

  return (
    <>
      <div className="modal-container">
        {isUpLoading && <LoadingModal />}
        <div className="modalForUnit" style={{ width: "800px" }}>
          <div className="modal-header">
            <h4>{t("common.myInfo.manageMyAccount")}</h4>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <div className="row mb-2">
                <div className="col-2 text-end">
                  <label htmlFor="fullname" className="col-form-label">
                    {t("common.user.fullname") || "이름"}
                  </label>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    name="fullname"
                    className="form-control"
                    defaultValue={updateUserInfo && updateUserInfo.fullname}
                    readOnly
                  />
                </div>
                <div className="col-2 text-end">
                  <label htmlFor="gender" className="col-form-label">
                    {t("common.user.gender") || "성별"}
                  </label>
                </div>
                <div className="col-3">
                  <input
                    type="text"
                    name="gender"
                    className="form-control"
                    defaultValue={updateUserInfo && updateUserInfo.gender}
                    readOnly
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 text-end">
                  <label htmlFor="username" className="col-form-label">
                    {t("common.user.user") || "사용자"}{" "}
                    {t("common.labels.id") || "아이디"}
                  </label>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    defaultValue={updateUserInfo && updateUserInfo.username}
                    readOnly
                  />
                </div>
                <div className="col-2 text-end">
                  <label htmlFor="role" className="col-form-label">
                    {t("common.user.role")}
                  </label>
                </div>
                <div className="col-3">
                  <input
                    type="text"
                    name="role"
                    className="form-control"
                    defaultValue={
                      updateUserInfo && getRoleName(updateUserInfo.role)
                    }
                    readOnly
                  />
                </div>
              </div>

              {updateUserInfo &&
              updateUserInfo.idatschool !== null &&
              updateUserInfo.idatschool.length > 0 ? (
                <div className="row mb-2">
                  <div className="col-3 text-end">
                    <label htmlFor="idatschool" className="col-form-label">
                      {t("common.user.idatschool") || "학번"}
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name="idatschool"
                      className="form-control"
                      defaultValue={updateUserInfo.idatschool}
                      readOnly
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row mb-2">
                <div className="col-2 text-end">
                  <label htmlFor="birthday" className="col-form-label">
                    {t("common.user.birthday") || "생년월일"}
                  </label>
                </div>
                <div className="col-3">
                  <input
                    type="date"
                    name="birthday"
                    className="form-control"
                    value={
                      updateUserInfo && updateUserInfo.birthday
                        ? updateUserInfo.birthday
                        : ""
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div
                  className="col-2 text-end p-0"
                  style={{ fontSize: "16px" }}
                >
                  <label htmlFor="password" className="col-form-label">
                    {t("common.login.resetPassword")}
                  </label>
                </div>
                <div className="col-4">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    value={password}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-2 text-end">
                  <label htmlFor="confirmPassword" className="col-form-label">
                    {t("common.commonPages.confirmPassword")}
                  </label>
                </div>
                <div className="col-4">
                  <input
                    type="password"
                    name="confirmPassword"
                    className="form-control"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  {confirmPassword.length > 0 &&
                    (notValidPassword ? (
                      <span
                        className="text-success ms-3 fw-bold"
                        style={{ fontSize: "12px" }}
                      >
                        {t("common.messages.passwordMatch") || "일치함!"}
                      </span>
                    ) : (
                      <span
                        className="text-danger ms-3 fw-bold"
                        style={{ fontSize: "12px" }}
                      >
                        {t("common.messages.passwordNotMatch") ||
                          "비밀번호가 일치하지 않습니다."}
                      </span>
                    ))}
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-2 text-end">
                  <label htmlFor="profileImage" className="col-form-label">
                    {t("common.myInfo.profileImage")}
                  </label>
                </div>
                <div className="col-4">
                  <input
                    type="file"
                    name="profileImage"
                    accept="image/*"
                    className="form-control"
                    onChange={handleImageChange}
                  />
                </div>

                <div className="col-2 text-end">
                  <label htmlFor="signatureImage" className="col-form-label">
                    {t("common.myInfo.signatureImage")}
                  </label>
                </div>
                <div className="col-4">
                  <input
                    type="file"
                    name="signatureImage"
                    accept="image/*"
                    className="form-control"
                    onChange={handleImageChange}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-6 row">
                  {previewImage ? (
                    <>
                      <div className="col-6 text-center">
                        <label className="col-form-label">
                          {t("common.myInfo.preview")}
                        </label>
                      </div>
                      <div className="col-6">
                        <img
                          src={previewImage}
                          alt="preview"
                          style={{ width: "100px" }}
                        />
                      </div>
                    </>
                  ) : (
                    updateUserInfo.profile_link && (
                      <>
                        <div className="col-6 text-center">
                          <label className="col-form-label">
                            {t("common.myInfo.currentProfile")}
                          </label>
                        </div>
                        <div className="col-6">
                          <img
                            src={updateUserInfo.profile_link}
                            alt="current profile"
                            style={{ width: "100px" }}
                          />
                        </div>
                      </>
                    )
                  )}
                </div>

                <div className="col-6 row">
                  {previewImageTwo ? (
                    <>
                      <div className="col-6 text-center ps-5">
                        <label className="col-form-label">
                          {t("common.myInfo.preview")}
                        </label>
                      </div>
                      <div className="col-6">
                        <img
                          src={previewImageTwo}
                          alt="preview"
                          style={{ width: "100px" }}
                        />
                      </div>
                    </>
                  ) : (
                    updateUserInfo.signature_link && (
                      <>
                        <div className="col-6 text-center ps-5">
                          <label className="col-form-label">
                            {t("common.myInfo.currentSignature")}
                          </label>
                        </div>
                        <div className="col-6">
                          <img
                            src={updateUserInfo.signature_link}
                            alt="current signature"
                            style={{ width: "100px" }}
                          />
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                {t("common.labels.cancel") || "취소"}
              </button>
              {isSaved ? (
                <button type="submit" className="btn btn-primary" disabled>
                  {t("common.labels.savedWell")}
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  {t("common.labels.doSave")}
                </button>
              )}
            </div>
          </form>
          <UniDivider />
          <form onSubmit={handleEmailVerification}>
            <div className="row">
              <div
                className="col-2 text-end"
                title="변경신청을 통해서만 변경 가능합니다."
              >
                <label htmlFor="email" className="col-form-label">
                  {t("common.user.email") || "이메일"}
                </label>
              </div>
              <div className="col-4 d-flex">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={handleEmail || ""}
                  onChange={(e) => setHandleEmail(e.target.value)}
                  title={t("common.myInfo.emailChangeTooltip")}
                />
              </div>
              <div className="col-6 d-flex">
                <button
                  className="btn btn-success m-0 ms-2 p-0 ps-1 pe-1"
                  style={{ minWidth: "75px" }}
                  title={t("common.myInfo.emailChangeTooltip")}
                >
                  {t("common.myInfo.emailChange")}
                </button>
                <div
                  style={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="ms-2"
                >
                  {t("common.myInfo.emailUsedForPasswordReset")}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
