import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import useSettingEvalItems from "../../../../../hooks/useSettingEvalItems";

export default function DetailedGrades({ selectedLesson }) {
  const [evalItemsData, setEvalItemsData] = useState([]);

  const { t } = useTranslation();
  const { getEvalItemsByLessonId } = useSettingEvalItems();
  const getEvalItemsByLessonIdRef = useRef(getEvalItemsByLessonId);

  useEffect(() => {
    if (selectedLesson?.id) {
      const forWhere = {
        schoolid: selectedLesson?.schoolid,
        lesson_id: selectedLesson?.lesson_id || selectedLesson?.id,
      };

      getEvalItemsByLessonIdRef.current.mutate(forWhere, {
        onSuccess: (data) => {
          setEvalItemsData(data);
        },
        onError: (error) => {
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(t("common.messages.errorAlert") + error.message);
        },
      });
    }
  }, [selectedLesson, t, getEvalItemsByLessonIdRef]);

  // 선택된 레슨이 없으면 빈 화면 표시
  if (!selectedLesson || selectedLesson.evaluationType !== "평가항목별") {
    return (
      <div className="text-center p-3">
        {t("student.studentGrades.noGradesMessage")}
      </div>
    );
  }

  // 계층 레벨별 항목 찾기
  const findByLevels = (level) =>
    evalItemsData.filter((item) => item.hierarchy_level === level);

  const findItems = (parentId, level) =>
    evalItemsData.filter(
      (item) => item.hierarchy_level === level && item.parent_id === parentId
    );

  // 평가 항목 셀 렌더링
  function renderTd(evaluationType, eval_item_grades, evalItemId) {
    const uniqueKey = `${evalItemId}-${evaluationType}`;

    switch (evaluationType) {
      case "100점제":
        return (
          <td key={uniqueKey} className="text-center align-middle">
            {eval_item_grades?.[evalItemId] ?? ""}
          </td>
        );
      case "이수제":
        return (
          <td key={uniqueKey} className="text-center align-middle">
            {eval_item_grades?.[evalItemId] === "pass"
              ? t("teacher.lessonRelated.pass")
              : eval_item_grades?.[evalItemId] === "fail"
              ? t("teacher.lessonRelated.fail")
              : ""}
          </td>
        );
      case "3단평가":
        const value3 = eval_item_grades?.[evalItemId];
        let text3 = "";
        if (value3 === "3") text3 = t("teacher.lessonRelated.good");
        else if (value3 === "2") text3 = t("teacher.lessonRelated.normal");
        else if (value3 === "1") text3 = t("teacher.lessonRelated.effort");
        else if (value3 === "0") text3 = t("teacher.lessonRelated.notDone");

        return (
          <td key={uniqueKey} className="text-center align-middle">
            {text3}
          </td>
        );
      case "ABC":
        return (
          <td key={uniqueKey} className="text-center align-middle">
            {eval_item_grades?.[evalItemId] ?? ""}
          </td>
        );
      case "직접입력":
        return (
          <td key={uniqueKey} className="text-center align-middle">
            {eval_item_grades?.[evalItemId] ?? ""}
          </td>
        );
      case "5단평가":
        return (
          <td key={uniqueKey} className="text-center align-middle">
            {eval_item_grades?.[evalItemId] ?? ""}
          </td>
        );
      default:
        return (
          <td key={uniqueKey} className="text-center align-middle">
            -
          </td>
        );
    }
  }

  // 테이블 행 렌더링
  const renderTableRows = () => {
    const level1Items = findItems(0, 1);

    return level1Items.flatMap((level1) => {
      const level2Items = findItems(level1.id, 2);

      if (level2Items.length === 0) {
        const rowKey = `level1-${level1.id}`;
        return (
          <tr key={rowKey}>
            <td className="text-center align-middle">
              {level1.content}
              {level1.extra_data?.weight && (
                <>
                  <br />
                  {t("teacher.lessonRelated.weight")}:{" "}
                  {level1.extra_data.weight}%
                </>
              )}
              {level1.extra_data?.total && !level2Items.length && (
                <>
                  <br />
                  {t("teacher.lessonRelated.totalScore")}:{" "}
                  {level1.extra_data.total}
                </>
              )}
            </td>
            {findByLevels(2).length > 0 && <td></td>}
            {findByLevels(3).length > 0 && <td></td>}
            {renderTd(
              level1.evaluation_type,
              selectedLesson.eval_item_grades,
              level1.id
            )}
            <td className="text-center align-middle">
              {selectedLesson?.extra_data?.[level1.id] &&
                selectedLesson.extra_data[level1.id]}
            </td>
          </tr>
        );
      } else {
        return level2Items.flatMap((level2, level2Index) => {
          const level3Items = findItems(level2.id, 3);

          if (level3Items.length === 0) {
            const rowKey = `level2-${level2.id}-${level2Index}`;

            return (
              <tr key={rowKey}>
                <td className="text-center align-middle">
                  {level2Index === 0 ? (
                    <>
                      {level1.content}
                      {level1.extra_data?.weight && (
                        <>
                          <br />
                          {t("teacher.lessonRelated.weight")}:{" "}
                          {level1.extra_data.weight}%
                        </>
                      )}
                      {level1.extra_data?.total && !level2Items.length && (
                        <>
                          <br />
                          {t("teacher.lessonRelated.totalScore")}:{" "}
                          {level1.extra_data.total}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </td>
                <td className="text-center align-middle">
                  {level2.content}
                  {level2.extra_data?.weight && (
                    <>
                      <br />
                      {t("teacher.lessonRelated.weight")}:{" "}
                      {level2.extra_data.weight}%
                    </>
                  )}
                  {level2.extra_data?.total && !level3Items.length && (
                    <>
                      <br />
                      {t("teacher.lessonRelated.totalScore")}:{" "}
                      {level2.extra_data.total}
                    </>
                  )}
                </td>
                {findByLevels(3).length > 0 && <td></td>}
                {renderTd(
                  level2.evaluation_type,
                  selectedLesson.eval_item_grades,
                  level2.id
                )}
                <td className="text-center align-middle">
                  {selectedLesson?.extra_data?.[level2.id] &&
                    selectedLesson.extra_data[level2.id]}
                </td>
              </tr>
            );
          } else {
            return level3Items.flatMap((level3, level3Index) => {
              const rowKey = `level3-${level3.id}-${level3Index}`;

              return (
                <tr key={rowKey}>
                  <td className="text-center align-middle">
                    {level2Index === 0 && level3Index === 0 ? (
                      <>
                        {level1.content}
                        {level1.extra_data?.weight && (
                          <>
                            <br />
                            {t("teacher.lessonRelated.weight")}:{" "}
                            {level1.extra_data.weight}%
                          </>
                        )}
                        {level1.extra_data?.total && !level2Items.length && (
                          <>
                            <br />
                            {t("teacher.lessonRelated.totalScore")}:{" "}
                            {level1.extra_data.total}
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="text-center align-middle">
                    {level3Index === 0 ? (
                      <>
                        {level2.content}
                        {level2.extra_data?.weight && (
                          <>
                            <br />
                            {t("teacher.lessonRelated.weight")}:{" "}
                            {level2.extra_data.weight}%
                          </>
                        )}
                        {level2.extra_data?.total && !level3Items.length && (
                          <>
                            <br />
                            {t("teacher.lessonRelated.totalScore")}:{" "}
                            {level2.extra_data.total}
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="text-center align-middle">
                    {level3.content}
                    {level3.extra_data?.weight && (
                      <>
                        <br />
                        {t("teacher.lessonRelated.weight")}:{" "}
                        {level3.extra_data.weight}%
                      </>
                    )}
                    {level3.extra_data?.total && (
                      <>
                        <br />
                        {t("teacher.lessonRelated.totalScore")}:{" "}
                        {level3.extra_data.total}
                      </>
                    )}
                  </td>
                  {renderTd(
                    level3.evaluation_type,
                    selectedLesson.eval_item_grades,
                    level3.id
                  )}
                  <td className="text-center align-middle">
                    {selectedLesson?.extra_data?.[level3.id] &&
                      selectedLesson.extra_data[level3.id]}
                  </td>
                </tr>
              );
            });
          }
        });
      }
    });
  };

  return (
    <div>
      <div className="table-responsive" style={{ minWidth: "500px" }}>
        <table
          className="table table-sm table-bordered"
          style={{
            fontSize: "14px",
            width: "auto",
          }}
        >
          <thead>
            <tr style={{ height: "41px" }}>
              <th
                className="align-middle bg-secondary-subtle text-center"
                style={{
                  minWidth: "110px",
                  maxWidth: "250px",
                }}
              >
                {t("student.studentGrades.level1")}
              </th>
              {findByLevels(2).length > 0 && (
                <th
                  className="align-middle bg-secondary-subtle text-center"
                  style={{
                    minWidth: "110px",
                    maxWidth: "250px",
                  }}
                >
                  {t("student.studentGrades.level2")}
                </th>
              )}
              {findByLevels(3).length > 0 && (
                <th
                  className="align-middle bg-secondary-subtle text-center"
                  style={{
                    minWidth: "120px",
                    maxWidth: "250px",
                  }}
                >
                  {t("student.studentGrades.level3")}
                </th>
              )}
              <th
                className="align-middle text-center bg-secondary-subtle"
                style={{
                  minWidth: "50px",
                  width: "100px",
                  maxWidth: "150px",
                }}
              >
                {t("student.studentGrades.score")}
              </th>
              <th
                className="align-middle text-center bg-secondary-subtle"
                style={{
                  minWidth: "50px",
                  //   width: "100px",
                  //   maxWidth: "150px",
                }}
              >
                Notes
              </th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
          <tfoot className="">
            <tr>
              <th className="align-middle text-center bg-light">
                {t("student.studentGrades.sum")}
              </th>
              {findByLevels(2).length > 0 && (
                <th className="align-middle bg-light"></th>
              )}
              {findByLevels(3).length > 0 && (
                <th className="align-middle bg-light"></th>
              )}
              <th className="text-center bg-light">{selectedLesson.grade}</th>
              <th className="text-center bg-light"></th>
            </tr>
            {/* {checkEvalTypes(["100점제", "3단평가", "5단평가"]) && (
              <tr>
                <th className="align-middle">
                  {t("teacher.lessonRelated.average")}
                </th>
                {findByLevels(2).length > 0 && (
                  <th className="align-middle"></th>
                )}
                {findByLevels(3).length > 0 && (
                  <th className="align-middle"></th>
                )}
                <th className="text-center">
                  {selectedLesson.eval_item_grades
                    ? typeof averageAllNumbersOfObject(
                        selectedLesson.eval_item_grades
                      ) === "number"
                      ? averageAllNumbersOfObject(
                          selectedLesson.eval_item_grades
                        ).toFixed(1)
                      : ""
                    : ""}
                </th>
              </tr>
            )}
            {selectedLesson.comment && (
              <tr>
                <th
                  colSpan={
                    findByLevels(3).length > 0
                      ? 3
                      : findByLevels(2).length > 0
                      ? 2
                      : 1
                  }
                >
                  {t("teacher.lessonRelated.specificComment")}
                </th>
                <td className="p-2">{selectedLesson.comment}</td>
              </tr>
            )} */}
          </tfoot>
        </table>
      </div>
    </div>
  );
}
