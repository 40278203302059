import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { validateByField } from "../../util/validate";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import styles from "./CSS/LoginModal.module.css"; // 스타일 파일을 임포트합니다.
import { useTranslation } from "react-i18next";

const LoginModal = ({ isOpen, onClose }) => {
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuthContext();
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
    schoolid: "",
    role: "",
  });

  // 저장된 선택값 불러오기
  useEffect(() => {
    const storedRole = localStorage.getItem("selectedRole");
    const storedSchool = localStorage.getItem("selectedSchool");
    const storedUsername = localStorage.getItem("selectedUsername");

    if (storedRole) setUserInfo((prev) => ({ ...prev, role: storedRole }));
    if (storedSchool)
      setUserInfo((prev) => ({ ...prev, schoolid: storedSchool }));
    if (storedUsername)
      setUserInfo((prev) => ({ ...prev, username: storedUsername }));
  }, []);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateByField("#loginModal input")) {
      try {
        await login(userInfo);
        setMessage(null);

        // 로그인 성공 시에만 로컬 스토리지 업데이트
        localStorage.setItem("selectedRole", userInfo.role);
        localStorage.setItem("selectedSchool", userInfo.schoolid);
        localStorage.setItem("selectedUsername", userInfo.username);

        setUserInfo((prev) => ({
          ...prev,
          password: "",
        }));
        onClose(); // 로그인 성공 시 모달 닫기
      } catch (err) {
        console.error(err);
        setMessage(err.toString() || t("common.messages.unknownError"));
      }
    } else {
      setMessage(t("common.messages.checkInput"));
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <h4 className="mb-1 fw-normal">
          {t("common.uniComponents.loginAgain")}
        </h4>
        <p>{t("common.uniComponents.loginAgainMessage")}</p>
        <form
          id="loginModal"
          onSubmit={handleSubmit}
          className="needs-validation"
          noValidate
        >
          <div className="form-floating mb-2">
            <select
              value={userInfo.role}
              className="form-control"
              id="role"
              name="role"
              onChange={handleUserChange}
              required
            >
              <option value="noSelected" disabled>
                {t("common.user.userType")}
              </option>
              <option value="teacher">
                {t("common.user.faculty") || "교직원"}
              </option>
              <option value="student">
                {t("common.user.student") || "학생"}
              </option>
              <option value="parent">
                {t("common.user.parent") || "학부모"}
              </option>
              <option value="admin">
                {t("common.user.admin") || "관리자"}
              </option>
            </select>
            <label>{t("common.user.userType")}</label>
          </div>
          <div className="form-floating mb-2">
            <input
              type="text"
              className="form-control"
              id="schoolid"
              name="schoolid"
              placeholder="School ID"
              value={userInfo.schoolid}
              onChange={handleUserChange}
              required
            />
            <label>School ID</label>
          </div>
          <div className="form-floating mb-2">
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              placeholder="Username"
              value={userInfo.username}
              onChange={handleUserChange}
              required
            />
            <label>
              {t("common.user.user") || "사용자"}{" "}
              {t("common.labels.id") || "아이디"}
            </label>
          </div>
          <div className="form-floating mb-2">
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                name="password"
                value={userInfo.password}
                onChange={handleUserChange}
                required
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <MdOutlineVisibilityOff size={20} />
                ) : (
                  <MdOutlineVisibility size={20} />
                )}
              </button>
            </div>
          </div>
          <button
            type="submit"
            className="w-100 btn btn-lg btn-primary submitButton"
          >
            {t("common.uniComponents.login") || "로그인"}
          </button>
          {message && (
            <p className="bg-warning mt-2 p-1 rounded fw-bold text-center">
              {message}
            </p>
          )}
        </form>
        <button
          className={`btn btn-secondary mt-3 ${styles.closeButton}`}
          onClick={onClose}
        >
          {t("common.labels.close") || "닫기"}
        </button>
      </div>
    </div>
  );
};

export default LoginModal;
