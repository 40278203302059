import React, { useState } from "react";
import {
  MdClose,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from "react-icons/md";
import { sendEmailVerification } from "../../../api/firebase";
import { useUIContext } from "../../../context/UIContext";
import {
  validateFields,
  validatePasswordParent,
  validatePhoneNumber,
} from "../../../util/validate";
import useUsers from "../../../hooks/useUsers";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";

export default function SignupForm({
  handleSignUp,
  formData,
  setFormData,
  verifiedEmail,
}) {
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [verifiedSchoolid, setVerifiedSchoolid] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { handleToastCenterTop, handleToastCenterBottom, setIsLoadingModal } =
    useUIContext();

  const { checkNewUsername, checkSchoolId, signupUser } = useUsers();

  const { login } = useAuthContext();
  const handleEmailVerification = async () => {
    try {
      const email = formData.email;
      if (!email) {
        handleToastCenterTop(t("common.login.inputEmail"));
        return;
      }
      setIsLoadingModal(true);
      checkNewUsername.mutate(email, {
        onSuccess: async (success) => {
          await sendEmailVerification(email); // Firebase 함수 호출
          setIsLoadingModal(false);

          handleToastCenterTop(
            `인증 이메일이 ${email}로 전송되었습니다. 이메일 인증 후 진행할 수 있습니다.`
          );
        },
        onError: (error) => {
          setIsLoadingModal(false);
          handleToastCenterTop("이미 존재하는 아이디(이메일)입니다.");
        },
      });
    } catch (error) {
      console.error("Error sending verification email:", error);
      handleToastCenterTop("이메일 전송 중 문제가 발생했습니다.");
    }
  };

  const handleSchoolidVerification = async () => {
    try {
      const schoolId = formData.schoolid;
      if (!schoolId) {
        handleToastCenterTop("학교 아이디를 입력해 주세요.");
        return;
      }

      checkSchoolId.mutate(schoolId, {
        onSuccess: async (success) => {
          setVerifiedSchoolid(true);
        },
        onError: (error) => {
          handleToastCenterTop("존재하지 않는 학교 아이디 입니다.");
        },
      });
    } catch (error) {
      console.error("Error verifying schoolid:", error);
      handleToastCenterTop("학교아이디 확인 중 문제가 발생했습니다.");
    }
  };

  const handleChange = (e) => {
    if (!verifiedEmail && e.target.name !== "email") {
      handleToastCenterBottom("이메일 인증 후 입력 가능합니다.");
      return;
    }
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    // 해당 필드의 에러 제거
    setErrors((prev) => {
      const { [name]: removedError, ...remainingErrors } = prev;
      return remainingErrors;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const fieldsToValidate = [
      { key: "password", type: "string", required: true, name: "비밀번호" },
      { key: "fullname", type: "string", required: true, name: "이름" },
      { key: "birthday", type: "string", required: true, name: "생년월일" },
      { key: "mobile", type: "string", required: true, name: "휴대폰 번호" },
      { key: "schoolid", type: "string", required: true, name: "학교아이디" },
    ];

    const validationErrors = validateFields(formData, fieldsToValidate);

    if (!verifiedSchoolid) {
      validationErrors.schoolid = "학교 아이디를 확인해 주세요.";
    }

    if (!validatePasswordParent(formData.password)) {
      validationErrors.password =
        t("common.messages.passwordRule2") ||
        "영문, 숫자, 특수문자 중 최소 2가지 이상 포함하여 8자 이상이어야 합니다.";
    }

    // 휴대폰 번호 검증 추가
    if (!validatePhoneNumber(formData.mobile)) {
      validationErrors.mobile =
        "휴대폰 번호는 000-0000-0000 형식이어야 합니다.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // 에러 상태 업데이트
      return;
    }

    if (!formData.terms_accepted) {
      handleToastCenterBottom("약관에 동의해 주세요.");
      return;
    }

    //verifiedEmail을 적용하고, 서버로 보내서 create 하는 것 만들면 된다.

    // console.log("모두 통과함 이제 제출!!", formData);

    formData.role = "parent";

    setIsLoadingModal(true);

    signupUser.mutate(formData, {
      onSuccess: async (username) => {
        try {
          setIsLoadingModal(false);
          const loginInfo = {
            username: formData.username,
            password: formData.password,
            schoolid: formData.schoolid,
            role: "parent",
          };

          await login(loginInfo);

          localStorage.setItem("selectedRole", loginInfo.role);
          localStorage.setItem("selectedUsername", loginInfo.username);
          localStorage.setItem("selectedSchool", loginInfo.schoolid);
          navigate(`/${formData.schoolid}/parent/link-student`);
        } catch (loginError) {
          console.error("Login error:", loginError);
          // alert("가입은 되었지만, 로그인에 실패했습니다. 다시 시도해주세요.");
        }
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        alert(error.message);
        setFormData({});
      },
    });
  };

  // 필드별 에러 표시
  const renderError = (key) => {
    return errors[key] ? (
      <span style={{ color: "red", fontSize: "11px" }}>{errors[key]}</span>
    ) : null;
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        maxWidth: "500px",
        margin: "0 auto",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <div className="d-flex justify-content-between">
        <h5 style={{ marginBottom: "20px" }}>회원가입</h5>
        <MdClose
          size={24}
          onClick={handleSignUp}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div style={{ display: "none" }}>
        <input type="text" name="username" />
        <input type="email" name="email" />
        <input type="password" name="password" />
      </div>

      <p>
        부모인{" "}
        <u>
          <b>본인</b>
        </u>{" "}
        정보로 회원가입해 주세요. <br />
        입력한 정보로 학생의 부모가 맞는지 확인합니다. <br />
        자주 사용하는 개인 이메일을 사용해 주세요. <br />
      </p>

      {/* 이메일 입력 */}
      <div className="d-flex align-items-center mt-2">
        <label htmlFor="email" style={{ minWidth: "80px", fontSize: "15px" }}>
          가입이메일
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder={t("common.login.inputSignupEmail")}
          className="form-control"
          disabled={verifiedEmail}
          autoComplete="off"
        />
        <button
          type="button"
          className="btn btn-success ms-2 ps-1 pe-1"
          onClick={handleEmailVerification}
          disabled={verifiedEmail}
          style={{ minWidth: "75px" }}
        >
          {verifiedEmail
            ? t("common.login.verified")
            : t("common.login.verify")}
        </button>
      </div>
      {renderError("email")}

      <div className="d-flex align-items-center mt-2">
        <label htmlFor="fullname" style={{ minWidth: "80px" }}>
          {t("common.labels.school")} ID
        </label>
        <input
          type="text"
          name="schoolid"
          value={formData.schoolid}
          onChange={handleChange}
          placeholder={t("common.login.inputSchoolid")}
          className="form-control"
          disabled={verifiedSchoolid}
          required
          autoComplete="off"
        />
        <button
          type="button"
          className="btn btn-success ms-2 ps-1 pe-1"
          onClick={handleSchoolidVerification}
          disabled={verifiedSchoolid}
          style={{ minWidth: "75px" }}
        >
          {verifiedSchoolid
            ? t("common.login.confirmed")
            : t("common.login.confirm")}
        </button>
      </div>
      {renderError("schoolid")}

      {/* 비밀번호 */}
      <div className="d-flex align-items-center mt-2">
        <label htmlFor="password" style={{ minWidth: "80px" }}>
          {t("common.user.password") || "비밀번호"}
        </label>
        <div className="input-group" style={{ height: "38px" }}>
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            className="form-control"
            placeholder={t("common.login.passwordRule")}
            name="password"
            onChange={handleChange}
            required
            value={formData.password}
            autoComplete="off"
            style={{ height: "100%" }}
          />
          <button
            type="button"
            className="btn btn-outline-secondary m-0"
            onClick={() => setShowPassword(!showPassword)}
            style={{ height: "100%" }}
          >
            {showPassword ? (
              <MdOutlineVisibilityOff size={20} />
            ) : (
              <MdOutlineVisibility size={20} />
            )}
          </button>
        </div>
      </div>
      {renderError("password")}

      {/* 이름 */}
      <div className="d-flex align-items-center mt-2">
        <label htmlFor="fullname" style={{ minWidth: "80px" }}>
          {t("common.user.fullname") || "이름"}
        </label>
        <input
          type="text"
          id="fullname"
          name="fullname"
          value={formData.fullname}
          onChange={handleChange}
          placeholder={t("common.login.inputParentName")}
          className="form-control"
          required
          autoComplete="off"
        />
      </div>
      {renderError("fullname")}

      {/* {t("common.user.gender") || "성별"} */}
      <div className="d-flex align-items-center mt-2">
        <label htmlFor="gender" style={{ minWidth: "80px" }}>
          {t("common.user.gender")}
        </label>
        <select
          id="gender"
          name="gender"
          value={formData.gender}
          onChange={handleChange}
          className="form-control"
          required
        >
          <option value="남">{t("common.labels.male") || "남"}</option>
          <option value="여">{t("common.labels.female") || "여"}</option>
        </select>
      </div>
      {renderError("gender")}

      {/* 생년월일 */}
      <div className="d-flex align-items-center mt-2">
        <label htmlFor="birthday" style={{ minWidth: "80px" }}>
          {t("common.user.birthday") || "생년월일"}
        </label>
        <input
          type="date"
          id="birthday"
          name="birthday"
          value={formData.birthday}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>
      {renderError("birthday")}

      {/* 휴대폰 */}
      <div className="d-flex align-items-center mt-2">
        <label htmlFor="mobile" style={{ minWidth: "80px" }}>
          {t("common.user.mobile")}
        </label>
        <input
          type="text"
          id="mobile"
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
          placeholder="010-0000-0000"
          className="form-control"
          required
        />
      </div>
      {renderError("mobile")}

      {/* 약관 동의 */}
      <div className="d-flex align-items-center mt-2">
        <input
          type="checkbox"
          id="terms_accepted"
          name="terms_accepted"
          checked={formData.terms_accepted}
          onChange={handleChange}
          className="form-check-input me-2"
          required
        />
        <label className="form-check-label" htmlFor="terms_accepted">
          {t("common.login.agreeWithTerms")}{" "}
          <a
            href="https://www.eduseed.co.kr/%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("common.login.terms")}
          </a>
        </label>
      </div>

      {/* 가입하기 버튼 */}
      <button type="submit" className="btn btn-primary w-100 mt-3">
        {t("common.login.doSignup")}
      </button>
    </form>
  );
}
