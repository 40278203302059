import React, { useEffect, useState } from "react";
import { convertToYearMonthDayFormat } from "../../../util/formatdate";
import useCounseling from "../../../hooks/useCounseling";
import { useAuthContext } from "../../../context/AuthContext";
import { useUIContext } from "../../../context/UIContext";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";

//그 다음엔 1차적으로 끝이지만.. 여유가 된다면 재적으로도 검색할 수 있도록 만들자. (일단은 못 했음)

export default function UpdateCounselingModal({
  setModalOpen,
  updateCounselingInfo,
  setUpdateCounselingInfo,
  setIsUpLoading,
  userData,
}) {
  const [filteredTeacherData, setFilteredTeacherData] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [filteredFollowupUsers, setFilteredFollowupUsers] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { t } = useTranslation();

  const { updateCounseling, deleteCounseling } = useCounseling();

  const { hasPermission } = useAuthContext();

  const { handleToastCenterTop } = useUIContext();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (updateCounselingInfo) {
      setFilteredTeacherData([
        {
          id: updateCounselingInfo.id,
          username: updateCounselingInfo.counselor,
          fullname: updateCounselingInfo.counselorName,
        },
      ]);

      setFilteredFollowupUsers([
        {
          id: updateCounselingInfo.id,
          username: updateCounselingInfo.followup_user,
          fullname: updateCounselingInfo.followupName,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalChange = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;
    if (name === "entrance_date") {
      formattedValue = convertToYearMonthDayFormat(value);
    }
    setUpdateCounselingInfo((updateCounselingInfo) => ({
      ...updateCounselingInfo,
      [name]: formattedValue,
    }));
  };

  const handleFullNameChange = (e) => {
    const { value, name } = e.target;
    // console.log("userData", userData);

    const filtered = userData.filter((user) => {
      return (
        user.fullname.toLowerCase() === value.toLowerCase() &&
        user.role === "teacher" &&
        user.status === 1
      );
    });

    if (name === "counselorName") {
      setFilteredTeacherData([]);

      setUpdateCounselingInfo((updateCounselingInfo) => ({
        ...updateCounselingInfo,
        counselorName: value,
      }));

      if (filtered.length > 0) {
        setFilteredTeacherData(filtered);
        setUpdateCounselingInfo((updateCounselingInfo) => ({
          ...updateCounselingInfo,
          counselor: filtered[0].username,
        }));
      }
    } else if (name === "followupName") {
      setFilteredFollowupUsers([]);
      setUpdateCounselingInfo((updateCounselingInfo) => ({
        ...updateCounselingInfo,
        followupName: value,
      }));

      if (filtered.length > 0) {
        setFilteredFollowupUsers(filtered);

        setUpdateCounselingInfo((updateCounselingInfo) => ({
          ...updateCounselingInfo,
          followup_user: filtered[0].username,
        }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("updateCounselingInfo", updateCounselingInfo);

    if (!hasPermission(updateCounselingInfo.counselor, "can_update")) {
      handleToastCenterTop(t("common.messages.ownerUpdatePermission"));
      return;
    }
    setIsUpLoading(true);
    updateCounseling.mutate(updateCounselingInfo, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
          setModalOpen(false);
        }, 500);
        setIsUpLoading(false);
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleDelete = (e) => {
    const { name } = e.target;
    const { id } = updateCounselingInfo;
    if (name === "delete") {
      setConfirmDelete(true);
    } else {
      if (!hasPermission(updateCounselingInfo.counselor, "can_delete")) {
        handleToastCenterTop(t("common.messages.ownerDeletePermission"));
        return;
      }

      setIsUpLoading(true);
      deleteCounseling.mutate(id, {
        onSuccess: (successMessage) => {
          queryClient.invalidateQueries(["counseling"]);
          setIsUpLoading(false);
          setConfirmDelete(false);
          setModalOpen(false);
        },
        onError: (error) => {
          setIsUpLoading(false);
          setConfirmDelete(false);
          setModalOpen(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(error.message);
        },
      });
    }
  };

  return (
    <>
      <div className="modal-container">
        <div
          className="modalForUnit"
          style={{ width: "750px", maxHeight: "650px", overflowY: "auto" }}
        >
          <div className="modal-header mb-3">
            <h3>{t("teacher.counseling.modifyRecord")}</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <div className="row mb-3 ps-2">
                <div className="col-sm-3 col-3">
                  {updateCounselingInfo["1"]} {t("common.labels.schoolStage")}
                </div>
                <div className="col-sm-3 col-3">
                  {updateCounselingInfo["2"]} {t("common.labels.studentYear")}
                </div>
                <div className="col-sm-3 col-3">
                  {updateCounselingInfo["3"]} {t("common.labels.class")}
                </div>
                <div className="col-sm-3 col-3">
                  {updateCounselingInfo.fullname}
                </div>
              </div>
              <div className="row mb-3 ps-2">
                <div className="col-sm-3 col-4">
                  <label htmlFor="counseling_date" className="col-form-label">
                    {t("teacher.counseling.counselingDate")}
                  </label>
                </div>
                <div className="col-sm-3 col-7">
                  <input
                    type="date"
                    name="counseling_date"
                    className="form-control"
                    value={updateCounselingInfo?.counseling_date || ""}
                    onChange={handleModalChange}
                  />
                </div>
                <div className="col-sm-2 col-4">
                  <label htmlFor="category" className="col-form-label">
                    {t("common.labels.category")}
                  </label>
                </div>
                <div className="col-sm-3 col-7">
                  <select
                    className="form-control"
                    name="category"
                    value={updateCounselingInfo?.category || ""}
                    onChange={handleModalChange}
                  >
                    <option value="생활">{t("teacher.counseling.life")}</option>
                    <option value="교과">
                      {t("teacher.counseling.subject")}
                    </option>
                    <option value="진로">
                      {t("teacher.counseling.career")}
                    </option>
                    <option value="학부모">
                      {t("teacher.counseling.parent")}
                    </option>
                    <option value="가정방문">
                      {t("teacher.counseling.homeVisit")}
                    </option>
                    <option value="징계">
                      {t("teacher.counseling.discipline")}
                    </option>
                    <option value="전출">
                      {t("teacher.counseling.transfer")}
                    </option>
                    <option value="지도">
                      {t("teacher.counseling.guidance")}
                    </option>
                    <option value="건의">
                      {t("teacher.counseling.suggestion")}
                    </option>
                    <option value="기타">{t("teacher.counseling.etc")}</option>
                  </select>
                </div>
              </div>

              <div className="row  mb-3 ps-2">
                <div className="col-sm-2 col-3">
                  <label htmlFor="counselorName" className="col-form-label">
                    {t("teacher.counseling.counselor")}
                  </label>
                </div>
                <div className="col-sm-4 col-4">
                  <input
                    type="text"
                    name="counselorName"
                    className="form-control"
                    onChange={handleFullNameChange}
                    placeholder={t("teacher.counseling.counselorName")}
                    required
                    value={updateCounselingInfo?.counselorName || ""}
                  />
                </div>
                <div className="col-sm-5 col-5">
                  <select
                    className="form-control"
                    name="counselor"
                    value={updateCounselingInfo?.counselor || ""}
                    required
                    onChange={handleModalChange}
                  >
                    {filteredTeacherData.length > 0 &&
                      filteredTeacherData.map((user) => (
                        <option key={user.id} value={user.fullname || ""}>
                          {user.username}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="row  mb-3 ps-2">
                <div className="col-sm-2 col-2">
                  <label htmlFor="content" className="col-form-label">
                    {t("teacher.counseling.content")}
                  </label>
                </div>
                <div className="col-sm-9 col-9">
                  <textarea
                    type="text"
                    name="content"
                    className="form-control"
                    onChange={handleModalChange}
                    rows={4}
                    required
                    value={updateCounselingInfo?.content || ""}
                  />
                </div>
              </div>

              <div className="row mb-3 ps-2">
                <div className="col-sm-2 col-3">
                  <label htmlFor="followupName" className="col-form-label">
                    {t("teacher.counseling.followupName")}
                  </label>
                </div>
                <div className="col-sm-4 col-4">
                  <input
                    type="text"
                    name="followupName"
                    className="form-control"
                    onChange={handleFullNameChange}
                    placeholder={t("teacher.counseling.followupName")}
                    value={updateCounselingInfo?.followupName || ""}
                  />
                </div>
                <div className="col-sm-5 col-5">
                  <select
                    className="form-control"
                    name="followup_user"
                    onChange={handleModalChange}
                  >
                    {filteredFollowupUsers.length > 0 &&
                      filteredFollowupUsers.map((user) => (
                        <option key={user.id} value={user.fullname || ""}>
                          {user.username}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="row  mb-3 ps-2">
                <div className="col-sm-2 col-2">
                  <label htmlFor="followup" className="col-form-label">
                    {t("teacher.counseling.followup")}
                  </label>
                </div>
                <div className="col-sm-9 col-9">
                  <textarea
                    type="text"
                    name="followup"
                    className="form-control"
                    onChange={handleModalChange}
                    rows={4}
                    value={updateCounselingInfo?.followup || ""}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={confirmDelete ? "btn btn-danger" : "btn btn-warning"}
                name={confirmDelete ? "confirm" : "delete"}
                onClick={handleDelete}
              >
                {confirmDelete
                  ? t("teacher.counseling.delete")
                  : t("common.labels.delete") || "삭제"}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                {t("common.labels.cancel") || "취소"}
              </button>
              {isSaved ? (
                <button type="submit" className="btn btn-primary" disabled>
                  {t("common.labels.savedWell")}
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  {t("common.labels.save")}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
