import React, { useEffect, useRef, useState } from "react";
import LeftMenuSidebar from "../../../unicomponents/LeftMenuSidebar";
import useAllForms from "../../../hooks/useAllForms";
import { useLocation } from "react-router-dom";
import useComponentsToForms from "../../../hooks/useComponentsToForms";
import DynamicComponentsRenderer from "../../../unicomponents/formComponents/DynamicComponentsRenderer";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import useTermPeriod from "../../../hooks/useTermPeriod";
import { setTermIdByToday } from "../../../util/dateRelated";
import { generateRegistrationRows } from "../../../util/dealingArrObj";
import useRegistrationInfo from "../../../hooks/useRegistrationInfo";
import { useUIContext } from "../../../context/UIContext";
import FiltersBar from "../../../unicomponents/Dropdowns/FilterBar";
import ReportCardButtonBar from "./components/ReportCardButtonBar";
// import styles from "./css/ReportCard.module.css";

import { useReactToPrint } from "react-to-print";
import ApprovalProcess from "../../../unicomponents/approvalForms/ApprovalProcess";
import useDocumentForms from "../../../hooks/useDocumentForms";
import { useAuthContext } from "../../../context/AuthContext";
import useApprovalRequests from "../../../hooks/useApprovalRequest";
import LoadingInside from "../../../unicomponents/LoadingInside";
import SimpleAlertMessageBlock from "../../../unicomponents/SimpleAlertMessageBlock";
import { checkHrteachers } from "../../../util/checkPermissions";
import { useTranslation } from "react-i18next";
//${styles.tableStyle} 이런식으로 씀. class에 삽입

/* 
우엥... 인쇄까지 순탄하게 된다. 예전에 인쇄 엄청 어려워서 한참 시간 걸리고도 만족스럽게 못했는데...

좋아, 이제 할일
1. 인쇄 안 되는 윗대가리 디자인
2. 본문에 들어갈 컴포넌트들 개발
3. 넣어서 인쇄 확인
*/

export default function ReportCard() {
  const { t } = useTranslation();
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];
  const [filters, setFilters] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    organPathForSort: "/",
    termId: "",
    selectedTeacherId: "",
    selectedLessonId: "",
  });
  const [termId, setTermId] = useState("");
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [studentListForApproval, setStudentListForApproval] = useState([]);
  const [keyData, setKeyData] = useState({});
  // const [validateInput, setValidateInput] = useState({});
  const [modifyMode, setModifyMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approvalLines, setApprovalLines] = useState([]);
  const [studentApprovalRequests, setStudentApprovalRequests] = useState([]);
  const [showPageNumbers, setShowPageNumbers] = useState(false);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  const [activeMenu, setActiveMenu] = useState({
    menu_name: "",
    menu_id: "",
  });

  const conditions = {
    schoolid: schoolId,
    form_category: "reportCard",
  };

  const [formQueryConditions, setFormQueryConditions] = useState({
    schoolid: schoolId,
    form_id: "",
  });

  const [approvalData, setApprovalData] = useState({
    schoolid: schoolId,
    document_type: "fixedForm",
    document_category: "reportCard",
    document_name: "",
  });

  const [approvalConditions, setApprovalConditions] = useState({
    schoolid: schoolId,
    document_type: "fixedForm",
    document_category: "reportCard",
    document_name: "",
  });

  const requestType = "schoolTerm";

  const [requestTitle, setRequestTitle] = useState("");

  const [documentFormData, setDocumentFormData] = useState([]);

  const {
    allFormsQuery: { data: allFormsData },
  } = useAllForms(conditions);

  const {
    componentsToFormsQuery: { data: componentsToFormsData = [], isLoading },
  } = useComponentsToForms(formQueryConditions);

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting({ schoolid: schoolId });

  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod({ schoolid: schoolId });

  const {
    registrationInfoQuery: {
      isLoading: isLoadingRegi,
      data: registrationInfoData,
    },
    hrTeachersQuery: { data: hrTeachersData },
  } = useRegistrationInfo({ schoolid: schoolId }, { hrTeachers: true });

  const { getDocumentForms } = useDocumentForms();
  const getDocumentFormsRef = useRef(getDocumentForms);

  const { getApprovalRequestsByUserId } = useApprovalRequests();
  const getApprovalRequestsByUserIdRef = useRef(getApprovalRequestsByUserId);

  const { user, hasPermission } = useAuthContext();

  const { setIsLoadingModal, handleToastCenterTop, handleToastCenterBottom } =
    useUIContext();

  useEffect(() => {
    if (allFormsData) {
      setFormQueryConditions((prev) => ({
        ...prev,
        form_id: allFormsData[0].id,
      }));

      setActiveMenu({
        menu_name: allFormsData[0].form_name,
        menu_id: allFormsData[0].id,
      });
      setApprovalData((prev) => ({
        ...prev,
        document_name: allFormsData[0].form_name,
      }));
    }
  }, [allFormsData]);

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;
    // let initialUsername = null;

    if (
      filters.schoolYear === null &&
      settingData &&
      settingData.length > 0 &&
      registrationInfoData
    ) {
      const initialData = settingData.sort(
        (a, b) => b.school_year - a.school_year
      )[0];

      initialSchoolYear = initialData.school_year;
      initialSchoolYearId = initialData.id;
      setFilters((filters) => ({
        ...filters,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
      }));

      if (termPeriodData) {
        setTermIdByToday(termPeriodData, setTermId, initialSchoolYearId);
      }

      //학생 리스트
      if (initialSchoolYear) {
        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          initialSchoolYear,
          filters.organPathForSort
        );

        if (hasPermission(null, "can_read")) {
          setStudentList(sortedRows);
        } else if (checkHrteachers(hrTeachersData, "", filters.schoolYear)) {
          const filteredHrTeachersData = hrTeachersData.filter(
            (student) => student.schoolYear === filters.schoolYear
          );
          const filteredRows = sortedRows.filter((r) =>
            filteredHrTeachersData.find((data) => data.username === r.username)
          );
          setStudentList(filteredRows);

          handleToastCenterBottom(t("common.messages.homeroomReadOnly"));
        }
      }
    } else {
      //rows 데이터처리
      if (registrationInfoData && settingData) {
        // console.log("registrationInfoData", registrationInfoData);
        // console.log("filters", filters);

        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          filters.schoolYear,
          filters.organPathForSort
        );

        // console.log("sortedRows", sortedRows);
        if (hasPermission(null, "can_read")) {
          setStudentList(sortedRows);
        } else if (checkHrteachers(hrTeachersData, "", filters.schoolYear)) {
          const filteredHrTeachersData = hrTeachersData.filter(
            (student) => student.schoolYear === filters.schoolYear
          );
          const filteredRows = sortedRows.filter((r) =>
            filteredHrTeachersData.find((data) => data.username === r.username)
          );
          setStudentList(filteredRows);
        }

        // setStudentList(sortedRows);
      }
    }
  }, [
    filters,
    filters.schoolYear,
    handleToastCenterBottom,
    hasPermission,
    hrTeachersData,
    registrationInfoData,
    settingData,
    termPeriodData,
    t,
  ]);

  useEffect(() => {
    if (hrTeachersData && filters.schoolYear)
      if (checkHrteachers(hrTeachersData, "", filters.schoolYear)) {
        const thisYearData = hrTeachersData.filter(
          (r) => r.schoolYear === filters.schoolYear
        );
        const splited = thisYearData[0].organization_path.split("/");

        // console.log("splited", splited);
        // console.log("filterOrgan", filterOrgan);

        setFilters((prevFilter) => ({
          ...prevFilter,
          hierarchyOneId: splited[2],
          hierarchyTwoId: splited[3],
          hierarchyThreeId: splited[4],
          organPathForSort: thisYearData[0].organization_path,
        }));
      }
  }, [filters.schoolYear, hrTeachersData]);

  useEffect(() => {
    if (termPeriodData) {
      const nowPeriod = termPeriodData.filter(
        (r) => r.id.toString() === termId.toString()
      )[0];

      if (nowPeriod) {
        // console.log("nowPeriod", nowPeriod);
        setCurrentPeriod(nowPeriod);
      } else {
        setCurrentPeriod({});
      }
    }
  }, [termId, termPeriodData]);

  useEffect(() => {
    if (studentList && filters.selectedStudent) {
      const selectedObject = studentList.find(
        (r) => r.username === filters.selectedStudent
      );

      if (currentPeriod && Object.keys(currentPeriod).length > 0) {
        setKeyData({
          ...selectedObject,
          currentPeriod: currentPeriod,
        });

        const makeConditions = {
          schoolid: schoolId,
          target_username: filters.selectedStudent,
          document_id: documentFormData[0]?.id || "",
          request_type: requestType,
          term_id: currentPeriod?.id,
        };

        if (makeConditions.document_id) {
          setApprovalConditions(makeConditions);

          getApprovalRequestsByUserIdRef.current.mutate(makeConditions, {
            onSuccess: (data) => {
              setStudentApprovalRequests(data);
            },
            onError: (error) => {
              console.error(error); // 일단 콘솔에 에러를 출력합니다.
              console.error("위와 같은 에러가 났으니 고치삼");
              alert(t("common.messages.errorAlert") + error.message);
            },
          });
        } else {
          setStudentApprovalRequests([]);
        }
      } else {
        setKeyData(selectedObject);
      }
    } else {
      setKeyData({});
    }
  }, [
    currentPeriod,
    documentFormData,
    filters.selectedStudent,
    schoolId,
    studentList,
    t,
  ]);

  useEffect(() => {
    if (approvalData && approvalData.document_name.length > 0) {
      getDocumentFormsRef.current.mutate(approvalData, {
        onSuccess: (data) => {
          setDocumentFormData(data);
        },
        onError: (error) => {
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(t("common.messages.errorAlert") + error.message);
        },
      });
    }
  }, [approvalData, t]);

  const handleQueryCondition = (item) => {
    if (item?.id) {
      setFormQueryConditions((prev) => ({
        ...prev,
        form_id: item.id,
      }));

      setApprovalData((prev) => ({
        ...prev,
        document_name: item.form_name,
      }));
    }
  };

  const handleExtraFunction = () => {
    getApprovalRequestsByUserIdRef.current.mutate(approvalConditions, {
      onSuccess: (data) => {
        setStudentApprovalRequests(data);
      },
      onError: (error) => {
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    const dataName = e.target.selectedOptions[0].getAttribute("data-name");
    let organPath = "";
    if (!dataName) {
      organPath = "N/A";
    } else {
      organPath = dataName + "/" + value;
    }
    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      setTermIdByToday(termPeriodData, setTermId, value);
    }

    if (name === "1") {
      setFilters((filters) => ({
        ...filters,
        hierarchyOneId: value,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      setTermIdByToday(termPeriodData, setTermId, filters.schoolYearId, value);
    }

    if (name === "2") {
      setFilters((filters) => ({
        ...filters,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      setTermIdByToday(
        termPeriodData,
        setTermId,
        filters.schoolYearId,
        filters.hierarchyOneId,
        value
      );
    }

    if (name === "3") {
      setFilters((filters) => ({
        ...filters,
        hierarchyThreeId: value,
        organPathForSort: organPath,
        selectedStudent: "",
      }));
    }

    if (name === "students") {
      setFilters((filters) => ({
        ...filters,
        selectedStudent: value,
      }));
    }

    if (name === "termDate") {
      setTermId(value);
    }
  };

  // const showAllInfo = () => {
  //   if (filters.selectedStudent === "") {
  //     handleToastCenterTop("학생을 선택하세요.");
  //     setValidateInput((prev) => ({ ...prev, user: true }));
  //     return;
  //   } else {
  //     setValidateInput((prev) => ({ ...prev, user: false }));
  //   }
  // };

  const handleModifyMode = (e) => {
    e.preventDefault();

    if (
      keyData.school_year &&
      keyData[1] &&
      keyData[2] &&
      keyData[3] &&
      keyData.currentPeriod?.term_name &&
      keyData.fullname
    ) {
      if (
        !hasPermission(
          "",
          "can_update",
          checkHrteachers(hrTeachersData, keyData.username, keyData.school_year)
        )
      ) {
        handleToastCenterTop(t("common.messages.homeroomModifyOnly"));
        return;
      }

      const onOff = e.target.name;
      if (onOff === "modifyOn") {
        setModifyMode(false);
      } else {
        setModifyMode(true);
      }
    } else {
      console.log(
        "keyData is incomplete. Please ensure all data is available."
      );
      handleToastCenterTop(t("teacher.printingRelated.canModifyAfterSelect"));
    }
  };

  const adminMode = () => {
    console.log("adminMode 임");
  };

  // const addPageNumbers = () => {
  //   const pages = document.querySelectorAll(".print-page");
  //   pages.forEach((page, index) => {
  //     const footer = document.createElement("div");
  //     footer.className = "page-footer";
  //     footer.innerText = `Page ${index + 1}`;
  //     page.appendChild(footer);
  //   });
  // };

  const triggerPrint = useReactToPrint({
    content: () => {
      return contentToPrint.current;
    },
    documentTitle: `${keyData.school_year}-${keyData?.currentPeriod?.term_name}-${keyData[1]}-${keyData[2]}-${keyData[3]}-${keyData.fullname}`,
    onBeforePrint: () => {
      const content = contentToPrint.current;
      if (content) {
        content.style.width = "210mm";
        content.style.height = "auto";
      }
    },
    // onAfterPrint: () => {
    //   console.log("after printing...");
    //   // Cleanup the page numbers after printing
    //   document.querySelectorAll(".page-footer").forEach((el) => el.remove());
    // },
    removeAfterPrint: true,
    pageStyle: `
    @media print {
      @page {
        size: A4;
        margin: 10mm;
      }
      .no-print {
        display: none !important;
      }        
      .print-page {
        display: block;
        width: 210mm;
        margin: auto;
      }
      .page-break {
        page-break-before: always;
      }        
      .pageBreakAfter {
        page-break-before: always;
        page-break-after: avoid;
      }        
    }
    ${
      showPageNumbers
        ? `
        @page {
          @bottom-center {
            content: counter(page);
            font-size: 10pt;
            margin-bottom: 5mm;
          }
        }
      `
        : ""
    }
    body {
      counter-reset: page;
    }
    
    .print-page {
      counter-increment: page;
    }
    
    table {
      page-break-inside: auto;
    }
    
    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    
    thead {
      display: table-header-group;
    }
    
    tfoot {
      display: table-footer-group;
    }
  }
  `,
  });

  // 일단 페이지 넘버는 포기
  // body {
  //   counter-reset: page;
  // }

  //       tfoot:before {
  //         counter-increment: page;
  //         content: "Page " counter(page);
  //         position: fixed;
  //         bottom: 0;
  //         left: 0;
  //         right: 0;
  //         text-align: center;
  //       }

  // .page-footer {
  //   position: fixed;
  //   bottom: 10mm;
  //   left: 0;
  //   right: 0;
  //   text-align: center;
  // }

  const contentToPrint = useRef(null);
  const handlePrint = () => {
    // console.log("keyData", keyData);
    if (
      keyData.school_year &&
      keyData[1] &&
      keyData[2] &&
      keyData[3] &&
      keyData.currentPeriod?.term_name &&
      keyData.fullname
    ) {
      triggerPrint();
    } else {
      console.log(
        "keyData is incomplete. Please ensure all data is available."
      );
      handleToastCenterTop(t("common.messages.printAfterSelectStudent"));
    }
  };

  const handleOpenModal = (e) => {
    const { name } = e.target;

    if (documentFormData.length < 1) {
      handleToastCenterTop(t("teacher.printingRelated.noApprovalSetting"));
      return;
    }

    const matchSchoolStage = documentFormData[0].approving_possible_names?.find(
      (name) => name === keyData[1]
    );

    if (!matchSchoolStage) {
      handleToastCenterTop(t("teacher.printingRelated.noMatchDocument"));
      return;
    }

    // 권한 체크 로직
    const isApprover = documentFormData.some(
      (form) =>
        form.approvalGroupMembers.username === user.username ||
        keyData.hr_teacher_username.some(
          (teacher) => teacher.username === user.username
        )
    );

    if (!isApprover) {
      handleToastCenterTop(t("teacher.printingRelated.noApprovalPermission"));
      return;
    }

    let schoolYear = "";
    let schoolTermName = "";
    let schoolStage = "";
    let studentYear = "";
    let className = "";
    let documentLabel = "";
    let studentName = "";
    let studentId = "";
    let preTitle = "";
    const bulkApproval = "일괄결재";

    // 1단계 결재권자 설정
    const firstStageApprover = keyData.hr_teacher_username.find(
      (teacher) => teacher.username === user.username
    );

    const firstStageUsername = firstStageApprover
      ? firstStageApprover.username
      : keyData.hr_teacher_username[0].username;
    const firstStageFullname = firstStageApprover
      ? firstStageApprover.tname
      : "담임";

    // approvalLines 설정
    const approvalLines = [
      {
        username: firstStageUsername,
        fullname: firstStageFullname,
        stage: 1,
      },
      ...documentFormData.map((member, index) => ({
        username: member.approvalGroupMembers.username,
        fullname: member.approvalGroupMembers.user.fullname,
        stage: member.approvalGroupMembers.stage,
      })),
    ];

    setApprovalLines(approvalLines);

    if (name === "approval") {
      if (
        keyData.school_year &&
        keyData[1] &&
        keyData[2] &&
        keyData[3] &&
        keyData.currentPeriod?.term_name &&
        keyData.fullname
      ) {
        schoolYear = keyData.currentPeriod.school_year;
        schoolTermName = keyData.currentPeriod.term_name;
        schoolStage = keyData["1"];
        studentYear = keyData["2"];
        className = keyData["3"];
        documentLabel = documentFormData[0].document_label;
        studentName = keyData.fullname;
        studentId = keyData.username;

        preTitle =
          schoolYear +
          "-" +
          schoolTermName +
          "-" +
          schoolStage +
          "-" +
          studentYear +
          "-" +
          className +
          "-" +
          documentLabel +
          "-" +
          studentName +
          "-" +
          studentId;

        setRequestTitle(preTitle);
        setIsModalOpen(true);
        setStudentListForApproval(
          studentList.filter((r) => r.username === studentId)
        );
      } else {
        handleToastCenterTop(t("common.messages.printAfterSelectStudent"));
      }
    } else if (name === "bulkApproval") {
      if (
        keyData.school_year &&
        keyData[1] &&
        keyData[2] &&
        keyData[3] &&
        keyData.currentPeriod?.term_name
      ) {
        schoolYear = keyData.currentPeriod.school_year;
        schoolTermName = keyData.currentPeriod.term_name;
        schoolStage = keyData["1"];
        studentYear = keyData["2"];
        className = keyData["3"];
        documentLabel = documentFormData[0].document_label;

        preTitle =
          schoolYear +
          "-" +
          schoolTermName +
          "-" +
          schoolStage +
          "-" +
          studentYear +
          "-" +
          className +
          "-" +
          documentLabel +
          "-" +
          bulkApproval;

        setStudentListForApproval(studentList);
        setRequestTitle(preTitle);
        setIsModalOpen(true);
      } else {
        handleToastCenterTop(t("teacher.printingRelated.printAfterSelect"));
      }
    }

    //2024-1-중등-7-소민-학기성적표-여중등-middle.girl@sampleschool
    //2024-1-중등-7-소민-학기성적표-일괄결재
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (isLoadingRegi) {
    return <LoadingInside />;
  }

  if (!hasPermission(null, "can_access", checkHrteachers(hrTeachersData))) {
    return (
      <SimpleAlertMessageBlock
        message={
          t("common.messages.homeroomAccessOnly") ||
          "담임 또는 접근 권한이 있는 사람만 접근할 수 있습니다."
        }
      />
    );
  }

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <LeftMenuSidebar
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              menuData={allFormsData}
              extraFunction={handleQueryCondition}
              itemName="form_name"
              itemLabel="form_label"
            />
          </div>
          <div className="col-12 col-md-10">
            <div className="d-flex justify-content-end flex-wrap mt-2">
              <FiltersBar
                settingData={settingData}
                handleDropdownChange={handleDropdownChange}
                filters={filters}
                studentList={studentList}
                // validateInput={validateInput}
                termPeriodData={termPeriodData}
                termId={termId}
                currentPeriod={currentPeriod}
                modifyMode={modifyMode}
                disabled={modifyMode}
              />
            </div>
            <div className="d-flex justify-content-end flex-wrap mb-3 mt-2">
              <ReportCardButtonBar
                handlePrint={handlePrint}
                handleModifyMode={handleModifyMode}
                initiateApproval={handleOpenModal}
                adminMode={adminMode}
                contentToPrint={contentToPrint}
                modifyMode={modifyMode}
                showPageNumbers={showPageNumbers}
                setShowPageNumbers={setShowPageNumbers}
                showAdditionalInfo={showAdditionalInfo}
                setShowAdditionalInfo={setShowAdditionalInfo}
                componentsToFormsData={componentsToFormsData}
              />
            </div>
            <div
              id="gradeReportPrintArea"
              className="d-flex justify-content-center print-page"
              ref={contentToPrint}
            >
              <div style={{ width: "205mm" }}>
                {/* <table className="" style={{ width: "210mm" }}>
                <thead>
                  <tr>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="1"></td>
                    <td colSpan="1" className="p-2"> */}
                {!isLoading &&
                  componentsToFormsData &&
                  componentsToFormsData.map((item, index) => (
                    // <div key={`${item.id}-${item.order}-${index}`}>
                    <DynamicComponentsRenderer
                      components={item.components}
                      customizedContents={item.customized_contents}
                      keyData={keyData}
                      modifyMode={modifyMode}
                      setIsLoadingModal={setIsLoadingModal}
                      key={`${item.id}-${item.order}-${index}`}
                      componentsToFormId={item.id}
                      approvalData={studentApprovalRequests}
                      showAdditionalInfo={showAdditionalInfo}
                    />
                    // </div>
                  ))}
              </div>
              {/* </td>
                    <td colSpan="1"></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <div style={{ height: "10mm" }}></div>
                    </td>
                  </tr>
                </tfoot>
              </table> */}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ApprovalProcess
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          documentData={keyData}
          approvalData={approvalData}
          requestType={requestType}
          requestTitle={requestTitle}
          studentListForApproval={studentListForApproval}
          documentFormData={documentFormData}
          approvalLines={approvalLines}
          handleExtraFunction={handleExtraFunction}
        />
      )}
    </div>
  );
}
