import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  setTimeout(() => {
    navigate("/login");
  }, 4000);
  return (
    <div>
      <h2>Not Found ({t("common.commonPages.notFound")})</h2>
    </div>
  );
}
