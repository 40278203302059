import React from "react";
import styles from "./CSS/ToastPromptTop.module.css";
import { useUIContext } from "../context/UIContext";
import { useTranslation } from "react-i18next";

export default function ToastPromptTop() {
  const { toastPromptModalState, setToastPromptModalState } = useUIContext();
  const { t } = useTranslation();
  if (!toastPromptModalState.visible) return null;

  const handleConfirm = () => {
    if (toastPromptModalState.onConfirm) toastPromptModalState.onConfirm();
    setToastPromptModalState({
      visible: false,
      message: "",
      onConfirm: null,
      onCancel: null,
    });
  };

  const handleCancel = () => {
    if (toastPromptModalState.onCancel) toastPromptModalState.onCancel();
    setToastPromptModalState({
      visible: false,
      message: "",
      onConfirm: null,
      onCancel: null,
    });
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.modalContent}>
          <p>{toastPromptModalState.message}</p>
          <button onClick={handleConfirm} className="btn btn-primary">
            {t("common.labels.confirm") || "확인"}
          </button>
          <button onClick={handleCancel} className="btn btn-secondary">
            {t("common.labels.cancel") || "취소"}
          </button>
        </div>
      </div>
    </div>
  );
}
