import React, { useState } from "react";
// import About from "./components/About";
import useUsers from "../../hooks/useUsers";
import ModifyModal from "./components/ModifyModal";
import HelpHyperLink from "../../unicomponents/HelpHyperLink";
import { useTranslation } from "react-i18next";
import useFlexibleData from "../../hooks/useFlexibleData";

export default function MyInfo() {
  const { t } = useTranslation();
  const {
    userTeacherInfoQuery: { isLoading, error, data: userData },
  } = useUsers();

  const {
    flexibleDataQuery: { data: flexibleData },
  } = useFlexibleData({
    schoolid: userData?.schoolid,
    main_category: "schoolSetting",
  });

  const [modalOpen, setModalOpen] = useState(false);

  // flexibleData에서 표시할 필드 목록 가져오기
  const displayFields =
    (flexibleData &&
      flexibleData.length > 0 &&
      flexibleData[0].data_value?.myInfo) ||
    [];

  // 필드 레이블 매핑
  const fieldLabels = {
    korean_id_no: t("teacher.studentInfo.registerationNo") || "주민등록번호",
    phone_number: t("teacher.studentInfo.studentPhone") || "학생연락처",
    zipcode: t("teacher.studentInfo.zipcode") || "우편번호",
    address: t("teacher.studentInfo.address") || "주소",
    address_detail: t("teacher.studentInfo.addressDetail") || "상세주소",
    schoolid: t("common.labels.schoolId") || "학교 ID",
    entrance_date: t("teacher.studentInfo.entranceDate") || "입학일",
    "academic_record.elementary": `${
      t("teacher.studentInfo.elementary") || "초등"
    } ${t("teacher.studentInfo.academicRecord") || "학적사항"}`,
    "academic_record.middle": `${t("teacher.studentInfo.middle") || "중등"} ${
      t("teacher.studentInfo.academicRecord") || "학적사항"
    }`,
    "academic_record.high": `${t("teacher.studentInfo.high") || "고등"} ${
      t("teacher.studentInfo.academicRecord") || "학적사항"
    }`,
    "siblings.sname": t("teacher.studentInfo.siblings") || "형제",
    father_name: t("teacher.studentInfo.fatherName2") || "아버지 이름",
    father_phone: t("teacher.studentInfo.fatherPhone2") || "아버지 연락처",
    mother_name: t("teacher.studentInfo.motherName2") || "어머니 이름",
    mother_phone: t("teacher.studentInfo.motherPhone2") || "어머니 연락처",
    memo: t("teacher.studentInfo.memo") || "메모",
  };

  // 중첩된 객체에서 값을 가져오는 함수
  function getNestedValue(obj, path) {
    const keys = path.split(".");
    return keys.reduce(
      (o, key) => (o && o[key] !== undefined ? o[key] : null),
      obj
    );
  }

  return (
    <div className="vw-100">
      {isLoading && <p>Loading...</p>}
      {error && <p>error...</p>}
      {modalOpen && (
        <ModifyModal setModalOpen={setModalOpen} userData={userData} />
      )}
      <div className="container">
        <div className="row border rounded mt-2">
          <div className="ps-3 pt-3 pb-3 col-md-3">
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => setModalOpen(true)}
              >
                {t("common.labels.modify") || "수정"}
              </button>
              <HelpHyperLink link={"https://youtu.be/R6JAFDF2bZ4"} size={25} />
            </div>
            <div className="text-center">
              <img
                src={
                  userData && userData.profile_link !== null
                    ? userData.profile_link
                    : "/images/default_profile.png"
                }
                className="navbarImg dropdown-toggle"
                alt="내사진"
                role="button"
                height="150"
                width="150"
                style={{ objectFit: "cover", borderRadius: 9999 }}
                onClick={() => setModalOpen(true)}
              />
            </div>
            <div>
              <span className="fullname">{userData?.fullname}</span>
            </div>
            <div>
              <span className="username">{userData?.username}</span>
            </div>
            <div>
              <span className="gender">{userData?.gender}</span>
            </div>
            <div>
              <span className="birthday">
                {userData?.birthday && userData.birthday}
              </span>
            </div>
            <div>
              <span className="schoolRole">
                {userData?.role === "teacher"
                  ? t("common.user.faculty") || "교직원"
                  : ""}
              </span>
            </div>
            <div>
              <span className="email">{userData?.email && userData.email}</span>
            </div>
          </div>
          <div className="pt-3 pb-3 col-md-9">
            {userData?.role === "student" &&
              userData?.studentinfos &&
              userData.studentinfos.length > 0 &&
              flexibleData &&
              flexibleData.length > 0 &&
              flexibleData[0].data_value?.myInfo &&
              flexibleData[0].data_value.myInfo.length > 0 && (
                <div className="student-info-container">
                  <h5 className="ms-2 mb-3 border-bottom pb-2">
                    {t("teacher.studentInfo.studentBasicInfo") ||
                      "학생 기본정보"}
                  </h5>
                  <div className="ms-3">
                    {/* 기본 필드 표시 */}
                    {displayFields.map((field) => {
                      // academic_record와 siblings는 특별 처리
                      if (field === "academic_record" || field === "siblings") {
                        return null; // 이 필드들은 별도로 처리하므로 여기서는 건너뜀
                      }

                      return (
                        <div key={field} className="mb-3">
                          <div className="d-flex">
                            <div
                              className="student-info-label fw-bold"
                              style={{ width: "200px" }}
                            >
                              {fieldLabels[field] || field}:
                            </div>
                            <div className="student-info-value">
                              {userData.studentinfos[0][field] !== undefined &&
                              userData.studentinfos[0][field] !== null &&
                              userData.studentinfos[0][field] !== ""
                                ? userData.studentinfos[0][field]
                                : "-"}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {/* 형제자매 정보 표시 */}
                    {displayFields.includes("siblings") && (
                      <div className="mb-3">
                        <div className="d-flex">
                          <div
                            className="student-info-label fw-bold"
                            style={{ width: "200px" }}
                          >
                            {fieldLabels["siblings.sname"]}:
                          </div>
                          <div className="student-info-value">
                            {userData.studentinfos[0].siblings &&
                            userData.studentinfos[0].siblings.length > 0
                              ? userData.studentinfos[0].siblings
                                  .map((sib) => sib.sname)
                                  .join(", ")
                              : "-"}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* 학적 정보 표시 */}
                    {displayFields.includes("academic_record") &&
                      ["elementary", "middle", "high"].map((level) => (
                        <div key={`academic_${level}`} className="mb-3">
                          <div className="d-flex">
                            <div
                              className="student-info-label fw-bold"
                              style={{ width: "200px" }}
                            >
                              {fieldLabels[`academic_record.${level}`]}:
                            </div>
                            <div className="student-info-value">
                              {getNestedValue(
                                userData.studentinfos[0],
                                `academic_record.${level}`
                              ) || "-"}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
