import { useMutation } from "@tanstack/react-query";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";
import JubileeAwardService from "../service/jubileeAward";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useJubileeAward(conditions) {
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const jubileeAwardService = new JubileeAwardService(httpClient);
  // const queryClient = useQueryClient();

  const jubileeAwardQuery = useMutation((queryConditions) => {
    return jubileeAwardService.findJubileeAwardWithDatesClassByConditions(
      queryConditions
    );
  });

  // console.log("initialSchoolYear2", initialSchoolYear);

  // 보내는 데이터
  const createJubileeAward = useMutation(
    (jubileeAwardInfo) =>
      jubileeAwardService.createJubileeAward(jubileeAwardInfo),
    {
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error creating:", error);
        // Add your error handling logic here
      },
    }
  );

  const updateJubileeAward = useMutation(
    (jubileeAwardInfo) =>
      jubileeAwardService.updateJubileeAward(jubileeAwardInfo),
    {
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating:", error);
      },
    }
  );

  return {
    jubileeAwardQuery,
    createJubileeAward,
    updateJubileeAward,
  };
}
