import React, { useState } from "react";
import { useUIContext } from "../../../context/UIContext";
import useUsers from "../../../hooks/useUsers";
import { useAuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { MdClose, MdOutlineCancel } from "react-icons/md";
import { useTranslation } from "react-i18next";

/*
1. 연결요청시 자녀이름, 자녀성별, 자녀ID를 보내면
중간에 부모 정보 찾아서 schoolid 적용하고, 
서버에서 fullanme, gender, username으로 일치하는 학생 찾아서
updateUser에서 학생 것에 guardian에 추가
[]안에
{"username": "username email", "fullname": "부모이름", 
"gender": "부모성별", "mobile": "부모전화번호", 
"status": "pending"}
최대 2명까지만 가능하게

하지만 이렇게 넣으면 조인이 어렵네.
업데이트도 좀 어렵고.
어려워도 할 수 있지 뭐.


@@(0) useUsers에 query 새로 만들어서 접속한 학부모의 아이디가 자녀 목록에 있는지 찾아와서 가져와서 아래 연결 대기중 이나 완료 표시하기 
+ (1) useUsers에 linkRequest 함수 새로 만들고 서버에도 만들어야 함
(2) mutate로 보내는 것 만들기 (validate 후에)
  - success하면 성공 안내 메시지 나오고 아래 연결 대기중 뜨기
(3) 연결 대기중이나 완료를 취소 할 수도 있음. (정말 묻기)

(4) 학생계정 페이지에서 수락/삭제 할 수 있도록


자녀와 연결 안되어 있으면 login 페이지로 튕기도록
그런데 로그인 상태면 자녀계정 연동 페이지로 이동
*/

export default function LinkStudent() {
  const navigate = useNavigate();
  const { user, refreshUser } = useAuthContext();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    fullname: "",
    gender: "남",
    username: "",
    status: "pending",
    parentUsername: user?.username,
  });

  const [requested, setRequested] = useState(false);

  const {
    userGuardianQuery: { data: guardianData },
    linkRequest,
    cancelLinkRequest,
  } = useUsers({ guardian: true });

  const { handleToastCenterTop, handleToastPromptModal, setIsLoadingModal } =
    useUIContext();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.fullname || !formData.username) {
      handleToastCenterTop(t("parent.linkStudent.inputMessage"));
      return;
    }

    if (
      guardianData?.some(
        (student) =>
          student.username === formData.username &&
          student.guardian.some((g) => g.username === user.username)
      )
    ) {
      handleToastCenterTop(t("parent.linkStudent.alreadyRequested"));
      return;
    }

    try {
      formData.parentUsername = user?.username;
      formData.status = "pending";
      formData.role = "student";

      setIsLoadingModal(true);
      //username 존재 체크용으로 잘 쓰여야겠는데
      linkRequest.mutate(formData, {
        onSuccess: async (success) => {
          setIsLoadingModal(false);

          setRequested(true);
          // Reset form after successful submission
          setFormData({
            fullname: "",
            gender: "남",
            username: "",
          });

          handleToastCenterTop(t("parent.linkStudent.linkRequestSuccess"));
        },
        onError: (error) => {
          alert(error);
          setIsLoadingModal(false);
        },
      });
    } catch (error) {
      handleToastCenterTop(
        error.message || t("parent.linkStudent.linkRequestError")
      );
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handleCancel = (username) => {
    handleToastPromptModal(
      t("parent.linkStudent.cancelLinkRequest"),
      () => {
        const deleteData = {
          username: username,
          parentUsername: user?.username,
        };

        try {
          setIsLoadingModal(true);

          //username 존재 체크용으로 잘 쓰여야겠는데
          cancelLinkRequest.mutate(deleteData, {
            onSuccess: async () => {
              refreshUser();
              setIsLoadingModal(false);
            },
            onError: (error) => {
              alert(error);
              setIsLoadingModal(false);
            },
          });
        } catch (error) {
          handleToastCenterTop(
            error.message || t("parent.linkStudent.linkRequestError")
          );
        }
      },
      () => {
        return;
      }
    );
  };

  return (
    <div
      style={{
        maxWidth: "500px",
        margin: "10px auto",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "8px",
      }}
    >
      <div className="d-flex justify-content-end align-items-center">
        <button
          className="btn btn-link p-0"
          onClick={handleClose}
          style={{ color: "black" }}
        >
          <MdClose size={24} />
        </button>
      </div>
      <div>
        <h3 style={{ fontWeight: "bold" }}>
          {t("parent.linkStudent.welcome")}
        </h3>
      </div>
      <div>{t("parent.linkStudent.childAccount")}</div>
      <p className="mb-4"></p>

      <form onSubmit={handleSubmit}>
        <div className="d-flex align-items-center mt-2">
          <label htmlFor="fullname" style={{ minWidth: "80px" }}>
            {t("parent.linkStudent.childName")}
          </label>
          <input
            type="text"
            id="fullname"
            name="fullname"
            value={formData.fullname}
            onChange={handleChange}
            placeholder={t("parent.linkStudent.childNamePlaceholder")}
            className="form-control"
            required
          />
        </div>

        <div className="d-flex align-items-center mt-2">
          <label htmlFor="gender" style={{ minWidth: "80px" }}>
            {t("parent.linkStudent.childGender")}
          </label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            className="form-control"
            required
          >
            <option value="남">{t("common.labels.male") || "남"}</option>
            <option value="여">{t("common.labels.female") || "여"}</option>
          </select>
        </div>

        <div
          title={t("parent.linkStudent.tooltipMessage")}
          className="d-flex align-items-center mt-2"
        >
          <label htmlFor="username" style={{ minWidth: "80px" }}>
            {t("parent.linkStudent.child")}ID
          </label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="username@schoolid"
            className="form-control"
            required
          />
        </div>

        <button type="submit" className="btn btn-primary w-100 mt-3">
          {t("parent.linkStudent.linkRequest")}
        </button>
      </form>

      {requested && (
        <div className="p-3 mt-2" style={{ color: "#666666" }}>
          {t("parent.linkStudent.requestCompleted")}
          <br />
          {t("parent.linkStudent.waitConfirmation")}
        </div>
      )}
      <div className="mt-2">
        {guardianData?.some((student) =>
          student.guardian.some(
            (g) => g.status === "pending" && g.username === user.username
          )
        ) && (
          <div className="alert alert-info">
            <p className="mb-0">{t("parent.linkStudent.pendingLink")}:</p>
            <ul className="list-unstyled mb-0 ms-2">
              {guardianData
                ?.filter((student) =>
                  student.guardian.some(
                    (g) =>
                      g.status === "pending" && g.username === user.username
                  )
                )
                .map((student) => (
                  <li
                    key={student.username}
                    className="d-flex justify-content-between align-items-center p-1 px-2 mb-1 mt-1 rounded hover-shadow"
                    style={{
                      backgroundColor: "#f8f9fa",
                      transition: "all 0.2s ease",
                      border: "1px solid #dee2e6",
                    }}
                  >
                    <span>
                      {student.fullname} ({student.username}){" "}
                      <span style={{ fontSize: "12px" }}>
                        {t("parent.linkStudent.requestDate")}:
                        {student.guardian[0].request_date}
                      </span>
                    </span>
                    <MdOutlineCancel
                      role="button"
                      className="text-danger"
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleCancel(student.username)}
                    />
                  </li>
                ))}
            </ul>
          </div>
        )}

        {guardianData?.some((student) =>
          student.guardian.some(
            (g) => g.status === "linked" && g.username === user.username
          )
        ) && (
          <div className="alert alert-success mb-2">
            <p>{t("parent.linkStudent.linked")}:</p>
            <ul className="list-unstyled mb-0">
              {guardianData
                ?.filter((student) =>
                  student.guardian.some(
                    (g) => g.status === "linked" && g.username === user.username
                  )
                )
                .map((student) => (
                  <li
                    key={student.username}
                    className="d-flex justify-content-between align-items-center p-1 px-2 mb-1 mt-1 rounded hover-shadow"
                    style={{
                      backgroundColor: "#f8f9fa",
                      transition: "all 0.2s ease",
                      border: "1px solid #dee2e6",
                    }}
                  >
                    <span>
                      {student.fullname} ({student.username}){" "}
                      <span style={{ fontSize: "12px" }}>
                        {t("parent.linkStudent.linkedDate")}:
                        {student.guardian[0].request_date}
                      </span>
                    </span>
                    <MdOutlineCancel
                      role="button"
                      className="text-danger"
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleCancel(student.username)}
                    />
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>

      {requested && (
        <div className="p-3 pt-0 mt-0" style={{ color: "#666666" }}>
          {t("parent.linkStudent.multipleChildren")}
          <br />
          {t("parent.linkStudent.multipleChildrenMessage")}
        </div>
      )}
    </div>
  );
}
