import React, { useEffect, useState } from "react";
import useOrganizationSetting from "../../hooks/useOrganizationSetting";
import useRegistrationInfo from "../../hooks/useRegistrationInfo";
import { useLocation } from "react-router-dom";
import { generateRegistrationRows } from "../../util/dealingArrObj";
import { useAuthContext } from "../../context/AuthContext";
import { checkHrteachers } from "../../util/checkPermissions";
import ImageUploader from "../formComponents/input/ImageUploader";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

export default function RegistrationVariousRecordInputTable({
  organSettingInfo,
  inputData,
  studentInputData = [],
  handleSaveState,
  handleSaveClick,
  handleSaveAll,
  savedRows,
  savedBulk,
  addfontSize = "",
  addMinWidth = "",
  statistics = [],
  extraSettings = "",
  hrTeachersData,
  exportFileName = "students_records",
  exportable = false,
  exportButtonLabel = "Excel",
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = {
    schoolid: schoolId,
  };

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    registrationInfoQuery: { data: registrationInfoData },
  } = useRegistrationInfo(conditions);

  const { hasPermission } = useAuthContext();

  //필터된 학생들 정보를 나오게 하려고
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (organSettingInfo.schoolYear) {
      //rows 데이터처리
      if (registrationInfoData && settingData) {
        // console.log("registrationInfoData", registrationInfoData);

        //사실 Mui dataGrid row용으로 만든 건데, 유용해서 계속 쓰자.
        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          organSettingInfo.schoolYear,
          organSettingInfo.organPathForSort
        );

        if (hasPermission(null, "can_read")) {
          setRows(sortedRows);
        } else if (
          checkHrteachers(hrTeachersData, "", organSettingInfo.schoolYear)
        ) {
          const filteredRows = sortedRows.filter((r) =>
            hrTeachersData.find(
              (data) => data.organization_path === r.organization_path
            )
          );
          setRows(filteredRows);
        }
      }
    }

    //여기 dependency가 있어서 schoolYear와 organPathForSort 필터에 맞게 필터되어서 배포된다.
  }, [
    settingData,
    organSettingInfo.schoolYear,
    registrationInfoData,
    organSettingInfo.organPathForSort,
    hasPermission,
    hrTeachersData,
  ]);

  const calculateStyle = (baseStyle, addValue) => {
    const baseSize = parseFloat(baseStyle) || 0;
    const addSize = parseFloat(addValue) || 0;
    return `${baseSize + addSize}px`;
  };

  function renderInput(r, row, value, handleSaveState) {
    switch (r.inputs) {
      case "select":
        return (
          <select
            className="select-like-span"
            onChange={(e) => handleSaveState(e, row.username, r.id)}
            value={value}
            name={r.name}
          >
            {r.options &&
              r.options.map((option, index) => (
                <option key={`${index}-${row.id}`} value={option?.value || ""}>
                  {option?.label || ""}
                </option>
              ))}
          </select>
        );
      case "textarea":
        return (
          <textarea
            className="input-like-span"
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, r.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
            rows={r?.options[0]?.row || 5}
            style={{ width: `${r?.options[0]?.width || 200}px` }}
          />
        );
      case "empty":
        return value ? value : "";
      case "image":
        return (
          <ImageUploader
            initialImage={value} // 초기 이미지 URL
            onFileSelect={({ value, name }) =>
              handleSaveState(
                { target: { value, name } }, // name과 value를 함께 전달
                row.username,
                r.id
              )
            }
            name={r.name}
            id={row.username + r.name}
            options={r.options}
          />
        );
      case "text":
      default:
        return (
          <input
            className="input-like-span"
            type={r.inputs || "text"}
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, r.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
          />
        );
    }
  }

  const countedStudents = inputData.reduce((acc, r) => {
    acc[r.name] = 0; // 모든 r.name을 0으로 초기화
    return acc;
  }, {});

  // Excel 내보내기 함수 추가
  const exportToExcel = () => {
    // 헤더 행 생성
    const headers = [
      t("common.labels.schoolStage"),
      "학년",
      "반",
      t("common.user.fullname"),
      ...inputData.map((r) => r.title),
    ];

    // 데이터 행 생성
    const data = rows.map((row) => {
      const rowData = [
        row[1], // schoolStage
        row[2], // grade
        row[3], // class
        row.fullname, // name
        ...inputData.map((r) => {
          const studentData = studentInputData?.find(
            (s) => s.username === row.username
          );
          return studentData?.value_object?.[r.name] ?? "";
        }),
      ];
      return rowData;
    });

    // 통계 행 추가 (statistics가 있는 경우)
    if (statistics.length > 0) {
      const statsRow = [
        "총원",
        "",
        rows.length.toString(),
        "출석",
        ...inputData.map((r) => {
          const count = countedStudents[r.name] || 0;
          const percentage =
            rows.length > 0 ? ((count / rows.length) * 100).toFixed(1) : "0";
          return `${count}명 / ${percentage}%`;
        }),
      ];
      data.push(statsRow);
    }

    // 워크시트 생성
    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

    // 워크북 생성
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // 파일 저장
    XLSX.writeFile(wb, `${exportFileName}.xlsx`);
  };

  return (
    <>
      {exportable && (
        <div className="d-flex justify-content-end mb-2">
          <button
            className="btn btn-sm btn-secondary"
            onClick={exportToExcel}
            style={{
              backgroundColor: "#2e7d32",
              color: "white",
              border: "none",
              borderRadius: "4px",
              padding: "6px 12px",
              fontSize: "13px",
              fontWeight: "bold",
              cursor: "pointer",
              transition: "background-color 0.2s",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {exportButtonLabel}
          </button>
        </div>
      )}
      <table
        className="table table-bordered text-center"
        style={{ fontSize: "13px" }}
      >
        <thead>
          <tr className="table-secondary p-1">
            <th className="p-1 align-middle" style={{ minWidth: "50px" }}>
              {t("common.labels.schoolStage")}
            </th>
            <th className="p-1 align-middle" style={{ minWidth: "50px" }}>
              학년
            </th>
            <th className="p-1 align-middle" style={{ minWidth: "50px" }}>
              반
            </th>
            <th className="p-1 align-middle" style={{ minWidth: "60px" }}>
              {t("common.user.fullname") || "이름"}
            </th>
            {inputData &&
              inputData.map((r) => (
                <th
                  className="align-middle p-0"
                  style={{
                    minWidth: calculateStyle("60px", addMinWidth),
                    fontSize: calculateStyle("11px", addfontSize),
                  }}
                  key={`th-${r.id}`}
                >
                  {r.title} <br />
                  {extraSettings === "termName"
                    ? r?.extra_settings?.termName + t("common.labels.termName")
                    : ""}
                  {r.description ? `(${r.description})` : ""}
                </th>
              ))}
            <th className="align-middle p-1" style={{ minWidth: "72px" }}>
              {handleSaveAll && (
                <button
                  className="btn btn-sm btn-primary p-0 pe-1 ps-1"
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    handleSaveAll();
                  }}
                >
                  {savedBulk
                    ? t("common.labels.complete") || "완료"
                    : t("common.labels.saveAll")}
                </button>
              )}
            </th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "13px" }} className="align-middle">
          {rows?.map((row) => (
            <tr key={row.id}>
              <td className="p-1">{row[1]}</td>
              <td className="p-1">{row[2]}</td>
              <td className="p-1">{row[3]}</td>
              <td className="p-1">{row.fullname}</td>
              {inputData &&
                inputData.map((r) => {
                  const studentData = studentInputData?.find(
                    (s) => s.username === row.username
                  );
                  const value = studentData?.value_object?.[r.name] ?? "";
                  const isInStatistics = statistics.includes(
                    studentData?.value_object?.[r.name]
                  );

                  // countedStudents[r.name]가 undefined인 경우 0으로 초기화
                  // if (!countedStudents[r.name]) {
                  //   countedStudents[r.name] = 0;
                  // }

                  if (isInStatistics) {
                    countedStudents[r.name] += 1;
                  }

                  return (
                    <td key={`th-${r.id}-${row.id}`} className="p-1">
                      {renderInput(r, row, value, handleSaveState)}
                    </td>
                  );
                })}
              <td className="p-1">
                <button
                  className="btn btn-sm p-0 pe-1 ps-1 btn-primary"
                  style={{ fontSize: "12px" }}
                  onClick={() => {
                    handleSaveClick(row);
                  }}
                >
                  {savedRows.includes(row.username)
                    ? t("common.labels.complete") || "완료"
                    : t("common.labels.save") || "저장"}
                </button>
              </td>
            </tr>
          ))}
          {statistics.length > 0 && (
            <tr>
              <th colSpan={2}>총원</th>
              <th>{rows.length}</th>
              <th>출석</th>
              {inputData &&
                inputData.map((r) => {
                  // value_object[r.name]가 statistics 배열에 포함된 경우만 필터링

                  return (
                    <td key={`td-${r.id}-${r.name}`} className="p-1">
                      {countedStudents[r.name]}명 /{" "}
                      {rows.length > 0
                        ? (
                            (countedStudents[r.name] / rows.length) *
                            100
                          ).toFixed(1)
                        : 0}
                      %
                    </td>
                  );
                })}
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
