import React, { useState, useEffect, useCallback } from "react";
import defaultStyles from "../CSS/tableStyles/DynamicTable.module.css";
import stripedStyles from "../CSS/tableStyles/StripedTable.module.css";
import spreadSheetStyles from "../CSS/tableStyles/SpreadSheetTable.module.css";
import cleanModernStyles from "../CSS/tableStyles/CleanModernTable.module.css";
import bootstrapTableStyles from "../CSS/tableStyles/BootstrapTable.module.css";
import * as XLSX from "xlsx";

// 스타일 맵 정의
const styleMap = {
  default: defaultStyles,
  striped: stripedStyles,
  spreadSheet: spreadSheetStyles,
  cleanModern: cleanModernStyles,
  bootstrap: bootstrapTableStyles,
};

/**
 * DynamicTable - 다양한 기능을 갖춘 테이블 컴포넌트
 *
 * 기능:
 * - 다양한 테이블 스타일 (default, striped, spreadSheet, cleanModern)
 * - 페이지네이션
 * - 정렬
 * - 검색
 * - Excel 내보내기
 * - 입력 필드 (input, textarea)
 *
 * @param {Object} tableConfig - 테이블 구성 설정
 * @param {Array} tableConfig.headers - 테이블 헤더 설정
 * @param {Array} tableConfig.columns - 테이블 열 설정
 * @param {Array} tableConfig.rows - 테이블 행 데이터
 * @param {string} selectedItem - 선택된 항목 ID
 * @param {Object} itemData - 항목 데이터
 * @param {string} dataStructure - 데이터 구조 타입 ('nested' 또는 'flat', 기본값: 'nested')
 * @param {Function} handleChange - 데이터 변경 핸들러
 * @param {string} group - 그룹 식별자
 * @param {string} tableStyle - 테이블 스타일 ('default', 'striped', 'spreadSheet', 'cleanModern')
 * @param {boolean} pagination - 페이지네이션 활성화 여부
 * @param {number} pageSize - 페이지당 행 수
 * @param {number} initialPage - 초기 페이지
 * @param {boolean} sortable - 정렬 활성화 여부
 * @param {string} initialSortField - 초기 정렬 필드
 * @param {string} initialSortDirection - 초기 정렬 방향 ('asc' 또는 'desc')
 * @param {Function} onSortChange - 정렬 변경 핸들러
 * @param {boolean} searchable - 검색 활성화 여부
 * @param {string} searchPlaceholder - 검색 입력란 플레이스홀더
 * @param {Array} searchFields - 검색할 필드 배열
 * @param {Function} onSearch - 검색 핸들러
 * @param {boolean} exportable - Excel 내보내기 활성화 여부
 * @param {string} exportFileName - 내보낼 파일 이름
 * @param {Array} customExportData - 사용자 정의 내보내기 데이터
 * @param {string} exportButtonLabel - 내보내기 버튼 레이블
 * @param {Function} onRowClick - 행 클릭 시 호출될 함수, 클릭된 행 데이터를 인자로 받음
 * @param {boolean} rowClickable - 행 클릭 가능 여부
 * @param {string} selectedRowId - 선택된 행 ID
 * @param {Function} setSelectedRowId - 선택된 행 ID를 설정하는 함수
 *
 * @example
 * // 기본 사용법
 * <DynamicTable
 *   tableConfig={{
 *     headers: [
 *       { label: "번호", style: { width: "60px" }, sortField: "number" },
 *       { label: "이름", style: { width: "140px" }, sortField: "name" }
 *     ],
 *     columns: [
 *       { valueKey: "number" },
 *       { valueKey: "name" }
 *     ],
 *     rows: [
 *       { number: 1, name: "홍길동" },
 *       { number: 2, name: "김철수" }
 *     ]
 *   }}
 *   tableStyle="default"
 * />
 *
 * @example
 * // 고급 기능 사용
 * <DynamicTable
 *   tableConfig={tableConfig}
 *   selectedItem={selectedStudent}
 *   itemData={studentData}
 *   handleChange={handleChange}
 *   group="students"
 *   tableStyle="cleanModern"
 *   pagination={true}
 *   pageSize={10}
 *   sortable={true}
 *   searchable={true}
 *   searchFields={["name", "input:score", "textarea:comment"]}
 *   exportable={true}
 *   exportFileName="학생_데이터"
 * />
 */
function DynamicTable({
  tableConfig,
  selectedItem,
  itemData,
  dataStructure = "nested", // 'nested' 또는 'flat'
  handleChange,
  group = "",
  tableStyle = "default", // 기본 스타일은 'default'
  // 페이지네이션 관련 props 추가
  pagination = false,
  pageSize = 10,
  initialPage = 1,
  // 정렬 관련 props 추가
  sortable = false,
  initialSortField = null,
  initialSortDirection = "asc",
  onSortChange = null, // 외부에서 정렬 상태를 관리하고 싶을 때 사용
  // 검색 관련 props 추가
  searchable = false,
  searchPlaceholder = "Search...",
  searchFields = [], // 검색할 필드 배열 (비어있으면 모든 필드 검색)
  onSearch = null, // 외부 검색 핸들러 (서버 사이드 검색 등에 사용)
  // Excel 내보내기 관련 props 추가
  exportable = false,
  exportFileName = "Excel Download",
  customExportData = null, // 사용자 정의 내보내기 데이터
  exportButtonLabel = "Excel",
  // 행 클릭 관련 props 추가
  onRowClick = null,
  rowClickable = false,
  // 선택된 행 ID를 외부에서 제어하기 위한 props 추가
  selectedRowId = null,
  setSelectedRowId = null, // 새로 추가된 prop
}) {
  // 선택된 스타일 가져오기
  const styles = styleMap[tableStyle] || defaultStyles;

  // 페이지네이션 상태 관리
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [paginatedRows, setPaginatedRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  // 정렬 상태 추가
  const [sortField, setSortField] = useState(initialSortField);
  const [sortDirection, setSortDirection] = useState(initialSortDirection);

  // 검색 상태 추가
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  // 제어 컴포넌트 여부 판단 (두 props가 모두 있어야 함)
  const isSelectionEnabled =
    typeof selectedRowId !== "undefined" &&
    typeof setSelectedRowId === "function";

  // 값 접근 함수 - useCallback으로 메모이제이션
  const getValue = useCallback(
    (item, key) => {
      if (!item || !key) return "";

      try {
        if (dataStructure === "nested") {
          // itemData가 없는 경우 빈 문자열 반환
          if (!itemData) return "";

          // 기존 value_object 구조 (nested)
          return itemData[item.name]?.[key] ?? "";
        } else {
          // flat 구조 (rows 배열)
          // 키에 점이 있는지 확인하여 중첩 객체 처리
          if (key.includes(".")) {
            const keys = key.split(".");
            let value = item;

            // 중첩 객체를 따라 값을 찾음
            for (const k of keys) {
              value = value?.[k];
              if (value === undefined || value === null) return "";
            }

            return value;
          }

          return item[key] ?? "";
        }
      } catch (error) {
        console.warn("Error accessing data:", error);
        return "";
      }
    },
    [itemData, dataStructure]
  );

  // sortData 함수를 useCallback으로 감싸기
  const sortData = useCallback(
    (data, field, direction) => {
      if (!field || !data || !Array.isArray(data)) return [];

      try {
        return [...data].sort((a, b) => {
          let aValue, bValue;

          // 특수 정렬 필드 처리 (input/textarea 값)
          if (field.startsWith("input:") || field.startsWith("textarea:")) {
            const actualField = field.split(":")[1];

            if (dataStructure === "nested") {
              // itemData가 없는 경우 빈 문자열로 처리
              if (!itemData) {
                aValue = "";
                bValue = "";
              } else {
                aValue = itemData[a?.name]?.[actualField] ?? "";
                bValue = itemData[b?.name]?.[actualField] ?? "";
              }
            } else {
              // flat 구조
              aValue = a?.[actualField] ?? "";
              bValue = b?.[actualField] ?? "";
            }
          } else {
            // 일반 필드 처리
            aValue = a?.[field] ?? "";
            bValue = b?.[field] ?? "";
          }

          // null, undefined 처리
          if (aValue === null || aValue === undefined) aValue = "";
          if (bValue === null || bValue === undefined) bValue = "";

          // 숫자 비교
          if (typeof aValue === "number" && typeof bValue === "number") {
            return direction === "asc" ? aValue - bValue : bValue - aValue;
          }

          // 문자열로 변환하여 비교
          aValue = String(aValue).toLowerCase();
          bValue = String(bValue).toLowerCase();

          if (aValue < bValue) return direction === "asc" ? -1 : 1;
          if (aValue > bValue) return direction === "asc" ? 1 : -1;
          return 0;
        });
      } catch (error) {
        console.warn("Error sorting data:", error);
        return [...data];
      }
    },
    [itemData, dataStructure]
  );

  // searchData 함수를 useCallback으로 감싸기
  const searchData = useCallback(
    (data, term) => {
      if (!term || !data || !Array.isArray(data)) return data || [];

      try {
        const lowerTerm = term.toLowerCase();

        return data.filter((item) => {
          if (!item) return false;

          if (searchFields && searchFields.length > 0) {
            return searchFields.some((field) => {
              if (!field) return false;

              let value;

              try {
                if (
                  field.startsWith("input:") ||
                  field.startsWith("textarea:")
                ) {
                  const actualField = field.split(":")[1];

                  if (dataStructure === "nested") {
                    // itemData가 없는 경우 빈 문자열로 처리
                    if (!itemData) {
                      value = "";
                    } else {
                      value = itemData[item.name]?.[actualField];
                    }
                  } else {
                    value = item[actualField];
                  }
                } else {
                  value = item[field];
                }

                if (value === null || value === undefined) return false;
                return String(value).toLowerCase().includes(lowerTerm);
              } catch (error) {
                return false;
              }
            });
          } else {
            // 모든 필드 검색
            return Object.values(item).some((val) => {
              if (val === null || val === undefined) return false;
              return String(val).toLowerCase().includes(lowerTerm);
            });
          }
        });
      } catch (error) {
        console.warn("Error searching data:", error);
        return data;
      }
    },
    [itemData, dataStructure, searchFields]
  );

  // 검색 변경 핸들러 추가
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // 외부 검색 핸들러가 있으면 호출
    if (onSearch) {
      onSearch(value);
    }

    // 검색 시 첫 페이지로 이동
    setCurrentPage(1);
  };

  // 데이터 처리 - 검색 및 정렬
  useEffect(() => {
    if (tableConfig?.rows) {
      // 1. 검색 적용
      const searched =
        searchable && searchTerm
          ? searchData(tableConfig.rows, searchTerm)
          : tableConfig.rows;

      // 2. 정렬 적용
      const sorted =
        sortable && sortField
          ? sortData(searched, sortField, sortDirection)
          : searched;

      // 이전 값과 비교하여 변경이 있을 때만 상태 업데이트
      setFilteredRows((prevRows) => {
        // 배열 길이가 다르면 확실히 변경된 것
        if (!prevRows || prevRows.length !== sorted.length) {
          return sorted;
        }

        // 내용이 같은지 간단히 비교 (깊은 비교는 아님)
        const hasChanged = JSON.stringify(prevRows) !== JSON.stringify(sorted);
        return hasChanged ? sorted : prevRows;
      });

      // 페이지네이션 관련 상태 업데이트
      if (pagination) {
        const newTotalPages = Math.max(1, Math.ceil(sorted.length / pageSize));
        setTotalPages((prevPages) =>
          prevPages !== newTotalPages ? newTotalPages : prevPages
        );

        // 현재 페이지가 총 페이지 수를 초과하면 조정
        if (currentPage > newTotalPages && currentPage !== 1) {
          setCurrentPage(1);
        }
      }
    } else {
      // tableConfig나 rows가 없는 경우 빈 배열로 설정
      setFilteredRows((prevRows) => (prevRows.length > 0 ? [] : prevRows));
      setTotalPages((prevPages) => (prevPages !== 1 ? 1 : prevPages));
    }
  }, [
    tableConfig?.rows,
    searchTerm,
    sortField,
    sortDirection,
    pageSize,
    searchable,
    sortable,
    pagination,
    searchData,
    sortData,
    currentPage,
  ]);

  // 페이지네이션 처리 - 완전히 분리
  useEffect(() => {
    if (pagination && filteredRows.length > 0) {
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      setPaginatedRows(filteredRows.slice(startIndex, endIndex));
    } else {
      setPaginatedRows(filteredRows);
    }
  }, [filteredRows, currentPage, pageSize, pagination]);

  // 페이지 변경 핸들러
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // 정렬 변경 핸들러
  const handleSort = (field) => {
    // 정렬 불가능한 열이면 무시
    if (!sortable) return;

    let newDirection = "asc";

    // 같은 필드를 다시 클릭하면 정렬 방향 전환
    if (field === sortField) {
      newDirection = sortDirection === "asc" ? "desc" : "asc";
    }

    // 외부 정렬 콜백이 있으면 호출
    if (onSortChange) {
      onSortChange(field, newDirection);
    } else {
      // 내부에서 정렬 상태 관리
      setSortField(field);
      setSortDirection(newDirection);
    }

    // 정렬 시 첫 페이지로 이동
    if (pagination) {
      setCurrentPage(1);
    }
  };

  // 정렬 아이콘 렌더링
  const renderSortIcon = (field) => {
    if (!sortable) return null;

    if (field !== sortField) {
      return (
        <div className={styles.sortIconContainer}>
          <span className={styles.sortIconInactive}>▲</span>
          <span className={styles.sortIconInactive}>▼</span>
        </div>
      );
    }

    return (
      <div className={styles.sortIconContainer}>
        {sortDirection === "asc" ? (
          <>
            <span className={styles.sortIconActive}>▲</span>
            <span className={styles.sortIconInactive}>▼</span>
          </>
        ) : (
          <>
            <span className={styles.sortIconInactive}>▲</span>
            <span className={styles.sortIconActive}>▼</span>
          </>
        )}
      </div>
    );
  };

  // 검색 UI 렌더링 함수 추가
  const renderSearch = () => {
    if (!searchable) return null;

    return (
      <div className={styles.searchContainer || "search-container"}>
        <input
          type="text"
          className={styles.searchInput || "search-input"}
          placeholder={searchPlaceholder}
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {searchTerm && (
          <button
            className={styles.clearSearchButton || "clear-search-button"}
            onClick={() => setSearchTerm("")}
            aria-label="Clear search"
          >
            ×
          </button>
        )}
      </div>
    );
  };

  // Excel 내보내기 함수
  const exportToExcel = () => {
    // 내보낼 데이터 준비
    let dataToExport = customExportData;

    if (!dataToExport) {
      // 기본 내보내기 데이터 생성
      const headers = tableConfig.headers.map((header) => header.label);

      const data = filteredRows.map((row) => {
        const rowData = {};

        tableConfig.headers.forEach((header, index) => {
          const column = tableConfig.columns[index];
          let cellValue;

          if (column.valueKey && row[column.valueKey] !== undefined) {
            // 직접 값이 있는 경우
            cellValue = row[column.valueKey];
          } else if (column.type === "input" || column.type === "textarea") {
            // input/textarea 필드인 경우
            cellValue = getValue(row, column.key);
          } else if (column.formatter) {
            // 포맷터가 있는 경우
            const value = getValue(row, column.key);
            cellValue = column.formatter(value, row);
          } else {
            // 기본 값
            cellValue = getValue(row, column.key) || "";
          }

          rowData[header.label] = cellValue;
        });

        return rowData;
      });

      dataToExport = [
        headers,
        ...data.map((row) => headers.map((header) => row[header])),
      ];
    }

    // 워크시트 생성
    const ws = XLSX.utils.aoa_to_sheet(dataToExport);

    // 워크북 생성
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // 파일 저장
    XLSX.writeFile(wb, `${exportFileName}.xlsx`);
  };

  // 내보내기 버튼 렌더링
  const renderExportButton = () => {
    if (!exportable) return null;

    return (
      <button
        className={styles.exportButton || "export-button"}
        onClick={exportToExcel}
      >
        {exportButtonLabel}
      </button>
    );
  };

  // 셀 렌더링 함수 - 의존성 배열에 styles 추가
  const renderCell = useCallback(
    (column, item) => {
      if (!column || !item) return null;

      try {
        // valueKey 처리 수정
        if (column.valueKey && column.valueKey.includes(".")) {
          const keys = column.valueKey.split(".");
          let value = item;

          for (const key of keys) {
            value = value?.[key];
            if (value === undefined || value === null) break;
          }

          // valueFormatter가 있으면 변환된 값을 표시
          if (value !== undefined && value !== null && column.valueFormatter) {
            return column.valueFormatter(value, item);
          }

          return value ?? "";
        } else if (column.valueKey && item[column.valueKey] !== undefined) {
          const rawValue = item[column.valueKey];

          // valueFormatter가 있으면 변환된 값을 표시
          if (column.valueFormatter) {
            return column.valueFormatter(rawValue, item);
          }

          return rawValue;
        }

        const value = getValue(item, column.key);

        switch (column.type) {
          case "input":
            return (
              <input
                className={styles.input}
                type={column.inputType || "text"}
                onChange={(e) => {
                  if (handleChange) {
                    handleChange(e, selectedItem, column.key, group);
                  }
                }}
                value={value}
                name={item.name}
                disabled={!handleChange}
                {...(column.props || {})}
              />
            );

          case "textarea":
            return (
              <textarea
                className={styles.textarea}
                onChange={(e) => {
                  if (handleChange) {
                    handleChange(e, selectedItem, column.key, group);
                  }
                }}
                value={value}
                name={item.name}
                rows={column.rows || "2"}
                disabled={!handleChange}
                {...(column.props || {})}
              />
            );

          case "display":
          default:
            // 단순 표시용 셀
            return column.formatter ? column.formatter(value, item) : value;
        }
      } catch (error) {
        console.warn("Error rendering cell:", error);
        return null;
      }
    },
    [getValue, handleChange, selectedItem, group, styles.input, styles.textarea]
  );

  // 페이지네이션 컨트롤 렌더링
  const renderPagination = () => {
    if (!pagination || totalPages <= 1) return null;

    // 현재 표시 중인 항목 범위 계산
    const totalItems = filteredRows.length;
    const startItem = totalItems === 0 ? 0 : (currentPage - 1) * pageSize + 1;
    const endItem = Math.min(currentPage * pageSize, totalItems);

    return (
      <div className={styles.pagination || "pagination-container"}>
        <div className={styles.paginationStats || "pagination-stats"}>
          {totalItems > 0 ? (
            <span>{`${startItem}-${endItem} / Total ${totalItems}`}</span>
          ) : (
            <span>No Items</span>
          )}
        </div>

        <div className={styles.paginationControls || "pagination-controls"}>
          <button
            className={styles.paginationButton || "pagination-button"}
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          >
            &laquo;
          </button>
          <button
            className={styles.paginationButton || "pagination-button"}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &lt;
          </button>

          <span className={styles.paginationInfo || "pagination-info"}>
            {currentPage} / {totalPages}
          </span>

          <button
            className={styles.paginationButton || "pagination-button"}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
          <button
            className={styles.paginationButton || "pagination-button"}
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            &raquo;
          </button>
        </div>
      </div>
    );
  };

  // 행 클릭 핸들러 수정
  const handleRowClick = (item) => {
    // 선택 기능이 활성화된 경우에만 선택 상태 업데이트
    if (isSelectionEnabled) {
      const rowId = item.id || item.key || item.name;
      setSelectedRowId(rowId);
    }

    // onRowClick은 항상 호출 (선택 상태와 관계없이)
    if (onRowClick) {
      onRowClick(item);
    }
  };

  // 테이블 렌더링 조건부 처리
  if (!tableConfig || !tableConfig?.rows) {
    return <div className={styles.noData}>테이블 설정이 없습니다.</div>;
  }

  return (
    <div className={styles.tableContainer || "table-container"}>
      <div className={styles.tableControls || "table-controls"}>
        {/* 검색 UI */}
        {renderSearch()}

        {/* 내보내기 버튼 */}
        {renderExportButton()}
      </div>

      <table
        className={tableConfig.className || styles.table}
        style={tableConfig.style}
      >
        <thead>
          <tr className={tableConfig.headerRowClass || styles.headerRow}>
            {tableConfig.headers.map((header, index) => {
              // 정렬 가능한 헤더인지 확인
              const isSortable = sortable && header.sortField;
              const headerClassName = `${header.className || styles.th} ${
                isSortable ? styles.sortableHeader : ""
              }`;

              return (
                <th
                  key={index}
                  className={headerClassName}
                  style={header.style}
                  onClick={
                    isSortable ? () => handleSort(header.sortField) : undefined
                  }
                >
                  <div className={styles.headerContent || "header-content"}>
                    {header.label}
                    {isSortable && renderSortIcon(header.sortField)}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {(pagination ? paginatedRows : filteredRows).map((item, rowIndex) => {
            // 현재 행이 선택된 행인지 확인 (선택 기능이 활성화된 경우에만)
            const rowId = item.id || item.key || item.name;
            const isSelected = isSelectionEnabled && rowId === selectedRowId;

            return (
              <tr
                key={rowId || `row-${rowIndex}`}
                className={`${tableConfig.rowClass || styles.tr} 
              ${rowClickable ? styles.clickableRow : ""} 
              ${isSelected ? styles.selectedRow : ""}`}
                onClick={rowClickable ? () => handleRowClick(item) : undefined}
                style={tableConfig.rowStyle}
              >
                {tableConfig.columns.map((column, colIndex) => (
                  <td
                    key={`${
                      item.id || item.key || item.name || rowIndex
                    }-${colIndex}`}
                    className={column.cellClassName || styles.td}
                    style={column.cellStyle}
                  >
                    {column.valueKey && item[column.valueKey] !== undefined
                      ? column.valueFormatter
                        ? column.valueFormatter(item[column.valueKey], item)
                        : item[column.valueKey]
                      : renderCell(column, item)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {renderPagination()}
    </div>
  );
}

export default DynamicTable;

/*
사용 예제:

1. 기본 테이블
const tableConfig = {
  headers: [
    { label: "번호", style: { width: "60px" }, sortField: "number" },
    { label: "이름", style: { width: "140px" }, sortField: "name" }
  ],
  columns: [
    { valueKey: "number" },
    { valueKey: "name" }
  ],
  rows: [
    { number: 1, name: "홍길동" },
    { number: 2, name: "김철수" }
  ]
};

<DynamicTable tableConfig={tableConfig} tableStyle="default" />

2. 입력 필드가 있는 테이블
// 구현...

3. 모든 기능이 활성화된 테이블
// 구현...
*/
