import React from "react";
// import useEducationalActivities from "../../../hooks/useEducationalActivities";
import styles from "../CSS/SideMenu.module.css";
import { useTranslation } from "react-i18next";

export default function SideMenu({
  educationalActivitiesTypeData,
  activeMenu,
  setActiveMenu,
  extraFunction,
  schoolId,
}) {
  // const typeConditions = { ...conditions, type: "true" };
  // const {
  //   educationalActivitiesTypeQuery: { data: educationalActivitiesTypeData },
  // } = useEducationalActivities(typeConditions);
  const { t } = useTranslation();
  return (
    <div className={`align-items-start bg-secondary-subtle ${styles.sideMenu}`}>
      <div className="p-2 w-100">
        <h4 className="text-center mt-3">
          {t("common.commonPages.selectActivity")}
        </h4>
        {educationalActivitiesTypeData?.map((item, index) => (
          <button
            key={index} // Key should be unique for each sibling.
            className={`btn my-2 w-100 ${
              activeMenu.activity_type === item.activity_type
                ? "btn-info"
                : "btn-light"
            }`}
            type="button"
            onClick={() => {
              setActiveMenu({
                activity_type: item.activity_type,
                activity_type_id: item.id,
                options: item.options?.filter((item) => schoolId in item) || [],
                schoolid: schoolId,
                activity_name: item.activity_name,
              });

              if (extraFunction) {
                extraFunction(item);
              }
            }}
          >
            {item.activity_name}
          </button>
        ))}
      </div>
    </div>
  );
}
