import React from "react";
import { useTranslation } from "react-i18next";
import { MdHelpOutline } from "react-icons/md";

export default function HelpHyperLink({ link, size = 21 }) {
  const { t } = useTranslation();
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <MdHelpOutline
        size={size}
        color="#008080"
        title={t("common.uniComponents.linkToManualVideo")}
      />
    </a>
  );
}
