import React, { useEffect, useState } from "react";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import ChangeDate from "./components/ChangeDate";
import JubileeAwardTable from "./components/JubileeAwardTable";
import { useLocation } from "react-router-dom";
import { uniqueDataOptionsKeys } from "../../../util/dealingArrObj";
import useRegistrationInfo from "../../../hooks/useRegistrationInfo";
import { dateToYearMonthDayFormat } from "../../../util/formatdate";
import LoadingModal from "../../../unicomponents/LoadingModal";
import { checkHrteachers } from "../../../util/checkPermissions";
import SimpleAlertMessageBlock from "../../../unicomponents/SimpleAlertMessageBlock";
import { useAuthContext } from "../../../context/AuthContext";
import LoadingInside from "../../../unicomponents/LoadingInside";
import { useTranslation } from "react-i18next";
import useJubileeAward from "../../../hooks/useJubileeAward";
import DropdownSchoolYear from "../../../unicomponents/Dropdowns/DropdownSchoolYear";
import DropdownHierarchyOne from "../../../unicomponents/Dropdowns/DropdownHierarchyOne";
import DropdownHierarchyTwo from "../../../unicomponents/Dropdowns/DropdownHierarchyTwo";
import DropdownHierarchyThree from "../../../unicomponents/Dropdowns/DropdownHierarchyThree";
import DropdownTermDate from "../../../unicomponents/Dropdowns/DropdownTermDate";
import useTermPeriod from "../../../hooks/useTermPeriod";
import { setTermIdByToday } from "../../../util/dateRelated";

// 날짜 조정 함수 추가
function adjustDate(dateString, days) {
  if (!dateString) return "";
  const date = new Date(dateString);
  date.setDate(date.getDate() + days);
  return dateToYearMonthDayFormat(date);
}

export default function JubileeAward() {
  const { t } = useTranslation();
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  const conditions = { schoolid: schoolId };

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod(conditions);

  const {
    registrationInfoQuery: { isLoading, data: registrationInfoData },
    hrTeachersQuery: { data: hrTeachersData },
  } = useRegistrationInfo(conditions, { hrTeachers: true });

  const { jubileeAwardQuery } = useJubileeAward(conditions);

  const [schoolYear, setSchoolYear] = useState(null);
  const [filterOrgan, setFilterOrgan] = useState({});
  const [idsForKey, setIdsForKey] = useState(null);
  const [rows, setRows] = useState([]);
  const [filteredRegistrationInfo, setFilteredRegistrationInfo] = useState([]);
  const [weekStartEndDates, setWeekStartEndDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [termInfo, setTermInfo] = useState({});

  const [currentDate, setCurrentDate] = useState(new Date()); // 현재 날짜
  const [jubileeAwardRecord, setJubileeAwardRecord] = useState([]);
  const [isUpLoading, setIsUpLoading] = useState(false);
  const [saveJubileeAward, setSaveJubileeAward] = useState([]);
  const [termId, setTermId] = useState("");

  const { hasPermission } = useAuthContext();

  const [showGuide, setShowGuide] = useState(false);

  useEffect(() => {
    if (registrationInfoData) {
      const filtered = registrationInfoData.filter(
        (r) => r.status === "재학중"
      );
      // console.log("filtered", filtered);

      setFilteredRegistrationInfo(filtered);
    }
  }, [registrationInfoData]);

  useEffect(() => {
    if (schoolYear === null) {
      //초기 데이터를 학년도로 필터해서 제공해야 하기 때문에 오름차순 제일 마지막 걸로
      let initialSchoolYear = null;
      let initialSchoolYearId = null;
      if (settingData && settingData.length > 0) {
        // console.log("settingData", settingData);
        const initialData = settingData.sort(
          (a, b) => b.school_year - a.school_year
        )[0];
        initialSchoolYear = initialData.school_year;
        initialSchoolYearId = initialData.id;
      }
      setSchoolYear(initialSchoolYear);

      const filtered = settingData.filter(
        (r) => r.school_year === initialSchoolYear
      );

      setFilterOrgan((prevFilter) => ({
        ...prevFilter,
        0: initialSchoolYearId,
      }));

      const forIdsForKey = uniqueDataOptionsKeys(
        filtered,
        "hierarchy_level",
        "level_name_new"
      );
      setIdsForKey(forIdsForKey);

      // 학년도가 설정되면 해당 학년도에 맞는 학기 ID 설정
      if (termPeriodData && termPeriodData.length > 0 && initialSchoolYearId) {
        setTermIdByToday(termPeriodData, setTermId, initialSchoolYearId);
        // console.log("termId 되나?");
      }
    }
  }, [settingData, schoolYear, termPeriodData]);

  useEffect(() => {
    if (termPeriodData && termId) {
      const currentPeriod = termPeriodData.find(
        (period) => period.id.toString() === termId.toString()
      );
      setTermInfo(currentPeriod);

      const queryConditions = {
        schoolid: schoolId,
        startdate: adjustDate(currentPeriod.start_date, -6), // 시작일 6일 전으로 확장
        enddate: currentPeriod.end_date, // 종료일은 그대로 유지
      };

      setIsUpLoading(true);
      jubileeAwardQuery.mutate(queryConditions, {
        onSuccess: (data) => {
          // 가져온 데이터를 저장만 하고, rows는 변경하지 않음
          setJubileeAwardRecord(data);
          setIsUpLoading(false);
        },
        onError: (error) => {
          setIsUpLoading(false);
          console.error(error);
          alert(error.message);
        },
      });
    }
    //eslint-disable-next-line
  }, [termPeriodData, termId]);

  useEffect(() => {
    if (hrTeachersData && schoolYear)
      if (checkHrteachers(hrTeachersData, "", schoolYear)) {
        const thisYearData = hrTeachersData.filter(
          (r) => r.schoolYear === schoolYear
        );
        const organPath = thisYearData[0].organization_path;
        const splited = organPath.split("/");

        setFilterOrgan((prevFilter) => ({
          ...prevFilter,
          1: splited[2],
          2: splited[3],
          3: splited[4],
        }));

        // 현재 선택된 학기 정보 가져오기
        if (organPath) {
          const rows = processRegistrationInfo(
            filteredRegistrationInfo,
            settingData,
            schoolYear,
            organPath
          );
          setRows(rows);
        }
      }
  }, [hrTeachersData, schoolYear, filteredRegistrationInfo, settingData]);

  useEffect(() => {
    const now = new Date(currentDate); // 현재 날짜
    now.setHours(0, 0, 0, 0);
    const startOfWeek = new Date(
      now.setDate(now.getDate() - ((now.getDay() + 6) % 7))
    ); // 이번 주 월요일 (첫째날)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);
    //이번 주 일요일 (마지막날)
    setWeekStartEndDates({
      startDate: dateToYearMonthDayFormat(startOfWeek),
      endDate: dateToYearMonthDayFormat(endOfWeek),
    });
  }, [currentDate]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0]?.text || "";
    const dataName =
      e.target.selectedOptions[0]?.getAttribute("data-name") || "";
    let organPath = "";
    if (!dataName) {
      organPath = "N/A";
    } else {
      organPath = dataName + "/" + value;
    }

    // 드롭다운 name 속성은 문자열 숫자("0", "1", "2" 등)입니다
    const key = name;

    // 학년도 변경 처리 (name="0")
    if (name === "0") {
      setFilterOrgan({
        0: value,
      });
      setTermInfo({});

      const filtered = settingData.filter(
        (r) => r.school_year === selectedOptionText
      );
      const uniqueOptions = uniqueDataOptionsKeys(
        filtered,
        "hierarchy_level",
        "level_name_new"
      );
      setIdsForKey(uniqueOptions);
      setSchoolYear(selectedOptionText);
      setRows([]);

      // 학년도가 변경되면 해당 학년도에 맞는 학기 ID 설정
      if (termPeriodData) {
        setTermIdByToday(termPeriodData, setTermId, value);
      }
    }

    // 학기 변경 처리
    if (name === "termDate") {
      setTermId(value);

      if (value.length < 1) {
        setJubileeAwardRecord([]);
        setTermInfo({});
        return;
      }

      // 학기가 변경되면 해당 학기에 맞는 데이터 조회
      const currentPeriod = termPeriodData.find(
        (period) => period.id.toString() === value.toString()
      );

      if (currentPeriod) {
        setTermInfo(currentPeriod);

        // 학기 변경 시 해당 기간의 데이터만 조회
        const queryConditions = {
          schoolid: schoolId,
          startdate: adjustDate(currentPeriod.start_date, -6), // 시작일 6일 전으로 확장
          enddate: currentPeriod.end_date, // 종료일은 그대로 유지
        };

        setIsUpLoading(true);
        jubileeAwardQuery.mutate(queryConditions, {
          onSuccess: (data) => {
            // 가져온 데이터를 저장만 하고, rows는 변경하지 않음
            setJubileeAwardRecord(data);
            setIsUpLoading(false);
          },
          onError: (error) => {
            setIsUpLoading(false);
            console.error(error);
            alert(error.message);
          },
        });
      }
    }

    // 계층 구조 변경 처리 (name="1", "2", "3", "4")
    if (key === "1" || key === "2" || key === "3" || key === "4") {
      // filterOrgan 상태 업데이트
      setFilterOrgan((prevFilter) => {
        const newFilter = { ...prevFilter, [key]: value };
        // 하위 계층 초기화
        Object.keys(newFilter).forEach((filterKey) => {
          if (Number(filterKey) > Number(key)) {
            delete newFilter[filterKey];
          }
        });
        return newFilter;
      });

      // 계층 3 또는 4 변경 시 학생 데이터 조회
      if (key === "3" || key === "4") {
        // 현재 선택된 학기 정보 가져오기
        if (organPath) {
          const rows = processRegistrationInfo(
            filteredRegistrationInfo,
            settingData,
            schoolYear,
            organPath
          );
          setRows(rows);
        } else if (filterOrgan["3"] || filterOrgan["4"]) {
          // 이미 선택된 반이 있는 경우, 해당 반의 데이터를 다시 로드
          const selectedClassId = filterOrgan["3"] || filterOrgan["4"];
          const selectedClass = settingData.find(
            (s) => s.id.toString() === selectedClassId.toString()
          );

          if (selectedClass && selectedClass.organization_path) {
            const rows = processRegistrationInfo(
              filteredRegistrationInfo,
              settingData,
              schoolYear,
              selectedClass.organization_path
            );
            setRows(rows);
          }
        }
      }

      // 계층 1 또는 2 변경 시 학기 ID 업데이트
      if (key === "1" || key === "2") {
        if (termPeriodData) {
          const hierarchyOneId = key === "1" ? value : filterOrgan["1"];
          const hierarchyTwoId = key === "2" ? value : filterOrgan["2"];
          setTermIdByToday(
            termPeriodData,
            setTermId,
            filterOrgan["0"],
            hierarchyOneId,
            hierarchyTwoId
          );
        }
      }
    }
  };

  const handleDateChange = () => {
    const queryConditions = {
      schoolid: schoolId,
      startdate: dateToYearMonthDayFormat(weekStartEndDates.startDate),
      enddate: dateToYearMonthDayFormat(weekStartEndDates.endDate),
    };

    setIsUpLoading(true);
    jubileeAwardQuery.mutate(queryConditions, {
      onSuccess: (data) => {
        setJubileeAwardRecord(data);
        setIsUpLoading(false);
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(error.message);
      },
    });
  };

  function processRegistrationInfo(
    filteredRegistrationInfo,
    settingData,
    schoolYear,
    organPath
  ) {
    if (filteredRegistrationInfo && settingData) {
      const toBeRows = filteredRegistrationInfo
        .filter(
          (r) =>
            r.school_year === schoolYear &&
            r.organization_path === organPath &&
            r.user // user 객체가 존재하는지 확인
        )
        .map((row) => {
          const {
            organization_path,
            hr_teacher_username,
            user,
            ...otherFields
          } = row;
          // organization_path를 분해하여 필드 만들기
          const paths = organization_path.split("/").filter((path) => path);
          const pathFields = paths.reduce((acc, curr, index) => {
            const thisGroup = settingData.find((r) => r.id === Number(curr));

            if (thisGroup) {
              return {
                ...acc,
                [index]: thisGroup.group_name,
                ["order" + index]: thisGroup.order,
              };
            } else {
              console.error(`No group found with id: ${curr}`);
              return acc;
            }
          }, {});

          // user 객체가 있는지 확인하고 안전하게 접근
          const userObject = user
            ? {
                fullname: user.fullname || "",
                gender: user.gender || "",
              }
            : { fullname: "", gender: "" };

          return {
            ...otherFields,
            ...pathFields,
            ...userObject,
            organization_path,
          };
        })
        .sort((a, b) => a.studentno - b.studentno);

      const sortedArray = toBeRows.sort((a, b) => {
        // 'order' 필드를 찾아서 역순으로 정렬
        const orderKeysA = Object.keys(a)
          .filter((key) => key.startsWith("order"))
          .sort();
        const orderKeysB = Object.keys(b)
          .filter((key) => key.startsWith("order"))
          .sort();

        // 같은 'order' 레벨에서 높은 값을 우선으로 정렬
        for (let i = 0; i < orderKeysA.length; i++) {
          if (a[orderKeysA[i]] !== b[orderKeysB[i]]) {
            return a[orderKeysA[i]] - b[orderKeysB[i]];
          }
        }

        // 모든 'order' 값이 같을 경우에는 그대로 유지
        return 0;
      });

      return sortedArray;
    }

    return []; // registrationInfoData 또는 settingData가 없는 경우
  }

  const refetchData = (startDate, endDate) => {
    const queryConditions = {
      schoolid: schoolId,
      startdate: adjustDate(startDate, -6),
      enddate: endDate,
    };

    jubileeAwardQuery.mutate(queryConditions, {
      onSuccess: (data) => {
        setJubileeAwardRecord(data);
        setIsUpLoading(false);
        setSaveJubileeAward([]);
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error);
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(error.message);
      },
    });
  };

  if (isLoading) {
    return <LoadingInside />;
  }

  if (!hasPermission(null, "can_access", checkHrteachers(hrTeachersData))) {
    return (
      <SimpleAlertMessageBlock
        message={
          t("common.messages.homeroomAccessOnly") ||
          "담임 또는 접근 권한이 있는 사람만 접근할 수 있습니다."
        }
      />
    );
  }

  return (
    <>
      <div className="ms-3 basicWidth">
        <div className="container-fluid">
          {isUpLoading && <LoadingModal />}
          {/* {error && error.toString()} */}
          <div className="row mb-4 mt-2">
            <div className="col-3">
              <div className="d-flex">
                <h3 className="fw-bold mt-3">주빌리 Award</h3>
              </div>
            </div>
            <div className="col-9 d-flex gap-2 align-items-center">
              <div style={{ width: "95px" }}>
                <DropdownSchoolYear
                  settingData={settingData}
                  handleDropdownChange={handleDropdownChange}
                  schoolYearId={filterOrgan["0"]}
                  allYears={false}
                />
              </div>
              <div style={{ width: "95px" }}>
                <DropdownHierarchyOne
                  settingData={settingData}
                  handleDropdownChange={handleDropdownChange}
                  schoolYear={schoolYear}
                  hierarchyOneId={filterOrgan["1"]}
                  hierarchyOneTitle={
                    idsForKey && idsForKey["1"] ? idsForKey["1"][0] : ""
                  }
                />
              </div>
              <div style={{ width: "95px" }}>
                <DropdownHierarchyTwo
                  settingData={settingData}
                  handleDropdownChange={handleDropdownChange}
                  schoolYear={schoolYear}
                  hierarchyOneId={filterOrgan["1"]}
                  hierarchyTwoId={filterOrgan["2"]}
                  hierarchyTwoTitle={
                    idsForKey && idsForKey["2"] ? idsForKey["2"][0] : ""
                  }
                />
              </div>
              <div style={{ width: "95px" }}>
                <DropdownHierarchyThree
                  settingData={settingData}
                  handleDropdownChange={handleDropdownChange}
                  schoolYear={schoolYear}
                  hierarchyTwoId={filterOrgan["2"]}
                  hierarchyThreeId={filterOrgan["3"]}
                  hierarchyThreeTitle={
                    idsForKey && idsForKey["3"] ? idsForKey["3"][0] : ""
                  }
                />
              </div>
              <div style={{ width: "95px" }} className="me-1">
                <DropdownTermDate
                  termPeriodData={termPeriodData || []}
                  handleDropdownChange={handleDropdownChange}
                  termId={termId || ""}
                  schoolYearId={filterOrgan["0"] || ""}
                  hierarchyOneId={filterOrgan["1"] || ""}
                  hierarchyTwoId={filterOrgan["2"] || ""}
                />
              </div>
              <div style={{ width: "155px" }} className="me-1">
                <input
                  type="text"
                  className="form-control pt-2 pb-2 ps-1 pe-1"
                  readOnly
                  value={
                    termPeriodData && termId
                      ? (() => {
                          const currentPeriod = termPeriodData.find(
                            (period) =>
                              period.id.toString() === termId.toString()
                          );
                          return currentPeriod
                            ? currentPeriod.start_date +
                                "~" +
                                currentPeriod.end_date
                            : "---------- ~ ----------";
                        })()
                      : "---------- ~ ----------"
                  }
                  style={{ fontSize: "13px" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <ChangeDate
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                setJubileeAwardRecord={setJubileeAwardRecord}
                handleDateChange={handleDateChange}
              />
            </div>
            <div className="col-6">
              <div className="card border-0 shadow-sm">
                <div
                  className="card-header bg-light d-flex justify-content-between align-items-center py-2 px-3 cursor-pointer"
                  onClick={() => setShowGuide(!showGuide)}
                  style={{ cursor: "pointer" }}
                >
                  <span className="fw-bold">주빌리 어워드 안내</span>
                  <i
                    className={`bi bi-chevron-${showGuide ? "up" : "down"}`}
                  ></i>
                </div>
                <div
                  className={`card-body p-2 ${showGuide ? "" : "d-none"}`}
                  style={{ fontSize: "12px" }}
                >
                  <div className="d-flex gap-2">
                    <ul className="ps-3 mb-0" style={{ width: "170px" }}>
                      <li>R: RAZ - T </li>
                      <li>L: Library - HR</li>
                      <li>J: J-Privilege - HR</li>
                      <li>P: P-Privilege - HR</li>
                      <li>F: 가정예배 - 없음</li>
                      <li>C: 주일예배 - 없음</li>
                      <li>D: 디메릿 - 없음</li>
                    </ul>
                    <ul className="ps-3 mb-0">
                      <li>
                        매주 월요일 12시 이후 유니스 선생님께서 올려주세요.
                      </li>
                      <li>
                        G1-G2 부모님과 하루 15분 5회이상; G3-G6 부모님과 하루
                        15분 5회이상(G3-G4:20분 / G5-G6:30분)
                      </li>
                      <li>
                        가정예배 7회 + 주빌리 지혜암송(영어,중국어,우리말){" "}
                        <span className="text-danger">
                          금요일재검사통과 + Demerit 5회 이하
                        </span>
                      </li>
                      <li>
                        가정예배 7회 + Raz 5회 + 함께읽기 5회 + 수학물주기 5회 +
                        하브루타 5회
                      </li>
                      <li>수행 여부는 1과 0으로 표시</li>
                      <li>
                        가정예배, 디메릿, 에코 마일리지는 횟수, 개수별 표시
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <JubileeAwardTable
              rows={rows}
              weekStartEndDates={weekStartEndDates}
              idsForKey={idsForKey}
              jubileeAwardRecord={jubileeAwardRecord}
              setIsUpLoading={setIsUpLoading}
              schoolId={schoolId}
              refetchData={refetchData}
              setJubileeAwardRecord={setJubileeAwardRecord}
              saveJubileeAward={saveJubileeAward}
              setSaveJubileeAward={setSaveJubileeAward}
              hrTeachersData={hrTeachersData}
              termInfo={termInfo}
              termId={termId}
            />
          </div>
        </div>
      </div>
    </>
  );
}
