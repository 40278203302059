import React, { useEffect, useState } from "react";
import DropdownSchoolYear from "../../../unicomponents/Dropdowns/DropdownSchoolYear";
import DropdownHierarchyOne from "../../../unicomponents/Dropdowns/DropdownHierarchyOne";
import DropdownHierarchyTwo from "../../../unicomponents/Dropdowns/DropdownHierarchyTwo";
import DropdownHierarchyThree from "../../../unicomponents/Dropdowns/DropdownHierarchyThree";
import DropdownUniqueItems from "../../../unicomponents/Dropdowns/DropdownUniqueItems";
import HelpHyperLink from "../../../unicomponents/HelpHyperLink";
import RegistrationVariousRecordInputTable from "../../../unicomponents/registrationVariousRecordInputTable/RegistrationVariousRecordInputTable";
import useVariousStudentRecords from "../../../hooks/useVariousStudentRecords";
import { useUIContext } from "../../../context/UIContext";
import { useAuthContext } from "../../../context/AuthContext";
import { checkHrteachers } from "../../../util/checkPermissions";
import SimpleAlertMessageBlock from "../../../unicomponents/SimpleAlertMessageBlock";
import { useTranslation } from "react-i18next";

export default function PhysicalRecord({
  settingData,
  inputData,
  schoolId,
  inputQueryConditions,
  hrTeachersData,
}) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    organPathForSort: "N/A",
    group: "1",
  });

  const [groupData, setGroupData] = useState([]);
  const [queryConditions, setQueryConditions] = useState({
    schoolid: schoolId,
    school_year_id: "",
    record_list_id: inputQueryConditions?.record_list_id,
  });
  const [dataToSave, setDataToSave] = useState([]);
  const [savedRows, setSavedRows] = useState([]);
  const [savedBulk, setSavedBulk] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  // const memoizedConditions = useMemo(
  //   () => ({
  //     schoolid: queryConditions.schoolid,
  //     school_year_id: queryConditions.school_year_id,
  //     record_list_id: queryConditions.record_list_id,
  //   }),
  //   [
  //     queryConditions.schoolid,
  //     queryConditions.school_year_id,
  //     queryConditions.record_list_id,
  //   ]
  // );

  const {
    variousStudentRecordsQuery: { data: variousStudentData },
    createOrUpdateVariousStudentRecords,
    createOrUpdateVariousStudentRecordsBulk,
    deleteVariousStudentRecords,
  } = useVariousStudentRecords(queryConditions);

  const { setIsLoadingModal, handleToastCenterTop, setAlertModalState } =
    useUIContext();

  const { hasPermission } = useAuthContext();

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;

    if (filters.schoolYear === null && settingData && settingData.length > 0) {
      const initialData = settingData.sort(
        (a, b) => b.school_year - a.school_year
      )[0];

      //이거 궁극적으로는 termData를 join해 와서 그것으로 정렬해야겠다.
      // console.log(
      //   "초기데이터",
      //   settingData.sort((a, b) => b.school_year - a.school_year)
      // );

      initialSchoolYear = initialData.school_year;
      initialSchoolYearId = initialData.id;
      setFilters((filters) => ({
        ...filters,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
      }));

      setQueryConditions((prev) => ({
        ...prev,
        school_year_id: initialSchoolYearId,
        record_list_id: inputQueryConditions?.record_list_id,
      }));
    } else {
      setQueryConditions((prev) => ({
        ...prev,
        school_year_id: filters.schoolYearId,
        record_list_id: inputQueryConditions?.record_list_id,
      }));
    }
  }, [
    filters.schoolYear,
    filters.schoolYearId,
    inputQueryConditions?.record_list_id,
    settingData,
  ]);

  useEffect(() => {
    if (hrTeachersData && filters.schoolYear)
      if (checkHrteachers(hrTeachersData, "", filters.schoolYear)) {
        const thisYearData = hrTeachersData.filter(
          (r) => r.schoolYear === filters.schoolYear
        );
        const splited = thisYearData[0].organization_path.split("/");

        setFilters((filters) => ({
          ...filters,
          hierarchyOneId: splited[2],
          hierarchyTwoId: splited[3],
          hierarchyThreeId: splited[4],
          organPathForSort: thisYearData[0].organization_path,
        }));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.schoolYear, hrTeachersData]);

  useEffect(() => {
    if (variousStudentData) {
      const studentDataFilteredByGroup = variousStudentData.filter(
        (r) => r.group === filters.group
      );
      setDataToSave(studentDataFilteredByGroup);
    }
  }, [filters.group, variousStudentData]);

  useEffect(() => {
    if (variousStudentData) {
      const mappedGroupData = variousStudentData
        .filter((r) => r.group !== "") // 빈 문자열을 제외
        .map((r) => ({
          group: r.group,
        }));

      // 새로운 그룹 추가
      mappedGroupData.push({ group: "1" });
      setGroupData(mappedGroupData);
    }
  }, [variousStudentData, inputQueryConditions]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    const dataName = e.target.selectedOptions[0].getAttribute("data-name");
    let organPath = "";
    if (!dataName) {
      organPath = "N/A";
    } else {
      organPath = dataName + "/" + value;
    }

    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "",
        organPathForSort: "N/A",
      }));
      setQueryConditions((prev) => ({ ...prev, school_year_id: value }));
    }

    if (name === "1") {
      setFilters((filters) => ({
        ...filters,
        hierarchyOneId: value,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
        organPathForSort: "N/A",
      }));
    }

    if (name === "2") {
      setFilters((filters) => ({
        ...filters,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
        organPathForSort: organPath,
      }));
    }

    if (name === "3") {
      setFilters((filters) => ({
        ...filters,
        hierarchyThreeId: value,
        organPathForSort: organPath,
      }));
    }

    if (name === "group") {
      setFilters((filters) => ({
        ...filters,
        group: value,
      }));
    }
  };

  function calculateBMI(height, weight) {
    try {
      // height와 weight가 둘 다 있어야 하고 숫자여야 함
      if (!height || !weight || isNaN(height) || isNaN(weight)) {
        return ""; // 빈칸 반환
      }

      const heightInMeters = height / 100; // cm를 m로 변환

      // height와 weight가 0일 때도 빈칸 반환
      if (heightInMeters === 0 || weight === 0) {
        return "";
      }

      return (weight / (heightInMeters * heightInMeters)).toFixed(2); // BMI 계산
    } catch (error) {
      console.error("Error calculating BMI:", error);
      return ""; // 에러 발생 시 빈칸 반환
    }
  }

  //이건... 보건 지침에 따라서 계속 변하기 때문에 일단 보류하고, 직접 입력하는 것으로 하자.
  // function getBmiClass(bmiIndex) {
  //   if (isNaN(bmiIndex)) {
  //     return "";
  //   } else if (bmiIndex < 18.5) {
  //     return "저체중";
  //   } else if (bmiIndex < 23) {
  //     return "정상";
  //   } else if (bmiIndex < 25) {
  //     return "과체중";
  //   } else if (bmiIndex < 30) {
  //     return "경도비만";
  //   } else if (bmiIndex < 35) {
  //     return "중도비만";
  //   } else {
  //     return "고도비만";
  //   }
  // }

  function calculateAverageRank(values) {
    const numericValues = values.filter(
      (value) => !isNaN(value) && value !== null && value.trim() !== ""
    );
    const sum = numericValues.reduce(
      (acc, value) => acc + parseFloat(value),
      0
    );
    return (sum / numericValues.length).toFixed(1);
  }

  const handleSaveState = (e, username, inputs_id) => {
    // console.log("dataToSave", dataToSave);
    // console.log(username);
    if (!filters?.group && filters?.group?.length < 1) {
      setAlertModalState({
        visible: true,
        message: t("teacher.variousRecords.chooseRound"),
      });

      return;
    }

    let value = e.target.value;
    let name = e.target.name;

    setDataToSave((prevData) => {
      const existingItem = prevData.find((item) => item.username === username);

      if (existingItem) {
        let updatedValueObject = {
          ...existingItem.value_object,
          [name]: value,
        };
        let bmi = "";

        if (name === "height" || name === "weight") {
          const height =
            name === "height" ? value : existingItem.value_object["height"];
          const weight =
            name === "weight" ? value : existingItem.value_object["weight"];

          if (height && weight) {
            bmi = calculateBMI(height, weight);
            updatedValueObject["bmi"] = bmi; // BMI 값을 업데이트
            // updatedValueObject["bmiClass"] = getBmiClass(bmi); // BMI Class 값을 업데이트
          }
        }

        if (name.includes("Rank")) {
          const rankValues = Object.keys(updatedValueObject)
            .filter((key) => key.includes("Rank"))
            .map((key) => updatedValueObject[key]);

          if (rankValues.length > 0) {
            const physicalAverage = calculateAverageRank(rankValues);
            updatedValueObject["physicalAverage"] = physicalAverage; // Physical Average 값을 업데이트
          }
        }

        return prevData.map((item) =>
          item.username === username
            ? {
                ...item,
                value_object: updatedValueObject,
                isModified: true,
              }
            : item
        );
      } else {
        let valueObject = { [name]: value };
        return [
          ...prevData,
          {
            schoolid: schoolId,
            school_year_id: filters.schoolYearId, // 적절한 기본값을 설정합니다.
            record_list_id: inputQueryConditions?.record_list_id,
            group: filters.group, // 적절한 기본값을 설정합니다.
            username,
            value_object: valueObject,
            isModified: true,
            // 기본값을 설정합니다. 필요에 따라 다른 기본값을 설정할 수 있습니다.
          },
        ];
      }
    });
  };

  const handleSaveClick = (row) => {
    // console.log("row", row);
    // console.log("dataToSave", dataToSave);

    const trimmedDataToSave = dataToSave.map((item) => {
      const trimmedValueObject = Object.fromEntries(
        Object.entries(item.value_object).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim() : value,
        ])
      );
      return { ...item, value_object: trimmedValueObject };
    });

    const toSave = trimmedDataToSave.filter((r) => r.username === row.username);
    // console.log("toSave", toSave);

    // console.log("toSave.isModified", toSave.isModified);

    if (toSave.length < 1) {
      handleToastCenterTop(t("common.messages.notModifed"));

      return;
    }

    if (!toSave[0].isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop(t("common.messages.notModifed"));
      return;
    }

    if (!hasPermission(null, "can_create")) {
      handleToastCenterTop(t("common.messages.noSavePermission"));
      return;
    }

    setIsLoadingModal(true);
    createOrUpdateVariousStudentRecords.mutate(toSave[0], {
      onSuccess: (successMessage) => {
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, toSave[0].username]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);

        // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
        setDataToSave((currentDataSets) =>
          currentDataSets.map((dataSet) =>
            dataSet.username === toSave[0].username
              ? { ...dataSet, isModified: false }
              : dataSet
          )
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleSaveAll = () => {
    const trimmedDataToSave = dataToSave.map((item) => {
      const trimmedValueObject = Object.fromEntries(
        Object.entries(item.value_object).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim() : value,
        ])
      );
      return { ...item, value_object: trimmedValueObject };
    });

    const modifiedData = trimmedDataToSave.filter((r) => r.isModified === true);
    if (modifiedData.length < 1) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop(t("common.messages.notModifed"));
      return;
    }

    if (!hasPermission(null, "can_create")) {
      handleToastCenterTop(t("common.messages.noSavePermission"));
      return;
    }

    // 변경된 데이터 세트 처리 로직...
    // console.log("제출된 데이터 세트:", dataArray);
    setIsLoadingModal(true);
    createOrUpdateVariousStudentRecordsBulk.mutate(modifiedData, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedBulk(true);
        setTimeout(() => {
          setSavedBulk(false);
        }, 1000);

        // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
        setDataToSave((currentDataSets) =>
          currentDataSets.map((data) =>
            data.isModified === true ? { ...data, isModified: false } : data
          )
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const addGroup = () => {
    if (!hasPermission(null, "can_create")) {
      handleToastCenterTop(t("common.messages.noCreatePermission"));
      return;
    }

    const maxGroup = Math.max(...groupData.map((obj) => parseInt(obj.group)));

    // Create a new array with the added group object
    const newGroupData = [...groupData, { group: (maxGroup + 1).toString() }];

    // Update the state with the new array
    setGroupData(newGroupData);
    setFilters((filters) => ({
      ...filters,
      group: (maxGroup + 1).toString(),
    }));
    setConfirmDelete(false);
  };

  const handleDelete = () => {
    if (!filters.group) {
      handleToastCenterTop(t("teacher.variousRecords.noRoundChosen"));
      return;
    }

    const dataSet = {
      schoolid: schoolId,
      school_year_id: filters.schoolYearId,
      record_list_id: queryConditions.record_list_id,
      group: filters.group,
    };

    const findIfExist = variousStudentData.filter(
      (r) =>
        r.schoolid === dataSet.schoolid &&
        r.school_year_id.toString() === dataSet.school_year_id.toString() &&
        r.group.toString() === dataSet.group.toString()
    );

    if (findIfExist.length < 1) {
      if (Number(dataSet.group) > 1) {
        setGroupData(groupData.filter((item) => item.group !== dataSet.group));
        setFilters((filters) => ({
          ...filters,
          group: Number(dataSet.group) - 1,
        }));
      } else {
        setFilters((filters) => ({
          ...filters,
          group: "1",
        }));
      }
      setConfirmDelete(false);
      return;
    }

    const userConfirmed = window.confirm(
      t("teacher.variousRecords.deleteAlert")
    );
    if (!userConfirmed) {
      handleCancelDelete();
      return;
    }

    if (!hasPermission(null, "can_delete")) {
      handleToastCenterTop(t("common.messages.noDeletePermission"));
      return;
    }

    setIsLoadingModal(true);
    deleteVariousStudentRecords.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setConfirmDelete(false);

        if (Number(dataSet.group) > 1) {
          setFilters((filters) => ({
            ...filters,
            group: Number(dataSet.group) - 1,
          }));
        } else {
          setFilters((filters) => ({
            ...filters,
            group: "1",
          }));
        }
      },
      onError: (error) => {
        setIsLoadingModal(false);
        setConfirmDelete(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(t("common.messages.errorAlert") + error.message);
      },
    });
  };

  const handleCancelDelete = () => {
    setConfirmDelete(false);
  };

  const handleConfirmDelete = () => {
    // console.log("filters.group", filters.group);
    if (!filters.group) {
      handleToastCenterTop(t("teacher.variousRecords.noRoundChosen"));
      return;
    }
    setConfirmDelete(true);
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-1"></div>
        <div className="col">
          <DropdownSchoolYear
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYearId={filters.schoolYearId}
            allYears={false}
          />
        </div>
        <div className="col">
          <DropdownHierarchyOne
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyOneId={filters.hierarchyOneId}
          />
        </div>
        <div className="col">
          <DropdownHierarchyTwo
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyOneId={filters.hierarchyOneId}
            hierarchyTwoId={filters.hierarchyTwoId}
          />
        </div>
        <div className="col">
          <DropdownHierarchyThree
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyTwoId={filters.hierarchyTwoId}
            hierarchyThreeId={filters.hierarchyThreeId}
          />
        </div>
        <div className="col">
          <DropdownUniqueItems
            displayData={groupData}
            displayKey={"group"}
            valueKey={"group"}
            uniqueKey={"group"}
            sortKey={"group"}
            isDesc={false}
            handleDropdownChange={handleDropdownChange}
            selectedItem={filters.group}
            selectName="group"
            dropdownTitle={t("teacher.variousRecords.round")}
          />
        </div>
        <div className="col text-end pe-1 ps-0">
          <button className="btn btn-primary ps-1 pe-1" onClick={addGroup}>
            {t("teacher.variousRecords.addRound")}
          </button>
        </div>
        <div className="col text-start ps-0 pe-0 ms-0">
          {confirmDelete ? (
            <>
              <button
                className="btn btn-danger ps-1 pe-1"
                onClick={handleDelete}
              >
                {t("common.labels.confirm") || "확인"}
              </button>
              <button
                className="btn btn-secondary ps-1 pe-1"
                onClick={handleCancelDelete}
              >
                {t("common.labels.cancel") || "취소"}
              </button>
            </>
          ) : (
            <button
              className="btn btn-warning ps-1 pe-1"
              onClick={handleConfirmDelete}
            >
              {t("teacher.variousRecords.deleteRound")}
            </button>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-auto">
          <h3 className="fw-bold">
            {t("teacher.variousRecords.physicalRecord")}{" "}
            <HelpHyperLink link={"https://youtu.be/6j7ei3kl_O0"} />
          </h3>
        </div>
      </div>
      <div className="row mt-3">
        {!hasPermission(null, "can_read") ? (
          <SimpleAlertMessageBlock
            message={t("common.messages.noReadPermission")}
          />
        ) : (
          <div className="col" style={{ minWidth: "800px", overflowX: "auto" }}>
            <RegistrationVariousRecordInputTable
              organSettingInfo={filters}
              inputData={inputData}
              studentInputData={dataToSave}
              handleSaveState={handleSaveState}
              handleSaveClick={handleSaveClick}
              handleSaveAll={handleSaveAll}
              savedRows={savedRows}
              savedBulk={savedBulk}
              addfontSize=""
              addMinWidth="-5"
              hrTeachersData={hrTeachersData}
            />
          </div>
        )}
      </div>
    </>
  );
}
